import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';

import { alpha, styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import './VorteilspartnerListe.Module.css';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

import FilterSidebarVorteil from "./FilterSidebarVorteil";

function VorteilspartnerListe(props) {

  const navigate = useNavigate();

  const [vorteil, setVorteil] = useState([]);
  const [vorteilFiltered, setVorteilFiltered] = useState([]);
  const [loading, setLoading] = useState(true);


  const [filter, setFilter] = useState({});



  useEffect(() => {

    async function getVorteilspartnerListe() {
      AuthAxios.get('/vorteilspartner')
      .then(res => {
        setVorteil(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Vorteilspartner konnten nicht geladen werden."
        });
      });
    }

    if(props.activeTab == props.tab) {
      getVorteilspartnerListe();
    }

},[props.activeTab]);




  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "vorteilId",
      headerName: "ID",
      minWidth: 50,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 280,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      headerName: "Art",
      minWidth: 108,
      flex: 1,
      headerClassName: "super-app-theme--header",
      type: 'string',
      valueGetter: ({ row }) => {
        if (row.online && !row.offline) {
          return "online";
        }
        else if (!row.online && row.offline) {
          return "offline";
        }
        else if (row.online && row.offline) {
          return "online/offline";
        }
        else {
          return "-";
        }
      }
    },
    {
      field: "kategorie",
      headerName: "Kategorie",
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => (
        params.row.kategorie?.charAt(0).toUpperCase() + params.row.kategorie?.slice(1)
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "appStatus",
      headerName: "App sichtbar",
      minWidth: 110,
      flex: 1,
      valueGetter: (params) => (
        params.row.appStatus ? "Ja" : "-"
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "landingpageStatus",
      headerName: "Landingp. sichtbar",
      minWidth: 110,
      flex: 1,
      valueGetter: (params) => (
        params.row.landingpageStatus ? "Ja" : "-"
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "webseite",
      headerName: "Webseite",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 70,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "land",
      headerName: "Land",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "webseite",
      headerName: "Webseite",
      width: 155,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];

  const handleNavigation = (e) => {
    //console.log(e.id);
    props.setActiveTab(1);
    navigate("/dash/vorteilspartner/" + e.id);
  }


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>

      </GridToolbarContainer>
    );
  }

  const onFilterModelChange = () => {
    //console.log("filter changed..")
  }




    return (
      <div style={{width: "100%"}}>
        <Box
          sx={{
            height: '80vh',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

        {props.activeTab == props.tab && (
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            rows={vorteil}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            autoPageSize
            //onFilterModelChange={onFilterModelChange}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
          />
        )}
        
        </Box>

      </div>
    );
  }
  

export default VorteilspartnerListe