import React from 'react'

import Messenger from './components/Messenger';

import "./_utils.scss";
import "./_reset.scss";
import "./_grid.scss";

import "./_activeFriend.scss";
import "./_rightSide.scss";
import "./_messenger.scss";
import "./_messageSend.scss";
import "./_message.scss";
import "./_friends.scss";
import "./_friendInfo.scss";

function WhatsappChat() {
  return (
    <div className="whatsapp-chat">
        <Messenger />
    </div>
  )
}

export default WhatsappChat