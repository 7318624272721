import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { COLORS } from '../../constants';
import SelectPLZ from "../../Bausteine/SelectPLZ";
import SelectGruppen from "../../Bausteine/SelectGruppen";
import SelectLand from "../../Bausteine/SelectLand";

import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2'

function NeuerExklusivmakler(props) {

  const [aufgabenArten, setAufgabenArten] = useState([]);


  useEffect(() => {

    AuthAxios.get('/tasktype')
      .then(res => {
        setAufgabenArten(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Aufgabenarten konnten nicht abgerufen werden."
        })
      });


  }, [])


  return (
    <Container fluid >
      <Row>
        <Col xs={12} md={6} lg={3}>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                    borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Grunddaten
            </legend>

            <Form.Check
              type="checkbox"
              id="loginGesperrt"
              label="Login für Exklusivmakler gesperrt"
              name="loginGesperrt"
              checked={props.data.loginGesperrt ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            
            <Form.Label>Eindeutiger Name für ganzes Partnerteam (Pflichtfeld - keine Leerzeichen möglich - dient auch als Zuweisung für Registrierung von Kunden über App, daher nicht änderbar)</Form.Label>
            {!props.id &&
              <Form.Control
                type="text"
                name="name"
                placeholder="Eindeutiger Name"
                value={props.data.name ? props.data.name : ""}
                onChange={(e) => props.setExklusivmaklerManually("name", e.target.value.trim())}
              />
            }
            {props.id &&
              <Form.Control
                className="readOnly"
                type="text"
                name="name"
                placeholder="Eindeutiger Name"
                value={props.data.name ? props.data.name : ""}
                onChange={() => {}}
              />
            }

            <Form.Label>Vorname (Pflichtfeld)</Form.Label>
            <Form.Control
              type="text"
              name="vorname"
              placeholder="Vorname"
              value={props.data.vorname ? props.data.vorname : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>Nachname (Pflichtfeld)</Form.Label>
            <Form.Control
              type="text"
              name="nachname"
              placeholder="Nachname"
              value={props.data.nachname ? props.data.nachname : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>E-Mail für Hauptaccount Login (partner.vin1.eu) - Passwort muss über "Passwort vergessen" selbst gesetzt werden (Pflichtfeld)</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="E-Mail"
              value={props.data.email ? props.data.email : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>Geburtsdatum</Form.Label>
            <Form.Control
              type="date"
              name="geburtsdatum"
              placeholder="Geburtsdatum"
              value={props.data.geburtsdatum ? props.data.geburtsdatum.substring(0, 10) : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <hr />

            <Form.Label>Telefon/Mobil</Form.Label>
            <Form.Control
              type="text"
              name="telefon"
              placeholder="Telefon/Mobil"
              value={props.data.telefon ? props.data.telefon : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>Anschrift</Form.Label>
            <Form.Control
              type="text"
              name="anschrift"
              placeholder="Anschrift"
              value={props.data.anschrift ? props.data.anschrift : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>PLZ</Form.Label>
            <Form.Control
              type="number"
              name="plz"
              placeholder="PLZ"
              value={props.data.plz ? props.data.plz : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>Stadt</Form.Label>
            <Form.Control
              type="text"
              name="stadt"
              placeholder="Stadt"
              value={props.data.stadt ? props.data.stadt : ""}
              onChange={(e) => props.setExklusivmakler(e)}
            />

            <Form.Label>Land</Form.Label>
            <SelectLand
              setSelect={(label, value) => props.setExklusivmaklerManually(label, value)}
              selectedValue={props.data.land ? props.data.land : "Deutschland"}
            />



          </fieldset>
        </Col>


        
        <Col xs={12} md={6} lg={6}>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Bereich
            </legend>


            <p>Für welche Region ist der Partner zuständig (erster Kundenfilter)? (nur Mockup als Idee!) z.B. alle Kunden aus NRW, oder Sauerland, ... Recionen müssen definiert werden mit PLZs!</p>
            <select>
              <option value="NRW">NRW</option>
              <option value="Sauerland">Sauerland</option>
              <option value="Berlin">Berlin</option>
            </select>

            <hr />

            <p>Für welche Kunden (PLZ-Gebiet/e) ist der Makler zuständig? (harter Filter für Kunden, zählt mehr wie Aufgabenbereiche oder Arten)</p>

            <SelectPLZ 
              setSelectMulti={props.setSelectMulti}
              selectedValues={props.data.plzGebiete ? props.data.plzGebiete : ""}
            />

            <hr />

            <p>Welchen Bereich darf der Partner bearbeiten (leer lassen für keine übergeordnete Gruppe/-n)?</p>
            
            {/* Backendfeld Array: groups*/}
            <Col xs={12} sm={9} md={8}>
              <SelectGruppen 
                setSelectMulti={props.setSelectMulti}
                selectedValues={props.data.groups ? props.data.groups.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
              />
            </Col>    
            
            <Form.Check
              type="checkbox"
              id="energie"
              label="Energie"
              name="energie"
              checked={props.data.energie ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <Form.Check
              type="checkbox"
              id="versicherungen"
              label="Versicherungen"
              name="versicherungen"
              checked={props.data.versicherungen ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <Form.Check
              type="checkbox"
              id="finanzierungen"
              label="Finanzierungen"
              name="finanzierungen"
              checked={props.data.finanzierungen ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <Form.Check
              type="checkbox"
              id="immobilien"
              label="Immobilien"
              name="immobilien"
              checked={props.data.immobilien ? true : false}
              onChange={(e) => props.setCBs(e)}
            />
            
            <Form.Check
              type="checkbox"
              id="invest"
              label="Invest"
              name="invest"
              checked={props.data.invest ? true : false}
              onChange={(e) => props.setCBs(e)}
            />


            <Form.Check
              type="checkbox"
              id="schadensbearbeitung"
              label="Schadensbearbeitung"
              name="schadensbearbeitung"
              checked={props.data.schadensbearbeitung ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <hr />

            <p>Welche Aufgabenarten darf der Partner bearbeiten? (DEMO/Mockup!!)</p>
            <p>Damit auch Partner übergreifend Aufgaben bearbeiten können, für diverse Aufgabenarten die PLZ Filter überschreiben. Der Region filter würde aber bleiben.</p>
            <p>Kunden wären dann immer Sichtbar, wenn diese einmalig die Aufgabenart hinterlegt haben.</p>


            <table>
              <thead>
                <tr>
                  <th style={{paddingRight: "20px"}}>Aufgabenart</th>
                  <th>Filter</th>
                </tr>
              </thead>
              <tbody>

                  {/* Backendfeld Array: aufgabenARten*/}
              {/*aufgabenArten.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(aufgabe => */}
              {aufgabenArten.length >= 0 && aufgabenArten.sort((a, b) => {
                  if (a.art && b.art) {
                    return a.art?.label.localeCompare(b.art?.label);
                  }
                  return 0;
                }).map(aufgabe => 

                  aufgabe.art ?
                    <tr key={aufgabe._id}>
                      <td style={{paddingRight: "20px"}}>{aufgabe.art?.label ? aufgabe.art.label : ""}</td>
                      <td>
                      <select>
                        <option value="eingeschränkte PLZ">eingeschränkte PLZ</option>
                        <option value="alle PLZ Region">Kunden der PLZ Region</option>
                        <option value="alle PLZ">Gesamte Kunden</option>
                      </select>
                      </td>
                    </tr>
                    :
                    null
              )}
              </tbody>
            </table>



            <br />
            <br />
        
          </fieldset>

        </Col>



        <Col xs={12} md={6} lg={3}>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                    borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Mitarbeiter
            </legend>

              <Button variant={'primary'}>
                Neuen Mitarbeiter anlegen (Coming Soon)
              </Button>

            </fieldset>
          </Col>



      </Row>
    </Container>
  );
}

export default NeuerExklusivmakler