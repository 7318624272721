import React from "react";
import { Form } from "react-bootstrap";
import { COLORS } from "../constants";

function InputInteresse(props) {
  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Interessiert an
      </legend>
      <div key="inline-checkbox" className="mb-3">

        <Form.Check
          type="checkbox"
          id="exklusivpartner"
          label="Exklusivpartner"
          name="exklusivpartner"
          checked={props.data.exklusivpartner ? true : false}
          onChange={(e) => props.setCBs(e)}
        />

        <Form.Check
          type="checkbox"
          id="investor"
          label="Investor"
          name="investor"
          checked={props.data.investor ? true : false}
          onChange={(e) => props.setCBs(e)}
        />
        
        <Form.Check
          type="checkbox"
          id="tippgeber"
          label="Tippgeber"
          name="tippgeber"
          checked={props.data.tippgeber ? true : false}
          onChange={(e) => props.setCBs(e)}
        />

        <Form.Check
          type="checkbox"
          id="vorteilspaket"
          label="Vorteilspaket"
          name="vorteilspaket"
          checked={props.data.vorteilspaket ? true : false}
          onChange={(e) => props.setCBs(e)}
        />

        <Form.Check
          type="checkbox"
          id="rabattpartner"
          label="Rabattpartner"
          name="rabattpartner"
          checked={props.data.rabattpartner ? true : false}
          onChange={(e) => props.setCBs(e)}
        />  
     
      </div>
    </fieldset>
  );
}

export default InputInteresse;
