import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../contexts/MainContext';

import { useNavigate } from 'react-router-dom'

function Vertriebsstruktur() {

    const { user } = useContext(MainContext);
    const navigate = useNavigate();

  return (
    <div style={{padding: "50px 0px 0px 15px", margin: "0px", width: "100%"}}>
        <h1>
          Vertriebsstruktur
        </h1>
        <p className="lead">
            - Hier die Vertriebsstruktur abbilden - 
        </p>

    </div>
  )
}

export default Vertriebsstruktur