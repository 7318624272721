import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, NavItem, Tab, Tabs } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';

import { alpha, styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import './VorteilspartnerListe.Module.css';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

import FilterSidebarVorteil from "./FilterSidebarVorteil";

import OffeneVertraege from './PipelineBausteine/OffeneVertraege';
import OffeneZahlung from './PipelineBausteine/OffeneZahlung';
import Bezahlt from './PipelineBausteine/Bezahlt';
import FlyerBestellen from './PipelineBausteine/FlyerBestellen';
import LandingpageFreischalten from './PipelineBausteine/LandingpageFreischalten';
import AfterSales from './PipelineBausteine/AfterSales';

function VorteilspartnerPipeline(props) {

  /*
  const steps = [
    "Offene Verträge", // Bestätigungsbtn, Zahlungslink autom. versenden!
    "Offene Zahlung",
    "Bezahlt",
    "Flyer bestellen", // Btn Download PDFs mit QR-Code drauf! //später auch direktversand an Druckerei!
    "Landingpage freischalten",
    "After-Sales"
  ];
  */

  const [activePipelineTab, setActivePipelineTab] = useState(null);

  useEffect(() => {
    setActivePipelineTab(1);
  },[])

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  const [offeneZahlungssumme, setOffeneZahlungssumme] = useState(0);


/*
  useEffect(() => {

    async function getOffeneZahlungen() {
      AuthAxios.get('/vorteilspartner/offene-zahlungen')
      .then(res => {
        setData(res.data);
        setLoading(false);

        let offeneSumme = 0;
        res.data.forEach(zahlung => {
          offeneSumme += zahlung.gesamtsumme;
        });

        setOffeneZahlungssumme(offeneSumme);

      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Offene Zahlungen konnten nicht geladen werden."
        });
      });
    }

    if(props.activeTab === "4") {
      getOffeneZahlungen();
    }

},[props.activeTab]);
*/






    return (
      <Container fluid >
      
      <div style={{background: "white"}}>
          <Tabs
              activeKey={activePipelineTab}
              onSelect={(key) => setActivePipelineTab(parseInt(key))}
              //defaultActiveKey="mitarbeiter"
              id="fill-tab-example"
              fill
              style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
          >
              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} title={"Offene Verträge"} eventKey={1} > 
                <OffeneVertraege activeTab={activePipelineTab} tab={1} />
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} title={"Offene Zahlung"} eventKey={2} >
                <OffeneZahlung activeTab={activePipelineTab} tab={2} />     
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} title={"Bezahlt"} eventKey={3} >
                <Bezahlt activeTab={activePipelineTab} tab={3} />             
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} title={"Flyer bestellen"} eventKey={4} >
                <FlyerBestellen activeTab={activePipelineTab} tab={4} />          
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} title={"Landingpage freischalten"} eventKey={10} >            
                <LandingpageFreischalten activeTab={activePipelineTab} tab={10} />   
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} title={"After-Sales"} eventKey={5} tab={5} >    
                <AfterSales activeTab={activePipelineTab} tab={5} />           
              </Tab>
          </Tabs>
      </div>
    


  </Container>
    );
  }
  

export default VorteilspartnerPipeline