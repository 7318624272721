import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import style from "./CardSub.module.css"

function CardSub(props) {

    let navigate = useNavigate();

  return (
       
    <div onClick={()=>navigate(props.url)} className={style.cardContainerSub} style={{outline: props.selected == 1 ? "4px solid "+props.color : ""}}>
        <p className={style.cardTitleSub}>{props.title}</p>
    </div>
  )
}

export default CardSub