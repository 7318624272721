import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'

import AuthAxios from "../utils/AuthAxios";
import Swal from "sweetalert2";

function SelectRegions (props) {

    const [selectedValues, setSelectedValues] = useState([]);

    const [regionen, setRegionen] = useState([]);

    /*
    const handleValue = (selected) => {
        selected.map(s => {
            console.log({value: s, label: s});    
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));       
         })
    }
    */

    useEffect(() => {

        async function getRegionen () {
        
            try {
                const res = await AuthAxios.get('/region');

                const regionenListe = [];
                res.data.map(g => {
                    regionenListe.push({"value": "R"+g.regionId, "label": "R"+g.regionId+" | "+g.regionenname});
                });
                setRegionen(regionenListe);
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Regionen konnten nicht geladen werden."
                });
            }               
        }
        getRegionen();  

    },[]);

    useEffect(() => {

        setSelectedValues(regionen.filter(region => props.selectedValues.includes(region.value)) );

        /*
        let sel = [];
        //handleValue(props.selectedValue);
        props.selectedValue?.map(s => {

            sel.push({value: s, label: s});
            
            //setSelectedValues(prevState => ({
            //    selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
            //  }));
                
        });
        setSelectedValues(sel);
        */

    },[props.selectedValues]);
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        props.setSelectMulti(name, selection);
    };

    /*
    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex" }}>
          <div>{label}</div>
        </div>
    );
    */

    return (
        <Col xs={12} md={12}>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedValues ? selectedValues : ""}
                //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                onChange={(selection, e) => handleChange(e.name, selection)}
                options={regionen}
                noOptionsMessage={() => "Keine Regionen gefunden"}
                placeholder={<div className="select-placeholder-text">Wähle eine oder mehrere Regionen</div>}
                name="regions"
            />
        </Col>
    );
   
    
}

export default SelectRegions