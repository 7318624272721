import React, { useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

/*
  Übergabe eines Arrays mit Key-Value-Pairs
  Parent:
  const [profile, setProfile] = useState({});
  const categories = [
        {vertical: "KFZ", name: "Auto"},
        {vertical: "OTHER", name: "Sonstiges"}
    ];
<DropdownListKVP
  values={categories}
  selected={profile.kategorie ?? null}
  setSelected={(val) => setProfile({ ...profile, kategorie: val })} 
/>
*/
export default function DropdownListKVP (props) {

  //const [selectedPerson, setSelectedPerson] = useState(people[0]);

  return (

    <div className="">
      <div className="w-auto max-w-xs mb-3">
        {/*   
            <div className="flex items-center justify-center p-12">
            <div className="w-auto max-w-xs mx-auto">
        */}
        
        <Listbox
          as="div"
          className="space-y-1"
          value={props.selected}
          onChange={(val) => {
            console.log(val);
            props.setSelected(val)}
          }
        >
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700">

              </Listbox.Label>
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    <span className="block truncate">
                        {props.values?.find(item => item.value === props.selected)?.label ?? "- Label not found -"}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                >
                  <Listbox.Options
                    static
                    className="max-h-60 pl-0 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                  >
                    {props.values?.map((item) => (
                      <Listbox.Option key={item.value} value={item.value}>
                        {({ selected, active }) => (
                          <div
                            className={`${
                              active
                                ? "text-blue-500"
                                : "text-gray-900"
                            } cursor-default select-none relative py-2 pl-8 pr-4 bg-white z-10`}
                          >
                            <span
                              className={`${
                                selected ? "font-semibold" : "font-normal"
                              } block truncate`}
                            >
                              {item.label}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? "text-white" : "text-blue-600"
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}
