import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { useNavigate, Link } from "react-router-dom";

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import { CSSTransition } from 'react-transition-group'

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";

import CustomModal from "../../Bausteine/CustomModal"
import TextareaAutosize from 'react-textarea-autosize';


function FeedbackListe(props) {
  
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState({});


  /*
  useEffect(() => {
    async function getFeedback() {
      AuthAxios.get("/my-vin1/feedback")
        .then((res) => {
          props.setFeedbackListe(res.data);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Feedbacks konnten nicht geladen werden.",
          });
        });
    }
    getFeedback();
  }, []);
  */

  /*
  useEffect(() => {
    async function getFeedbackOnTabChange() {
      AuthAxios.get("/my-vin1/feedback")
        .then((res) => {
          props.setFeedbackListe(res.data);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Feedbacks konnten nicht geladen werden.",
          });
        });
    }

    if (props.activeTab === "1") {
      getFeedbackOnTabChange();
    }
  }, [props.activeTab]);
  */


  const handleModal = (e) => {
    setFeedback(props.data.find(feedbacks => feedbacks._id == e.id));
    setShowModal(true);
  };
  




  const columns = [
    {
      field: "kdnr",
      headerName: "Kdnr",
      width: 100,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "beschreibung",
      headerName: "Nachricht",
      minWidth: 400,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

        </Row>

      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: `calc(100vh - 120px)`, width: "100%" }}>
      {props.activeTab === 1 && 
        <Box
          sx={{
            height: `calc(100% - 0px)`,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#c58164",
            },
            "& .MuiDataGrid-toolbarContainer": {
              padding: "10px",
            },
            margin: "0px",
            padding: "5px 10px",
            fontWeight: "bold",
            boxShadow: 3,
          }}
        >
          <StripedDataGrid
            rows={props.data}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleModal}
            pageSize={30}
            rowsPerPageOptions={[30]}
            density={"compact"}
            //components={{ Toolbar: GridToolbar }}
              components={{ Toolbar: CustomToolbar }}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
            /*
            initialState={{
              sorting: {
                sortModel: [{ field: 'taskId', sort: 'asc' }],
              },
            }}
            */
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.dark",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              bgcolor: "background.paper",
            }}
          />
        </Box>
      }


      {showModal && 
          <CustomModal 
              title={"Verbesserung/Kritik VIN1 App"}
              visible={showModal}
              setVisible={setShowModal}
              abbrechen={"Schließen"}
          >
            <Row>
              <Col>
                <p className="mb-2">Kunde:&nbsp;
                  <b>
                    <Link to={`/dash/kunde/${feedback.kdnr}`} style={{color: '#c58164'}}>{feedback.kdnr +" "+feedback.vorname+" "+feedback.nachname}</Link>
                  </b>
                </p>

                <p className="mb-4">Erstellt:&nbsp;<b>
                  {new Date(feedback.createdAt).toLocaleString("de-DE", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric"
                            })
                  }
                  </b>
                </p>
                <TextareaAutosize
                  defaultValue={feedback.beschreibung ? feedback.beschreibung : "keine Nachricht übermittelt"}
                  style={{width: "100%", border: "none", borderRadius: "8px"}}
                  className="readOnly"
                  readOnly
                />     
              </Col>
            </Row>
    
          </CustomModal>
        }


    </div>
  );
}

export default FeedbackListe;
