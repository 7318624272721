import { useEffect } from 'react';
import io from 'socket.io-client';


let socket;

const getSocket = () => {
  if (!socket) {
    socket = io(process.env.REACT_APP_WEBSOCKET);
    //console.log(process.env.REACT_APP_WEBSOCKET);
    //socket = io(process.env.REACT_APP_WEBSOCKET);
    //socket = io('localhost:5000');
    //socket = io('https://node2.mariusschulte.com');
  }
  return socket;
}


export function useWebsocketTasksHook(activeTab, currentTab, setAufgabenListe) {

  const socket = getSocket();
  
  useEffect(() => {

    function setupSocketListeners() {

      console.log("Task setupSocketListeners!");

      socket.on('taskAdded', (newTask) => {
          setAufgabenListe(prevTasks => [...prevTasks, newTask]);
          /*
          // Count anpassen, mit Aufgaben pro Tab! globaleren state mit zahl in Aufgaben.js
          // const [meineAufgabenCount, setMeineAufgabenCount] = useState(0);
          // const [gruppenAufgabenCount, setGruppenAufgabenCount] = useState(0);
          if (newTask.type === 'meine') {
              setMeineAufgabenCount(prevCount => prevCount + 1);
          } else if (newTask.type === 'gruppen') {
              setGruppenAufgabenCount(prevCount => prevCount + 1);
          }
          */
      });



      socket.on('taskUpdated', (updatedTask) => {

          setAufgabenListe(prevTasks => 
              prevTasks.map(task => task._id === updatedTask._id ? updatedTask : task)
          );
      });

      socket.on('taskDeleted', (deletedTaskId) => {
        setAufgabenListe(prevTasks => 
            prevTasks.filter(task => task._id !== deletedTaskId)
        )
      });
    
    }

    function clearSocketListeners() {
        socket.off('taskAdded');
        socket.off('taskUpdated');
        socket.off('taskDeleted');
    }

    if(activeTab === currentTab) {

      console.log("Websocket Tab: ", activeTab, currentTab);
      setupSocketListeners();
    }

    return () => {
        clearSocketListeners();
        //socket.disconnect();
    };

  }, [activeTab, currentTab, setAufgabenListe]);

    return socket;
  

}
