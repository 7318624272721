import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Alert, Container, Row, Col, Button } from 'react-bootstrap';

import KundenOhnePartner from './KundenOhnePartner';
import KundenMitPlzPartner from './KundenMitPlzPartner';
import KundenMitStandortPartner from './KundenMitStandortPartner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";



import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';



function KundenBackoffice() {

  let { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  // Letzte Aktivitäten des Kunden
  const [letzteAktivität, setLetzteAktivität] = useState([]);

  // Tracking ob es Änderungen am Kunde gab
  const [changed, setChanged] = useState(false)
  const [reload, setReload] = useState(false)

  const [standorte, setStandorte] = useState([]);


  /*
  useEffect(() => {
  
    async function getKundeByMongoID(id) {
      AuthAxios.get('/kunde/' + id)
        .then(res => {
          setKunde(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht gefunden werden."
          });
        });
    }

    async function getKundeByKdNr(id) {
      AuthAxios.get('/kunde/'+ id)
        .then(res => {
          setKunde(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined && id.length === 24) {
      getKundeByMongoID(id);
    }

    if (id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getKundeByKdNr(id);
    }

    if(reload) {
      getKundeByMongoID(id);
      setReload(false);
    }

     
    if(reload && id !== undefined && id.length === 24) {
      getKundeByMongoID(id);
      setReload(false);
    } else if (reload && id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getKundeByKdNr(id);
      setReload(false);
    }
    else if (reload && (id == undefined || id.length != 24)) {
      newCustomer();
      setReload(false);
    }
    
  }, [id, reload]);
  */


  /*
  useEffect(() => {

    if(kunde.kdnr !== undefined) {
      AuthAxios.get('/kunde/latest-tasks/'+kunde.kdnr)
      .then(res => {
        setLetzteAktivität(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Letzte Aktivitäten konnte nicht gefunden werden."
        });
      });
    }

  },[kunde.kdnr])
  */


  useEffect(() => {

    async function getStandorte() {
      AuthAxios.get('/exklusivmakler')
        .then(res => {
          let standorteArr = [];
          res.data.map(s => {
            standorteArr.push({label: "Standort: "+ s.name +" | Name: "+ s.vorname +" "+ s.nachname, value: s._id })
          })
          setStandorte(standorteArr);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Standort-Partner konnten nicht geladen werden."
          });
        });
    }

    getStandorte();

  }, []);
  
  


  return (

    <Container fluid style={{ marginLeft: "0px", marginTop: "35px"}}>

      <div style={{ marginLeft: "0px", marginTop: "0px", marginBottom: "0px", width: "100%" }}>
        <div className="mainWork">
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(parseInt(key))}
            defaultActiveKey="stammdaten"
            id="fill-tab-example"
            fill
            style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
          >

              <Tab style={{ background: "white", position: "relative" }} eventKey="1" title="Ohne Partner">
                <KundenOhnePartner standorte={standorte} activeTab={activeTab} />
              </Tab>
            

              <Tab style={{ background: "white", position: "relative" }} eventKey="2" title="Mit PLZ Partner">
                <KundenMitPlzPartner standorte={standorte} activeTab={activeTab} />
              </Tab>
            

              <Tab style={{ background: "white", position: "relative" }} eventKey="3" title="Mit Standort Partner">
                <KundenMitStandortPartner standorte={standorte} activeTab={activeTab} />
              </Tab>
            
          </Tabs>
        </div>
      </div>


    </Container>

  );
}

export default KundenBackoffice;