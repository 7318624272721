import { defineAbility } from '@casl/ability';

export default defineAbility((can, cannot) => {

    can('manage', 'all');
    cannot('delete', 'User');


    // for any unknown subject or action CASL returns false, by default everything is forbidden (if manage and all keywords are not used).

    // manage and all are special keywords in CASL. manage represents any action and all represents any subject.

    //  can('update', 'Article', ['title', 'description'], { authorId: user.id })
    /*
    if (user.isModerator) {
        can('update', 'Article', ['published'])
    }
    */

    // User Informieren
    /*
    cannot('read', 'all', { private: true })
        .because('You are not allowed to read private information');
    */
})


// Restricting fields access
// https://casl.js.org/v5/en/guide/restricting-fields

/*
    export UserAbility (user) => defineAbility((can) => {
        can('read', 'Article');
    
        if (user.isLoggedIn) {
        can('update', 'Article', { authorId: user.id });
        can('create', 'Comment');
        can('update', 'Comment', { authorId: user.id });
        }
    });

     ----------------------

    import defineAbilityFor from './defineAbility';

    const user = { id: 1 };
    const ability = defineAbilityFor(user);
    const article = // intentionally not defined;

    ability.can('read', article);
*/