import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Button, Col, Row, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//import Länder from '../Data/LaenderListe';

import AuthAxios from "../utils/AuthAxios";
import Swal from 'sweetalert2'

function SelectGruppe (props) {

    const [gruppen, setGruppen] = useState([]);
    const [gruppenActive, setGruppenActive] = useState([]);

    const [selected, setSelected] = useState("");

    useEffect(() => {    

        async function getGruppen () {
            
            try {
                const res = await AuthAxios.get('/group');

                const activeGroupList = [];
                //let aktiveGruppen = res.data.filter((grp) => grp.status !== false);
                //aktiveGruppen.map(g => {
                res.data.map(g => {
                    activeGroupList.push({"value": "G"+g.groupId, "label": "G"+g.groupId+" | "+g.gruppenname});
                });
                setGruppenActive(activeGroupList);

                const gruppenListe = [];
                res.data.map(g => {
                    gruppenListe.push({"value": "G"+g.groupId, "label": "G"+g.groupId+" | "+g.gruppenname});
                });
                setGruppen(gruppenListe);
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Gruppen konnten nicht geladen werden."
                });
            }
        }
     
        getGruppen();

    }, []);


    useEffect(() => {
        // Warten bis Props geladen sind!
        if(props.selectedValue !== null && props.selectedValue !== undefined) {
            setGruppen([{label: props.selectedValue.label, value: props.selectedValue.value}]);
        }
    },[props.selectedValue])

    const handleRemove = () => {
        props.setSelect("gruppe", "");
    }

    const handleChange = (name, selection) => {
        props.setSelect(name, {label: selection.label, value: selection.value}); // Object
    };



    return (
        <Row>
            <Form.Label>Gruppe</Form.Label>
            <Col xs={12}>
                <ReactSelect
                   value={props.selectedValue ? gruppen.filter((f) => f.value == props.selectedValue?.value) : null}
                    onChange={(selection, e) => {
                        if (e.action === 'clear') {
                            handleRemove();
                        } else {
                            handleChange(e.name, selection);
                        }
                    }}
                    placeholder={<div className="select-placeholder-text">Suche nach Gruppe</div>}
                    options={gruppenActive}
                    noOptionsMessage={() => "Suche nach Gruppe, um Optionen zu erhalten."}
                    filterOption={createFilter({ignoreAccents: false})}
                    name="gruppe"
                    //onInputChange={handleInputChange}
                    isClearable={true}
                />
            </Col>
            {/*
            <Col xs={1} md={1}>
            {selected !== "" &&
                <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="1x"
                        title="Gruppe entfernen"
                    />
                </Button>
            }
            </Col>*/}
        </Row>
    );
      
}

export default SelectGruppe