import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import AuthAxios from '../../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import { Form, Row, Col, Button } from "react-bootstrap";

import CustomModal from '../../../Bausteine/CustomModal';

import moment from 'moment';

function OffeneVertraege(props) {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [showAblehnungModal, setShowAblehnungModal] = useState(false);
  const [selected, setSelected] = useState({}); // wegen Ablehnung Modal
  const [ablehnungGrund, setAblehnungGrund] = useState(""); // wegen Ablehnung

  //Modal Vertragsdetails
  const [selectedDetails, setSelectedDetails] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  useEffect(() => {

    async function getData () {
      AuthAxios.get('/vorteilspartner/offene-vertraege')
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Offene Verträge konnten nicht geladen werden."
        });
      });
    }

    if(props.tab == props.activeTab) {
      getData();
    }
  },[props.activeTab]);


  
  const handleVertrag = (id, newStatus) => {
    AuthAxios.post('/vorteilspartner/antrag-entscheidung', {
      id: id,
      status: newStatus,
      declinedReason: ablehnungGrund
    })
    .then(res => {
      setShowAblehnungModal(false);
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Partner erfolgreich abgelehnt."
      });
      //Remove Abgelehnten Antrag von Tabelle
      setData(prevData => prevData.filter(item => item._id !== id));
      setAblehnungGrund("");
    })
    .catch(err => {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Fehler beim Ablehnen von Partner. Später erneut versuchen."
      });
    })
  }


  const columns = [
    {
      field: "kundeId",
      headerName: "Kunde",
      minWidth: 300,
      renderCell: (params) => (
        <span>{params.row.kundeId.kdnr+" | "+params.row.kundeId.vorname+" "+params.row.kundeId.nachname}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vertriebId",
      headerName: "Von Vertrieb",
      minWidth: 180,
      flex: 1,
      valueGetter: (params) =>
        params.row.vertriebId ? "ja" : "-",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "file",
      headerName: "Vertrag",
      minWidth: 350,
      flex: 1,
      renderCell: (params) => (
        <>
        {params.row.fileUrl ?
          <>
            <Button onClick={() => window.open(params.row.fileUrl, '_blank')}>Vertrag ansehen PDF</Button>
            <Button onClick={() => {
                setShowDetailsModal(true);
                setSelectedDetails(params.row);
              }}
              style={{marginLeft: "8px"}}
            >
              Details anzeigen
            </Button>
          </>
          :
          <p>Vertrag nicht vorhanden</p>
        }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 110,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.createdAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      headerName: "Aktionen",
      minWidth: 340,
      flex: 1,
      renderCell: (params) => (
        <>
          <Button variant="success" style={{marginRight: "8px"}} onClick={() => handleVertrag(params.row._id, "approved")}>Vertrag akzeptieren</Button>
          <Button variant="danger" onClick={() => {
            setShowAblehnungModal(true);
            setSelected(params.row);
          }}>Vertrag ablehnen</Button>
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
        </Row>
      </GridToolbarContainer>
    );
  }



  return (
    
   
    <div style={{ height: `calc(100vh - 250px)`, width: "100%" }}>
      {props.activeTab == props.tab &&
        <>
          <Box
            sx={{
              height: `100%`,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c58164",
              },
              "& .MuiDataGrid-toolbarContainer": {
                padding: "10px",
              },
              margin: "0px",
              padding: "5px 10px",
              fontWeight: "bold",
              boxShadow: 3,
            }}
          >
            <StripedDataGrid
              rows={data}
              columns={columns}
              getRowId={(row) => row._id}
              onRowClick={() => {}}
              pageSize={20}
              disableRowSelectionOnClick={true}
              //loading={loading}
              rowsPerPageOptions={[20]}
              density={"compact"}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'kdnr', sort: 'desc' }],
                },
              }}
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.dark',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                bgcolor: 'background.paper'
              }}
            />
          </Box>


          {showAblehnungModal && selected &&
            <CustomModal 
               title={"Vorteilspartnerantrag ablehnen - Partner: "+ selected.copyAntrag?.name}
               visible={showAblehnungModal}
               setVisible={setShowAblehnungModal}
               handleSave={() => handleVertrag(selected._id, "declined")}
               handleSaveText={"Partner ablehnen"}
            >
      
            <p>
              Der Ablehnungsgrund ist nur sichtbar im Sales-Partner Dashboard.<br />
              Der <b>Sales-Partner</b> muss selbstständig mit seinem "Vorteilspartner" den Grund klären.
            </p>
         
             <Form.Group className="mb-3">
               <Form.Label>Ablehnungsgrund</Form.Label>
               <Form.Control as="textarea" rows={4}
                 placeholder="Der Partner entspricht nicht..."
                 name="ablehnungGrund"
                 value={ablehnungGrund ?? ""}
                 onChange={(e) => setAblehnungGrund(e.target.value)}
               />
             </Form.Group>
   
           </CustomModal>
          }

          {showDetailsModal && selectedDetails &&
            <CustomModal 
               title={"Vorteilspartnerantrag Details - "+ selectedDetails.copyAntrag?.name}
               visible={showDetailsModal}
               setVisible={setShowDetailsModal}
            >
              <div>
                {Object.keys(selectedDetails.copyAntrag).map((key) => {
                    // Define an array with keys to exclude
                    const excludeKeys = ['_id', 'unterschrift', 'ibanValid'];
   
                    // Check if the key is not in the excludeKeys array
                    if (!excludeKeys.includes(key)) {
                
                      // Check if the value is an object
                      if (typeof selectedDetails.copyAntrag[key] === 'object' && selectedDetails.copyAntrag[key] !== null) {
                          // Concatenate values of the sub-object
                          const concatenatedValues = Object.values(selectedDetails.copyAntrag[key]).join(' ');
                          return <p key={key}><strong>{key}:</strong> {concatenatedValues}</p>;
                      } else {
                          // Display normal key-value pair
                          return <p key={key}><strong>{key}:</strong>

                              &nbsp;&nbsp;
                             {selectedDetails.copyAntrag[key] ?
                                <>
                                  {selectedDetails.copyAntrag[key] === true ?
                                    "ja"
                                    :
                                    selectedDetails.copyAntrag[key]
                                  }
                                </>
                                :
                                <i>-keine Angabe-</i>
                             }
                            </p>;
                      }

                    }
                    return null;
                })}
            </div>
           </CustomModal>
          }

          {/*
            <CSSTransition in={filterIsOpen} timeout={500} classNames="right-to-left" mountOnEnter unmountOnExit>
              <FilterSidebarAufgaben data={filter} setCBs={setCBs} setFilterIsOpen={setFilterIsOpen} />
            </CSSTransition>
          */}
        </>
      }

    </div>
  )
}

export default OffeneVertraege