import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import PartnerListeAktiv from "./PartnerListeAktiv";
import PartnerListeInaktiv from "./PartnerListeInaktiv";
import Partner from "./Partner";




function PartnerAnalyse(props) {

  

  return (
    <>
      <Row className="pb-4">
        <p>Statistiken zu Dateienuploads und Bewertungen, ...</p>
      </Row>
    </>
  );

  }
  

export default PartnerAnalyse