import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Container, Row, Col, Form, Button, InputGroup, Accordion } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import { COLORS } from '../../constants';

import { MainContext } from "../../contexts/MainContext";

import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import MitarbeiterAufgabenModal from "../../Bausteine/MitarbeiterAufgabenModal";
import SelectMitarbeiter from "../../Bausteine/SelectMitarbeiter"
import SelectMitarbeiterTable from "../../Bausteine/SelectMitarbeiterTable";
import PagesTabelle from "../../Bausteine/PagesTabelle";
import TrackingCodesTabelle from "../../Bausteine/TrackingCodesTabelle";


//const AufgabenverwaltungListeMemo = React.memo(GruppenListe);
//const RegionenListeMemo = React.memo(RegionenListe);
//const VertriebsteamListeMemo = React.memo(VertriebsteamListe);



function Verwaltung(props) {

  const { user } = useContext(MainContext);

  const newAufgabenartRef = useRef("");

  // nur für Admins!
  const [newSeite, setNewSeite] = useState(""); 
  const newSeiteBeschreibungRef = useRef("");

  //Tracking Codes my.vin1.eu
  const [newTrackingSource, setNewTrackingSource] = useState("");
  const newTrackingSourceBeschreibungRef = useRef("");
  
  const activeTabMemo = useMemo(() => (props.activeTab));

  const location = useLocation();

  const [aufgabenArten, setAufgabenArten] = useState([]);
  const [pages, setPages] = useState([]);
  const [trackingCodes, setTrackingCodes] = useState([]);

  //const [regionen, setRegionen] = useState([]);
  //const [vertriebsteams, setVertriebsteams] = useState([]);


  const [selectedAufgabenart, setSelectedAufgabenart] = useState("");
  const [showMitarbeiterAufgabenModal, setShowMitarbeiterAufgabenModal] = useState(false);

  // Mitarbeiter Select Listen
  const [mitarbeiter, setMitarbeiter] = useState([]);
  const [mitarbeiterActive, setMitarbeiterActive] = useState([]);


  async function getMitarbeiter () {
    try {
        const res = await AuthAxios.get('/user/select');

        const activeMitarbeiterList = [];
        let aktiveMitarbeiter = res.data.filter((ma) => ma.loginGesperrt !== true);
        aktiveMitarbeiter.map(ma => {
            activeMitarbeiterList.push({"value": ma.mitarbeiterId, "label": ma.mitarbeiterId+" | "+ma.vorname+" "+ma.nachname});
        });
        setMitarbeiterActive(activeMitarbeiterList);

        const mitarbeiterList = [];
        res.data.map(ma => {
            mitarbeiterList.push({"value": ma.mitarbeiterId, "label": ma.mitarbeiterId+" | "+ma.vorname+" "+ma.nachname});
        });
        setMitarbeiter(mitarbeiterList);

        //return mitarbeiterList; // nur bei Combi select
    }
    catch(err) {
        console.log(err);
        Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Mitarbeiter konnten nicht geladen werden."
        });
    }
  }


  async function getTaskTypes () {
    try {
        const res = await AuthAxios.get('/tasktype');
        setAufgabenArten(res.data);
    }
    catch(err) {
        console.log(err);
        Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Aufgabenarten konnten nicht abgerufen werden."
        });
    }
  }


  async function getPages () {
    try {
        const res = await AuthAxios.get('/page');
        setPages(res.data);
    }
    catch(err) {
        console.log(err);
        Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Pages konnten nicht geladen werden."
        });
    }
  }


  async function getTrackingcodes () {
    try {
        const res = await AuthAxios.get('/tracking-code');
        setTrackingCodes(res.data);
    }
    catch(err) {
        console.log(err);
        Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Tracking-Codes konnten nicht geladen werden."
        });
    }
  }


  useEffect(() => {

    getMitarbeiter();
    getTaskTypes();
    getPages();
    getTrackingcodes();

  }, [])







  const handleSelectMitarbeiter = (field, object, tasktype) => {
    console.log(object);
  }




  const handleModalMitarbeiter = (aufgabenart) => {
    setShowMitarbeiterAufgabenModal(true);
    setSelectedAufgabenart(aufgabenart);
    //console.log(aufgabenart);
    //newGroup={newGroup} setNewGroup={setNewGroup} createNewGroup={createNewGroup}
  }


  const createNewAufgabenart = () => {

    let newAufgabenart = newAufgabenartRef.current.value;

    if (newAufgabenart === undefined || newAufgabenart === "" ) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib einen Namen und versuche es erneut."
      })
      return;
    }
    
    AuthAxios.post('/tasktype', {
      art: {value: newAufgabenart, label: newAufgabenart}
    })
      .then(res => {

        setAufgabenArten([
          {
            _id: res.data._id,
            art: res.data.art
          },
          ...aufgabenArten,
        ]);
        
        newAufgabenartRef.current.value = "";

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Die Aufgabenart wurde erfolgreich angelegt!"
        })
      })
      .catch(err => {
        if(err.code == 11000) {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Die Aufgabenart mit dem Schlüssel: "+newAufgabenart+" existiert bereits!"
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Die Aufgabenart konnte nicht angelegt werden."
          });
        }
      });

  }





  const createNewSeite = () => {

    let newSeiteBeschreibung = newSeiteBeschreibungRef.current.value;

    if (newSeite === undefined || newSeite === "" ) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib einen Seitennamen und versuche es erneut."
      })
      return;
    }

    if (newSeiteBeschreibung === undefined || newSeiteBeschreibung === "" ) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib eine Beschreibung und versuche es erneut."
      })
      return;
    }
    
    
    AuthAxios.post('/page', {
      page: "readPage"+newSeite, 
      beschreibung: newSeiteBeschreibung,
      mitarbeiterId: user._id
    })
      .then(res => {

        setPages([
          {
            _id: res.data._id,
            page: res.data.page,
            beschreibung: res.data.beschreibung,
            mitarbeiter: user.vorname+" "+user.nachname
          },
          ...pages,
        ]);
        
        setNewSeite("");
        newSeiteBeschreibungRef.current.value = "";

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Die Seite wurde erfolgreich angelegt!"
        })
      })
      .catch(err => {
        if(err.code == 11000) {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Die Seite mit dem Schlüssel: readPage"+newSeite+" existiert bereits!"
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Die Seite konnte nicht angelegt werden."
          });
        }
      });
      

  }





  const createNewTrackingSource = () => {

    let beschreibung = newTrackingSourceBeschreibungRef.current.value;

    if (newTrackingSource === undefined || newTrackingSource === "") {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib einen Namen und versuche es erneut."
      })
      return;
    }

    if (beschreibung === undefined || beschreibung === "") {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib eine Beschreibung als Quelle!"
      })
      return;
    }

    
    AuthAxios.post('/tracking-code', {
      trackingcode: newTrackingSource,
      beschreibung: beschreibung,
      mitarbeiterId: user._id
    })
      .then(res => {

        setTrackingCodes([
          {
            _id: res.data._id,
            trackingcode: res.data.trackingcode,
            beschreibung: res.data.beschreibung,
            mitarbeiter: user.vorname+" "+user.nachname
          },
          ...trackingCodes,
        ]);
      
        setNewTrackingSource("");
        newTrackingSourceBeschreibungRef.current.value = "";

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Der Trackingcode wurde erfolgreich angelegt!"
        })
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Der Trackingcode konnte nicht angelegt werden."
        });
      });
  }




/*
  const arten = [
    {value:"", label: "-"},
    {value:"Angebot erstellen", label: "Angebot erstellen"},
    {value:"Angebot Gas", label: "Angebot Gas"},
    {value:"Angebot Solar", label: "Angebot Solar"},
    {value:"Angebot Strom", label: "Angebot Strom"},
    {value:"Berater Connect", label: "Berater Connect"},
    {value:"Berater Connect Dateien", label: "Berater Connect Dateien"},
    {value:"Beratung", label: "Beratung"},
    {value:"Connect Prozess", label: "Connect Prozess"},
    {value:"E-Mail vervollständigen", label: "E-Mail vervollständigen"},
    {value:"Erstkontakt", label: "Erstkontakt"},
    {value:"Exklusivmakler", label: "Exklusivmakler"},
    {value:"Female Business", label: "Female Business"},
    {value:"GoXplore-Join Now", label: "GoXplore-Join Now"},
    {value:"Maklerprozess", label: "Maklerprozess"},
    {value:"Objektaufnahme", label: "Objektaufnahme"},
    {value:"Onboarding Neukunde", label: "Onboarding Neukunde"},
    {value:"Schadensmeldung", label: "Schadensmeldung"},
    {value:"Supportticket - GoXplore", label: "Supportticket - GoXplore"},
    {value:"Telefax", label: "Telefax"},
    {value:"Telefon", label: "Telefon"},
    {value:"Termin vereinbaren", label: "Termin vereinbaren"},
    {value:"Tippgeber/Influencer", label: "Tippgeber/Influencer"},
    {value:"Tippgeber verifiziert", label: "Tippgeber verifiziert"},
    {value:"ToDo", label: "ToDo"},
    {value:"Versicherungen-Gewerbe", label: "Versicherungen-Gewerbe"},
    {value:"Versicherungen-KFZ", label: "Versicherungen-KFZ"},
    {value:"Versicherungen-Kranken", label: "Versicherungen-Kranken"},
    {value:"Versicherungen-Leben", label: "Versicherungen-Leben"},
    {value:"Versicherungen-Pflege", label: "Versicherungen-Pflege"},
    {value:"Versicherungen-Sach", label: "Versicherungen-Sach"},
    {value:"Vorteilsparter anlegen", label: "Vorteilsparter anlegen"},
    {value:"Rückruf", label: "Rückruf"}
];
*/



  return (
    <Container fluid >
      <Row>

        <Col xs={12} md={8}>

          <Accordion defaultActiveKey="-">

            <Accordion.Item eventKey="0">
              <Accordion.Header>Aufgabenarten + Mitarbeiter Verantwortung</Accordion.Header>
              <Accordion.Body>

                <Form.Label>Neue Aufgabenart anlegen</Form.Label>
                <Col xs={12} md={8}>
                
                  <Form.Control
                    style={{minWidth: "100%"}}
                    type="text"
                    name="aufgabenart"
                    ref={newAufgabenartRef}
                  />
                  <Button
                    style={{maxWidth: "100%", marginBottom: "30px"}}
                    className="mt-2"
                    onClick={createNewAufgabenart}
                  >
                    {/*<FontAwesomeIcon
                      icon={faPlus}
                      style={{paddingRight: "10px"}}
                      size="lg"
                      className="actionIcon"
                      title="Aufgabe anlegen"
                    />*/}
                    Neue Aufgabenart anlegen
                  </Button>
                </Col>

      

                <table>
                  <thead>
                    <tr>
                      <th style={{paddingRight: "20px"}}>Aufgabenart</th>
                      <th style={{paddingRight: "20px"}}>Verantwortung</th>
                      <th style={{paddingRight: "20px"}}>Bearbeiter</th>
                      {/*<th>Aktion</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                  {/*aufgabenArten.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(aufgabe => */}
                  {aufgabenArten.length >= 0 && aufgabenArten.sort((a, b) => {
                      if (a.art && b.art) {
                        return a.art?.label.localeCompare(b.art?.label);
                      }
                      return 0;

                    }).map(aufgabe => 

                      aufgabe.art ?
                        <tr key={aufgabe._id}>
                          <td style={{paddingRight: "20px"}}>{aufgabe.art?.label ? aufgabe.art.label : ""}</td>
                          {/*
                            <td style={{paddingRight: "40px"}}>{aufgabe.verantwortung ? aufgabe.verantwortung.label : "-"}</td>
                            <td style={{paddingRight: "40px"}}>{aufgabe.bearbeiter ? aufgabe.bearbeiter.label : "-"}</td>
                          */}
                          <td style={{paddingRight: "40px"}}>
                            <SelectMitarbeiterTable
                              mitarbeiter={mitarbeiter}
                              mitarbeiterActive={mitarbeiterActive}
                              hideLabel={true}
                              setSelect={(field, object) => {handleSelectMitarbeiter(field, object, aufgabe.art)}}
                              selectedValue={{value: 1, label: "1 | Marius Schulte"}}
                            />
                          </td>
                          <td style={{paddingRight: "40px"}}>
                            <SelectMitarbeiterTable
                              mitarbeiter={mitarbeiter}
                              mitarbeiterActive={mitarbeiterActive}
                              hideLabel={true}
                              setSelect={(field, object) => {handleSelectMitarbeiter(field, object, aufgabe.art)}}
                              selectedValue={{value: 1, label: "1 | Marius Schulte"}}
                            />
                          </td>
                          
                          <td>
                            <Button onClick={() => handleModalMitarbeiter(aufgabe.art?.value)}>Speichern</Button>
                          </td>
                          
                        </tr>
                        :
                        null
                  )}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="1">
              <Accordion.Header>Seiten für Rechtevergabe (Zugriffsteuerung)</Accordion.Header>
              <Accordion.Body>
               
                  <Row>
                    <Col xs={12} md={6}>
                    <Form.Label>Neue Seite anlegen (UserRights)</Form.Label>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">readPage</InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="seite"
                          placeholder="Test"
                          value={newSeite}
                          onChange={(e) => setNewSeite(e.target.value.trim())}
                        />
                      </InputGroup>
                    </Col>

                    <Col xs={12} md={4}>  
                      <Form.Label>Kurzbeschreibung</Form.Label>
                      <Form.Control
                        style={{minWidth: "100%"}}
                        type="text"
                        name="page_beschreibung"
                        ref={newSeiteBeschreibungRef}
                      />
                    </Col>
                  </Row>

                  <Button
                    style={{maxWidth: "100%", marginBottom: "30px"}}
                    className="mt-2"
                    onClick={createNewSeite}
                  >
                    {/*<FontAwesomeIcon
                      icon={faPlus}
                      style={{paddingRight: "10px"}}
                      size="lg"
                      className="actionIcon"
                      title="Aufgabe anlegen"
                    />*/}
                    Neue Seite anlegen
                  </Button>
              

                  <PagesTabelle
                    data={pages}
                  />

              </Accordion.Body>
            </Accordion.Item>




            <Accordion.Item eventKey="2">
              <Accordion.Header>App Registrierungen - Quellen (Tracking) Registrierungen per {"https://my.vin1.eu/register?id=<Platzhalter>"}</Accordion.Header>
              <Accordion.Body>
                
                <Row>
                  <Col xs={12} md={4}>  
                    <Form.Label>Neuen Trackingcode anlegen</Form.Label>
                    <Form.Control
                        style={{minWidth: "100%"}}
                        type="text"
                        name="trackingcode"
                        value={newTrackingSource}
                        onChange={(e) => setNewTrackingSource(e.target.value.toLowerCase().trim())}
                    />
                  </Col>

                  <Col xs={12} md={6}>  
                    <Form.Label>Kurzbeschreibung (z.B. Vorteilspartnerflyer)</Form.Label>
                    <Form.Control
                      style={{minWidth: "100%"}}
                      type="text"
                      name="trackingcode_beschreibung"
                      ref={newTrackingSourceBeschreibungRef}
                    />
                  </Col>
                </Row>
                  
                <Button
                  style={{maxWidth: "100%", marginBottom: "30px"}}
                  className="mt-2"
                  onClick={createNewTrackingSource}
                >
                  {/*<FontAwesomeIcon
                    icon={faPlus}
                    style={{paddingRight: "10px"}}
                    size="lg"
                    className="actionIcon"
                    title="Aufgabe anlegen"
                  />*/}
                  Neue Quelle hinzufügen
                </Button>

                <TrackingCodesTabelle
                  data={trackingCodes}
                />
                  
              </Accordion.Body>
            </Accordion.Item>



          </Accordion>

        </Col>
      </Row>

      {showMitarbeiterAufgabenModal &&
        <MitarbeiterAufgabenModal title={"Mitarbeiter Aufgabenart zuweisen"} art={selectedAufgabenart} setChanged={props.setChanged} visible={showMitarbeiterAufgabenModal} setVisible={setShowMitarbeiterAufgabenModal} />
      }


    </Container>
  );
}

export default Verwaltung;