
import React, { useState, useContext, useEffect } from 'react';
//import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import "./Kategorien.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faCheckCircle, faCheck, faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs, Alert, Container, Row, Col, Button } from 'react-bootstrap';

import ExklusivmaklerListe from './ExklusivmaklerListe';


import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";
import NeuerExklusivmakler from './NeuerExklusivmakler';


const Exklusivmakler = () => {

  let { id } = useParams();
  //const { user } = useContext(MainContext);
  const navigate = useNavigate();

  // Tracking ob es Änderungen am Makler gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadAll, setReloadAll] = useState(false);


  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertError, setAlertError] = useState("");

  const [exklusivmakler, setExklusivmakler] = useState({
    loginStatus: false
  });
  const [exklusivmaklerListe, setExklusivmaklerListe] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);


  useEffect(() => {

    AuthAxios.get('/exklusivmakler')
    .then(res => {
      setExklusivmaklerListe(res.data);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler beim Abrufen von Exlusivmaklern",
        html: "Versuche es später erneut."
      })
    })

    setLoading(false);

  },[activeTab]);



  useEffect(() => {


    async function getExklusivmaklerListe() {
      AuthAxios.get('/exklusivmakler')
      .then(res => {
        setExklusivmaklerListe(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Exklusivmakler konnten nicht geladen werden."
        });
      });
    }


    async function getExklusivmakler(id) {
      AuthAxios.get('/exklusivmakler/' + id)
      .then(res => {
        //console.log(res.data);
        setExklusivmakler(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Exklusivmakler konnte nicht gefunden werden."
        });
      });
    }
    
    if( id !== undefined && id.length === 24) {
      getExklusivmakler(id);
      //alert("id: "+id)
    }

    if(reload) {
      getExklusivmakler(id);
      setReload(false);
    }

    
    if(reloadAll) {
      getExklusivmaklerListe();
      setReloadAll(false);
    }

    
  }, [id, reload, reloadAll]);



  
  const newExklusivmakler = () => {
    setExklusivmakler({
        loginStatus: false    
    });
    navigate("/dash/exklusivmakler"); // id entfernen ggfs.
  };


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setExklusivmakler({
      ...exklusivmakler,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setExklusivmakler({
      ...exklusivmakler,
      [name]: value
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setExklusivmakler({
      ...exklusivmakler,
      [name]: newVals
    });

  }
  

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    const value = event.target.value;
    setExklusivmakler({
      ...exklusivmakler,
      [event.target.name]: value
    });
  }

  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setExklusivmakler({
      ...exklusivmakler,
      [name]: value
    });
  }


  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setChanged(false);
        setReload(true);
      } 
    })
  }


  

  const saveExklusivmakler = () => {

    if (!exklusivmakler.vorname && !exklusivmakler.nachname && !exklusivmakler.email) {
      Swal.fire({
        icon: 'error',
        title: "Speichern",
        html: "Gib einen Vornamen, Nachnamen oder eine E-Mail Adresse ein und versuche es erneut."
      });
      return;
    }

    AuthAxios.post('/exklusivmakler', exklusivmakler)
      .then(res => {
        //console.log(res.data);
        setExklusivmakler({
          ...exklusivmakler,
          _id: res.data._id, //Damit nicht erneut ein Exklusivmakler erstellt wird
        });

        setAlertSuccess("Exklusivmakler erfolgreich gespeichert.");
      })
      .catch(err => {
        console.log(err);
        setAlertError("Fehler beim Speichern des Exklusivmaklers.");
      });

    setChanged(false);
    setReloadAll(true);
  }



  return (
 
    <Container fluid style={{ marginLeft: "0px", marginTop: "40px"}}>

        {/* so ists in Aufgaben hinterlegt 
        <Col xs={12} md={12} className="mainActions" style={{ justifyContent: "space-between" }}>
        */}
        <Container fluid >
            <Row className="mainActions">
                <Col xs={12} sm={2}>
                    <div>
                        <FontAwesomeIcon
                            icon={faPlus}
                            size="2x"
                            className="actionIcon"
                            onClick={newExklusivmakler}
                            title="Neuen Exklusivmakler anlegen"
                        />

                        {changed && activeTab == 1 &&
                            <>
                            <span style={{paddingLeft: "8px"}}>
                                <FontAwesomeIcon
                                icon={faSave}
                                size="2x"
                                className="actionIcon"
                                onClick={saveExklusivmakler}
                                title="Exklusivmakler speichern"
                                />
                            </span>
                            <span style={{paddingLeft: "8px"}}>
                                <FontAwesomeIcon
                                icon={faXmark}
                                size="2x"
                                className="actionIcon"
                                onClick={cancelEdit}
                                title="Änderungen verwerfen"
                                />
                            </span>
                            </>
                        }
                        {!changed &&
                            <FontAwesomeIcon
                            icon={faCheckCircle}
                            size="2x"
                            className="actionIcon"
                            title="Keine Änderung erfolgt"
                            />
                        }
                    </div>
                </Col>
                <Col xs={12} sm={5} className="meldungen">
                    {alertSuccess.length > 0 &&
                        <Col xs={12} md={6}>
                        <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                            {alertSuccess}
                        </Alert>
                        </Col>
                    }
                    {alertError.length > 0 &&
                        <Col xs={12} sm={6} md={6}>
                        <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                            {alertError}
                        </Alert>
                        </Col>
                    }
                </Col>
            
                {/* Hier muss noch bei Neukunden die "ObjectID" geprüft werden */}
                {id &&
                <Col xs={12} sm={5} md={3}> 
                    <Row>
                    {/* <Col style={{width: "100%"}}>
                            <Button
                            style={{width: "100%"}}
                            onClick={handleArchiv}
                            className="mt-2"
                            >
                            Archivieren
                            </Button>
                        </Col>*/}
                        <Col> 
                        <Button
                            style={{width: "100%"}}
                            className="mt-2"
                            onClick={() =>
                            navigate("/dash/aufgabe")
                            }
                        >
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                className="actionIcon"
                                title="Aufgabe anlegen"
                            />
                            Aufgabe
                        </Button>
                        </Col>
                    </Row>
                </Col>
                }

            </Row>
        </Container>

        <div className="mainWork">
            <Tabs
            activeKey={activeTab}
            onSelect={(key) => {
              if(key != parseInt(1)) {
                // Revove ID falls am Ende von URL!
                const currentUrl = window.location.href;
                const base = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
                window.history.replaceState({}, document.title, base);
              }
              setActiveTab(parseInt(key))
            }}
            //defaultActiveKey="partner"
            id="fill-tab-example"
            fill
            style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
            >
                <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Exklusivmakler anlegen">
                    <NeuerExklusivmakler data={exklusivmakler} id={id} newExklusivmakler={newExklusivmakler} setActiveTab={setActiveTab} activeTab={activeTab} setExklusivmakler={handleInputChange} setExklusivmaklerManually={handleInputChangeManually} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setChanged={setChanged}/>
                </Tab>

                <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="2" title="Übersicht Exklusivmakler">
                    <ExklusivmaklerListe data={exklusivmaklerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
                </Tab>

            </Tabs>
        </div>
      
    
    </Container>
  );
}

export default Exklusivmakler
