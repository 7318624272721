import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Form, Button, Spinner, FormControl } from 'react-bootstrap'
import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faReply } from "@fortawesome/free-solid-svg-icons";

import { COLORS } from '../../constants';



function WAProfilePreview(props) {

    const colorSelected = "#e1e9f5";

    const [isLoading, setIsLoading] = useState(false);

    const [profile, setProfile] = useState({});


    const [templateName, setTemplateName] = useState("");
    const [sprachen, setSprachen] = useState([
        {
            value: "de",
            label: "Deutsch"
        },
        {
            value: "en_US",
            label: "Englisch US"
        }
    ]);


    //https://developers.facebook.com/docs/whatsapp/cloud-api/reference/business-profiles/
    /*
    const verticals = {
        UNDEFINED: "Nicht angegeben",
        AUTO: "KFZ",
        BEAUTY: "Kosmetik und Schönheitspflege",
        APPAREL: "Bekleidung",
        EDU: "Bildung",
        ENTERTAIN: "Unterhaltung",
        EVENT_PLAN: "Veranstaltungsplanung und -service",
        FINANCE: "Finanzen und Bankwesen",
        GROCERY: "Essen und Lebensmittel",
        GOVT: "Öffentliche Dienstleistungen",
        HOTEL: "Hotel und Unterkunft",
        HEALTH: "Medizin und Gesundheit",
        NONPROFIT: "Gemeninnützige Organisation",
        PROF_SERVICES: "Professionelle Dienstleistungen",
        RETAIL: "Einzelhandel",
        TRAVEL: "Reisen und Verkehr",
        RESTAURANT: "Restaurant",
        OTHER: "Sonstiges",
        //NOT_A_BIZ: "??",
    };
    */




    return (

        <div className="pb-5">
           
            {/* Cover Image Background behind Profile Picture */}
            {/*
            <Row xs={12}>
                <div
                    style={{
                        height: "150px",
                        backgroundImage: `url("${props.data.profile_picture_url}")`,
                        backgroundSize: "cover", // Use "cover" to maintain aspect ratio and fill the div
                        backgroundPosition: "center", // Center the background image
                        borderRadius:"25px 25px 0px 0px"
                    }}
                >
                </div>

                <div
                    className="h-100 rounded-[2rem]"
                    style={{
                        padding: "0px 12px",
                        backgroundImage: `url("${props.data.profile_picture_url}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                >
                </div>
            </Row>
             <Row
                xs={12}
                className="d-flex justify-content-center align-items-center"
                style={{marginTop:"-65px"}}
            >
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "40px",
                    backgroundImage: `url("${props.data.profile_picture_url}")`,
                    backgroundSize: "cover",
                    border:"3px solid black"
                  }}
                >
                </div>
            </Row>
            */}

            {/* Round Profile Picture */}
            <Row
                xs={12}
                className="d-flex justify-content-center align-items-center"
                style={{marginTop:"16px"}}
            >
                <p className="text-white text-xs fw-bold text-center">Unternehmensinfo</p>
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "40px",
                    backgroundImage: `url("${props.data.profile_picture_url}")`,
                    backgroundSize: "cover",
                    border:"3px solid black"
                  }}
                >
                </div>
                <p className="text-white text-md fw-bold text-center mb-0">VIN1 Germany GmbH</p>
                <p className="text-gray-500 text-xs text-center">+49 1515 4206524</p>
            </Row>

            <Row
                xs={12}
                className="d-flex justify-content-center px-4 mb-3"
            >
                <Col xs={6} className="text-center">
                    <div className="bg-slate-800 rounded">
                        <FontAwesomeIcon
                            icon={faReply}
                            style={{ transform: "scaleX(-1)" }}
                            size="xs"
                            title="Btn"
                            color="#3B82F6"
                        />
                        <p className="text-xs text-blue-500 m-0 pb-1">Teilen</p>
                    </div>
                </Col>
                <Col xs={6} className="text-center">
                    <div className="bg-slate-800 rounded">
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            size="xs"
                            title="Btn"
                            color="#3B82F6"
                        />
                        <p className="text-xs text-blue-500 m-0 pb-1">Suchen</p>
                    </div>
                </Col>
            </Row>

            <Row
                xs={12}
                className="d-flex justify-content-center px-4"
            >
                {/*
                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">UNTERNEHMENSNAME</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">Marius Schulte - Loka1</span>
                </div>
                
                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">TELEFONNUMMER</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">+49 1579 2380183</span>
                </div>
                */}

                <div className="bg-slate-800 px-2 py-1 rounded">
                    <div className="divide-y divide-slate-600">
                        <p className="text-xs text-white pl-2 m-0 py-1">{props.kategorie ?? "Sonstiges"}</p>
                        <p className="text-xs text-gray-500 pl-2 m-0 py-1">Dies ist ein Unternehmenskonto.</p>
                    </div>
                </div>

                <div className="bg-slate-800 px-2 py-1 rounded mt-3 p-1">
                    <div className="divide-y divide-slate-600">
                        <p className="text-xs text-white pl-2 m-0 py-1">{props.data.about ?? "-Unternehmensbeschreibung-"}</p>
                        <p className="text-xs text-white pl-2 m-0 py-1">Aspastr. 24, 59394 Nordkirchen</p>
                        <p className="text-xs text-blue-600 pl-2 m-0 py-1">{props.data.email ?? "-E-Mail-"}</p>
                        {props.data.websites && props.data.websites.length > 0 && 
                            <p className="text-xs text-blue-600 pl-2 m-0 py-1">{props.data.websites[0]}</p>
                        }
                        {props.data.websites && props.data.websites.length > 1 && 
                            <p className="text-xs text-blue-600 pl-2 m-0 py-1">{props.data.websites[1]}</p>
                        }
                    </div>
                </div>

                {/*
                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">GESCHÄFTSZEITEN</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">{props.data.about}</span>
                </div>
                    
                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">ADRESSE</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">{props.data.about}</span>
                </div>
                          
                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">E-MAIL ADRESSE</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">{props.data.about}</span>
                </div>

                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">WEBSEITE(N)</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">{props.data.about}</span>
                </div>

                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">KATALOG</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">{props.data.about}</span>
                </div>

                <label className="text-xs text-gray-500 mt-3 ml-2 p-1">FACEBOOK & INSTAGRAM</label>
                <div className="bg-slate-700 px-2 py-1 rounded">
                    <span className="text-sm text-white pl-2">+ Icon Konto hinzufügen</span>
                </div>
            */}


            </Row>


            {/*<Row xs={12}>
                <p className="text-white">{JSON.stringify(props)}
                </p>
            </Row>
            */}
        </div>
    )
}

export default WAProfilePreview




