export default [
    {
        "mainCategory": "Energie",
        "category": [
            {
                key: "Gas",
                label: "Gas"
            },
            {
                key: "Solar",
                label: "Solar / PV"
            },
            {
                key: "Öl",
                label: "Öl"
            },
            {
                key: "Stromzertifikate",
                label: "Stromzertifikate"
            },
            {
                key: "Strom",
                label: "Strom"
            },
        ]
    },
    {
        "mainCategory": "Versicherung",
        "category": [
            {
                key: "Gesundheit",
                label: "Gesundheit"
            },
            {
                key: "Risiko",
                label: "Risiko & Vorsorge"
            },
            {
                key: "Alter",
                label: "Alter & Pflege"
            },
            {
                key: "Haft",
                label: "Haft & Recht"
            },
            {
                key: "Fahrzeuge",
                label: "Fahrzeuge"
            },
            {
                key: "Immo",
                label: "Haus & Wohnung"
            },
            {
                key: "Tier",
                label: "Tier"
            },
            {
                key: "HandyReise",
                label: "Handy & Reise"
            },
        ]
    },
    {
        "mainCategory": "Immobilien",
        "category": [
            {
                key: "Verkaufen",
                label: "Verkaufen"
            },
            {
                key: "Kaufen",
                label: "Kaufen"
            },
            {
                key: "Vermietung",
                label: "Vermietung"
            },
            {
                key: "Finanzierung",
                label: "Finanzierung"
            },
            {
                key: "Marketing",
                label: "Marketing"
            },
            {
                key: "Architekt",
                label: "Architekt"
            },
        ]
    },
    {
        "mainCategory": "Kredit",
        "category": [
            {
                key: "Mobil-Kredit",
                label: "Mobil-Kredit"
            },
            {
                key: "Baufinanzierung",
                label: "Baufinanzierung"
            },
            {
                key: "Verbraucherkredit",
                label: "Verbraucherkredit"
            },
           
        ]
    },

    {
        "mainCategory": "Invest",
        "category": [
            {
                key: "Gold",
                label: "Gold"
            },
            {
                key: "Silber",
                label: "Silber"
            },
            {
                key: "Krypto",
                label: "Krypto"
            },
            {
                key: "Immobilien",
                label: "Immobilien"
            },
            {
                key: "Asset Protection",
                label: "Asset Protection"
            },
           
        ]
    },
];

/* Backup
export default [
    {
        "mainCategory": "Energie",
        "category": ["Gas", "Solar", "Öl", "Stromzertifikate", "Strom"]
    },
    {
        "mainCategory": "Versicherungen",
        "category": ["Gesundheit", "Risiko", "Alter", "Haft", "Fahrzeuge", "Immo", "Tier", "HandyReise"]
    },
    {
        "mainCategory": "Immobilien",
        "category": ["Verkaufen", "Kaufen", "Vermietung", "Finanzierung", "Marketing", "Architekt"]
    },
    {
        "mainCategory": "Finanzierung",
        "category": ["Mobil-Kredit", "Baufinanzierung", "Verbraucherkredit"]
    },
];
*/