import React from "react";
import { COLORS } from '../constants';
import { Form } from "react-bootstrap";

function InputBestätigungen(props) {
  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Bestätigungen (nur Lesemodus)
      </legend>
      <div key="inline-checkbox" className="mb-3">

      <Form.Check
          type="checkbox"
          id="WebzugangAngelegt"
          label="VIN1 App Zugriff"
          checked={props.data.appzugriffCb ? true : false}
          disabled 
        />

        <Form.Check
          type="checkbox"
          id="investorStatusCb"
          label="ist Investor"
          checked={props.data.investorStatusCb ? true : false}
          disabled 
        />

        <Form.Check
          type="checkbox"
          id="tippgeberVerifiziert"
          label="Tippgeber verifiziert"
          checked={props.data.tippgeber_verifiziertCb ? true : false}
          disabled 
        />

        <Form.Check
          type="checkbox"
          id="maklervollmacht"
          label="Maklervollmacht akzeptiert"
          checked={props.data.maklervollmachtCb ? true : false}
          disabled 
        />

        <Form.Check
          type="checkbox"
          id="maklervollmacht_signed"
          label="Maklervollmacht unterschrieben"
          checked={props.data.maklervollmachtSignedCb ? true : false}
          disabled 
        />

        <Form.Check
          type="checkbox"
          id="connect"
          label="Bestandsübertragung"
          checked={props.data.connectCb ? true : false}
          disabled 
        />

        <Form.Label>Herkunft</Form.Label>
        <Form.Control
            type="text"
            className="readOnly"
            placeholder="händisch angelegt"
            value={
              props.data.herkunft
                ? props.data.herkunft
                : "-händisch angelegt-"
            }
            onChange={() => {}}
        />
        {/*
          <Form.Select
            className="readOnly"
            aria-label="Default select"
            name="herkunft"
            value={props.data.herkunft}
            onChange={() => {}}
          >
            <option>händisch angelegt</option>
            <option value="VIN App">VIN App</option>
            <option value="VIN Formular Strom">VIN Formular Strom</option>
            <option value="VIN Formular Gas">VIN Formular Gas</option>
            <option value="VIN Formular Solar">VIN Formular Solar</option>
            <option value="VIN Formular Gewerbe">VIN Formular Gewerbe</option>
            <option value="VIN Formular Pflege">VIN Formular Pflege</option>
            <option value="VIN Formular Kranken">VIN Formular Kranken</option>
            <option value="VIN Formular KFZ">VIN Formular KFZ</option>
            <option value="VIN Formular Leben">VIN Formular Leben</option>
            <option value="VIN Formular Sach">VIN Formular Sach</option>
          </Form.Select>

        */}
      
        
      </div>
    </fieldset>
  );
}

export default InputBestätigungen