import React from 'react'
import styles from './FilterSidebar.module.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function FilterSidebar(props) {

    return (
        <div style={{
                height: "100%",
                marginTop: "35px",
                padding: "35px 20px 0px 0px",
                position: "absolute",
                zIndex: "10",
                top: "0",
                right: "0",
                background: "#55aacf",
                paddingTop: "10px"    
        }}>
            <Row style={{marginLeft: "20px", width: "100%", display: "flex", justifyContent: "space-between"}}>
                <Col>
                    <h4 style={{margin: "0px", padding: "0px"}}>Filter</h4>
                </Col>
                <Col style={{alignItems: "center", marginRight: "20px"}}>
                    <FontAwesomeIcon
                        style={{width: "auto", cursor: "pointer", marginRight: "5px" }}
                        icon={faXmark}
                        size="lg"
                        onClick={() => props.setFilterIsOpen(false)}
                    />
                </Col>
            </Row>
            <Row style={{marginLeft: "20px", width: "275px"}}>
                
                {/*

                    <hr />
                    <Form.Label>Suche nach KdNr</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Webseite"
                        name="webseite"
                        
                    />
                */}

                <hr style={{marginTop: "15px"}} />
                <h6 style={{padding: "0px"}}>Bestätigungen</h6>
                
                <Form.Check
                    type="checkbox"
                    id="appzugriffCb"
                    label="VIN App"
                    name="appzugriffCb"
                    checked={props.data.appzugriffCb ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="maklervollmachtCb"
                    label="Maklervollmacht"
                    name="maklervollmachtCb"
                    checked={props.data.maklervollmachtCb ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />
                
                <Form.Check
                    type="checkbox"
                    id="connectCb"
                    label="Bestandsübertragung durchgef."
                    name="connectCb"
                    checked={props.data.connectCb ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="tippgeber_verifiziertCb"
                    label="Tippgeber Verifizierung"
                    name="tippgeber_verifiziertCb"
                    checked={props.data.tippgeber_verifiziertCb ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />



                <hr style={{marginTop: "15px"}} />
                <h6 style={{padding: "0px"}}>Bestätigungen - Gegenteil</h6>
                
                <Form.Check
                    type="checkbox"
                    id="appzugriffCb"
                    label="kein VIN App Acc"
                    name="appzugriffCbNot"
                    checked={props.data.appzugriffCbNot ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="maklervollmachtCb"
                    label="keine Maklervollmacht"
                    name="maklervollmachtCbNot"
                    checked={props.data.maklervollmachtCbNot ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />
                
                <Form.Check
                    type="checkbox"
                    id="connectCb"
                    label="keine Bestandsübertragung durchgef."
                    name="connectCbNot"
                    checked={props.data.connectCbNot ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="tippgeber_verifiziertCb"
                    label="keine Tippgeber Verifizierung"
                    name="tippgeber_verifiziertCbNot"
                    checked={props.data.tippgeber_verifiziertCbNot ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />




                <hr style={{marginTop: "15px"}} />
                <h6 style={{padding: "0px"}}>Interesse</h6>
                
                <Form.Check
                    type="checkbox"
                    id="exklusivpartner"
                    label="Exklusivpartner"
                    name="exklusivpartner"
                    checked={props.data.exklusivpartner ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="investor"
                    label="Investor"
                    name="investor"
                    checked={props.data.investor ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />
                
                <Form.Check
                    type="checkbox"
                    id="tippgeber"
                    label="Tippgeber"
                    name="tippgeber"
                    checked={props.data.tippgeber ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="vorteilspaket"
                    label="Vorteilspaket - goXplore"
                    name="vorteilspaket"
                    checked={props.data.vorteilspaket ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Check
                    type="checkbox"
                    id="vorteilspartner"
                    label="Vorteilspartner"
                    name="vorteilspartner"
                    checked={props.data.vorteilspartner ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

            </Row>
        </div>
    )
}

export default FilterSidebar