
import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import "./Kategorien.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import { faPlus, faSave, faCheckCircle, faCheck, faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs, Alert, Row, Col, Container, Button } from 'react-bootstrap';

import PartnerListe from './PartnerListe';
import PartnerClearingOffen from './PartnerClearingOffen';
import PartnerClearingAbgeschl from './PartnerClearingAbgeschl';
import PartnerAnalyse from './PartnerAnalyse';


import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";
import NeuerPartner from './NeuerPartner';
import ClearingAnalyse from './ClearingAnalyse';
import Bewertungen from './Bewertungen';
import Gewinnspiele from './Gewinnspiele';


const Partner = () => {

  let { id } = useParams();
  //const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [b2bPartner, setB2BPartner] = useState({

    loginStatus: true,
    appStatus: true,

    kunde: null,
    //partner_name: "Testfirma",

    telefon: "025966148883",
    email_buchhaltung: "info@mariusschulte.com",

    email_benachrichtigung_status: true,
    email_benachrichtigung: "marius.schulte@web.de",

    anschrift: "Aspastr 24",
    plz: "59394",
    stadt: "Nordkirchen",

    
    lizenzen: 3,
    lizenzLaufzeit: "2025-03-25",

    chatLizenz_status: true,
    chatLizenzAILaufzeit: "2025-03-25",

    email_login: "marius.schulte@web.de",
    vorname: "Max",
    nachname: "Schulte",

    //lizenzLaufzeit: new Date().toISOString().substring(0, 10),
    //partnerIMG: => wird als FormData in der Funktion "save" angehändg. "https://img.freepik.com/vektoren-kostenlos/goldener-vogel-logo-design_1195-336.jpg?w=1380&t=st=1683283486~exp=1683284086~hmac=3d4ac841fa369f74c09b9d0e8775501204f87834b746d22feb4e259237cae3ed"
    kategorien: ["Test 1", "Test 2", "Test 3"],
    dateienSenden: true,
    dateienSendenKategorien: ["Kategorie 1", "Kategorie 2"]
  });

  //const [cropImage, setCropImage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [newImageURL, setNewImageURL] = useState(null);

  const [b2bPartnerListe, setB2BPartnerListe] = useState([]);

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertError, setAlertError] = useState("");

  // Tracking ob es Änderungen am Makler gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadAll, setReloadAll] = useState(false);


  useEffect(() => {

    AuthAxios.get('/partner')
    .then(res => {
      setB2BPartnerListe(res.data);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler beim Abrufen von Partnern",
        html: "Versuche es später erneut."
      })
    })

    setLoading(false);
    setNewImage(null);
    setNewImageURL(null);

  },[activeTab]);



  useEffect(() => {

    async function getPartner(id) {
      AuthAxios.get('/partner/' + id)
      .then(res => {
        //console.log(res.data);
        setB2BPartner(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Partner konnte nicht gefunden werden."
        });
      });
    }
    
    if( id !== undefined && id.length === 24) {
      getPartner(id);
      //alert("id: "+id)
    }
    
  }, [id]);
  

  
  /*
  useEffect(() => {
    async function getpartner() {
      AuthAxios.get('/partner')
      .then(res => {
        //console.log(res.data);
        setPartner(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "partner konnten nicht geladen werden."
        });
      });
    }
    getpartner();

  },[]);
  */


  
  const newB2BPartner = () => {
    setB2BPartner({
        loginStatus: false    
    });
    navigate("/dash/partner"); // id entfernen ggfs.
  };


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setB2BPartner({
      ...b2bPartner,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setB2BPartner({
      ...b2bPartner,
      [name]: value
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setB2BPartner({
      ...b2bPartner,
      [name]: newVals
    });

  }
  

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    const value = event.target.value;
    setB2BPartner({
      ...b2bPartner,
      [event.target.name]: value
    });
  }

  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setB2BPartner({
      ...b2bPartner,
      [name]: value
    });
  }


  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setChanged(false);
        setReload(true);
      } 
    })
  }


  const handleB2BPartnerIMG = (files) => {
    
    // Reset wenn löschen
    if(!files)
    {
      setNewImage(null);
      setNewImageURL(null);
    }
    else
    {
      setNewImage(files[0]);
      // Bilder können nicht direkt so dargetellt werden, daher umwandeln!
      setNewImageURL(URL.createObjectURL(files[0]));
    }
  }


  const saveB2BPartner = () => {

    
    if (!b2bPartner.name && !b2bPartner.telefon && !b2bPartner.email_buchhaltung && !b2bPartner.anschrift && !b2bPartner.plz && !b2bPartner.stadt &&
        (!b2bPartner.image || !b2bPartner.newImage) && !b2bPartner.lizenz && !b2bPartner.lizenzLaufzeit) {
      Swal.fire({
        icon: 'error',
        title: "Pflichtfelder",
        html: "Alle Grunddaten, Firmenlogo (App-Daten)"
      });
      return;
    }
    

    let formData = new FormData();
    
    
    //if (cropImage !== "") {
    //  formData.append('file', cropImage);
    //}

    
    if (newImage !== "") {
      formData.append('file', newImage);
    }

    formData.append('b2bPartner', JSON.stringify(b2bPartner)); // nur strings, kein JSON...

    const headerConfig = { headers: { 'content-type': 'multipart/form-data' } };

    // Übergeben der Daten als formData!



    AuthAxios.post('/partner', formData, headerConfig)
      .then(res => {
        //console.log(res.data);
        setB2BPartner({
          ...b2bPartner,
          _id: res.data._id, //Damit nicht erneut ein Partner erstellt wird
        });

        setAlertSuccess("B2B Partner erfolgreich gespeichert.");
        setChanged(false);
      })
      .catch(err => {
        console.log(err);
        setAlertError("Fehler beim Speichern des B2B Partners.");
      });
   
    setReloadAll(true);
  }




  return (
    <Container fluid style={{ marginLeft: "0px", marginTop: "40px"}}>

        {/* so ists in Aufgaben hinterlegt 
        <Col xs={12} md={12} className="mainActions" style={{ justifyContent: "space-between" }}>
        */}
        <Container fluid >
            <Row className="mainActions">
                <Col xs={12} sm={2}>
                    <div>
                        <FontAwesomeIcon
                            icon={faPlus}
                            size="2x"
                            className="actionIcon"
                            onClick={newB2BPartner}
                            title="Neuen B2B Partner anlegen"
                        />

                        {changed && activeTab == 1 &&
                            <>
                            <span style={{paddingLeft: "8px"}}>
                                <FontAwesomeIcon
                                icon={faSave}
                                size="2x"
                                className="actionIcon"
                                onClick={saveB2BPartner}
                                title="B2B Partmer speichern"
                                />
                            </span>
                            <span style={{paddingLeft: "8px"}}>
                                <FontAwesomeIcon
                                icon={faXmark}
                                size="2x"
                                className="actionIcon"
                                onClick={cancelEdit}
                                title="Änderungen verwerfen"
                                />
                            </span>
                            </>
                        }
                        {!changed &&
                            <FontAwesomeIcon
                            icon={faCheckCircle}
                            size="2x"
                            className="actionIcon"
                            title="Keine Änderung erfolgt"
                            />
                        }
                    </div>
                </Col>
                <Col xs={12} sm={5} className="meldungen">
                    {alertSuccess.length > 0 &&
                        <Col xs={12} md={6}>
                        <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                            {alertSuccess}
                        </Alert>
                        </Col>
                    }
                    {alertError.length > 0 &&
                        <Col xs={12} sm={6} md={6}>
                        <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                            {alertError}
                        </Alert>
                        </Col>
                    }
                </Col>
            
                {/* Hier muss noch bei Neukunden die "ObjectID" geprüft werden */}
                {id &&
                <Col xs={12} sm={5} md={3} style={{display: "flex", justifyContent: "flex-end"}}> 
                    <Row>
                    {/* <Col style={{width: "100%"}}>
                            <Button
                            style={{width: "100%"}}
                            onClick={handleArchiv}
                            className="mt-2"
                            >
                            Archivieren
                            </Button>
                        </Col>*/}
                        <Col> 
                        <Button
                            style={{width: "auto"}}
                            className="mt-2"
                            onClick={() =>
                            navigate("/dash/aufgabe")
                            }
                        >
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                className="actionIcon"
                                title="Aufgabe anlegen"
                            />
                            Aufgabe
                        </Button>
                        </Col>
                    </Row>
                </Col>
                }

            </Row>
        </Container>

        <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="partner"
          id="fill-tab-example"
          fill
          style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
        >
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="B2B Partner anlegen">
            {/* cropImage={cropImage} setCropImage={setCropImage} */}
            <NeuerPartner data={b2bPartner} id={id} newImage={newImageURL} newB2BPartner={newB2BPartner} setActiveTab={setActiveTab} activeTab={activeTab} setB2BPartner={handleInputChange} setB2BPartnerManually={handleInputChangeManually} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setChanged={setChanged} handleB2BPartnerIMG={handleB2BPartnerIMG} />
          </Tab>

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="2" title="Übersicht Partner">
            <PartnerListe data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="3" title="Partner Analyse">
            <PartnerAnalyse data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>


          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="4" title="Clearing offen">
            <PartnerClearingOffen data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="5" title="Clearing abgeschl.">
            <PartnerClearingAbgeschl data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="6" title="Clearing Analyse">
            <ClearingAnalyse data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab> 

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="7" title="Bewertungen">
            <Bewertungen data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="8" title="Gewinnspiele">
            <Gewinnspiele data={b2bPartnerListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

        </Tabs>
      </div>
      
    
  </Container>
  );
}

export default Partner
