import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import moment from 'moment';

import FilterSidebarAufgaben from "./FilterSidebarAufgaben";
import { CSSTransition } from 'react-transition-group'

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";
import { useWebsocketTasksHook } from "./useWebsocketTasksHook";

function OhneGruppenAufgabenListe(props) {

  const navigate = useNavigate();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterSidebarActive, setFilterSidebarActive] = useState(false);

  const [kunden, setKunden] = useState([]);
  const [kundenFiltered, setKundenFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRows, setSelectedRows] = useState(["asfasf"]);

  let selRows = [];

  const handleCheckboxSelection = (selectedArray) => {
    // Array of Row-IDs
    //setSelectedRows(selectedArray);
    //console.log(selectedArray);
    selRows = selectedArray;
    // Einzel Aktion in Kunde selbst, in Liste nur für Admins!
  }

  /*
  const handleArchive = async () => {

    setKunden(kunden => {
      return kunden.filter(kd => !selRows.includes(kd._id));
    });

    setKundenFiltered(kundenFiltered => {
      return kundenFiltered.filter(kd => !selRows.includes(kd._id));
    });
  }
  */


  const [filter, setFilter] = useState({});
  /*
      appzugriffCb: false,
    maklervollmachtCb: false,
    connectCb: false,
    tippgeber_verifiziertCb: false,
    exklusivpartner: false,
    investor: false,
    tippgeber: false,
    vorteilspaket: false,
    rabattpartner: false
    */

  const setCBs = (e) => {

    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
  }

  /*
  const filters = {
    investor: [true],
    //anrede: ["Frau"]
  };
  */


  useWebsocketTasksHook(props.activeTab, props.tab, props.setAufgabenListe);


  useEffect(() => {

    setKundenFiltered(kunden.filter(elem => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;

      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });

      if (filterActive) {

        setFilterSidebarActive(true);

        // Filterstatus vormerken, falls die Schnittmenge mit anderen Filtern nicht gegeben ist, darf
        // Eintrag nicht zum Ergebnis hinzugefügt werden
        let filterStatus = false;

        for (var key in filter) {

          // Filter nur Anwenden, wenn aktiv
          if (filter[key] === true) {
            if (elem[key] === undefined || elem[key] != filter[key]) {
              // Kunde nicht zurückgeben, falls einer der Filter nicht matcht
              return false;
            }
            else {
              // gefilterten Kunden zurückgeben
              filterStatus = true;
            }
          }
        }
        // Nachdem alle Filter geprüft wurden, kann über den Eintrag entschieden werden
        return filterStatus;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));

  }, [filter])




  async function getAufgaben() {
    AuthAxios.get("/task/ohne-gruppe-aufgaben")
      .then((res) => {
        props.setAufgabenListe(res.data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Aufgaben konnten nicht geladen werden.",
        });
      });
  }



  useEffect(() => {
    getAufgaben();
  }, []);



  useEffect(() => {
    if (props.activeTab == props.tab) {

      getAufgaben();
    }
  }, [props.activeTab]);




  const handleNavigation = (e) => {
    //console.log(e.id);
    props.setActiveTab(1);
    navigate("/dash/aufgabe/" + e.id);
  };




  const columns = [
    {
      field: "taskId",
      headerName: "Nr.",
      width: 60,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "kunde",
      headerName: "Verkn. Adresse",
      minWidth: 180,
      flex: 1,
      valueGetter: (params) =>
        params.row.kunde?.label ? params.row.kunde.label : "-",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "art",
      headerName: "Art",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
        {
          field: "project",
          headerName: "Proj.",
          width: 80,
          headerClassName: "super-app-theme--header",
        },
    */
    {
      field: "betreff",
      headerName: "Betreff",
      minWidth: 280,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
      {
        field: "verantwortung",
        headerName: "Verantw.",
        width: 120,
        headerClassName: "super-app-theme--header",
      },
    */
    {
      field: "mitarbeiter",
      headerName: "Bearbeiter",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row.mitarbeiter !== undefined
          ? params.row.mitarbeiter.label
          : "Backoffice",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deadline",
      headerName: "Deadline",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.deadline).format("dd, DD.MM.YY")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "prio",
      headerName: "Prio.",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 110,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.createdAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 110,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.updatedAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },

    /*
    {
      field: "webseite",
      headerName: "Webseite",
      width: 155,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

          <Col xs={12} md={2}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={() => setFilterIsOpen(!filterIsOpen)}
          >
            <FontAwesomeIcon
              style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
              icon={faFilter}
              size="lg"
            />
            {filterSidebarActive && <span>Filter (aktiv)</span>}
            {!filterSidebarActive && <span>Filter</span>}
          </Col>

        </Row>

      </GridToolbarContainer>
    );
  }



  return (
    
   
    <div style={{ height: `calc(100vh - 200px)`, width: "100%" }}>
      {props.activeTab == props.tab &&
        <>
          <Box
            sx={{
              height: `100%`,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c58164",
              },
              "& .MuiDataGrid-toolbarContainer": {
                padding: "10px",
              },
              margin: "0px",
              padding: "5px 10px",
              fontWeight: "bold",
              boxShadow: 3,
            }}
          >
            <StripedDataGrid
              rows={props.data}
              columns={columns}
              getRowId={(row) => row._id}
              onRowClick={handleNavigation}
              pageSize={30}
              rowsPerPageOptions={[30]}
              density={"compact"}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'kdnr', sort: 'desc' }],
                },
              }}
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.dark',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                bgcolor: 'background.paper'
              }}
            />
          </Box>

          <CSSTransition in={filterIsOpen} timeout={500} classNames="right-to-left" mountOnEnter unmountOnExit>
            <FilterSidebarAufgaben data={filter} setCBs={setCBs} setFilterIsOpen={setFilterIsOpen} />
          </CSSTransition>
        </>
      }

    </div>
      
  );
}

export default OhneGruppenAufgabenListe;
