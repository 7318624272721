import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';

import StripedDataGrid from "../../Bausteine/StripedDataGrid";

import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import moment from 'moment';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

import FilterSidebar from "./FilterSidebar";
import { CSSTransition } from 'react-transition-group'

function KundenListe() {

  const navigate = useNavigate();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterSidebarActive, setFilterSidebarActive] = useState(false);

  const [kunden, setKunden] = useState([]);
  const [kundenFiltered, setKundenFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRows, setSelectedRows] = useState(["asfasf"]);

  let selRows = [];

  const handleCheckboxSelection = (selectedArray) => {
    // Array of Row-IDs
    //setSelectedRows(selectedArray);
    //console.log(selectedArray);
    selRows = selectedArray;
    // Einzel Aktion in Kunde selbst, in Liste nur für Admins!
  }

  /*
  const handleArchive = async () => {

    setKunden(kunden => {
      return kunden.filter(kd => !selRows.includes(kd._id));
    });

    setKundenFiltered(kundenFiltered => {
      return kundenFiltered.filter(kd => !selRows.includes(kd._id));
    });
  }
  */


  const [filter, setFilter] = useState({});
  /*
      appzugriffCb: false,
    maklervollmachtCb: false,
    connectCb: false,
    tippgeber_verifiziertCb: false,
    exklusivpartner: false,
    investor: false,
    tippgeber: false,
    vorteilspaket: false,
    vorteilspartner: false
    */

  const setCBs = (e) => {

    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
  }

  /*
  const filters = {
    investor: [true],
    //anrede: ["Frau"]
  };
  */

  useEffect(() => {

    async function getKunden() {
      AuthAxios.get('/kunde')
        .then(res => {
          setKunden(res.data);
          setKundenFiltered(res.data);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunden konnten nicht geladen werden."
          });
        });
    }
    getKunden();

  }, []);


  const handleNavigation = (e) => {
    //console.log(e.id);
    navigate("/dash/kunde/" + e.id);
  }



  // Filter ohne "Nicht" => funktionsfähig
  /*
  useEffect(() => {

    setKundenFiltered(kunden.filter(elem => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;

      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });

      if (filterActive) {

        setFilterSidebarActive(true);

        // Filterstatus vormerken, falls die Schnittmenge mit anderen Filtern nicht gegeben ist, darf
        // Eintrag nicht zum Ergebnis hinzugefügt werden
        let filterStatus = false;

        for (var key in filter) {

          // Filter nur Anwenden, wenn aktiv
          if (filter[key] === true) {
            if (elem[key] === undefined || elem[key] != filter[key]) {
              // Kunde nicht zurückgeben, falls einer der Filter nicht matcht
              return false;
            }
            else {
              // gefilterten Kunden zurückgeben
              filterStatus = true;
            }
          }
        }
        // Nachdem alle Filter geprüft wurden, kann über den Eintrag entschieden werden
        return filterStatus;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));

  }, [filter])
  */




  
  useEffect(() => {

    setKundenFiltered(kunden.filter(kunde => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;
      
      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });



      if (filterActive) {

        setFilterSidebarActive(true);

            // Filterung
            const appzugriffCb = filter.appzugriffCb ? kunde.appzugriffCb : true;
            const maklervollmachtCb = filter.maklervollmachtCb ? kunde.maklervollmachtCb : true;
            const connectCb = filter.connectCb ? kunde.connectCb : true;
            const tippgeber_verifiziertCb = filter.tippgeber_verifiziertCb ? kunde.tippgeber_verifiziertCb : true;

            // negative Filterung
            const appzugriffCbNot = filter.appzugriffCbNot ? !kunde.appzugriffCb : true;
            const maklervollmachtCbNot = filter.maklervollmachtCbNot ? !kunde.maklervollmachtCb : true;
            const connectCbNot = filter.connectCbNot ? !kunde.connectCb : true;
            const tippgeber_verifiziertCbNot = filter.tippgeber_verifiziertCbNot ? !kunde.tippgeber_verifiziertCb : true;

            // Interessen
            const exklusivpartner = filter.exklusivpartner ? kunde.exklusivpartner : true;
            const investor = filter.investor ? kunde.investor : true;
            const tippgeber = filter.tippgeber ? kunde.tippgeber : true;
            const vorteilspaket = filter.vorteilspaket ? kunde.vorteilspaket : true;
            const vorteilspartner = filter.vorteilspartner ? kunde.vorteilspartner : true;


            return appzugriffCb && maklervollmachtCb && connectCb && tippgeber_verifiziertCb && appzugriffCbNot && maklervollmachtCbNot && connectCbNot && tippgeber_verifiziertCbNot && exklusivpartner && investor && tippgeber && vorteilspaket && vorteilspartner;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));


  },[filter])




  const columns = [
    {
      field: "kdnr",
      headerName: "KdNr",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anrede",
      headerName: "Anrede",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "firma",
      headerName: "Firma",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "geburtsdatum",
      headerName: "Geburtsd.",
      type: "number",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <>
          {(params.row.geburtsdatum && params.row.geburtsdatum != "0000-00-00" ) && <span>{new Date(params.row.geburtsdatum).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
          {(!params.row.geburtsdatum || params.row.geburtsdatum == "0000-00-00") && <span>-</span>}
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 70,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "land",
      headerName: "Land",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "telefon",
      headerName: "Telefon",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.telefon}`}>{params.row.telefon}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobil",
      headerName: "Mobil",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.mobil}`}>{params.row.mobil}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.createdAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.updatedAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "webseite",
      headerName: "Webseite",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];

  const onFilterModelChange = () => {
    //console.log("filter changed..")
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

          <Col xs={12} md={2}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={() => setFilterIsOpen(!filterIsOpen)}
          >
            <FontAwesomeIcon
              style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
              icon={faFilter}
              size="lg"
            />
            {filterSidebarActive && <span>Filter (aktiv)</span>}
            {!filterSidebarActive && <span>Filter</span>}
          </Col>

        </Row>

      </GridToolbarContainer>
    );
  }


  const CustomFooter = () => {
    return (
      <GridFooterContainer>
      {/* Add what you want here */}
      <GridFooter sx={{
          border: 'none', // To delete double border.
          alignItems: 'flex-start'
        }} />
    </GridFooterContainer>
    );
  }




  return (
    <>
      <div style={{width: "100%" }}>
        <Box
          sx={{
            height: `calc(100vh - 35px)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "35px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >
          {/* Server side pagination wenn zu viele Daten - https://mui.com/x/react-data-grid/pagination/ */}
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            rows={kundenFiltered}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            autoPageSize
            //onFilterModelChange={onFilterModelChange}
            loading={loading}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'kdnr', sort: 'desc' }],
              },
            }}
            sx={{/*
              boxShadow: 2,
              border: 2,
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
            */}}
          />

        </Box>

        <CSSTransition in={filterIsOpen} timeout={500} classNames="right-to-left" mountOnEnter unmountOnExit>
          <FilterSidebar data={filter} setCBs={setCBs} setFilterIsOpen={setFilterIsOpen} />
        </CSSTransition>


      </div>

    </>
  );
}


export default KundenListe