import React, { useEffect, useState, useRef, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom';


//import SelectVerantwortung from "../../Bausteine/SelectVerantwortung";
//import SelectMitarbeiter from "../../Bausteine/SelectMitarbeiter";
//import SelectKundeObj from "../../Bausteine/SelectKundeObj";
//import SelectLand from "../../Bausteine/SelectLand";

import { COLORS } from '../../constants';

//import KategorieData from './KategorieData';

import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';

//import { Editor } from "react-draft-wysiwyg";
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import GruppenListe from "./GruppenListe";
import RegionenListe from "./RegionenListe";
import VertriebsteamListe from "./VertriebsteamListe";

import GroupModal from "../../Bausteine/GroupModal";
import RegionModal from "../../Bausteine/RegionModal";
import VertriebsteamModal from "../../Bausteine/VertriebsteamModal";


const GruppenListeMemo = React.memo(GruppenListe);
const RegionenListeMemo = React.memo(RegionenListe);
const VertriebsteamListeMemo = React.memo(VertriebsteamListe);



function Gruppenverwaltung(props) {

  const activeTabMemo = useMemo(() => (props.activeTab));

  const location = useLocation();

  const [gruppen, setGruppen] = useState([]);
  const [regionen, setRegionen] = useState([]);
  const [vertriebsteams, setVertriebsteams] = useState([]);

  const [newGroup, setNewGroup] = useState({});
  const [newRegion, setNewRegion] = useState({});
  const [newVertriebsteam, setNewVertriebsteam] = useState({});


  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showCreateRegion, setShowCreateRegion] = useState(false);
  const [showCreateVertriebsteam, setShowCreateVertriebsteam] = useState(false);


  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showEditRegion, setShowEditRegion] = useState(false);
  const [showEditVertriebsteam, setShowEditVertriebsteam] = useState(false);

  

  useEffect(() => {

    AuthAxios.get('/group')
      .then(res => {
        setGruppen(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Gruppen konnten nicht abgerufen werden."
        })
      });


    AuthAxios.get('/region')
      .then(res => {
        setRegionen(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Regionen konnten nicht abgerufen werden."
        })
      });

    AuthAxios.get('/vertriebsteam')
      .then(res => {
        setVertriebsteams(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Vertriebsteams konnten nicht abgerufen werden."
        })
      });

  }, [])



  /*
  const handleInputChangeGroup = (e) => {
    setNewGroup({
      ...newGroup,
      [e.target.name]: e.target.value
    })
  }

  const handleInputChangeRegion = (e) => {
    setNewRegion({
      ...newRegion,
      [e.target.name]: e.target.value
    })
  }

  const handleInputChangeVertriebsteam = (e) => {
    setNewVertriebsteam({
      ...newVertriebsteam,
      [e.target.name]: e.target.value
    })
  }
  
  */




  const createNewGroup = () => {

    if (newGroup.groupId === undefined || newGroup.gruppenname === "" || newGroup.gruppenname === undefined) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib eine Zahl als Gruppen ID und einen Namen für die Gruppe und versuche es erneut."
      })
      return;
    }


    AuthAxios.post('/group', newGroup)
      .then(res => {
        setGruppen([
          ...gruppen,
          {
            _id: res.data._id,
            groupId: res.data.groupId,
            gruppenname: res.data.gruppenname,
            beschreibung: res.data.beschreibung,
            createdAt: res.data.createdAt
          }
        ]);
        setNewGroup({});

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Die Gruppe wurde erfolgreich angelegt!"
        })


      })
      .catch(err => {

        if (err.response) {

          if ("groupId" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Gruppe konnte nicht angelegt werden. Die ID ist bereits vergeben."
            });
          }

          if ("gruppenname" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Gruppe konnte nicht angelegt werden. Der Gruppenname ist bereits vergeben."
            });
          }
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Der Service steht momentan nicht zur Verfügung."
          });
        }

      });

  }


  const createNewRegion = () => {

    if (newRegion.regionId === undefined || newRegion.regionenname === "" || newRegion.regionenname === undefined) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib eine Zahl als Region ID und einen Namen für die Region und versuche es erneut."
      })
      return;
    }


    AuthAxios.post('/region', newRegion)
      .then(res => {
        setRegionen([
          ...regionen,
          {
            _id: res.data._id,
            regionId: res.data.regionId,
            regionenname: res.data.regionenname,
            beschreibung: res.data.beschreibung,
            createdAt: res.data.createdAt
          }
        ]);
        setNewRegion({});

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Die Region wurde erfolgreich angelegt!"
        })


      })
      .catch(err => {

        if (err.response) {

          if ("regionId" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Region konnte nicht angelegt werden. Die ID ist bereits vergeben."
            });
          }

          if ("regionenname" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Region konnte nicht angelegt werden. Der Regionenname ist bereits vergeben."
            });
          }
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Der Service steht momentan nicht zur Verfügung."
          });
        }
      });
  }



  const createNewVertriebsteam = () => {

    

    if (newVertriebsteam.vertriebsteamId === undefined || newVertriebsteam.vertriebsteamname === "" || newVertriebsteam.vertriebsteamname === undefined) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Vergib eine Zahl als Vertriebsteam ID und einen Namen für das Vertriebsteam und versuche es erneut."
      })
      return;
    }


    AuthAxios.post('/vertriebsteam', newVertriebsteam)
      .then(res => {
        setVertriebsteams([
          ...vertriebsteams,
          {
            _id: res.data._id,
            vertriebsteamId: res.data.vertriebsteamId,
            vertriebsteamname: res.data.vertriebsteamname,
            beschreibung: res.data.beschreibung,
            createdAt: res.data.createdAt
          }
        ]);
        setNewVertriebsteam({});

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Das Vertriebsteam wurde erfolgreich angelegt!"
        })


      })
      .catch(err => {

        if (err.response) {

          if ("groupId" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Gruppe konnte nicht angelegt werden. Die ID ist bereits vergeben."
            });
          }

          if ("gruppenname" in err.response.data.error.keyValue) {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              html: "Die Gruppe konnte nicht angelegt werden. Der Gruppenname ist bereits vergeben."
            });
          }
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Der Service steht momentan nicht zur Verfügung."
          });
        }

      });

  }





  return (
    <Container fluid >

      <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <Button onClick={() => setShowCreateGroup(true)}>Neue Gruppe anlegen</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <GruppenListeMemo data={gruppen} activeTab={activeTabMemo} setShowEdit={setShowEditGroup} />
        </Col>
      </Row>


      
      <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <Button onClick={() => setShowCreateRegion(true)}>Neue Region anlegen</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <RegionenListeMemo data={regionen} activeTab={activeTabMemo} setShowEdit={setShowEditRegion} />
        </Col>
      </Row>


      
      <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <Button onClick={() => setShowCreateVertriebsteam(true)}>Neues Vertriebsteam anlegen</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <VertriebsteamListeMemo data={vertriebsteams} activeTab={activeTabMemo} setShowEdit={setShowEditVertriebsteam} />
        </Col>
      </Row>



      {/*
            <Row>

            
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
              />;
              
              <h4>Vielleicht Content später mit diesem Editor bearbeiten..</h4>
        <Editor
              apiKey='syb4ulosbsi7b7712jpcn95nng2ls7jp3k3v3q4lq7atum7k'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue=""
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
            <button onClick={log}>Log editor content</button>
            </Row>
      */}




      {/* Erstellen der Einträge */}

      {/* Einträge generischer machen! */}

      {showCreateGroup &&
        <GroupModal title={"Gruppe erstellen"} setChanged={props.setChanged} visible={showCreateGroup} setVisible={setShowCreateGroup} newGroup={newGroup} setNewGroup={setNewGroup} createNewGroup={createNewGroup} />
      }

      {showCreateRegion &&
        <RegionModal title={"Region erstellen"} setChanged={props.setChanged} visible={showCreateRegion} setVisible={setShowCreateRegion} newGroup={newRegion} setNewGroup={setNewRegion} createNewGroup={createNewRegion} />
      }

      {showCreateVertriebsteam &&
        <VertriebsteamModal title={"Vertriebsteam erstellen"} setChanged={props.setChanged} visible={showCreateVertriebsteam} setVisible={setShowCreateVertriebsteam} newGroup={newVertriebsteam} setNewGroup={setNewVertriebsteam} createNewGroup={createNewVertriebsteam} />
      }


      {/* Bearbeiten der Einträge

        muss noch mit funktionen hinterlegt werden!
        Tasks, User Gruppen (Labels), ... müssen dann mit den neuen Werten angepasst werden!

      {showEditGroup &&
        <GroupModal title={"Gruppe bearbeiten"} setChanged={props.setChanged} visible={showCreateGroup} setVisible={setShowEditVertriebsteam} newGroup={newGroup} setNewGroup={setNewGroup} createNewGroup={createNewGroup} />
      }

      {showEditRegion &&
        <RegionModal title={"Region bearbeiten"} setChanged={props.setChanged} visible={showCreateRegion} setVisible={setShowEditVertriebsteam} newGroup={newRegion} setNewGroup={setNewRegion} createNewGroup={createNewRegion} />
      }

      {showEditVertriebsteam &&
        <VertriebsteamModal title={"Vertriebsteam bearbeiten"} setChanged={props.setChanged} visible={showCreateVertriebsteam} setVisible={setShowEditVertriebsteam} newGroup={newVertriebsteam} setNewGroup={setNewVertriebsteam} createNewGroup={createNewVertriebsteam} />
      }
         */}

    </Container>
  );
}

export default Gruppenverwaltung