import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { Container, Row, Col, Button } from "react-bootstrap";
import "../../Input/Input.module.scss"


import CardSub from "./DateienBausteine/CardSub"
import CardUpload from "./DateienBausteine/CardUpload"
import CardDokument from "./DateienBausteine/CardDokument"

import style from './Dateien.module.css'

import kategorienData from './DateienBausteine/kategorienData';
import subKategorienData from './DateienBausteine/subKategorienData';

/*
import { ref, uploadBytesResumable, getDownloadURL, uploadTask, deleteObject } from "@firebase/storage";
import { auth, db, storage } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import { collection, doc, addDoc, getDoc, setDoc, getDocs, deleteDoc } from "firebase/firestore"; 

*/

import { makeID } from "../../utils/makeID";
import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";




function Dateien(props) {

  const [input, setInput] = useState("");
  const [googleUID, setGoogleUID] = useState("");
  const [email, setEmail] = useState(null);

  
  const [customerAction, setCustomerAction] = useState([]); //Objects with actions + notes for files
  
  const [isUploading, setIsUploading] = useState(false);




  useEffect(() => {

    if (props.activeTab == 3) {

      if (props.data.googleuid && props.data.googleuid !== "") {
        setGoogleUID(props.data.googleuid);

        if(!props.data.email) {
          Swal.fire({
            icon: "info",
            title: "E-Mail",
            html: "Keine E-Mail in CRM hinterlegt. Benachrichtigung über neues Dokument kann bei Upload nicht versendet werden. Zunächst E-Mail in Stammdaten hinterlegen."
          })
        }
        else {
          setEmail(props.data.email);
        }

      }
      else {
        Swal.fire({
          icon: "error",
          title: "Dateien",
          html: "Kunde ist noch nicht in der VIN App registriert!<br />"
            + "Kunde zunächst in der VIN-App registrieren lassen."
        })
      }
    }
  }, [props])




  const [mainCategory, setMainCategory] = useState([
    {
      key: "Versicherung",
      label: "Versicherung"
    },
    {
      key: "Kredit",
      label: "Kredit"
    },
    {
      key: "Invest",
      label: "Finanzen & Invest"
    },
    {
      key: "Energie",
      label: "Energie"
    },
    {
      key: "Immobilien",
      label: "Immobilien"
    },
  ]);

  const [selectedMainCat, setSelectedMainCat] = useState("");

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  const [selectedBezeichnung, setSelectedBezeichnung] = useState("");
  const [bezeichnung, setBezeichnung] = useState([
    "Angebot", "Rechnung", "Vertrag", "Sonstiges"
  ]);






  const handleMainCategory = (name) => {
    setCategory("");
    setSubcategory("");
    setSelectedMainCat(name);
    //console.log(kategorienData.filter((key) => key.mainCategory === name)[0].category);
  }

  const handleCategory = (kategorie) => {
    setSubcategory("");
    setCategory(kategorie);
  }

  /*
  const handleBezeichnung = (bezeichnung) => {
    //setSelectedBezeichnung("");
    setBezeichnung(bezeichnung);
  }
  */


  const [pickedFiles, setPickedFiles] = useState([]);
  const [privateDocs, setPrivateDocs] = useState([]);


  useEffect(() => {

    // Get Dokumente
    async function getDokumente() {


      let documents = await AuthAxios.post('/dokumente/docs', {
        googleUID: googleUID,
        category: selectedMainCat,
        categoryTwo: category
      })


      let DocumentsArr = [];
      documents.data.forEach(doc => {
        //console.log(doc.data());
        DocumentsArr.push({
          id: doc.id,
          data: doc
        }
        );
      })
      setPrivateDocs(DocumentsArr);

    }

    // Abruf, nur wenn eine Kategorie ausgewählt wurde.
    if (selectedMainCat != "" && category != "") {
      getDokumente();
    }

  }, [googleUID, selectedMainCat, category])




  function removeFileUploadHandler(id) {

    //Multiple Files
    setPickedFiles(pickedFiles.filter(doc => doc.id !== id));

    // Single File
    //setPickedFiles([]);
  }




  function handleFile(e) {

    let data = [];

    Array.from(e.target.files).forEach(file => {

      // Max Dateien pro Upload: 10
      if (pickedFiles.length + e.target.files.length <= 10) {

        data = [...data, {
          id: makeID(8),
          file: file,
          name: file.name,
          size: file.size,
          type: file.type
        }
        ]
      }
      else {
        Swal.fire({
          icon: "info",
          title: "Anzahl Dateien",
          html: "Die max. Anzahl an Dateiuploads ist auf 10 Dateien festgelegt."
        })
      }
    });

    /* 
    //Single File
      setPickedFiles([...pickedFiles, {
        id: makeID(8),
        file: e.target.files[0],
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
        path: e.target.value
      }
    ]);
    */
    setPickedFiles([...pickedFiles, ...data]);

  }



  function handleFileUpload() {

    const formData = new FormData();

    //single file
    //formData.append("files", selectedFile);

    //multiple files
    pickedFiles.forEach(f => {
      formData.append("files", f.file);
    });

    formData.append("googleUID", googleUID);
    formData.append("email", props.data.email);
    formData.append("vorname", props.data.vorname);
    formData.append("customerActions", JSON.stringify(customerAction));
    formData.append("category", selectedMainCat);
    formData.append("categoryTwo", category);
    formData.append("categoryThree", subcategory)
    formData.append("name", subcategory !== ""
      ? selectedBezeichnung + " " + category + "-" + subcategory
      : selectedBezeichnung + " " + category);
    formData.append("type", selectedBezeichnung);

    setIsUploading(true);

    if (pickedFiles.length >= 1) {

      AuthAxios({
        method: "post",
        url: "/dokumente/upload",
        headers: { "Content-Type": "multipart/form-data" }, // Mixed Data with Files and JSON
        data: formData
      })
        .then(res => {

          //Einbau Dokumente in aktueller Ansicht
          let newDocs = [];
          res.data.docs.forEach(doc => {
            newDocs.push(doc);
          });

          setPrivateDocs([...newDocs, ...privateDocs]);

          Swal.fire({
            title: "Erfolg",
            html: "Alle Dateien wurden erfolgreich hochgeladen.",
            icon: "success",
          });

          setIsUploading(false);
          setPickedFiles([]);

        })
        .catch(err => {

          Swal.fire({
            title: "Dokument Upload",
            text: "Es gab einen Fehler beim Upload deiner Dateien.",
            icon: "error",
          });
          setIsUploading(false);
        })
    }

    // Spinner bzw. Upload Fortschritt einbauen
  }




  function deleteFileAndDoc(id, newsfeeddocid, filename) {

    
    AuthAxios.post("/dokumente/delete", {
      googleUID: googleUID,
      metaDocID: id,
      newsfeedDocID: newsfeeddocid,
      filename: filename,
      hauptbereich: selectedMainCat
    })
    .then(res => {

      setPrivateDocs(privateDocs.filter(doc => doc.id !== id));

      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Dokument erfolgreich gelöscht."
      });

    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        title: "Dokument löschen",
        text: "Es gab einen Fehler beim Löschen der Dateien.",
        icon: "error",
      });
    })
  }


  return (

    <Container style={{ maxWidth: "1000px", minHeight: "100%" }} className="pb-4">

      {googleUID && email ?

        <>



          {/* ===================== HAUPTKATEGORIE ===================== */}
          
          <Row style={{ justifyContent: "center", marginBottom: "50px" }} >

            <p className="text-center" style={{ margin: "0px", fontWeight: "bold"}}>Kunde: {props.data.vorname+" "+props.data.nachname}</p>
            <p className="text-center" style={{ margin: "0px" }}>Wähle einen Hauptbereich</p>

            {mainCategory.map(name => (
              <Col
                key={name.key}
                xs={6} md={3} lg={2}
                style={{ marginTop: "20px" }}
                onClick={() => handleMainCategory(name.key)}
              >
                {(selectedMainCat === name.key) && (
                  <CardSub key={name.key} title={name.label} selected={1} color={"#520da7"} />
                )}
                {(selectedMainCat !== name.key) && (
                  <CardSub key={name.key} title={name.label} />
                )}
              </Col>
            ))
            }
          </Row>

          
          {/* ===================== UNTERKATEGIRIE ===================== */}

          <Row style={{ justifyContent: "center", marginBottom: "50px" }} >

            {selectedMainCat && <p className="text-center" style={{ margin: "0px" }}>Wähle einen Kategorie</p>}

            {selectedMainCat && (kategorienData.filter((f) => f.mainCategory === selectedMainCat)[0].category).map((kategorie) => 
              <Col
                key={kategorie.key}
                xs={6} md={3} lg={2}
                style={{ marginTop: "20px" }}
                onClick={() => (handleCategory(kategorie.key))}
              >
                {(category === kategorie.key) && (
                  <CardSub key={kategorie.key} title={kategorie.label} selected={1} color={"#520da7"} />
                )}
                {(category !== kategorie.key) && (
                  <CardSub key={kategorie.key} title={kategorie.label} />
                )}

              </Col>
            )}
          </Row>
      
          

          {/* ===================== SUBKATEGORIE ===================== */}
          {category &&
            (subKategorienData.filter((f) => (
              f.mainCategory === selectedMainCat
              && f.category === category
            )
            ).length >= 1) ?

            <Row style={{ justifyContent: "center", marginBottom: "50px" }} >
              <p className="text-center" style={{ margin: "0px" }}>Wähle einen Unterkategorie</p>

              {(subKategorienData.filter((f) => (
                f.mainCategory === selectedMainCat
                && f.category === category
              ))[0].subCategory).map((kategorie) => {
                return (
                  <Col
                    key={kategorie}
                    xs={6} md={3}
                    style={{ marginTop: "20px" }}
                    onClick={() => (setSubcategory(kategorie))}
                  >
                    {(subcategory === kategorie) && (
                      <CardSub key={kategorie} title={kategorie} selected={1} color={"#520da7"} />
                    )}
                    {(subcategory !== kategorie) && (
                      <CardSub key={kategorie} title={kategorie} />
                    )}
                  </Col>
                );
              })
              }
            </Row>
            : <></>
          }



          {/* ===================== BEZEICHNUNG (Angebot, Rechnung, Vertrag) ===================== */}
         
          <Row style={{ justifyContent: "center", marginBottom: "50px" }} >

            {bezeichnung && category && <p className="text-center">Wähle einen Kategorie für die Bezeichnung des Dokuments im Newsfeed</p>}

            {category && bezeichnung.map((bez) => {
              return (
                <Col
                  key={bez}
                  xs={6} md={3}
                  style={{ marginTop: "20px" }}
                  onClick={() => setSelectedBezeichnung(bez)}
                >
                  {(selectedBezeichnung === bez) && (
                    <CardSub key={bez} title={bez} selected={1} color={"#520da7"} />
                  )}
                  {(selectedBezeichnung !== bez) && (
                    <CardSub key={bez} title={bez} />
                  )}
                </Col>
              );
            })
            }
          </Row>







          {/* Dokument Upload nur anzeigen, wenn alle angezeigten Kategorien gewählt sind!*/}
          {googleUID != "" && category && selectedBezeichnung != "" &&
            (subKategorienData.filter((key) => (
              key.mainCategory === selectedMainCat
              && key.category === category
            )
            ).length >= 1 ? subcategory != "" : true)
            &&
            <>

              <Row style={{ marginTop: "16px", justifyContent: "center", marginBottom: "50px" }} >
                <Col
                  xs={12} md={12}
                  className={style.uploadContainer}
                >

                  <h4 className="text-center">Dateiupload für {input.label}</h4>
                  <p>1. Wähle Dateien aus (.pdf .jpg .png)</p>

                  <label htmlFor="docUpload" className={style.uploadBtn}>
                    Datei/-en auswählen
                    <input
                      id="docUpload"
                      type="file"
                      multiple
                      accept='application/pdf, image/png, image/jpeg'
                      style={{ display: "none" }}
                      onChange={handleFile}
                    />
                  </label>


                  {pickedFiles.length > 0 &&
                    <div className="text-center" style={{ marginTop: "20px", width: "100%" }}>
                      {/*<p>2. Gib der Datei eine kurze und knackige Beschreibung (z.B. "Perso" oder "Fahrzeugbrief")</p>*/}
                      <p>2. Lade das/die Dokument/-e hoch</p>

                      {pickedFiles.map((file) => {
                        return (
                          <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />
                        );
                      })

                      }

                      {!isUploading && <button className={style.uploadBtn} onClick={handleFileUpload}>Dokumente hochladen</button>}
                      {isUploading && <button className={style.uploadBtn} style={{opacity: "0.7"}} disabled>Dokumente werden hochgeladen...</button>}
                    
                    </div>


                  }

                </Col>
              </Row>

              <Row style={{ marginTop: "16px", justifyContent: "center", marginBottom: "60px", marginTop: pickedFiles.length > 0 ? "50px" : "0px" }} >
                {/* Dokumenten Liste */}
                <Col
                  xs={12} md={12}
                  className="text-center"
                >
                  <h4 className="text-center" style={{ marginBottom: "0px" }}>Kunden-Dokumente</h4>
                  <p className="text-center" style={{ marginBottom: "20px" }}>Es sind nur von uns hochgeladene Dokumente sichtbar!</p>

                  {(privateDocs.filter((key) => key.data.category == selectedMainCat)).length == 0 && <p style={{ marginTop: "10px" }}>Noch keine Dokumente vorhanden.</p>}

                  {(privateDocs.filter((key) => key.data.category == selectedMainCat)).sort((a, b) => (b.data.created._seconds > a.data.created._seconds ? 1 : -1)).map((doc) => {
                    return (
                      <CardDokument key={doc.id} data={doc} googleUID={googleUID} deleteFileAndDoc={deleteFileAndDoc} />
                    )
                  })
                  }

                </Col>
              </Row>


            </>
          }
        </>


        :
        <>
          {!googleUID &&
            <h5>
              Kunde ist noch nicht in der VIN1 App registriert. Daher kann nicht auf die Dateien zurückgegriffen werden.
            </h5>
          }
          {!email &&
            <h5>
              E-Mail fehlt. Daher kann nicht auf die Dateien zurückgegriffen werden.
            </h5>
          }

        </>
      
      }


    </Container>
  );
}
export default Dateien
