import { Tab, Tabs } from 'react-bootstrap';
import {Container, Row, Col} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  DndContext,
  closestCenter
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {useState, useRef} from 'react';
import { SortableItem } from './SortableItem';

import PipelineListe from './PipelineListe'
import PipelineModal from '../../Bausteine/PipelineModal'

import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';



function NeuePipeline (props) {

  const [pipelineName, setPipelineName] = useState("");
  const item = useRef("");
  
  const [itemList, setItemList] = useState([]);

  //const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Edit Pipeline
  const [showEditModal, setShowEditModal] = useState(false);
  const [pipelineEditData, setPipelineEditData] = useState({
    id: "",
    name: "",
    items: []
  });

  //const [selectedPipeline, setSelectedPipeline] = useState("");
  //const [pipelineSteps, setPipelineSteps] = useState([]);

  const handleSave = () => {

    if(!pipelineName) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        text: "Du musst einen Pipeline Namen vergeben, um die Pipeline anzulegen."
      })
      return;
    }

    AuthAxios.post('/pipeline', {
      name: pipelineName,
      items: itemList
    })
    .then(data => {
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        text: "Pipeline erfolgreich erstellt."
      })
      setShowModal(false);
    })
    .catch(err => {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        text: "Pipeline konnte nicht gespeichert werden."
      })
    })
  }



  // ================================================ Bei neuer Pipeline ================================================
  const handleNewItem = (e) => {
    
    if(item.current.value && !itemList.includes(item.current.value)) {
      setItemList([...itemList, item.current.value]);
    }
    else if (itemList.includes(item.current.value)) {
      alert("Ein Eintrag mit diesem Namen existiert bereits.");
    }

    item.current.value="";
    
  }

  const handleRemoveItem = (delItemName) => {
    setItemList(itemList.filter(item => item !== delItemName));
    /*
    Swal.fire({
      icon: "info",
      title:"Löschen",
      html: "Wird noch implementiert, da die Schritte die ggfs mit Daten befüllt sind noch angepasst werden müssen, damit die Daten nicht verloren sind."
    });
    */
  }
  

  const handleDragEnd = (event) => {
    const {active, over} = event;
    if(active.id !== over.id) {
      setItemList((items) => {
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);
        return arrayMove(items, activeIndex, overIndex); 
      });
    }
  }

  
  // ================================================ Bei Edit Pipeline ================================================

  const handleEditItem = (e) => {
    
    if(item.current.value && !pipelineEditData.items.includes(item.current.value)) {
      setPipelineEditData(prevPipeline => ({
        ...prevPipeline,
        items: [...prevPipeline.items, item.current.value]
      }));
    }
    else if (pipelineEditData.includes(item.current.value)) {
      alert("Ein Eintrag mit diesem Namen existiert bereits.");
    }

    item.current.value="";
    
  }

  const handleEditRemoveItem = (delItemName) => {
    //setPipelineEditData(pipelineEditData.items.filter(item => item !== delItemName));
    /*
    Swal.fire({
      icon: "info",
      title:"Löschen",
      html: "Wird noch implementiert, da die Schritte die ggfs mit Daten befüllt sind noch angepasst werden müssen, damit die Daten nicht verloren sind."
    });
    */
  }
  

  const handleEditDragEnd = (event) => {
    const {active, over} = event;
    if(active.id !== over.id) {

      const activeIndex = pipelineEditData.items.indexOf(active.id);
      const overIndex = pipelineEditData.items.indexOf(over.id);
      let items = arrayMove(pipelineEditData.items, activeIndex, overIndex); 

      setPipelineEditData(prevPipeline => ({
        ...prevPipeline,
        items: items
      }));
    }
  }

  const handleEditSave = () => {


    if(!pipelineEditData.name) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        text: "Du musst einen Pipeline Namen vergeben, um die Pipeline anzulegen."
      })
      return;
    }

    AuthAxios.post("/pipeline/"+pipelineEditData.id, pipelineEditData)
    .then(data => {
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        text: "Pipeline Bearbeitung erfolgreich gespeichert."
      })
      setShowEditModal(false);

      // Update Tabelle
      props.handleEditPipeline(pipelineEditData);
    })
    .catch(err => {

      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        text: "Pipeline Bearbeitung konnte nicht gespeichert werden."
      })
    })
    
  }


    return (

      <Container fluid >
        <div style={{marginLeft: "0px", marginTop: "0px", marginBottom: "50px", width: "100%", height: "100%", paddingLeft: "20px"}}>
          <Row>

            <Col>

              <Button className="mb-3" onClick={() => setShowModal(true)}>
                Neue Pipeline
              </Button>

              <p>Klicke auf einen Tabelleneintrag, um eine Pipeline zu bearbeiten.</p>
              <PipelineListe data={props.data} setPipelineEditData={setPipelineEditData} setActiveTab={props.setActiveTab} activeTab={props.activeTab} setShowEditModal={setShowEditModal} />

            </Col>

          </Row>
        </div>


        <PipelineModal
          title={"Neue Pipeline"}
          visible={showModal}
          setVisible={setShowModal}
          handleSave={handleSave}
        >
                <Container className="p-3" align="center">
                  <p>Lege eine neue Pipeline an. Vergebe dazu einen Namen.</p>
                  <Form>
                    <Row className="justify-content-center w-auto">
                        <Form.Label>Pipeline Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Pipeline-Name"
                          name="name"
                          value={pipelineName}
                          onChange={(e) => setPipelineName(e.target.value)}
                        />
                
                        <p className="mt-4">Füge der Pipeline Elemente hinzu.</p>
                       
                        <Form.Label className="text-left">Pipeline Schritt</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Pipeline-Schritt"
                          name="item"
                          ref={item}
                        />
                        <Button
                          className="btn btn-primary w-auto"
                          style={{margin: "15px 25px"}}
                          onClick={(e) => handleNewItem(e.target.value.trim())}
                        >
                          Hinzufügen
                        </Button>
                    </Row>
                  </Form>
                </Container>
              

               
              {itemList.length > 0 &&
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <Container className="p-3" align="center">
                    <h5>Aktuelle Pipeline Reihenfolge</h5>
                    <p className="mt-4">Du kannst die Reihenfolge durch Drag and Drop der Elemente verändern.</p>
                    <SortableContext
                    touch
                      items={itemList}
                      strategy={verticalListSortingStrategy}
                    >
                      {/* We need components that use the useSortable hook */}
                      {itemList.map(item => <SortableItem key={item} id={item} removeItem={handleRemoveItem} />)}
                    </SortableContext>

                  </Container>
                </DndContext>
              }
          
        </PipelineModal>




        <PipelineModal
          title={"Pipeline bearbeiten - "+ pipelineEditData.name}
          visible={showEditModal}
          setVisible={setShowEditModal}
          handleSave={handleEditSave}
        >
                <Container className="p-3" align="center">
                  <p>Bearbeite die vorhandenen Pipelineschritte in der Reihenfolge</p>
                  <Form>
                    <Row className="justify-content-center w-auto">
                      
                        <p className="mt-4">Füge der Pipeline weitere Elemente hinzu.</p>
                       
                        <Form.Label className="text-left">Pipeline Schritt</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Pipeline-Schritt"
                          name="itemEdit"
                          ref={item}
                        />
                        <Button
                          className="btn btn-primary w-auto"
                          style={{margin: "15px 25px"}}
                          onClick={(e) => handleEditItem(e.target.value.trim())}
                        >
                          Hinzufügen
                        </Button>
                    </Row>
                  </Form>
                </Container>
              

               
              {pipelineEditData.items &&
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleEditDragEnd}
                >
                  <Container className="p-3" align="center">
                    <h5>Aktuelle Pipeline Reihenfolge</h5>
                    <p className="mt-4">Du kannst die Reihenfolge durch Drag and Drop der Elemente verändern.</p>
                    <SortableContext
                    touch
                      items={pipelineEditData.items}
                      strategy={verticalListSortingStrategy}
                    >
                      {/* We need components that use the useSortable hook */}
                      {pipelineEditData.items.map(item => <SortableItem key={item} id={item} removeItem={() => {alert("Ein löschen vorhandener Pipeline Schritte momentan nur durch die IT.")}} />)}
                    </SortableContext>

                  </Container>
                </DndContext>
              }
          
        </PipelineModal>
      </Container>
    );
  }
  
  export default NeuePipeline;