import React, { useState } from 'react'
import { Row, Col, Form } from "react-bootstrap"
import { COLORS } from '../constants'

import SelectSponsor from '../Bausteine/SelectSponsor'; 
import InfoVerwaltung from './InputInteresse';

function InputTippgeberStatus(props) {

  const [sponsor, setSponsor] = useState({"label":"", "value":""});  

  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Tippgeber
      </legend>
      <Row>

        <p>Aktueller gewählter Sponsor: {props.data.sponsor}</p>
        <SelectSponsor
          setSelect={props.setSelect}
          selectedValue={props.data.sponsor}
        />
     

        <Col xs={12} md={12}>
          <Form.Label>Strukturebene (wird auf Basis Sponsor noch nachgepflegt)</Form.Label>
          <Form.Control
            readOnly
            type="text"
            placeholder="-"
            value={props.data.ebene}
            onChange={() => {}}
          />
        </Col>

      </Row>
    </fieldset>
  );
}
export default InputTippgeberStatus