import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../contexts/MainContext";
import { useNavigate, useParams } from "react-router-dom";

import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { COLORS } from '../../constants'

import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";
import SelectGruppen from "../../Bausteine/SelectGruppen";
import SelectRegions from "../../Bausteine/SelectRegions";
import SelectVertriebsteam from "../../Bausteine/SelectVertriebsteam";
import SelectRole from "../../Bausteine/SelectRole";


function MitarbeiterEdit(props) {


  const [mitarbeiterId, setMitarbeiterId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [geburtsdatum, setGeburtsdatum] = useState("1970-01-01");

  const { jwt, setJwt } = useContext(MainContext);

  const [mitarbeiter, setMitarbeiter] = useState({});

  let { id } = useParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);

  useEffect(() => {

    async function getMitarbeiter(id) {
      AuthAxios.get('/user/' + id)
        .then(res => {
          setMitarbeiter(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Mitarbeiter konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined) {
      getMitarbeiter(id);
    }

  }, [id]);





    const handleInputChange = (event) => {

      const value = event.target.value;
      setMitarbeiter({
        ...mitarbeiter,
        [event.target.name]: value
      });
    }


    // React Multi Select Zuweisung
    const setSelectMulti = (name, selection) => {

      
      //setChanged(true);
      let newVals = [];

      selection?.map(val => {
        //newVals.push({value: val.value, label: val.label});
        newVals.push(val.value);
      })
      

      setMitarbeiter({
        ...mitarbeiter,
        [name]: newVals
      });
      
    }

    // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
    const setCBs = (e) => {

      // Wenn das erste mal verändert, Speicherdiskette einblenden!!
      setChanged(true);
      if(e.target.name == "status") {
        //Sperren von Login => true = aktiv | false = kein Login
        setMitarbeiter({
          ...mitarbeiter,
          [e.target.name]: !e.target.checked 
        });
      }
      else
      {
        setMitarbeiter({
          ...mitarbeiter,
          [e.target.name]: e.target.checked 
        });
      }
    }


    const handleMitarbeiter = () => {
      // Neu + Update
      props.newMitarbeiter(mitarbeiter);
      //setMitarbeiter({});
      //props.setActiveTab(1);
    }



  return (
      <Container style={{maxWidth: "600px", margin: "15px 15px 15px 15px"}}>

        {id && 
          <Button
            onClick={() => setMitarbeiter({})}
            className="mb-4"
          >
            Neuen Mitarbeiter anlegen
          </Button>
        }

        <div>
          <h6>Persönliches</h6>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Personalnummer</Form.Label>
            </Col>
            {id ? 
               <Col xs={12} sm={9} md={8}>
               <Form.Control
                 className="readOnly"
                 type="number"
                 placeholder="Personalnummer (eindeutig)"
                 name="mitarbeiterId"
                 value={mitarbeiter.mitarbeiterId ? mitarbeiter.mitarbeiterId : ""}
                 onChange={() => {}}
                />
             </Col>
             :
             <Col xs={12} sm={9} md={8}>
             <Form.Control
               type="number"
               placeholder="Personalnummer (eindeutig)"
               name="mitarbeiterId"
               value={mitarbeiter.mitarbeiterId ? mitarbeiter.mitarbeiterId : ""}
               onChange={(e) => handleInputChange(e)}
             />
           </Col>
            }
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Vorname</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="text"
                placeholder="Vorname"
                name="vorname"
                value={mitarbeiter.vorname ? mitarbeiter.vorname : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Name</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
            <Form.Control
                type="text"
                placeholder="Nachname"
                name="nachname"
                value={mitarbeiter.nachname ? mitarbeiter.nachname : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>E-Mail</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="email"
                placeholder="email@email.de"
                name="username"
                value={mitarbeiter.username ? mitarbeiter.username : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Geburtsdatum</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="date"
                name="geburtsdatum"
                value={mitarbeiter.geburtsdatum ? mitarbeiter.geburtsdatum.substring(0, 10) : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>
        </div>


        <hr />


        <div>
          <h6>Passwort</h6>
          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Passwort festlegen</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>

            {!id &&
              <Form.Control
                type="password"
                placeholder="**********"
                name="password"
                value={mitarbeiter.password ? mitarbeiter.password : ""}
                onChange={(e) => handleInputChange(e)}
              />
            }
            {id &&
              <i>Passwort durch Benutzer bei Login per "Passwort vergessen" zurücksetzbar.</i>
            }
            </Col>
          </Row>
        </div>


        <hr />


        <div>
          <h6>Kontakt</h6>
          <p>Auch, um intern Kontakt anzurufen</p>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Telefon</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>

              <Form.Control
                type="text"
                placeholder="Telefon"
                name="telefonIntern"
                value={mitarbeiter.telefonIntern ? mitarbeiter.telefonIntern : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Mobil</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>

              <Form.Control
                type="text"
                placeholder="Mobil"
                name="telefonInternMobil"
                value={mitarbeiter.telefonInternMobil ? mitarbeiter.telefonInternMobil : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>
          </Row>

        </div>

        <hr />

        <div>
          <h6>Zuordung</h6>
          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Gruppe/-n</Form.Label>
            </Col>

            <Col xs={12} sm={9} md={8}>
              <SelectGruppen 
                setSelectMulti={setSelectMulti}
                selectedValues={mitarbeiter.groups ? mitarbeiter.groups.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
              />
            </Col>    
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Region/-en</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <SelectRegions 
                setSelectMulti={setSelectMulti}
                selectedValues={mitarbeiter.regions ? mitarbeiter.regions.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
              />
            </Col>   
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Vertriebsteam/-s</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <SelectVertriebsteam 
                setSelectMulti={setSelectMulti}
                selectedValues={mitarbeiter.vertriebsteams ? mitarbeiter.vertriebsteams.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
              />  
            </Col>
          </Row>

        </div>


        <hr />


        <div>
          <h6>DEMV (für CSV Export Kundendaten für Vermittler)</h6>
          <p>Wenn nur Vorname oder Nachname gesetzt ist, anderes Feld freilassen.</p>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>DEMV CSV Export für Mitarbeiter</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>

              <Form.Check
                style={{marginTop: "10px"}}
                type="checkbox"
                id="demvKundenExport"
                label="DEMV Export Mitarbeiter auflisten"
                name="demvKundenExport" 
                checked={mitarbeiter.demvKundenExport ? true : false} //Sperren von DEMV Export
                onChange={(e) => setCBs(e)}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>DEMV Systemnutzer Vorname</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="text"
                placeholder="DEMV Systemnutzer Vorname"
                name="demvVorname"
                value={mitarbeiter.demvVorname ? mitarbeiter.demvVorname : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>   
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>DEMV Systemnutzer Nachname</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Control
                type="text"
                placeholder="DEMV Systemnutzer Nachname"
                name="demvNachname"
                value={mitarbeiter.demvNachname ? mitarbeiter.demvNachname : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </Col>   
          </Row>

        </div>


        <hr />


        <div>
          <h6>Benutzerrolle</h6>
          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Benutzerrolle</Form.Label>
            </Col>
            <Col xs={12} sm={9} md={8}>
              <SelectRole
                placeholder={"Wähle eine oder mehrere Rollen"}
                setSelectMulti={setSelectMulti}
                selectedValues={mitarbeiter.roles} // ? mitarbeiter.roles.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
              />
            </Col>   
          </Row>

          <Row className="mt-4">
            <Col xs={12} sm={3} md={4}>
              <Form.Label>Benutzer inaktiv schalten</Form.Label>
             
            </Col>
            <Col xs={12} sm={9} md={8}>
              <Form.Check
                style={{marginTop: "10px"}}
                type="checkbox"
                id="loginGesperrt"
                label="Login gesperrt"
                name="loginGesperrt" 
                checked={!mitarbeiter.loginGesperrt ? false : true} //Sperren von Login => true = aktiv | false = kein Login
                onChange={(e) => setCBs(e)}
              />
            </Col>
          </Row>

        </div>

        <Row className="mt-4 pb-5">
          <Button variant="primary" onClick={handleMitarbeiter} >
            Speichern
          </Button>
        </Row>

      </Container>
  );
}

export default MitarbeiterEdit;

/*
  <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            Neuen Benutzer registrieren für Login
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => handleSubmit(e)}
          >
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="mitarbeiterId"
              label="Mitarbeiter Nummer"
              type="mitarbeiterId"
              id="mitarbeiterId"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Benutzername (aktuell E-Mail)"
              name="username"
              autoFocus
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="vorname"
              label="Vorname"
              id="vorname"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="nachname"
              label="Nachname"
              id="nachname"
              onChange={(e) => handleChange(e)}
            />

            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="geburtsdatum"
              label="Geburtsdatum"
              type="date"
              id="geburtsdatum"
              onChange={(e) => handleChange(e)}
              InputLabelProps={{ shrink: true }}
            />

            {showAlert ? (
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">
                {alertText}
              </Alert>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Neuen Benutzer anlegen
            </Button>
            <Grid container></Grid>
          </form>
        </div>
      </Container>
      <Container>
        <div style={{marginTop: "50px"}} >
          <h4>
            Hier wird eine Rechteverwaltung zu einem neuen/bereits Bestehenden
            MItarbeiter eingebaut
          </h4>
        </div>
      </Container>
      */
