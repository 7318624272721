import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//import { Doughnut } from 'react-chartjs-2';
import BarChartJahr from "../../Bausteine/BarChartJahr";
import ReactSelect from "react-select";
import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";
import BarChartTage from "../../Bausteine/BarChartTage";

import PieChartGender from "../../Bausteine/PieChartGender";
import PieChartKontaktart from "../../Bausteine/PieChartKontaktart";
import PieChartApp from "../../Bausteine/PieChartApp";
import PieChartHerkunft from "../../Bausteine/PieChartHerkunft";

export default function AppAnalyse() {

    const [last12Month, setLast12Month] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState({});
    const [year, setYear] = useState(-1);
    const [month, setMonth] = useState(-1);
    const [amountDays, setAmountDays] = useState(-1);


    // -------- Chart Data --------

    // --- Bar Charts ---
    const [dataMonthNeuregistrierung, setDataMonthNeuregistrierung] = useState({});
    const [dataYearNeuregistrierung, setDataYearNeuregistrierung] = useState({});

    const [dataMonthBestand, setDataMonthBestand] = useState({});
    const [dataMonthTippgeber, setDataMonthTippgeber] = useState({});
    const [dataMonthBerater, setDataMonthBerater] = useState({});
    const [dataMonthSchaden, setDataMonthSchaden] = useState({});

    // --- Pie Charts ---
    const [appnutzung, setAppnutzung] = useState({});
    const [gender, setGender] = useState({});
    const [kontaktart, setKontaktart] = useState({});
    const [herkunft, setHerkunft] = useState({});
    


    const getlast12month = async () => {

        //{label:'Jan 2022', value:01-2022},

        const monthName = new Array("Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez");

        const d = new Date();
        // Tag auf den ersten des Monats festelgen
        d.setDate(1);
        const entries = [];
        for (var i = 0; i <= 11; i++) {

            let json = {};
            json.value = d.getMonth() + 1 + '-' + d.getFullYear();
            json.label = monthName[d.getMonth()] + ' ' + d.getFullYear()
            entries.push(json);

            //Monat abziehen
            d.setMonth(d.getMonth() - 1);
        }
        setLast12Month(entries);
        setSelectedMonthYear(entries[0]);
    }


    const getNeukundenChartDataYear = (year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/neuregistrierungen/"+year)
        .then(res => {
            
            setDataYearNeuregistrierung({
                labels: res.data.labels,
                datasets: [{
                    label: res.data.labels,
                    maxBarThickness: 25,
                    backgroundColor: "#4e73df",
                    hoverBackgroundColor: "#2e59d9",
                    borderColor: "#4e73df",
                    data: res.data.values
                }],
                sum: res.data.sum
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }




    const getNeukundenChartDataMonth = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/neuregistrierungen/"+month_year)
        .then(res => {
            
            setDataMonthNeuregistrierung({
                labels: res.data.labels,
                datasets: [{
                    label: res.data.labels,
                    maxBarThickness: 25,
                    backgroundColor: "#4e73df",
                    hoverBackgroundColor: "#2e59d9",
                    borderColor: "#4e73df",
                    data: res.data.values
                }],
                sum: res.data.sum
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }


    
    const getBestandsuebertragungChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/bestandsuebertragungen/"+month_year)
        .then(res => {
            
            setDataMonthBestand({
                labels: res.data.labels,
                datasets: [{
                    label: res.data.labels,
                    maxBarThickness: 25,
                    backgroundColor: "#4e73df",
                    hoverBackgroundColor: "#2e59d9",
                    borderColor: "#4e73df",
                    data: res.data.values
                }],
                sum: res.data.sum
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }


    // Einbau Jahresstatistik....



    
    const getTippgeberChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/tippgeberverifizierungen/"+month_year)
        .then(res => {
            
            setDataMonthTippgeber({
                labels: res.data.labels,
                datasets: [{
                    label: res.data.labels,
                    maxBarThickness: 25,
                    backgroundColor: "#4e73df",
                    hoverBackgroundColor: "#2e59d9",
                    borderColor: "#4e73df",
                    data: res.data.values
                }],
                sum: res.data.sum
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }



    const getBeraterconnectChartData = (month_year) => {

        AuthAxios.get("/analyse/beraterconnect/"+month_year)
        .then(res => {
            
            setDataMonthBerater({
                labels: res.data.labels,
                datasets: [{
                    label: res.data.labels,
                    maxBarThickness: 25,
                    backgroundColor: "#4e73df",
                    hoverBackgroundColor: "#2e59d9",
                    borderColor: "#4e73df",
                    data: res.data.values
                }],
                sum: res.data.sum
            });
            
            
        })
        .catch(err => {
            
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }


    
    const getSchadenChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/schaden/"+month_year)
        .then(res => {
            
            setDataMonthSchaden({
                labels: res.data.labels,
                datasets: [{
                    label: res.data.labels,
                    maxBarThickness: 25,
                    backgroundColor: "#4e73df",
                    hoverBackgroundColor: "#2e59d9",
                    borderColor: "#4e73df",
                    data: res.data.values
                }],
                sum: res.data.sum
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }
    
    const getAppnutzungChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/appnutzung")
        .then(res => {
            
            setAppnutzung({
                labels: res.data.labels,
                datasets: [{
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                    data: res.data.values
                }],
                percent: res.data.percent
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }



    const getGenderChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/gender")
        .then(res => {
            
            setGender({
                labels: res.data.labels,
                datasets: [{
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                    data: res.data.values
                }]
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }




    const getKontaktartChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/kontaktart")
        .then(res => {
            
            setKontaktart({
                
                labels: res.data.labels.map(label => 
                    label.charAt(0).toUpperCase() + label.slice(1)
                ),
                datasets: [{
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                    data: res.data.values
                }]
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }



    const getHerkunftChartData = (month_year) => {
        //AuthAxios.get("/analyse/neuregistrierungen/"+new Date().getMonth()+1+"-"+new Date().getFullYear())
        AuthAxios.get("/analyse/herkunft")
        .then(res => {
            
            setHerkunft({
                labels: res.data.labels,
                datasets: [{
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                    data: res.data.values
                }]
            });
            
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Fehler beim Abrufen der aktuellen Statistiken."
            })
        })
    }






    // --------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------

    useEffect(() => {

        getlast12month();
        //setYear(new Date().getFullYear()); //2023
        //setMonth(new Date().getMonth()+1); //1-12

    }, [])


    useEffect(() => {

        getNeukundenChartDataYear(new Date().getFullYear());

        if(selectedMonthYear.value !== undefined && selectedMonthYear.value !== "") {
            getNeukundenChartDataMonth(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getBestandsuebertragungChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getTippgeberChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getBeraterconnectChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getSchadenChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023

            // Pie Charts
            getAppnutzungChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getGenderChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getKontaktartChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023
            getHerkunftChartData(selectedMonthYear.value); //selectedMonthYear.value == z.B. 1-2023

        }
    },[selectedMonthYear]);


    const [data, setData] = useState({
        labels: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        datasets: [{
            label: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            maxBarThickness: 25,
            backgroundColor: "#4e73df",
            hoverBackgroundColor: "#2e59d9",
            borderColor: "#4e73df",
            data: [50, 75, 100, 45, 75, 80, 60, 15, 87, 15, 16, 10]
        }]
    })



    return (
        <Container fluid>

            <Row>

                <div style={{ width: '300px', marginBottom: '10px' }}>
                    <p style={{ marginBottom: '5px' }}>Wähle einen Zeitraum aus</p>
                    <ReactSelect
                        value={selectedMonthYear}
                        options={last12Month}
                        onChange={(val) => setSelectedMonthYear(val)}
                    />
                </div>

            </Row>

            <Row>

                <Col xs={12} md={12} xl={6}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="RegistrierungenSummeMonat">App Neuregistrierungen laufender Monat: {dataMonthNeuregistrierung.sum}</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-bar" style={{ height: "260Px" }}>
                                <BarChartTage chartData={dataMonthNeuregistrierung} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={6}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="RegistrierungenSummeJahr">App Neuregistrierungen laufendes Jahr: {dataYearNeuregistrierung.sum} </span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-bar" style={{ height: "260Px" }}>
                                <BarChartJahr chartData={dataYearNeuregistrierung} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={6}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="RegistrierungenSummeMonat">Bestandsübertragungen laufender Monat: {dataMonthBestand.sum}</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-bar" style={{ height: "260Px" }}>
                                <BarChartTage chartData={dataMonthBestand} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={6}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="RegistrierungenSummeMonat">Tippgeberverifizierungen laufender Monat: {dataMonthTippgeber.sum}</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-bar" style={{ height: "260Px" }}>
                                <BarChartTage chartData={dataMonthTippgeber} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={6}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="RegistrierungenSummeMonat">Berater-Connect laufender Monat: {dataMonthBerater.sum}</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-bar" style={{ height: "260Px" }}>
                                <BarChartTage chartData={dataMonthBerater} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={6}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="RegistrierungenSummeMonat">Schadensmeldungen laufender Monat: {dataMonthSchaden.sum}</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-bar" style={{ height: "260Px" }}>
                                <BarChartTage chartData={dataMonthSchaden} />
                            </div>
                        </div>
                    </div>
                </Col>


                <Col xs={12} md={12} xl={4}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="AnalyseKontakteArt">Archivierte Kontakte: {parseInt(100 - appnutzung.percent).toFixed(1).replace(".", ",")} %</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-pie" style={{ height: "260Px" }}>
                                <PieChartApp chartData={appnutzung} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={4}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="AnalyseKontakteGender">Kontakte nach Gender</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-pie" style={{ height: "260Px" }}>
                                <PieChartGender chartData={gender} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={4}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="AnalyseKontakteArt">Kontakte nach Kontaktart</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-pie" style={{ height: "260Px" }}>
                                <PieChartKontaktart chartData={kontaktart} />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={12} xl={4}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h6 className="m-0 font-weight-bold text-primary"><span id="AnalyseKontakteArt">Kontakte nach Herkunft</span></h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-pie" style={{ height: "260Px" }}>
                                <PieChartHerkunft chartData={herkunft} />
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>



            <Row>


            </Row>

        </Container>
    );
}
