import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Col, Row, Form } from 'react-bootstrap'


function PagesTabelle (props) {

    return (
        <table>
            <thead>
            <tr>
                <th style={{paddingRight: "20px"}}>Seite</th>
                <th style={{paddingRight: "20px"}}>Beschreibung</th>
                <th style={{paddingRight: "20px"}}>Bearbeiter</th>
                {/*<th>Aktion</th>*/}
            </tr>
            </thead>
            <tbody>
                {props.data.map(d => 
                    <tr key={d.page}>
                        <td style={{paddingRight: "20px"}}>
                            {d.page}
                        </td>
                        <td style={{paddingRight: "40px"}}>
                            {d.beschreibung ?? "-"}
                        </td>
                        <td style={{paddingRight: "40px"}}>
                            {d.mitarbeiter ?? "None"}
                        </td>
                        
                    </tr>
                )}
            </tbody>
        </table>
    );
      
}

export default PagesTabelle