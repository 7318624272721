import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, NavItem, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';

import { alpha, styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import './VorteilspartnerListe.Module.css';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

import CustomModal from '../../Bausteine/CustomModal'

import { formatDateLong } from "../../utils/date";

function PersoCheckOpenListe(props) {

  const navigate = useNavigate();

  const [vorteil, setVorteil] = useState([]);
  const [vorteilFiltered, setVorteilFiltered] = useState([]);
  const [loading, setLoading] = useState(true);


  const [showPersoModal, setShowPersoModal] = useState(false);
  const [selectedPerso, setSelectedPerso] = useState({});

  // Rotate Image
  const [rotationFront, setRotationFront] = useState(0);
  const [rotationBack, setRotationBack] = useState(0);




  useEffect(() => {
    async function getPersoListeOffen() {
      AuthAxios.get('/perso-verifizierung/open')
      .then(res => {
        setVorteil(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Perso Verifizierungsdaten konnten nicht geladen werden."
        });
      });
    }

    if(props.activeTab == "2") {
      getPersoListeOffen();
    }
  },[props.activeTab]);




  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "kdnr",
      headerName: "Kdnr",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
  ];



  const getPersoDetails = async(id) => {
    let details = await AuthAxios.get('/perso-verifizierung/'+id);
    setSelectedPerso(details.data);
  }


  const handlePersoModal = (e) => {
    getPersoDetails(e.id);
    setShowPersoModal(true);
  }


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>

      </GridToolbarContainer>
    );
  }

  const onFilterModelChange = () => {
    //console.log("filter changed..")
  }




    return (
      <div style={{width: "100%"}}>
        <Box
          sx={{
            height: '80vh',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

        {props.activeTab == 2 && (
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            rows={vorteil}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handlePersoModal}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            autoPageSize
            //onFilterModelChange={onFilterModelChange}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
          />
        )}
        
        </Box>



        {showPersoModal && selectedPerso &&
        
          <CustomModal 
            title={"Perso validieren: "+selectedPerso.vorname+" "+selectedPerso.nachname}
            visible={showPersoModal}
            setVisible={setShowPersoModal}
            width={"xl"}
          >

            <h5 className="fw-normal mb-3">Perso-ID: <span className="fw-bold">{selectedPerso.persoid}</span></h5>
            <h5 className="fw-normal mb-3">Name: <span className="fw-bold">{selectedPerso.vorname} {selectedPerso.nachname}</span></h5>
            <h5 className="fw-normal mb-3">Geburtsdatum: <span className="fw-bold">{formatDateLong(selectedPerso.geburtsdatum)}</span></h5>
            <h5 className="fw-normal mb-3">Anschrift: <span className="fw-bold">{selectedPerso.anschrift ?? "-fehlt-"}</span></h5>
            <h5 className="fw-normal mb-3">Wohnort: <span className="fw-bold">{selectedPerso.plz ?? "-fehlt-"} {selectedPerso.stadt ?? "-fehlt-"}</span></h5>

            <hr />
            Anweisung: Prüfe, ob die Perso-ID die angegeben ist auf beiden Seiten des Persos sichtbar ist und ob der Namen und das Geburtsdatum passen.

            <hr />

            <h5>Vorderseite</h5>

            <div className="flex flex-row gap-4">
              <div style={{
                  display: 'flex',
                  justifyContent: 'center',  // Center horizontally
                  alignItems: 'center',      // Center vertically
                  width: 'max(600px, 100%)',  // Set according to your requirements
                  height: '600px',           // Set according to your requirements
                  overflow: 'hidden'         // Hide overflowing part
                }}
              >
                <img
                  src={selectedPerso.fileUrlFront}
                  className="basis-3/4"
                  style={{
                    border: "1px solid black",
                    maxHeight: '100%',
                    maxWidth: '100%',
                    objectFit: "contain",
                    transform: `rotate(${rotationFront}deg)`
                  }}
                />
              </div>
              <Button style={{maxHeight: "70px"}} onClick={() => setRotationFront(rotationFront-90)}>Links Drehen</Button>
              <Button style={{maxHeight: "70px"}} onClick={() => setRotationFront(rotationFront+90)}>Rechts Drehen</Button>

              <div className="basis-1/4 grid gap-4">
                <Button
                  style={{maxHeight: "300px"}}
                  variant={"success"}
                >
                  Daten passen
                </Button>
                <Button
                  style={{maxHeight: "300px"}}
                  variant={"danger"}
                >
                  Daten falsch
                </Button>
              </div>

            </div>

            <hr />

            <h5 className="mt-5">Rückseite</h5>

            
            <div className="flex flex-row gap-4">

              <div style={{
                  display: 'flex',
                  justifyContent: 'center',  // Center horizontally
                  alignItems: 'center',      // Center vertically
                  width: 'max(600px, 100%)',  // Set according to your requirements
                  height: '600px',           // Set according to your requirements
                  overflow: 'hidden'         // Hide overflowing part
                }}
              >
                <img
                  src={selectedPerso.fileUrlBack}
                  className="basis-3/4"
                  style={{
                    border: "1px solid black",
                    maxHeight: '100%',
                    maxWidth: '100%',
                    objectFit: "contain",
                    transform: `rotate(${rotationBack}deg)`
                  }}
                />
              </div>
              <Button style={{maxHeight: "70px"}} onClick={() => setRotationBack(rotationBack-90)}>Links Drehen</Button>
              <Button style={{maxHeight: "70px"}}onClick={() => setRotationBack(rotationBack+90)}>Rechts Drehen</Button>

              <div className="basis-1/4 grid gap-4">
                <Button
                  style={{maxHeight: "300px"}}
                  variant={"success"}
                >
                  Daten passen
                </Button>
                <Button
                  style={{maxHeight: "300px"}}
                  variant={"danger"}
                >
                  Daten falsch
                </Button>
              </div>

            </div>

          </CustomModal>
        }

      </div>
    );
  }
  

export default PersoCheckOpenListe