import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Col, Row, Form } from 'react-bootstrap'


function SelectMitarbeiterTable (props) {

    // Mitarbeiter Select in Matrixtabelle nur 1x vorladen


    //useEffect(() => {
        // Warten bis Props geladen sind!
    //    if(props.selectedValue !== null && props.selectedValue !== undefined) {
    //        props.setMitarbeiter([{label: props.selectedValue.label, value: props.selectedValue.value}]);
    //    }
    //},[props.selectedValue])

    const handleRemove = () => {
        props.setSelect("mitarbeiter", null); // Update Outer value
    }

    const handleChange = (name, selection) => {
        console.log("handleChange")
        props.setSelect(name, {label: selection.label, value: selection.value}); // Object
    };


    return (
        <Row>
            {!props.hideLabel && <Form.Label>Bearbeiter</Form.Label>}
                <Col xs={12}>
                    <ReactSelect
                        value={props.selectedValue ? props.mitarbeiter.filter((f) => f.value == props.selectedValue?.value) : null}
                        onChange={
                            (selection, e) => {
                            if (e.action === 'clear') {
                                handleRemove();
                            } else {
                                handleChange(e.name, selection);
                            }
                        }}
                        placeholder={<div className="select-placeholder-text">Suche nach Mitarbeiter</div>}
                        options={props.mitarbeiterActive}
                        noOptionsMessage={() => "Suche nach Mitarbeiter um Optionen zu erhalten."}
                        filterOption={createFilter({ignoreAccents: false})}
                        name="mitarbeiter"
                        //onInputChange={handleInputChange}
                        isClearable={true}
                    />
                </Col>
            {/*<Col xs={1} md={1}>
            {selected !== "" &&
                <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="1x"
                        title="Kunde entfernen"
                    />
                </Button>
            }
            </Col>*/}
        </Row>
    );
      
}

export default SelectMitarbeiterTable