import React, { useRef, useEffect, useCallback, useContext, useState, Fragment } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, Link } from 'react-router-dom';

import SelectVerantwortung from "../../Bausteine/SelectVerantwortung";

//import SelectMitarbeiter from "../../Bausteine/SelectMitarbeiter"; // keine Archivierten Mitarbeiter drin!
import SelectMitarbeiterAll from "../../Bausteine/SelectMitarbeiterAll";

import SelectGruppe from "../../Bausteine/SelectGruppe";
import SelectKundeObj from "../../Bausteine/SelectKundeObj";
import SelectTasktype from "../../Bausteine/SelectTasktype";
import { COLORS } from '../../constants'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode } from "@fortawesome/free-solid-svg-icons";

import moment from 'moment'

// ===== Comment Feed =====
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'

import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import { Listbox, Transition } from '@headlessui/react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,  faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import { v4 as uuidv4 } from 'uuid';

function NeueAufgabe(props) {

  // ===== Comment Feed =====
  const newComment = useRef(null);

  const iconMap = {
    faSpinner: faSpinner,
    faCircleCheck: faCircleCheck,
    faCircleXmark: faCircleXmark
  };

  //const [activity, setActivity] = useState(props.data.activity ?? []);
  /*[
    {
      id: 1,
      type: 'comment',
      person: { name: 'Eduardo Benz', id: 15 },
      comment:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ',
      date: new Date(new Date().setDate(new Date().getDate() - 6))
    },
    {
      id: 2,
      type: 'assignment',
      person: { name: 'Hilary Mahy', id: 10 },
      assigned: { name: 'Kristin Watson', href: '#' },
      date: new Date(new Date().setDate(new Date().getDate() - 2))
    },
    {
      id: 3,
      type: 'tags',
      person: { name: 'Hilary Mahy', id: 10 },
      tags: [
        { name: 'Bug', href: '#', color: 'fill-red-500' },
        { name: 'Accessibility', href: '#', color: 'fill-indigo-500' },
      ],
      date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 4,
      type: 'comment',
      person: { name: 'Jason Meyers', href: '#' },
      comment:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
      date: new Date(new Date().setDate(new Date().getDate() - 6))
    },
    {
      id: 5,
      type: 'status',
      icon: 'faSpinner',  
      person: { name: 'Marius Schulte', id: 1 },
      status: {
        name: 'In Bearbeitung', href: '#', colorIcon: 'orange', colorTag: 'fill-orange-500'
      },
      date: new Date(new Date().getTime() - (6 * 60 * 60 * 1000))
    },
    {
      id: 6,
      type: 'status',
      icon: 'faCircleCheck',      
      person: { name: 'Max Mustermann', href: '#' },
      status: {
        name: 'Erledigt', href: '#', colorIcon: 'green', colorTag: 'fill-green-500'
      },
      date: new Date(new Date().getTime() - (1 * 60 * 60 * 1000))
    },
    {
      id: 7,
      type: 'status',
      icon: 'faCircleXmark',      
      person: { name: 'Max Mustermann', href: '#' },
      status: {
        name: 'Abgebrochen', href: '#', colorIcon: 'red', colorTag: 'fill-red-500'
      },
      date: new Date(new Date().getTime() - (30 * 60 * 1000)),
    }
  ]);*/

  const handleComment = (event) => {
    event.preventDefault();

    let newCommentValue = {
      id: uuidv4(),
      type: 'comment',
      person: { name: props.user.vorname+" "+props.user.nachname, id: props.user.mitarbeiterId },
      comment: newComment.current.value,
      date: new Date()
    };

    // Fetch the current 'activity' array from the 'aufgabe' state
    const currentActivity = Array.isArray(props.data.activity) ? props.data.activity : [];

    // Append the new comment to the 'activity' array
    let updatedActivity = [...currentActivity, newCommentValue];
  
    // Call the parent's method to update the state
    props.setManually('activity', updatedActivity);
   
    //Reset Textarea
    newComment.current.value="";
   
  }

  /*
  const [selected, setSelected] = useState(moods[5])
  const moods = [
    { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
    { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
  ]
  */

  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }




  const location = useLocation();

  const [deadlineWochentag, setDeadlineWochentag] = useState("");


  const day_of_week = (date) =>  {
    
    var weekday = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    var n = new Date(date).getUTCDay();
    return weekday[n];
  }


  // Wenn Neue Aufgabe aus Kundenkonto aufgerufen wird, Kunden hinterlegen
  useEffect(() => {
    if(location.state && location.state.selectedKunde.value !== "") {
      props.setSelect("kunde", location.state.selectedKunde);
    }

    if(props.user && props.user.mitarbeiter) {
      props.setSelect("mitarbeiter", {"value":props.user.mitarbeiterId, label: props.user.mitarbeiterId+" | "+props.user.vorname+" "+props.user.nachname})
    }

  }, [])

  //Wochentag bei Deadline anzeigen
  useEffect(() => {
    if(moment(props.data.deadline, "YYYY-MM-DD", true).isValid())
    {
      setDeadlineWochentag(day_of_week(props.data.deadline));
    } else {
      setDeadlineWochentag("");
    }    
  }, [props.data.deadline]);

  //Kein Datum in der Vergangenheit zulassen.



  const handleUpload = () => {
    //console.log("Upload...")
  }
  


  return (
    <Container fluid >
      <Row>
        <Col xs={12} md={6} xl={4}>

        <fieldset
          className="border"
          style={{ background: "white", borderRadius: "8px" }}
        >
          <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
            Aufgabe
          </legend>

            {props.data._id &&
              <Row>
                {props.data.ersteller &&
                  <Col xs={12}>
                    <b className="mb-3">Ersteller: </b>{props.data.ersteller}
                  </Col>
                }
                <Col xs={12} md={6}>
                  <b className="mb-3">Erstellt: </b>{moment(props.data.createdAt).format('dd DD.MM.YY, HH:mm')}
                </Col>
                <Col xs={12} md={6}>
                  <b className="mb-3">Aktualisiert: </b>{moment(props.data.updatedAt).format('dd DD.MM.YY, HH:mm')}
                </Col>

                
              </Row>
            }



            {props.history?.length >= 1 &&
              <Row>
                <Col>
                  <b className="mb-3">Folgeaufgaben</b><br />
                  {props.history.map((id, index) =>
                    <span key={id}>
                      <Link
                        style={{
                          color: id==props.data.taskId ? "#000" : "#c58164",
                          textDecoration: id==props.data.taskId ? "none" : "underline",
                          fontWeight: id==props.data.taskId ? "bold" : "normal"
                        }}
                        to={"/dash/aufgabe/"+id}
                      >
                        {id}
                      </Link>
                      {index !== props.history.length - 1 && " > "}
                    </span>
                  )}
                </Col>
              </Row>
            }

            <Row>
              {props.data.partner && 
                <Col xs={12}>
                  <Form.Label>Partner zuständig: {props.data.partner}</Form.Label>
                </Col>
              }
              <Col sm={12} lg={6}>
                <Form.Label>Aufgaben ID</Form.Label>
                <Form.Control
                  className="readOnly"
                  type="text"
                  name="taskId"
                  value={props.data.taskId ? props.data.taskId : "-wird beim Speichern vergeben-" }
                  onChange={()=>{}}
                />
              </Col>
            </Row>

            <SelectKundeObj
              setSelect={props.setSelect}
              selectedValue={props.data.kunde}
            />
          
          {/*
            <SelectKunde
              setSelect={props.setSelect}
              selectedValue={props.data.kdnr}
            />
          */}

          <SelectVerantwortung
            setSelect={props.setSelect}
            selectedValue={props.data.verantwortung}
          />

          <SelectGruppe
            setSelect={props.setSelect}
            selectedValue={props.data.gruppe}
          />

          {/* Bearbeiter */}
          <SelectMitarbeiterAll
            setSelect={props.setSelect}
            selectedValue={props.data.mitarbeiter}
          />

           {/*
          <SelectTasktype
            setSelect={props.setSelect}
            selectedValue={props.data.art}
          />
          */}

        <Form.Label>Art</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="art"
            value={props.data.art ? props.data.art : ""}
            onChange={(e) => props.setAufgabe(e)}
          >
            <option>-</option>
            <option value="Angebot erstellen">Angebot erstellen</option>
            <option value="Angebot Gas">Angebot Gas</option>
            <option value="Angebot Solar">Angebot Solar</option>
            <option value="Angebot Strom">Angebot Strom</option>
            <option value="Berater Connect">Berater Connect</option>
            <option value="Berater Connect Dateien">Berater Connect Dateien</option>
            <option value="Beratung">Beratung</option>
            <option value="Bestandsübertragung">Bestandsübertragung</option>
            <option value="Connect Prozess">Connect Prozess</option>
            <option value="E-Mail vervollständigen">E-Mail vervollständigen</option>
            <option value="Energieangebot">Energieangebot</option>
            <option value="Erstkontakt">Erstkontakt</option>
            <option value="Exklusivmakler">Exklusivmakler</option>
            <option value="Female Business">Female Business</option>
            <option value="GoXplore-Join Now">GoXplore-Join Now</option>

            <option value="Lead-Mietkautionsversicherung">Lead-Mietkautionsversicherung</option>
            
            <option value="Maklerprozess">Maklerprozess</option>
            <option value="Objektaufnahme">Objektaufnahme</option>
            <option value="Onboarding Neukunde">Onboarding Neukunde</option>
            <option value="Schadensmeldung">Schadensmeldung</option>
            <option value="Supportticket - GoXplore">Supportticket - GoXplore</option>
            <option value="Telefax">Telefax</option>
            <option value="Telefon">Telefon</option>
            <option value="Termin vereinbaren">Termin vereinbaren</option>
            <option value="Tippgeber/Influencer">Tippgeber/Influencer</option>
            <option value="Tippgeber verifiziert">Tippgeber verifiziert</option>
            <option value="ToDo">ToDo</option>
            <option value="Versicherungen-Gewerbe">Versicherungen-Gewerbe</option>
            <option value="Versicherungen-KFZ">Versicherungen-KFZ</option>
            <option value="Versicherungen-Kranken">Versicherungen-Kranken</option>
            <option value="Versicherungen-Leben">Versicherungen-Leben</option>
            <option value="Versicherungen-Pflege">Versicherungen-Pflege</option>
            <option value="Versicherungen-Sach">Versicherungen-Sach</option>
            <option value="Vorteilsparter anlegen">Vorteilsparter anlegen</option>
            <option value="Rückruf">Rückruf</option>
          </Form.Select>


          <Form.Label>Status</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="status"
            value={props.data.status ? props.data.status : "nicht_begonnen"}
            onChange={(e) => props.setAufgabe(e)}
          >
            <option value="Nicht begonnen">Nicht begonnen</option>
            <option value="In Bearbeitung">In Bearbeitung</option>
            <option value="Erledigt">Erledigt</option>
            <option value="Zurückgestellt">Zurückgestellt</option>
            <option value="Abgebrochen">Abgebrochen</option>
          </Form.Select>

          <Form.Label>Priorität</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="prio"
            value={props.data.prio ? props.data.prio : "normal"}
            onChange={(e) => props.setAufgabe(e)}
          >
            <option value="höchste">höchste</option>
            <option value="hoch">hoch</option>
            <option value="normal">normal</option>
            <option value="niedrig">niedrig</option>
            <option value="niedrigste">niedrigste</option>
          </Form.Select>

       

          <Form.Label>
            Deadline
            {(deadlineWochentag !== "" && deadlineWochentag !== undefined) &&
              <span> - Wochentag: {deadlineWochentag}</span>
            }
          </Form.Label>
          <Form.Control
            type="date"
            name="deadline"
            min={new Date().toISOString().split('T')[0]}
            value={props.data.deadline ? props.data.deadline : ""}
            onChange={(e) => props.setAufgabe(e)}
          />

          {/* 
            var curr = new Date();
curr.setDate(curr.getDate() + 3);
var date = curr.toISOString().substring(0,10);
<input id="dateRequired" type="date" name="dateRequired" defaultValue={date} /> 


=> new Date(new Date().setDate(new Date("2022-05-10").getDate() + 3)).toISOString().substring(0,10);
          
          */}



          <Form.Label>Deadline setzen</Form.Label><br />
          <Button onClick={() => props.setManually("deadline", new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0,10))} className="ms-1 mb-1">+ 1 Tag</Button>
          <Button onClick={() => props.setManually("deadline", new Date(new Date().setDate(new Date().getDate() + 2)).toISOString().substring(0,10))} className="ms-1 mb-1">+ 2 T.</Button>
          <Button onClick={() => props.setManually("deadline", new Date(new Date().setDate(new Date().getDate() + 3)).toISOString().substring(0,10))} className="ms-1 mb-1">+ 3 T.</Button>
          <Button onClick={() => props.setManually("deadline", new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().substring(0,10))} className="ms-1 mb-1">+ 5 T.</Button>
          <Button onClick={() => props.setManually("deadline", new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().substring(0,10))} className="ms-1 mb-1">+ 7 T.</Button>

          </fieldset>

        </Col>

   
        <Col xs={12} md={6} xl={8}>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
                className="float-none w-auto p-2"
                style={{
                  color: COLORS.primCol,
                  background: COLORS.primBG,
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
              Inhalte
            </legend>

              <Form.Label>Betreff</Form.Label>
              <Form.Control
                type="text"
                placeholder="Betreff"
                name="betreff"
                value={props.data.betreff ? props.data.betreff : "" }
                onChange={(e) => props.setAufgabe(e)}
              />

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Beschreibung</Form.Label>
                <Form.Control as="textarea" rows={10}
                  placeholder="Aufgabenbeschreibung"
                  name="beschreibung"
                  value={props.data.beschreibung ? props.data.beschreibung : ""}
                  onChange={(e) => props.setAufgabe(e)}
                />
              </Form.Group>


              
              <Form.Label>Datei/-en</Form.Label><br />
              {(!props.data.dateien || props.data.dateien.length == 0 )&& <i style={{fontSize:"85%"}}>Keine Dateien vorhanden.</i>}

              <ul style={{paddingLeft: "0px"}}>
                {props.data.dateien && props.data.dateien.map((datei) => 
                  <li
                    key={datei.title}
                    className="mt-2"
                    style={{listStyle: "none", textDecoration: "underline", cursor: "pointer", width: "fit-content"}}
                    onClick={() => window.open(datei.url, '_blank').focus()}
                  >
                    {datei.title.substring(36)}
                  </li>
                )}
              </ul>
              

              {/*
              <Col xs={6} md={4} style={{marginTop: "10px", cursor: "pointer"}} onClick={handleUpload}>
                   <div style={{borderStyle: "solid", borderRadius: "16px", width: "100px", aspectRatio: "1", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                      <FontAwesomeIcon icon={faCirclePlus} size="2x" style={{marginBottom: "10px"}} />
                      <p style={{display: "inline-flex", margin: "0px"}}>Upload</p>
                  </div>
              </Col>
              
              
              <Row>
                <Col>
                  <Button onClick={handleUpload} className="d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={faCirclePlus} size="lg" style={{margin: "0px 8px 0px 0px"}} />Upload Datei</Button>
                  {"files picked?" == true &&
                    <p>Ausgeben der Liste</p>
                  }
                </Col>
              </Row>
              */}


              {/*
              <Row style={{alignItems: "flex-start"}}>
                <Col xs={6} md={1} className="ms-2">
                  <div style={{width: "100px", height: "100px", background: "grey", borderRadius: "8px", margin:"8px"}}></div>
                </Col>
                <Col xs={6} md={1} className="ms-2">
                  <div style={{width: "100px", height: "100px", background: "grey", borderRadius: "8px", margin:"8px"}}></div>
                </Col>
                <Col xs={6} md={1}>
                  <div style={{width: "100px", height: "100px", background: "grey", borderRadius: "8px", margin:"8px"}}></div>
                </Col>
              </Row>
            */}



    {props.data._id &&
      <Row>
        <Col className="pt-3">
        <h5 className="mb-3">Aufgabenverlauf</h5>
        <div className="flow-root">
          <ul role="list" className="-mb-8 pl-0">
            {props.data.activity && props.data.activity.length > 0 &&
              props.data.activity.map((activityItem, activityItemIdx) => (
              <li key={activityItem.id}>
                <div className="relative pb-6">
                  {activityItemIdx !== props.data.activity.length - 1 ? (
                    <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    {activityItem.type === 'comment' ? (
                      <>
                        <div className="relative">
                          {/*<img
                              className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                              src={activityItem.imageUrl}
                              alt=""
                            />
                          */}
                          <div
                            className="h-10 w-10 flex justify-center align-items-center rounded-full bg-indigo-500 ring-8 ring-white"
                          >
                            {props.user?.vorname?.length > 0 && props.user?.nachname?.length > 0 && 
                              <span className="fw-bold text-white">{props.user?.vorname[0]}{props.user?.nachname[0]}</span>
                            }
                          </div>

                          <span className="absolute -bottom-2 -right-1 rounded-tl bg-white px-0.5 py-px">
                            <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm">
                              <span href={activityItem.person.href} className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </span>
                            </div>
                            <p className="text-xs m-0 text-gray-500">{moment(activityItem.date).fromNow()}</p>
                          </div>
                          <div className="text-md mt-1 text-gray-900">
                            <p className="mb-0">{activityItem.comment}</p>
                          </div>
                        </div>
                      </>
                    ) : activityItem.type === 'status' ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">                        
                              <FontAwesomeIcon
                                icon={iconMap[activityItem.icon]}
                                size="lg"
                                style={{color: activityItem.status.colorIcon}}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-0">
                          <div className="text-sm leading-8 text-gray-500">
                            <span className="mr-0.5">
                              <a href={activityItem.person.href} className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </a>{' '}
                              hat den Status verändert auf
                            </span>{' '}
                            <span className="mr-0.5">
                            
                                  <a
                                    href=""
                                    className="cursor-default inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                  >
                                    <svg
                                      className={classNames(activityItem.status.colorTag, 'h-1.5 w-1.5')}
                                      viewBox="0 0 6 6"
                                      aria-hidden="true"
                                    >
                                      <circle cx={3} cy={3} r={3} />
                                    </svg>
                                    {activityItem.status.name}
                                  </a>{' '}

                            </span>
                            <span className="whitespace-nowrap text-xs">{moment(activityItem.date).fromNow()}</span>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/*
                    : activityItem.type === 'assignment' ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                              <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-1.5">
                          <div className="text-sm text-gray-500">
                            <a href={activityItem.person.href} className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </a>
                            {' hat '}
                            <a href={activityItem.assigned.href} className="font-medium text-gray-900">
                              {activityItem.assigned.name}
                            </a>{' hinzugefügt '}
                            <span className="whitespace-nowrap text-xs">{moment(activityItem.date).fromNow()}</span>
                          </div>
                        </div>
                      </>
                    ) : activityItem.type === 'tags' ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                              <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-0">
                          <div className="text-sm leading-8 text-gray-500">
                            <span className="mr-0.5">
                              <a href={activityItem.person.href} className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </a>
                              {' hat Status vergeben: '}
                            </span>{' '}
                            <span className="mr-0.5">
                              {activityItem.tags.map((tag) => (
                                <Fragment key={tag.name}>
                                  //<a href=tag.href erst wenn in mongodb pflegen!
                                  <div
                                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                  >
                                    <svg
                                      className={classNames(tag.color, 'h-1.5 w-1.5')}
                                      viewBox="0 0 6 6"
                                      aria-hidden="true"
                                    >
                                      <circle cx={3} cy={3} r={3} />
                                    </svg>
                                    {tag.name}
                                  </div>{' '}
                                </Fragment>
                              ))}
                            </span>
                            <span className="whitespace-nowrap text-xs">{moment(activityItem.date).fromNow()}</span>
                          </div>
                        </div>
                      </>
                    )
                    */}


                  </div>
                </div>
              </li>
            ))}
          </ul>

          {/* New comment form */}
        <div className="mt-6 flex gap-x-3">
          {/*
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
              className="h-6 w-6 flex-none rounded-full bg-gray-50"
            />
          */}
          {/*
            <div
              className="h-10 w-10 flex justify-center align-items-center rounded-full bg-indigo-500 ring-8 ring-white"
            >
              <span className="fw-bold text-white">{user?.vorname[0]}{user?.nachname[0]}</span>
            </div>
          */}
          <form action="" className="relative flex-auto">
            <div className="overflow-hidden rounded-lg shadow-md">
            {/* <div className="overflow-hidden rounded-lg pb-12 shadow-sm"> */}
              <textarea
                style={{border: "1.5px solid #0000001f", borderRadius: "8px"}}
                rows={2}
                name="comment"
                id="comment"
                className="block w-full resize-none bg-white px-2 py-2 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring focus:outline-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Kommentar..."
                defaultValue={''}
                ref={newComment}
              />
            </div>

            {/* Smiley/Status + Datei anhängen */}
            {/*<div className="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
            
            <div className="flex items-center space-x-5">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                  >
                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Attach a file</span>
                  </button>
                </div>
                <div className="flex items-center">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="sr-only">Your mood</Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                            <span className="flex items-center justify-center">
                              {selected.value === null ? (
                                <span>
                                  <FaceSmileIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                  <span className="sr-only">Add your mood</span>
                                </span>
                              ) : (
                                <span>
                                  <span
                                    className={classNames(
                                      selected.bgColor,
                                      'flex h-8 w-8 items-center justify-center rounded-full'
                                    )}
                                  >
                                    <selected.icon className="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
                                  </span>
                                  <span className="sr-only">{selected.name}</span>
                                </span>
                              )}
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute bottom-10 z-10 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                              {moods.map((mood) => (
                                <Listbox.Option
                                  key={mood.value}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-gray-100' : 'bg-white',
                                      'relative cursor-default select-none px-3 py-2'
                                    )
                                  }
                                  value={mood}
                                >
                                  <div className="flex items-center">
                                    <div
                                      className={classNames(
                                        mood.bgColor,
                                        'flex h-8 w-8 items-center justify-center rounded-full'
                                      )}
                                    >
                                      <mood.icon
                                        className={classNames(mood.iconColor, 'h-5 w-5 flex-shrink-0')}
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <span className="ml-3 block truncate font-medium">{mood.name}</span>
                                  </div>
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            
              <button
                type="submit"
                className="rounded-md bg-indigo-600 text-white px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500"
              >
                Hinzufügen
              </button>
            </div>
              */}

              <button
                onClick={handleComment}
                className="mt-2 rounded-md bg-indigo-600 text-white px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500"
              >
                Hinzufügen
              </button>

          </form>
        </div>


        </div>

        </Col>
      </Row>
    }



              

            </fieldset>
        </Col>


      </Row>

    </Container>
  );
}

export default NeueAufgabe