import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridFilterInputValue, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';



import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';

import StripedDataGrid from "../../Bausteine/StripedDataGrid";

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

import FilterSidebar from "./FilterSidebar";
import { CSSTransition } from 'react-transition-group'


function KundenListeServer() {

  const navigate = useNavigate();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterSidebarActive, setFilterSidebarActive] = useState(false);

  const [kundenFiltered, setKundenFiltered] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({});
 
  const previousPaginationModel = useRef(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const [filterCriteria, setFilterCriteria] = useState({});


  
  
  const fetchRows = async (page, pageSize, sortModel, filterModel) => {
    setLoading(true);
    const response = await AuthAxios.get('/kunde/server', {
      params: {
        page: page,
        pageSize: pageSize,
        sort: JSON.stringify(sortModel),
        filter: JSON.stringify(filterModel),
      },
    });
    setKundenFiltered(response.data.data);
    setTotalCount(response.data.total);
    setLoading(false);
  };
  
  
  useEffect(() => {
    // Call fetchRows in the useEffect callback
    fetchRows(1, 25, [{ field: 'kdnr', sort: 'desc' }], {});
  }, [])
  
  
  //useEffect(() => {
    //fetchRows(paginationModel.page+1, paginationModel.pageSize, sortModel, filterModel);
    //}, [paginationModel]);
    
    
    const handlePaginationModelChange = (newPagination) => {
      fetchRows(newPagination.page+1, newPagination.pageSize, [{ field: 'kdnr', sort: 'desc' }], {});
    };
    
    const handlePageChange = (newPage) => {
      console.log("new PAge"+newPage);
    }
    
    
    const handleSortModelChange = (newSortModel) => {
      //setSortModel(newSortModel);
      //fetchRows(page+1, pageSize, newSortModel, filterModel);
    };
  
    
   

    // Shared variable to check keyup and delay the search
    //let debounceTimeout = null;

    const onFilterChange = React.useCallback( (field, value) => {

      //Suche links oben
      const quickFilterValue = field.quickFilterValues[0];

      let columnFilters = {};

      field.items.forEach(filter => {
        if (filter.field) {
          // Add each filter value to the columnFilters object
          columnFilters[filter.field] = filter.value;
        } else {
          // Log an error or handle the undefined columnField case
          console.error("Undefined columnField in filter:", filter);
        }  
      });
    
      let combinedFilter = {
        ...columnFilters,
        allgemeineSuche: quickFilterValue
      };

      setFilterCriteria(combinedFilter);

      /*
      fetchRows(
        paginationModel.page + 1,
        paginationModel.pageSize,
        [{ field: 'kdnr', sort: 'desc' }],
        { filter: combinedFilter }
      );
      */

    }, []);


    const startSearch = () => {

      let searchInput = document.getElementsByClassName('MuiInputBase-inputTypeSearch')[0].value;
      
      let filterCriteria2 = { "allgemeineSuche": searchInput };
      
      fetchRows(
          paginationModel.page + 1,
          paginationModel.pageSize,
          [{ field: 'kdnr', sort: 'desc' }],
          { filter: filterCriteria2 }
      );
    };


    /*
    const debounceFetchRows = (filterCriteria) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
          fetchRows(
              paginationModel.page + 1,
              paginationModel.pageSize,
              [{ field: 'kdnr', sort: 'desc' }],
              { filter: filterCriteria }
          );
      }, 300);
    };
    */

    //useEffect(() => {
      //const handleKeyUp = () => {
          // Assuming you want to use some value for the filter when keyup occurs
          //const filterValue = document.getElementById('searchInput').value; // or however you obtain this value
        //  const filterCriteria = { /* ... set up filter criteria based on the input value ... */ };

      //    debounceFetchRows(filterCriteria);
     // };
/*
      const timeoutId = setTimeout(() => {
          const inputElement = document.getElementsByClassName('MuiInputBase-inputTypeSearch')[0];
          if (inputElement) {
              inputElement.addEventListener('keyup', handleKeyUp);
          } else {
              console.error("Input element not found");
          }
      }, 0);

      return () => {
          clearTimeout(timeoutId);
          const inputElement = document.getElementsByClassName('MuiInputBase-inputTypeSearch')[0];
          if (inputElement) {
              inputElement.removeEventListener('keyup', handleKeyUp);
          }
          clearTimeout(debounceTimeout);
      };
    }, []);
    */



  /*
    //Search input check keyup for debounce search
    useEffect(() => {

      // Function to handle keyup event
      const handleKeyUp = () => {
        // Clear and reset the shared timeout
        clearTimeout(debounceTimeout);
      };

      // Use a timeout to wait for the element to be available
      const timeoutId = setTimeout(() => {
        const inputElement = document.getElementsByClassName('MuiInputBase-inputTypeSearch')[0];
          if (inputElement) {
            inputElement.addEventListener('keyup', handleKeyUp);
          } else {
            console.error("Input element not found");
          }
        }, 0); // Timeout with 0ms to delay execution until after the DOM has updated

      // Cleanup function
      return () => {
          clearTimeout(timeoutId); // Clear the timeout set for waiting for the element
          const inputElement = document.getElementsByClassName('MuiInputBase-inputTypeSearch')[0];
          if (inputElement) {
              inputElement.removeEventListener('keyup', handleKeyUp);
          }
          clearTimeout(debounceTimeout); // Also clear the debounce timeout
      };
  }, []);
  */



    
    
    const handleNavigation = (e) => {
      //console.log(e.id);
      navigate("/dash/kunde/" + e.id);
    }
    
    
  /*
  useEffect(() => {

    setKundenFiltered(kunden.filter(kunde => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;
      
      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });



      if (filterActive) {

        setFilterSidebarActive(true);

            // Filterung
            const appzugriffCb = filter.appzugriffCb ? kunde.appzugriffCb : true;
            const maklervollmachtCb = filter.maklervollmachtCb ? kunde.maklervollmachtCb : true;
            const connectCb = filter.connectCb ? kunde.connectCb : true;
            const tippgeber_verifiziertCb = filter.tippgeber_verifiziertCb ? kunde.tippgeber_verifiziertCb : true;

            // negative Filterung
            const appzugriffCbNot = filter.appzugriffCbNot ? !kunde.appzugriffCb : true;
            const maklervollmachtCbNot = filter.maklervollmachtCbNot ? !kunde.maklervollmachtCb : true;
            const connectCbNot = filter.connectCbNot ? !kunde.connectCb : true;
            const tippgeber_verifiziertCbNot = filter.tippgeber_verifiziertCbNot ? !kunde.tippgeber_verifiziertCb : true;

            // Interessen
            const exklusivpartner = filter.exklusivpartner ? kunde.exklusivpartner : true;
            const investor = filter.investor ? kunde.investor : true;
            const tippgeber = filter.tippgeber ? kunde.tippgeber : true;
            const vorteilspaket = filter.vorteilspaket ? kunde.vorteilspaket : true;
            const vorteilspartner = filter.vorteilspartner ? kunde.vorteilspartner : true;


            return appzugriffCb && maklervollmachtCb && connectCb && tippgeber_verifiziertCb && appzugriffCbNot && maklervollmachtCbNot && connectCbNot && tippgeber_verifiziertCbNot && exklusivpartner && investor && tippgeber && vorteilspaket && vorteilspartner;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));


  },[filter])
  */





  const columns = [
    {
      field: "kdnr",
      headerName: "KdNr",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mitarbeiter",
      headerName: "Betreuung Intern",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <span>
          {params.row.mitarbeiter ?
            params.row.mitarbeiter.label
            : "-"}
        </span>
      ),
      valueGetter: (params) => {
        return params.row.mitarbeiter ? params.row.mitarbeiter.label : "-";
      },
      headerClassName: "super-app-theme--header",
    },
    {
      field: "exklusivmakler",
      headerName: "Standort P. (Direkt!)",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <span>
          {(params.row.exklusivmakler && params.row.exklusivmakler.label !== "Nordkirchen | VIN1 Backoffice") ?
            params.row.exklusivmakler.label
            : "-"}
        </span>
      ),
      valueGetter: (params) => {
        return (params.row.exklusivmakler && params.row.exklusivmakler.label !== "Nordkirchen | VIN1 Backoffice") ?
          params.row.exklusivmakler.label
          : "-";
      },
      //filterOperators: customFilterOperators, // Define this if the default filtering is not sufficient
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anrede",
      headerName: "Anrede",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "firma",
      headerName: "Firma",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "geburtsdatum",
      headerName: "Geburtsd.",
      type: "number",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <>
          {(params.row.geburtsdatum && params.row.geburtsdatum != "0000-00-00" ) && <span>{new Date(params.row.geburtsdatum).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
          {(!params.row.geburtsdatum || params.row.geburtsdatum == "0000-00-00") && <span>-</span>}
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 70,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "land",
      headerName: "Land",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "telefon",
      headerName: "Telefon",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.telefon}`}>{params.row.telefon}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobil",
      headerName: "Mobil",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.mobil}`}>{params.row.mobil}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    */
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.createdAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.updatedAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "webseite",
      headerName: "Webseite",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>

          <Col xs={12} lg={4}>
              <GridToolbarQuickFilter placeholder="KdNr, Vorname, Name" />
              <Button className="ml-2 px-4" onClick={startSearch}>Suche</Button>
          </Col>
    
         {/* <Col xs={12} lg={3}>
            <Row>
            
            </Row>
          </Col>
           
            <Col xs={6} lg={2} className="pl-0">
            </Col>
            <Col xs={6} lg={1}>
              <GridToolbarFilterButton />
            </Col>
            <Col xs={6} lg={1}>
              <GridToolbarColumnsButton />
            </Col>
            
            <Col xs={6} lg={1}>
              <GridToolbarExport />
            </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>


          <Col xs={12} md={2}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={() => setFilterIsOpen(!filterIsOpen)}
          >
            <FontAwesomeIcon
              style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
              icon={faFilter}
              size="lg"
            />
            {filterSidebarActive && <span>Filter (aktiv)</span>}
            {!filterSidebarActive && <span>Filter</span>}
          </Col>
        */}
        
        </Row>

      </GridToolbarContainer>
    );
  }


 



  return (
    <>
      <div style={{width: "100%" }}>
        <Box
          sx={{
            height: `calc(100vh - 35px)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "35px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >
          <StripedDataGrid
            rows={kundenFiltered}
            rowCount={totalCount}
            columns={columns}
            getRowId={(row) => row._id}
            loading={loading}
            //pageSizeOptions={[5]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(newModel) => {
              // Compare the new pagination model with the previous one
              if (
                newModel.pageSize !== previousPaginationModel.current?.pageSize ||
                newModel.page !== previousPaginationModel.current?.page
              ) {
                //console.log(newModel.pageSize, newModel.page);
                //set new default Value for pageSize, ... & fetch new Data
                setPaginationModel(newModel);
                handlePaginationModelChange(newModel);
              }
            }}
            autoPageSize
            disableSelectionOnClick
            density={'compact'}
            onRowClick={handleNavigation}

            filterMode="server"
            //onFilterModelChange={onFilterChange}
          
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
          />

        </Box>

      </div>

    </>
  );
}


export default KundenListeServer