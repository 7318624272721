import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'

import AuthAxios from "../utils/AuthAxios";
import Swal from "sweetalert2";

function SelectVertriebsteam (props) {

    const [selectedValues, setSelectedValues] = useState([]);

    const [vertriebsteams, setVertriebsteams] = useState([]);

    /*
    const handleValue = (selected) => {
        selected.map(s => {
            console.log({value: s, label: s});    
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));       
         })
    }
    */

    useEffect(() => {

        async function getVertriebsteams () {
        
            try {
                const res = await AuthAxios.get('/vertriebsteam');

                const vertriebsteamListe = [];
                res.data.map(g => {
                    vertriebsteamListe.push({"value": "T"+g.vertriebsteamId, "label": "T"+g.vertriebsteamId+" | "+g.vertriebsteamname});
                });
                setVertriebsteams(vertriebsteamListe);
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Vertriebsteams konnten nicht geladen werden."
                });
            }               
        }
        getVertriebsteams();  

    },[]);

    useEffect(() => {

        setSelectedValues(vertriebsteams.filter(team => props.selectedValues.includes(team.value)) );

    },[props.selectedValues]);
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        props.setSelectMulti(name, selection);
    };

    /*
    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex" }}>
          <div>{label}</div>
        </div>
    );
    */

    return (
        <Col xs={12} md={12}>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedValues ? selectedValues : ""}
                //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                onChange={(selection, e) => handleChange(e.name, selection)}
                options={vertriebsteams}
                placeholder={<div className="select-placeholder-text">Wähle ein oder mehrere Vertriebsteams</div>}
                name="vertriebsteams"
            />
        </Col>
    );
   
    
}

export default SelectVertriebsteam