import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { Scrollbar } from 'react-scrollbars-custom';

import { COLORS } from '../../constants';

import style from './TemplateVersenden.module.css'
import MessagePreview from './MessagePreview'
import CardSub from '../Kunden/DateienBausteine/CardSub';

//import { formatDateTimeLong } from '../../utils/date'

function TemplateVersenden() {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedKunde, setSelectedKunde] = useState({});

    const [filterStatus, setFilterStatus] = useState(false);
    const [filter, setFilter] = useState({});

    const [nachricht, setNachricht] = useState("");
    const [dateien, setDateien] = useState([]);
    const [frage, setFrage] = useState("");
    const [umfrage, setUmfrage] = useState(true);
    const [antworten, setAntworten] = useState({});

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [anzahlPersonen, setAnzahlPersonen] = useState(0);

    //Message Preview
    const [placeholderMapping, setPlaceholderMapping] = useState({});

    const [text, setText] = useState("");

    //Testversand
    const [testnummer, setTestnummer] = useState(null);

    

    const [versandzeitpunkt, setVersandzeitpunkt] = useState(
        (new Date( new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString()).slice(0, -5)
    );
    

    const demoData = {
        vorname: "Martin",
        nachname: "Mustermann",
        anschrift: "Aspastraße 24",
        plz: "59394",
        stadt: "Nordkirchen",
        neueDokumente: 5
    }

    
  const getWATemplates = async () => {

    try {
        const res = await AuthAxios.get('/wa/get-templates');
        setTemplates(res.data.data);
    }
    catch(err) {
        console.log(err);
        Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Whatsapp-Templates konnten nicht geladen werden."
        });
    }

  };
  
  
  useEffect(() => {
    getWATemplates();
  }, [])


  useEffect(() => {
    setPlaceholderMapping({});
  },[selectedTemplate]);



    const handleCheckRecipents = async() => {
        try {
            let res = await AuthAxios.post('/wa/get-kunden', {
                filter: {}
            });
            setAnzahlPersonen(res.data.anzahl);
        }
        catch(err) {
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Kunden konnten nicht ermittelt werden."
            })
        }
    }


    const handleSendTestNewsletter = async() => {
        try {
            await AuthAxios.post('/wa/send-test', {
                templateName: selectedTemplate.name,
                nummer: testnummer,
                placeholder: []
            });
        }
        catch(err) {
            console.log(err);
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Testnachricht konnten nicht gesendet werden."
            })
        }
    }


    return (


            <Container fluid className="pb-5">

                <Row>
                    <Col xs={12} md={6}>
                        <h1 className="px-3">Whatsapp Newsletterversand</h1>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xs={12} md={4}>

                        <fieldset
                            className="border"
                            style={{ background: "white", borderRadius: "8px" }}
                        >
                            <legend
                                className="float-none w-auto p-2"
                                style={{
                                    color: COLORS.primCol,
                                    background: COLORS.primBG,
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                }}
                            >
                                Empfänger Filter
                            </legend>

                            {/* ===== Filter Ja/Nein ===== */}
                            <Row style={{ justifyContent: "center", marginBottom: "50px" }} >
                                <p className="text-center" style={{ margin: "0px" }}>An alle VIN1 Kunden oder Filtern?</p>
                              
                                    {!filterStatus &&
                                        <>
                                            <Col
                                                xs={6} md={6}
                                                style={{ marginTop: "20px" }}
                                                onClick={() => setFilterStatus(false)}
                                            >
                                                <CardSub key={"nofilter"} title={"Kein Filter"} selected={1} color={"#520da7"} />
                                            </Col>
                                            <Col
                                                xs={6} md={6}
                                                style={{ marginTop: "20px" }}
                                                onClick={() => setFilterStatus(true)}
                                            >
                                            
                                                <CardSub key={"filter"} title={"Filtern"} />
                                            </Col>
                                        </>
                                    }

                                    {filterStatus &&
                                        <>
                                            <Col
                                                xs={6} md={6}
                                                style={{ marginTop: "20px" }}
                                                onClick={() => {
                                                    setFilterStatus(false);
                                                    setFilter({});
                                                }}
                                            >
                                                <CardSub key={"nofilter"} title={"Kein Filter"} />
                                            </Col>
                                            <Col
                                                xs={6} md={6}
                                                style={{ marginTop: "20px" }}
                                                onClick={() => setFilterStatus(true)}
                                            >
                                                <CardSub key={"filter"} title={"Filtern"} selected={1} color={"#520da7"} />
                                            </Col>
                                        </>
                                    }
                            
                            </Row>


                            {filterStatus &&
                                <>
                                    <h5>Filter einstellen</h5>

                                    <Form.Label>Geschlecht</Form.Label><br />
                                    <Form.Select
                                        aria-label="Default select"
                                        name="geschlecht"
                                        value={filter.geschlecht ? filter.geschlecht : ""}
                                        onChange={(e) => setFilter({ ...filter, geschlecht: e.target.value })}
                                    >
                                        <option>Alle</option>
                                        <option value="männlich">männlich</option>
                                        <option value="weiblich">weiblich</option>
                                    </Form.Select>


                                    <Form.Group className="mt-3">
                                        <Form.Label>Altersspanne</Form.Label><br />
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Label>von</Form.Label><br />
                                                <Form.Control
                                                    type="Number"
                                                    placeholder="minAlter"
                                                    name="minAlter"
                                                    min={0}
                                                    max={100}
                                                    value={filter.minAlter ? filter.minAlter : 0}
                                                    onChange={(e) => setFilter({ ...filter, minAlter: e.target.value })}
                                                />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Label>bis</Form.Label><br />
                                                <Form.Control
                                                    type="Number"
                                                    placeholder="maxAlter"
                                                    name="maxAlter"
                                                    min={0}
                                                    max={100}
                                                    value={filter.maxAlter ? filter.maxAlter : 100}
                                                    onChange={(e) => setFilter({ ...filter, maxAlter: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>


                                    <Form.Check
                                        type="checkbox"
                                        id="bestandsuebertragung"
                                        label="Bestandsübertragung durchgeführt"
                                        name="bestandsuebertragung"
                                        checked={filter.bestandsuebertragung ? true : false}
                                        onChange={(e) => setFilter({ ...filter, bestandsuebertragung: e.target.checked })}
                                    />

                                    <Form.Check
                                        type="checkbox"
                                        id="tippgeber"
                                        label="Tippgeber"
                                        name="tippgeber"
                                        checked={filter.tippgeber ? true : false}
                                        onChange={(e) => setFilter({ ...filter, tippgeber: e.target.checked })}
                                    />

                                </>
                            }

                            <Button
                                style={{
                                    background: "var(--vinColor)",
                                    border: "none",
                                    marginTop: "15px"
                                }}
                                onClick={handleCheckRecipents}
                               
                            >
                                Anzahl Empfänger ermitteln
                            </Button>


                        </fieldset>

                    </Col>




                    <Col xs={12} md={4}>

                        <fieldset
                            className="border"
                            style={{ background: "white", borderRadius: "8px" }}
                        >
                            <legend
                                className="float-none w-auto p-2"
                                style={{
                                    color: COLORS.primCol,
                                    background: COLORS.primBG,
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                }}
                            >
                                Whatsapp Nachricht
                            </legend>

                            <h5>Whatsapp Nachricht Parameter</h5>


                            
                            <Form.Label>Wähle ein Template aus</Form.Label><br />
                            <Form.Select
                                aria-label="Default select"
                                name="template"
                                value={selectedTemplate.name ? selectedTemplate.name : "-"}
                                onChange={(e) => {
                                    const selected = templates.find(template => template.name === e.target.value);

                                    let headerImg = selected.components.find(c => c.type === "HEADER" && c.format === "IMAGE")?.example?.header_handle;
                                    let text = selected.components.find(c => c.type === "BODY")?.text;
                                    let buttons = selected.components.find(c => c.type === "BUTTONS")?.buttons;

                                    setText(text);
                                    let regex = /{{\d+}}/g;
                                    let matches = text.match(regex);

                                    setSelectedTemplate({
                                        ...selected,
                                        headerImg: headerImg,
                                        extractedText: text,
                                        placeholderList: matches,
                                        buttons: buttons
                                        
                                    });
                                }}
                            >
                                <option value="-" disabled>-</option>
                                {templates.map(t => 
                                    <option key={t.id} value={t.name}>{t.name}</option>    
                                )}
                            </Form.Select>

                            {/*
                            <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Nachricht</Form.Label>
                                <Form.Control as="textarea" rows={6}
                                    placeholder="Nachricht"
                                    name="nachricht"
                                    value={nachricht ? nachricht : ""}
                                    onChange={(e) => setNachricht(e.target.value)}
                                />
                            </Form.Group>
                        

                            <div className="mt-5">
                                <label htmlFor="docUpload" className={style.uploadBtn}>
                                    Datei/-en auswählen
                                    <input
                                        id="docUpload"
                                        type="file"
                                        multiple
                                        accept='application/pdf, image/png, image/jpeg'
                                        style={{ display: "none" }}
                                        onChange={() => { }}
                                    />
                                </label>
                            </div>


                            <Form.Group className="mt-5">
                                <Form.Check
                                    type="checkbox"
                                    id="umfrage"
                                    label="Umfrage erstellen"
                                    name="umfrage"
                                    checked={umfrage ? true : false}
                                    onChange={(e) => setUmfrage(e.target.checked)}
                                />

                                {umfrage &&
                                    <>
                                        <Form.Label>Fragestellung</Form.Label><br />
                                        <Form.Control
                                            type="text"
                                            placeholder="Frage"
                                            name="frage"
                                            value={frage ? frage : ""}
                                            onChange={(e) => setFrage(e.target.value)}
                                        />
                                    </>
                                }
                            */}

                            {/*umfrage && frage &&
                                <>
                                    {
                                    + icon einbauen wie bei Bestandsübertragung VIN App!!
                                    }
                                    <div style={{ padding: "15px" }}>
                                        <Form.Label>Möglichkeit 1</Form.Label><br />
                                        <Form.Control
                                            type="text"
                                            placeholder="Antwort 1"
                                            name="antwort1"
                                            value={antworten.a1 ? antworten.a1 : ""}
                                            onChange={(e) => setAntworten({ ...antworten, a1: e.target.value })}
                                        />

                                        <Form.Label>Möglichkeit 2</Form.Label><br />
                                        <Form.Control
                                            type="text"
                                            placeholder="Antwort 2"
                                            name="antwort2"
                                            value={antworten.a2 ? antworten.a2 : ""}
                                            onChange={(e) => setAntworten({ ...antworten, a2: e.target.value })}
                                        />
                                        <Form.Label>Möglichkeit 3</Form.Label><br />
                                        <Form.Control
                                            type="text"
                                            placeholder="Antwort 3"
                                            name="antwort3"
                                            value={antworten.a3 ? antworten.a3 : ""}
                                            onChange={(e) => setAntworten({ ...antworten, a3: e.target.value })}
                                        />
                                        <Form.Label>Möglichkeit 4</Form.Label><br />
                                        <Form.Control
                                            type="text"
                                            placeholder="Antwort 4"
                                            name="antwort4"
                                            value={antworten.a4 ? antworten.a4 : ""}
                                            onChange={(e) => setAntworten({ ...antworten, a4: e.target.value })}
                                        />
                                        <Form.Label>Möglichkeit 5</Form.Label><br />
                                        <Form.Control
                                            type="text"
                                            placeholder="Antwort 5"
                                            name="antwort5"
                                            value={antworten.a5 ? antworten.a5 : ""}
                                            onChange={(e) => setAntworten({ ...antworten, a5: e.target.value })}
                                        />
                                    </div>
                                </>
                                
                                </Form.Group>
                            */}

                            <hr className="mt-4" />

                            {selectedTemplate?.name && selectedTemplate?.components &&
                                <>
                                    <p className="fw-bold">Nachrichten-Text (mit Platzhaltern):</p>
                                    <p>
                                        {selectedTemplate.extractedText}
                                    </p>

                                    <hr />

                                    <p className="fw-bold">Parameter zuweisen</p>
                                    {selectedTemplate.placeholderList &&
                                        Object.entries(selectedTemplate.placeholderList).map(([key, value], index) =>
                                        <>
                                            <Row key={value}>
                                                <Col>
                                                    <p>Parameter: {value}</p>
                                                </Col>

                                                <Col>
                                                    <Form.Select
                                                        aria-label="Default select"
                                                        name="template"
                                                        value={placeholderMapping[value] || "-"}
                                                        onChange={(e) => {
                                                            const newMapping = { ...placeholderMapping, [value]: e.target.value };
                                                            setPlaceholderMapping(newMapping);

                                                            const newText = Object.entries(newMapping).reduce(
                                                                (acc, [placeholder, key]) => acc.replace(placeholder, demoData[key] || placeholder),
                                                                selectedTemplate.extractedText
                                                            );
                                                            setText(newText);
                                                        }}
                                                    >
                                                        <option value="-" disabled>-</option>
                                                        {Object.keys(demoData).map(dataKey => (
                                                            <option key={dataKey} value={dataKey}>{dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Col> 
                                            </Row>
                                            {index !== selectedTemplate.placeholderList.length-1 &&
                                                <div className="px-3">
                                                    <hr />
                                                </div>
                                            }
                                        </>
                                    )}
                                    
                                </>
                            }

                          

                            <hr className="mt-4" />


                            <p className="lead fw-bold">Testversand</p>

                            <Form.Label>Handynummer</Form.Label><br />
                            <Form.Control
                                type="number"
                                placeholder="4915154123456"
                                name="testnummer"
                                value={testnummer ? testnummer : ""}
                                onChange={(e) => setTestnummer(e.target.value.trim())}
                            />



                            <Button
                                style={{
                                    background: "var(--vinColor)",
                                    border: "none",
                                    marginTop: "15px"
                                }}
                                onClick={handleSendTestNewsletter}
                            >
                                Whatsapp Newsletter an Nummer senden
                            </Button>


                                                        
                            <hr className="mt-4" />

                            <div className="mt-3">
                                <p className="lead fw-bold">Wann sollen die Newsletter versendet werden?</p>
                                <Form.Label>Datum+Uhrzeit</Form.Label>
                                <Form.Control
                                    id="versandzeitpunkt"
                                    name="versandzeitpunkt"
                                    type="datetime-local"
                                    value={versandzeitpunkt}
                                    onChange={(e) => setVersandzeitpunkt(e.target.value)}
                                />
                            </div>




                            <hr className="mt-4" />


                            <div className="mt-3">
                                <p className="lead fw-bold">Versand an {anzahlPersonen} Empfänger</p>
                                <h6>Ungefähre Kosten:{" "}
                                    {anzahlPersonen !== 0 &&
                                        <span>€ {(0.1131 * anzahlPersonen).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</span>
                                    }
                                    {anzahlPersonen == 0 &&
                                        <i>Bitte zunächst Anzahl ermitteln</i>
                                    }
                                </h6>
                            </div>

                            <Button
                                style={{
                                    background: "var(--vinColor)",
                                    border: "none",
                                    marginTop: "15px"
                                }}
                                onClick={() => alert("versenden")}
                            >
                                Whatsapp Newsletter versenden
                            </Button>

                        </fieldset>

                    </Col>


                    <Col xs={12} sm={12} md={4}>
                        <h6 className="mb-3 text-center">Ungefähre Vorschau Whatsapp Nachricht</h6>
                        <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[326px]">
                            <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
                            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                            <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                            
                            <Scrollbar style={{ width: "310px", height: "572px" }}>
                                <div className="rounded-[2rem] w-[300px] h-[572px] bg-white dark:bg-gray-800">
                                    {/*
                                        <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-light.png" className="dark:hidden w-[272px] h-[572px]" alt="" />
                                        <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-dark.png" class="hidden dark:block w-[272px] h-[572px]" alt="" />
                                    */}

                                    <div
                                        className="bg-[#0a131a] bg-[url('pages/Whatsapp/whatsappbg.webp')] bg-contain h-100"
                                        style={{padding: "12px 3%"}}
                                    >
                                        <MessagePreview
                                            msg={text}
                                            time={ new Intl.DateTimeFormat('de-DE', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            }).format(new Date())}
                                            isLink={false}
                                            img={selectedTemplate.headerImg}
                                            sent={true}
                                            BtnCTA={"Jetzt ansehen"} // max 25 Zeichen
                                            BtnPhone={"Jetzt anrufen"}
                                            BtnSchnellantwort={"Dies ist ein Test ansehen"}
                                        />
                                    
                                    </div>

                                
                                
                                
                                </div>
                            </Scrollbar>
                        </div>

                    </Col>


                </Row>


            </Container>
    )
}

export default TemplateVersenden