import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';


import style from "./GroupModal.module.css"

import { Form, Button, Modal } from "react-bootstrap";


const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}

const ModalOverlay = (props) => {


    const handleInputChangeGroup = (e) => {
      props.setNewGroup({
        ...props.newGroup,
        [e.target.name]: e.target.value,
      });
    };

    return (
      <Modal show={props.visible} onHide={() => props.setVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize: "70%"}}>{props.title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <p>Vertriebsteams dienen dazu Kontakte/Kunden Bearbeitern zuzuweisen, damit z.B. externe Partner diese Kunden betreuuen können.</p>
          
            <Form.Label>Vertriebsteam ID</Form.Label>
            <Form.Control
              type="number"
              placeholder="Eindeutige Zahl vergeben"
              name="vertriebsteamId"
              value={props.newGroup.vertriebsteamId ? props.newGroup.vertriebsteamId : ""}
              onChange={(e) => handleInputChangeGroup(e)}
            />

            <Form.Label>Vertriebsteam-Name</Form.Label>
            <Form.Control
              type="text"
              name="vertriebsteamname"
              placeholder="Name des Vertriebsteams"
              value={props.newGroup.vertriebsteamname ? props.newGroup.vertriebsteamname : ""}
              onChange={(e) => handleInputChangeGroup(e)}
            />

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Vertriebsteam-Beschreibung</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Vertriebsteam-Beschreibung"
                name="beschreibung"
                value={props.newGroup.beschreibung ? props.newGroup.beschreibung : ""}
                onChange={(e) => handleInputChangeGroup(e)}
              />
            </Form.Group>

        </Modal.Body>
        <Modal.Footer>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#df4759", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => props.setVisible(false)}
            >
              Schließen
            </p>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => {
                    props.setVisible(false)
                    props.createNewGroup(props.newGroup);
                }}
            >
              Speichern
            </p>
        </Modal.Footer>
      </Modal>
    )
}

const VertriebsteamModal = (props) => {

    return (
      <Fragment>
        {ReactDOM.createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {ReactDOM.createPortal(<ModalOverlay title={props.title} newGroup={props.newGroup} setNewGroup={props.setNewGroup} createNewGroup={props.createNewGroup} visible={props.visible} setVisible={props.setVisible} />, document.getElementById('overlay-root'))}
      </Fragment>
      
    )
}

export default VertriebsteamModal