import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import Länder from '../Data/LaenderListe';

function SelectLand (props) {

    const [laender, setLaender] = useState([]);
    
    // Setzen von Default Wert, damit kein leeres Feld
    const [selectedCountry, setSelectedCountry] = useState(
        { value: "Deutschland", label: "Deutschland" }
    );


    // Select befüllen + Setzen von Parent Wert
    useEffect(() => {
        async function setOptions() {
            await setLaender(Länder);  
        }
        setOptions();
    }, []);
     

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {
        //setSelectedCountry(selection);
        props.setSelect(name, selection.value);
    };


    // Dropdown zusammenstellen
    const favourites = [
        { value: "Deutschland", label: "Deutschland" },
        { value: "Österreich", label: "Österreich"},
        { value: "Schweiz", label: "Schweiz"}
    ];

    const allOptions = [
        {
            label: "Häufig verwendete",
            options: favourites
        },
        {
            label: "Andere Länder",
            options: laender
        }
    ];


    return (
        <ReactSelect
            value={props.selectedValue ? {value: props.selectedValue, label: props.selectedValue} : {value: "Deutschland", label: "Deutschland"}}
            onChange={(selection, e) => handleChange(e.name, selection)}
            options={allOptions}
            noOptionsMessage={() => "Suche nach Land um Optionen zu erhalten."}
            filterOption={createFilter({ignoreAccents: false})}
            name="land"
        />
    );
    
}

export default SelectLand