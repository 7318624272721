import React, { Fragment, useContext, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import style from "./CardNotiz.module.css"
import classLister from 'css-module-class-lister';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faFlag as faFlagLight, faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";


import { Button, Col, Row, Form } from 'react-bootstrap'
import { MainContext } from '../../contexts/MainContext';

import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);


function CardVorfall(props) {

    const { user } = useContext(MainContext);

    let navigate = useNavigate();
    const classes = classLister(style);

    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {

    }

    return (
   
      <div className={style.cardContainer}>
        {/*<img className={style.cardImg} src={require("../../img/"+props.img)} alt="" />*/}
        <Row style={{width: "100%"}}>
            
            <Col xs={12} md={9} >
                <span><b>{props.data.partner_name}</b> |  </span>
                <Link to={"/dash/kunde/"+props.data.kdnr} style={{color: "#c58164"}}>{props.data.vorname + " " + props.data.nachname}</Link>
               
                <span className={style.cardDate}>&nbsp;|&nbsp;
                    {new Intl.DateTimeFormat('de-DE', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                    }).format(new Date(props.data.createdAt))}
                </span>
                    {/*<p className={style.cardText} style={{overflow: "hidden", whiteSpace: "no-wrap", textOverflow: "ellipsis"}}>{props.data.notiz}</p>*/}
                    {!showMore &&
                        <ResponsiveEllipsis
                            text={props.data.nachricht}
                            maxLine='2'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                            /*onReflow={(clamped, text) => {
                                if(clamped) {
                                    props.setShowMore(props.data.id, true);
                                } else {
                                    props.setShowMore(props.data.id, false);
                                }
                            }}*/   
                        />
                    }
                    {showMore &&
                        <p className={style.cardText}>{props.data.nachricht}</p>
                    }

            </Col>
          
        </Row>
        
      </div>
  )
}

export default CardVorfall;