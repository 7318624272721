export default [
    {
        "mainCategory": "Versicherung",
        "category": "Gesundheit",
        "subCategory": ["Privat", "Gesetzlich", "Zahnzusatz", "Krankenzusatz", "Ausland"]
    },
    {
        "mainCategory": "Versicherung",
        "category": "Risiko",
        "subCategory": ["Risiko", "Unfall", "Berufsunfähigkeit", "Grundfähigkeit", "Sterbegeld"]
    },
    {
        "mainCategory": "Versicherung",
        "category": "Alter",
        "subCategory": ["Rente", "Riester", "Basis-Rente", "Sofort", "Pflege"]
    },
    {
        "mainCategory": "Versicherung",
        "category": "Haft",
        "subCategory": ["Privathaftpflicht", "Berufshaftpflicht", "Rechtsschutz"]
    },
    {
        "mainCategory": "Versicherung",
        "category": "Fahrzeuge",
        "subCategory": ["KFZ", "Zweirad", "Boot", "Flugzeug", "Sonstige"]
    },
    {
        "mainCategory": "Versicherung",
        "category": "Immo",
        "subCategory": ["Wohngebäude", "Elementar", "Hausrat", "Mietkaution", "Neubau"]
    },
    {
        "mainCategory": "Versicherung",
        "category": "Tier",
        "subCategory": ["Haftpflicht", "Kranken"]
    },

    {
        "mainCategory": "Versicherung",
        "category": "HandyReise",
        "subCategory": ["Handy", "Reise", "Auslandskranken"]
    },


    {
        "mainCategory": "Finanzierung",
        "category": "Mobil-Kredit",
        "subCategory": ["KFZ", "Zweirad", "Boot", "Flugzeug", "Sonstige"]
    },
    {
        "mainCategory": "Finanzierung",
        "category": "Baufinanzierung",
        "subCategory": ["Wohngebäude", "Gewerblich", "Sonstige"]
    },
    {
        "mainCategory": "Finanzierung",
        "category": "Verbraucherkredit",
        "subCategory": ["Urlaub", "Gegenstände"]
    }
];