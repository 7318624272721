import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from 'react-bootstrap';

import Card from 'react-bootstrap/Card'

export function SortableItem(props) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: props.id});

    const style = { 
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none", // sonst geht es am Smartphone nicht
        padding: 0,
    }

  return (
    <Row>
        <Col xs={10} md={11} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Card body className="mt-1 mb-1">
               <span style={{fontSize: "100%"}}>{props.id}</span>
            </Card>
        </Col>
        <Col
            xs={2}
            md={1}
            className="d-flex justify-content-center align-items-center"
            style={{cursor: "pointer"}}
            onClick={() => props.removeItem(props.id)}
        >
            <FontAwesomeIcon
                icon={faXmark}
                size="2x"
                className="actionIcon"
                title="Pipeline Schritt löschen"
            />
        </Col>
    </Row>
  )
}