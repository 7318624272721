import React from 'react';
import { FaCaretSquareDown,FaEdit,FaSistrix } from "react-icons/fa";

const FriendInfo = ({currentfriend,activeUser,message}) => {
  return (
     <div className='friend-info'>

          <input type="checkbox" id='gallery' />

          <div className='image-name'>
               
               <div className='image'>
                    <img src={currentfriend.image} alt='' />
               </div>

               {activeUser && activeUser.length > 0 && activeUser.some(u => u.userId === currentfriend._id) ?
                    <div className='active-user'>Aktiv</div>
                    :
                    ''
               }     

               <div className='name'>
                    <h5>{currentfriend.userName}</h5>
               </div>
          </div>

          <div className='others'>
               <div className='custom-chat'>
                    <h3>Chat anpassen</h3>
                    <FaCaretSquareDown/>
               </div>

               <div className='privacy'>
                    <h3>Datenschutz</h3>
                    <FaCaretSquareDown/>
               </div>

               <div className='media'>
                    <h3>Medien</h3>
                    <FaCaretSquareDown/>
               </div>
          </div>
     
          <div className='gallery'>
               {message && message.length > 0 ?
                    message.map((m,index)=>
                    m.message.image &&
                         <img key={index} src={m.message.image} />)
                         :
                         ''
               }
          </div> 

     </div>
  )
};

export default FriendInfo;
