import axios from 'axios';

//const API_URL = "http://localhost:5000/api/v1/user/";

// Rechteverwaltung
import ability from '../defineAbility';

const AuthService = {
  signup(credentials) {
    return axios.post(`${process.env.REACT_APP_BACKEND}/user/signup`, credentials)
  },
  login(credentials) {
    return axios.post(`${process.env.REACT_APP_BACKEND}/user/login`, credentials)
  },
  logout() {

    //Rechte clearen
    //ability.update([]);

    return sessionStorage.removeItem('token');
  },

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('token'))
  }
}

export default AuthService