import React from "react";
import moment from "moment";
import { FaRegCheckCircle } from "react-icons/fa";

const Friends = (props) => {

  const { fndInfo, msgInfo } = props.friend;
  const myId = props.myId;
  const { activeUser } = props;


  function formatDate(date) {
    const momentDate = moment(date*1000);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
  

    if (momentDate.isSame(today, 'd')) {
      // Format as 'HH:mm' if the date is today
      return momentDate.format('HH:mm');
    } else if (momentDate.isSame(yesterday, 'd')) {
      // Return 'Gestern' if the date is yesterday
      return 'Gestern';
    } else {
      // Format as 'DD.MM.YY' for any other date
      return momentDate.format('DD.MM.YY');
    }
  }


  return (

    <div className="friend">


      {/* Friend Active Icon */}
      <div className="friend-image">
        <div className="image">
          <img src={fndInfo.image} alt="" />
          {activeUser &&
            activeUser.length > 0 &&
            activeUser.some((u) => u.userId === fndInfo._id) ? (
               <div className="active_icon"></div>
          ) : (
            ""
          )}
        </div>
      </div>
      

      <div className="friend-name-seen">
        <div className="friend-name">


          <div className="username-line">

            <h4
              className={
                msgInfo?.senderId !== myId &&
                msgInfo?.status !== undefined &&
                msgInfo.status !== "seen"
                  ? "unseen_message Fd_name "
                  : "Fd_name"
              }
            >
              {fndInfo.userName}
            </h4>

            <span style={{fontSize: "80%", fontWeight: "normal"}}>
              {formatDate(msgInfo ? msgInfo.createdAt : fndInfo.createdAt)}
            </span>

          </div>


          <div className="msg-time">
            {msgInfo && msgInfo.senderId === myId ? (
              <span>Du: </span>
            ) : (
              <span
                className={
                  msgInfo?.senderId !== myId &&
                  msgInfo?.status !== undefined &&
                  msgInfo.status !== "seen"
                    ? "unseen_message "
                    : ""
                }
              >
                {fndInfo.userName + ": "}
              </span>
            )}
            
            {msgInfo && msgInfo.message.text && 
              <span
                className={
                    msgInfo?.senderId !== myId &&
                    msgInfo?.status !== undefined &&
                    msgInfo.status !== "seen"
                      ? "unseen_message "
                      : ""
                  }
              >
                {/*msgInfo.message.text.slice(0, 50)*/}
                 {msgInfo.message.text} "DU"
              </span>
            }

          </div>
        </div>

        {/* Icon from user as "seen status..." => Austauschen wie in whatsapp */}

        {myId === msgInfo?.senderId ? (
          <div className="seen-unseen-icon">
            {msgInfo.status === "seen" ? (
              <img src={fndInfo.image} alt="" />
            ) : msgInfo.status === "delivered" ? (
              <div className="delivered">
                {" "}
                <FaRegCheckCircle />{" "}
              </div>
            ) : (
              <div className="unseen"> </div>
            )}
          </div>
        ) : (
          <div className="seen-unseen-icon">
            {msgInfo?.status !== undefined && msgInfo?.status !== "seen" ? (
              <div className="seen-icon"> </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      
    </div>
  );
};

export default Friends;
