import React, {useEffect, useRef, useState } from 'react';
import { Map, Marker, Popup, TileLayer, GeoJSON } from 'react-leaflet';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line import/no-unresolved
import 'leaflet/dist/leaflet.css?url=false';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import { GmapWraper } from './map-style';


import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { Row, Col, Spinner, Button } from 'react-bootstrap';


// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


function LeafletMapBasic (props) {

  const [plz, setPlz] = useState([]);
  const [plzLoaded, setPLZLoaded] = useState(false);




  import('./PLZ_GEOJSON.json').then(module => {
    setPlz(module.default);
    setPLZLoaded(true);
  });

  //import plz from './PLZ_GEOJSON.json'; // Lädt zu lange.
  //const plz = lazy(() => import('./PLZ_GEOJSON.json')); // lazy nur für Componenten!

  // PLZ Anzahl 8116! Wenn sich das ändert hier anpassen!
  /*
  import("./PLZ_GEOJSON.json").then(plzs => {
    if(plzs.length == 8116) {
      setPlz(plzs);
      props.setAllDataLoaded(true);
      return;
    }
  });
  */


  const { latitude, longitude, width, height, zoom } = props;

  const position = [latitude, longitude];

  const centerDeutschland = [51.1642292, 10.4541194];

  const [exklusivmaklerPLZ, setExklusivmaklerPLZ] = useState([]);
  //const exklusivmaklerPLZ = ["59889", "59872", "59394"];


  const [listePLZ, setListePLZ] = useState([]);
  const [isMapFinished, setIsMapFinished] = useState(false);


  useEffect(() => {
    async function getExklusivmakler() {
      try {
        const res = await AuthAxios.get('/plz/karte');
        /*
        let plzList = [];
        res.data.map(p => {
          plzList.push(p.plz);
        });
        setExklusivmaklerPLZ(plzList);
        console.log(plzList);
        */
        setExklusivmaklerPLZ(res.data);
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Fehler beim Abrufen von Exklusivmaklern',
          html: 'Versuche es später erneut.',
        });
      }
    }
    getExklusivmakler();
  }, []);


  useEffect(() => {
    setIsMapFinished(true);
  }, []); // run only once after initial render

  
  
  /*
  // Alt wenn exklusivmaklerPLZ nur array aus PLZ!
  useEffect(() => {

    if(plz.length > 1) {
      //setListePLZ(plz);
      setListePLZ(plz.filter(plz => exklusivmaklerPLZ.includes(plz.properties.postcode)));
    }
  }, [plz, exklusivmaklerPLZ]);
  */

  useEffect(() => {

    if (plz.length > 1) {
 
      const postalCodes = exklusivmaklerPLZ.map((item) => item.plz);

      // Nur wenn PLZ als Nummer in DB gespecihert ist, da in GEOJSON File als String
      // const filteredPlz = plz.filter((item) => postalCodes.includes(parseInt(item.properties.postcode)));
      const filteredPlz = plz.filter((item) => postalCodes.includes(item.properties.postcode));

      const plzWithName = filteredPlz.map((item) => {
        const matchingObject = exklusivmaklerPLZ.find((obj) => obj.plz == item.properties.postcode);
        return { ...item, exklusivmakler: matchingObject.exklusivmakler.name };
      }); 

      setListePLZ(plzWithName); 
      
    }
  }, [plz, exklusivmaklerPLZ]);
  
  


  /*
  const geoJsonRef = useRef(null);

  useEffect(() => {
    if (geoJsonRef.current) {
      geoJsonRef.current.on('click', (event) => {
        console.log('Clicked!', event);
      });
    }
  }, [geoJsonRef]);


  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.data) {
      layer.bindPopup(feature.properties.data);
    }
  };
*/

  const [showData, setShowData] = useState(false);

  const handlePopup = () => {
    setShowData(true);
  }


  return (
    <>

      {!isMapFinished && <div>Lade Karte...</div>}
      {isMapFinished && !plzLoaded &&
        <Row>
          <Col xs={12} className="text-center mb-3">
            <Spinner animation="border" variant='primary' />
            <br />
            <h5>Bitte warten Daten werden geladen, dies kann einen Moment dauern!</h5>
          </Col>
        </Row>
      }

      {isMapFinished && 
  
        <GmapWraper width={width} height={height}>

          <Map center={centerDeutschland} zoom={zoom}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            />

            <Marker position={position}>
              <Popup>
                VIN1 Center
              </Popup>
            </Marker>

            {listePLZ.map(plz =>
              <GeoJSON
                key={plz.properties.postcode}
                data={plz}
                color="blue"
                fillColor="blue"
                fillOpacity={0.4}
                weight={1}
                //interactive={true}
                //onEachFeature={onEachFeature}
                //ref={geoJsonRef}
                onclick={handlePopup}
              >
                {showData &&
                  <Popup>
                        <b>Makler:</b> {plz.exklusivmakler}<br />
                        <b>PLZ:</b> {plz.properties.postcode}<br />
                        <b>Ort:</b>&nbsp; {plz.properties.name}
                  </Popup>
                }
              </GeoJSON>
            )}     

          </Map>
        </GmapWraper>
      }
    </>
     
  );
};

LeafletMapBasic.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  zoom: PropTypes.number,
};

const LeafletMapMultipleIcon = props => {
  const { latitude, longitude, width, height, zoom, data } = props;

  const position = [latitude, longitude];
  return (
    <GmapWraper width={width} height={height}>
      <Map center={position} zoom={zoom}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {data.map(item => {
          return (
            <Marker key={item.id} position={item.position}>
              <Popup>
                A pretty CSS3 popup.
                <br />
                Easily customizable.
              </Popup>
            </Marker>
          );
        })}
      </Map>
    </GmapWraper>
  );
};

LeafletMapMultipleIcon.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  zoom: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
};

const LeafletMapCustomIcon = props => {
  const { latitude, longitude, width, height, zoom, faIcon } = props;
  const fontAwesomeIcon = L.divIcon({
    html: `<i style="color: #2880CA" class="${faIcon}"></i>`,
    iconSize: [20, 20],
    className: 'myDivIcon',
  });
  const position = [latitude, longitude];
  return (
    <GmapWraper width={width} height={height}>
      <Map center={position} zoom={zoom}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={fontAwesomeIcon}>
          <Popup>
            A pretty CSS3 popup.
            <br />
            Easily customizable.
          </Popup>
        </Marker>
      </Map>
    </GmapWraper>
  );
};
LeafletMapCustomIcon.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  zoom: PropTypes.number,
  faIcon: PropTypes.string,
};

const LeafletMarkerCluster = props => {
  const { latitude, longitude, width, height, zoom, data } = props;

  const position = [latitude, longitude];
  return (
    <GmapWraper width={width} height={height}>
      <Map center={position} zoom={zoom} className="markercluster-map" maxZoom={18}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup>
          {data.map(item => {
            return (
              <Marker key={item.id} position={item.position}>
                <Popup>
                  A pretty CSS3 popup.
                  <br />
                  Easily customizable.
                </Popup>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      </Map>
    </GmapWraper>
  );
};

LeafletMarkerCluster.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  zoom: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
};

export { LeafletMapBasic, LeafletMapMultipleIcon, LeafletMapCustomIcon, LeafletMarkerCluster };
