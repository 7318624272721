import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { COLORS } from "../constants";
import moment from 'moment';


function InputVertrieb(props) {

  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Vertrieb freigeben/freigegeben
      </legend>
      <div key="inline-checkbox" className="mb-3">

        {/* Checkbox wird nur im Frontend gesetzt, auflösung Cb zu vertriebVorteilspartner(Date) im Backend! */}
        <Form.Check
          type="checkbox"
          id="vertriebVorteilspartner"
          label={
            !props.data.vertriebVorteilspartner
              ? "Vorteilspartner"
              : "Vorteilspartner - seit: " + moment(props.data.vertriebVorteilspartner).format('DD.MM.YYYY')
          }
          name="vertriebVorteilspartner"
          checked={props.data.vertriebVorteilspartner ? true : false}
          onChange={(e) => props.setCBs(e)}
        />
        {props.data.vertriebVorteilspartner != null &&
          <p style={{fontSize: "80%"}}>Sponsor Link: {"https://my.vin1.eu/vp-onboarding/"+props.data._id}</p>
        }

     
      </div>
    </fieldset>
  );
}

export default InputVertrieb;
