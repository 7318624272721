import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';


import { alpha, styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2'

import FilterSidebar from "./FilterSidebar";
import { CSSTransition } from 'react-transition-group';

import CustomModal from "../../Bausteine/CustomModal";
import ReactSelect from "react-select";

function KundenMitStandortPartner(props) {

  const navigate = useNavigate();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterSidebarActive, setFilterSidebarActive] = useState(false);

  const [kunden, setKunden] = useState([]);
  const [kundenFiltered, setKundenFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRows, setSelectedRows] = useState([]);

  const [showStandortModal, setShowStandortModal] = useState(false);
  const [standort, setStandort] = useState(null);



  const [filter, setFilter] = useState({});
  /*
      appzugriffCb: false,
    maklervollmachtCb: false,
    connectCb: false,
    tippgeber_verifiziertCb: false,
    exklusivpartner: false,
    investor: false,
    tippgeber: false,
    vorteilspaket: false,
    vorteilspartner: false
    */

  const setCBs = (e) => {

    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
  }

  /*
  const filters = {
    investor: [true],
    //anrede: ["Frau"]
  };
  */

  
  async function getKunden() {

    AuthAxios.get('/kunde/backoffice-standort')
      .then(res => {
        setKunden(res.data);
        setKundenFiltered(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Kunden konnten nicht geladen werden."
        });
      });
  }


  useEffect(() => {
    if (props.activeTab === 3) {
      getKunden();
    }
  }, [props.activeTab]);


  const handleNavigation = (e) => {
    //console.log(e.id);
    navigate("/dash/kunde/" + e.id);
  }

  const handleStandortZuweisen = () => {

    if(selectedRows.length == 0) {
      Swal.fire({
        icon: "info",
        title: "Zuweisen",
        html: "Wähle Kunden über die Checkbox aus, um diese einem Standort zuzuweisen."
      })
      return;
    }
   
    setShowStandortModal(true);

  }

  const handleEditSave = () => {

    if(!standort || standort.value == undefined || standort.value == null || standort.value.length != 24) {
      Swal.fire({
        icon: "info",
        title: "Standortpartner",
        html: "Kein Standortpartner ausgewählt!"
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Standortpartner",
      html: "Standort zuweisen implementieren"
    });


    //Ausgewählte Kunden zu Standortpartner zuweisen
    //Kunde Modal - Feld: exklusivmakler
    //AuthAxios.post(/exklusivmakler/update-kunden)
    
    /*AuthAxios.post('/exklusivmakler/update-kunden')
    .then(res => {

    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: "Fehler",
        html: "Kunden konnten Standort-Partner nicht zugewiesen werden."
      });
    });
    */
  }



  // Filter ohne "Nicht" => funktionsfähig
  /*
  useEffect(() => {

    setKundenFiltered(kunden.filter(elem => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;

      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });

      if (filterActive) {

        setFilterSidebarActive(true);

        // Filterstatus vormerken, falls die Schnittmenge mit anderen Filtern nicht gegeben ist, darf
        // Eintrag nicht zum Ergebnis hinzugefügt werden
        let filterStatus = false;

        for (var key in filter) {

          // Filter nur Anwenden, wenn aktiv
          if (filter[key] === true) {
            if (elem[key] === undefined || elem[key] != filter[key]) {
              // Kunde nicht zurückgeben, falls einer der Filter nicht matcht
              return false;
            }
            else {
              // gefilterten Kunden zurückgeben
              filterStatus = true;
            }
          }
        }
        // Nachdem alle Filter geprüft wurden, kann über den Eintrag entschieden werden
        return filterStatus;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));

  }, [filter])
  */




  
  useEffect(() => {

    setKundenFiltered(kunden.filter(kunde => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;
      
      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });



      if (filterActive) {

        setFilterSidebarActive(true);

            // Filterung
            const appzugriffCb = filter.appzugriffCb ? kunde.appzugriffCb : true;
            const maklervollmachtCb = filter.maklervollmachtCb ? kunde.maklervollmachtCb : true;
            const connectCb = filter.connectCb ? kunde.connectCb : true;
            const tippgeber_verifiziertCb = filter.tippgeber_verifiziertCb ? kunde.tippgeber_verifiziertCb : true;

            // negative Filterung
            const appzugriffCbNot = filter.appzugriffCbNot ? !kunde.appzugriffCb : true;
            const maklervollmachtCbNot = filter.maklervollmachtCbNot ? !kunde.maklervollmachtCb : true;
            const connectCbNot = filter.connectCbNot ? !kunde.connectCb : true;
            const tippgeber_verifiziertCbNot = filter.tippgeber_verifiziertCbNot ? !kunde.tippgeber_verifiziertCb : true;

            // Interessen
            const exklusivpartner = filter.exklusivpartner ? kunde.exklusivpartner : true;
            const investor = filter.investor ? kunde.investor : true;
            const tippgeber = filter.tippgeber ? kunde.tippgeber : true;
            const vorteilspaket = filter.vorteilspaket ? kunde.vorteilspaket : true;
            const vorteilspartner = filter.vorteilspartner ? kunde.vorteilspartner : true;


            return appzugriffCb && maklervollmachtCb && connectCb && tippgeber_verifiziertCb && appzugriffCbNot && maklervollmachtCbNot && connectCbNot && tippgeber_verifiziertCbNot && exklusivpartner && investor && tippgeber && vorteilspaket && vorteilspartner;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));


  },[filter])






  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));




  const columns = [
    {
      field: "plzPartner",
      headerName: "PLZ-Partner",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span>{params.row.plzPartner}</span>
      ),
    },
    {
      field: "exklusivmakler",
      headerName: "Standort-Partner",
      minWidth: 240,
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <span>{params.row.exklusivmakler?.label}</span>
      ),
    },
    {
      field: "kdnr",
      headerName: "KdNr",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anrede",
      headerName: "Anrede",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "firma",
      headerName: "Firma",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "geburtsdatum",
      headerName: "Geburtsd.",
      type: "number",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <>
          {(params.row.geburtsdatum && params.row.geburtsdatum != "0000-00-00" ) && <span>{new Date(params.row.geburtsdatum).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
          {(!params.row.geburtsdatum || params.row.geburtsdatum == "0000-00-00") && <span>-</span>}
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 70,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "land",
      headerName: "Land",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "telefon",
      headerName: "Telefon",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.telefon}`}>{params.row.telefon}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobil",
      headerName: "Mobil",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Link href={`tel:${params.row.mobil}`}>{params.row.mobil}</Link>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    
    {
      field: "webseite",
      headerName: "Webseite",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];

  const onFilterModelChange = () => {
    //console.log("filter changed..")
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

          <Col xs={12} md={2}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={() => setFilterIsOpen(!filterIsOpen)}
          >
            <FontAwesomeIcon
              style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
              icon={faFilter}
              size="lg"
            />
            {filterSidebarActive && <span>Filter (aktiv)</span>}
            {!filterSidebarActive && <span>Filter</span>}
          </Col>
          <Col xs={12} md={2}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
          >
            <Button onClick={handleStandortZuweisen}>
              Standort ändern ({selectedRows.length})
            </Button>
          </Col>

        </Row>

      </GridToolbarContainer>
    );
  }


  const CustomFooter = () => {
    return (
      <GridFooterContainer>
      {/* Add what you want here */}
      <GridFooter sx={{
          border: 'none', // To delete double border.
          alignItems: 'flex-start'
        }} />
    </GridFooterContainer>
    );
  }




  return (
    
    <div>

      {props.activeTab == 3 &&
        <>
          <Box
            sx={{
              height: 'calc(100vh - 100px)',
              width: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#c58164',
              },
              '& .MuiDataGrid-toolbarContainer': {
                padding: '10px'
              },
              margin: "0px 0px -90px 0px",
              fontWeight: 'bold',
              boxShadow: 0,
            }}
          >
            {/* Server side pagination wenn zu viele Daten - https://mui.com/x/react-data-grid/pagination/ */}
            <StripedDataGrid
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRows(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRows}
              //selectionModel={selectedRows}
              //disableMultipleSelection
              //onSelectionModelChange={handleCheckboxSelection}
              // isRowSelectable={(params: GridRowParams) => params.row.quantity > 50000}
              // keepNonExistentRowsSelected (bei paging über server!)
              //disableSelectionOnClick
              //autoHeight
              density={'compact'}
              rows={kundenFiltered}
              columns={columns}
              getRowId={(row) => row._id}
              //onRowClick={handleNavigation}
              //pageSize={20}
              //rowsPerPageOptions={[20]}
              autoPageSize
              //onFilterModelChange={onFilterModelChange}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              loading={loading}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'kdnr', sort: 'desc' }],
                },
              }}
              sx={{/*
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.dark',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                bgcolor: 'background.paper'
              */}}
            />

          </Box>

          <CSSTransition in={filterIsOpen} timeout={500} classNames="right-to-left" mountOnEnter unmountOnExit>
            <FilterSidebar data={filter} setCBs={setCBs} setFilterIsOpen={setFilterIsOpen} />
          </CSSTransition>

          {showStandortModal &&
            <CustomModal 
                title={"Standort ändern"}
                visible={showStandortModal}
                setVisible={setShowStandortModal}
                handleSave={handleEditSave}
            >
              <p><b>{selectedRows.length} Kunden</b> - Zuweisen zu neuem Standort:</p>
              <div style={{marginBottom: "200px"}}>
                <ReactSelect
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={standort}
                      //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                      onChange={(selection, e) => setStandort(selection)}
                      options={props.standorte}
                      noOptionsMessage={() => "Kein Standort-Partner gefunden"}
                      placeholder={<div className="select-placeholder-text">Wähle einen Standort-Partner</div>}
                      name="standort"
                />
              </div>
            </CustomModal>
          } 

        </>
      }
    
    </div>
  );
}


export default KundenMitStandortPartner