import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

import { deDE, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from '@mui/material/Box';


import TextareaAutosize from 'react-textarea-autosize';

import CustomModal from "../../Bausteine/CustomModal"
import AuthAxios from "../../utils/AuthAxios";
import { formatDateTime } from "../../utils/date";

function Gewinnspiele(props) {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [gewinnspiele, setGewinnspiele] = useState([]);


  const [selectedGewinn, setSelectedGewinn] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);


  useEffect(() => {

    AuthAxios.get('/gewinnspiel')
    .then(res => {
        setGewinnspiele(res.data);
        setLoading(false);
    })
    .catch(err => {
        console.log(err);
        setLoading(false);
    });
    
  }, []);
    


  const handleNavigation = (e) => {

    // setShowEditModal(true);
    //alert(e.id);
    //setSelectedVorfall(vorfall.find((v) => v._id == e.id));
    //console.log(vorfall.find((v) => v._id == e.id));

    //props.data.setActiveTab(1);
    //navigate("/dash/partner/" + e.id);
  }

  const handleEditSave = () => {
   // alert("Speichern implementieren")
  }
  


  const columns = [
    {
      field: "title",
      headerName: "Gewinnspiel",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Zeitraum",
      headerName: "Zeitraum",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => (
        <span>
          {params.row.startDate && formatDateTime(params.row.startDate)}
          -
          {params.row.endDate && formatDateTime(params.row.endDate)}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "partner_name",
      headerName: "Partner",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gewinner",
      headerName: "Gewinner",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.gewinner.kdnr} - {params.row.gewinner.vorname} {params.row.gewinner.nachname}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gewinnAusgeliefert",
      headerName: "Gewinn Ausgeliefert",
      minWidth: 140,
      flex: 1,
      renderCell: (params) => (
        <span>
            {!params.row.gewinnAusgeliefert && "Nein"}
            {params.row.gewinnAusgeliefert && formatDateTime(params.row.gewinnAusgeliefert)}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gewinnErhalten",
      headerName: "Kunde Gewinn erhalten",
      minWidth: 140,
      flex: 1,
      renderCell: (params) => (
        <span>
            {!params.row.gewinnErhalten && "Nein"}
            {params.row.gewinnErhalten && !params.row.gewinnErhaltenAm && "Ja"}
            {params.row.gewinnErhalten && params.row.gewinnErhaltenAm && `Ja - ${formatDateTime(params.row.gewinnErhaltenAm)}`}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <span>{formatDateTime(params.row.createdAt)}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>
      </GridToolbarContainer>
    );

  }


  return (

    <div style={{ height: `90%`, width: "100%", marginBottom: "30px" }}>
      {props.activeTab === 8 && 
        <Box
          sx={{
            height: `calc(100%)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "10px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={gewinnspiele}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            pageSize={20}
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            //components={{ Toolbar: GridToolbar }}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
            sx={{/*
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
              */
            }}
          />
        </Box>
      }

    {/*Object.keys(selectedGewinn).length > 0 &&
        
        <CustomModal 
            title={"Vorfall bearbeiten - Partner: "+ selectedVorfall.partner_name +" | "+ selectedVorfall.vorname+" "+selectedVorfall.nachname+ " | "+new Intl.DateTimeFormat('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              }).format(new Date(selectedVorfall.createdAt))
            }
            visible={showEditModal}
            setVisible={setShowEditModal}
            handleSave={handleEditSave}
        >
        <span>Zum Kunden: </span> <Link to={"/dash/kunde/"+selectedVorfall.kdnr} style={{color: "#c58164"}}>{selectedVorfall.vorname + " " + selectedVorfall.nachname}</Link>

          <TextareaAutosize
            defaultValue={selectedVorfall.nachricht ? selectedVorfall.nachricht : "keine Nachricht übermittelt"}
            style={{width: "100%", border: "none", borderRadius: "8px"}}
            className="readOnly"
            readOnly
          />

          
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Select>
              <option value="nicht bearbeitet">nicht bearbeitet</option>
              <option value="in Klärung">in Klärung</option>
              <option value="abgeschlossen-negativ">abgeschlossen - zum Nachteil für Partner</option>
              <option value="abgeschlossen-positiv">abgeschlossen - kein Nachteil für  Partner</option>
            </Form.Select>
          </Form.Group>

      
          <Form.Group className="mb-3">
            <Form.Label>Interne Notiz</Form.Label>
            <Form.Control as="textarea" rows={4}
              placeholder="Interne Notiz"
              name="notiz"
              value={selectedVorfall.notiz ? selectedVorfall.notiz : ""}
              onChange={(e) => setSelectedVorfall({...selectedVorfall, notiz: e.target.value})}
            />
          </Form.Group>

        </CustomModal>
      */} 

    </div>



  );

  }
  

export default Gewinnspiele