import React, { useEffect, useState } from "react";
import { MainContextProvider } from "./contexts/MainContext";

import WebFont from "webfontloader";

import "./App.scss";

//Tailwind
import "./tailwind.css";

import { BrowserRouter as Router, Route, Routes, Navigate, useParams, redirect, matchPath } from "react-router-dom";
import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";

import Mitarbeiter from "./pages/Mitarbeiter/Mitarbeiter";
import Dash from "./pages/Dash";
import AuthComponent from "./services/AuthComponent";

import Home from "./pages/Home"
import Analyse from "./pages/Analyse/Analyse"
import Kunde from "./pages/Kunden/Kunde";
import Aufgaben from "./pages/Aufgaben/Aufgaben";
import Feedback from "./pages/Feedback/Feedback";


import KundenListe from "./pages/KundenListe/KundenListe";
import KundenBackoffice from "./pages/KundenBackoffice/KundenBackoffice";

import KundenListeServer from "./pages/KundenListeServer/KundenListeServer";

import AufgabenListe from "./pages/Aufgaben/AufgabenListe";
import VorteilspartnerListe from "./pages/Vorteilspartner/VorteilspartnerListe";

import Pipeline from "./pages/Pipeline/Pipeline";
import MitarbeiterListe from "./pages/Mitarbeiter/MitarbeiterListe";

import Vorteilspartner from "./pages/Vorteilspartner/Vorteilspartner";
import Affiliate from "./pages/Affiliate/Affiliate";
import Partner from "./pages/Partner/Partner";
import FreigabeNewsGewinn from './pages/FreigabeNewsGewinn/FreigabeNewsGewinn'


import Exklusivmakler from "./pages/Exklusivmakler/Exklusivmakler";
import ExklusivmaklerKarte from "./pages/ExklusivmaklerKarte";
import Projekt from "./pages/Projekt";

import Vertriebsstruktur from "./pages/Vertriebsstruktur";
//import ImExport from "./pages/ImExport";

import Immo from "./pages/Immo/Immo";

import PersoCheck from "./pages/PersoCheck/PersoCheck";

import DEMVExport from "./pages/DEMVExport/DEMVExport";
import Whatsapp from "./pages/Whatsapp/Whatsapp";




import moment from 'moment';
import 'moment/locale/de'; // Import the German loc


const App = () => {
  
  //WebFont.load({google: {families: ["Montserrat:300,400,500,700"]}});

  const { id } = useParams();

  // Set moment to DE
  moment.locale('de');
  //console.log(moment.locales());


  return (

      <MainContextProvider>
        <Router basename="/">

          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/password-reset/:token/:id" element={<PasswordReset />} />

            {/*
              <Route path="/signup" element={
                <Signup />
              } />
            */}

            {/* Alle in diesem Block definierten Path sind Unterpunkte von /dash */}
            <Route path="/dash" element={
                <AuthComponent>
                  <Dash />
                </AuthComponent>
              }>

              <Route index element={<Navigate to="/dash/home" />} /> {/* Dont navigate to /dash, => redirect dash/home */}
              <Route path="home" element={<Home />} />

              <Route path="analyse" element={<Analyse />} />

              <Route path="kunde" element={<Kunde />} />
              <Route path="kunde/:id" element={<Kunde />} />

              <Route path="kunden" element={<KundenListe />} />
              <Route path="kunden-backoffice" element={<KundenBackoffice />} />

              <Route path="kunden-server" element={<KundenListeServer />} />


              <Route path="aufgabe" element={<Aufgaben />} />
              <Route path="aufgabe/:id" element={<Aufgaben />} />

              <Route path="feedback" element={<Feedback />} />
              <Route path="feedback/:id" element={<Feedback />} />


              <Route path="vorteilspartner" element={<Vorteilspartner />} />
              <Route path="vorteilspartner/:id" element={<Vorteilspartner />} />

              
              <Route path="affiliate" element={<Affiliate />} />
              <Route path="affiliate/:id" element={<Affiliate />} />

         
              <Route path="exklusivmakler" element={<Exklusivmakler />} />
              <Route path="exklusivmakler/:id" element={<Exklusivmakler />} />
              <Route path="exklusivmakler-karte" element={<ExklusivmaklerKarte />} />
            

              <Route path="projekt" element={<Projekt />} />


              <Route path="vertriebsstruktur" element={<Vertriebsstruktur />} />

              {/*<Route path="import-export" element={<ImExport />} />*/}

              <Route path="mitarbeiter" element={<Mitarbeiter />} />
              <Route path="mitarbeiter/:id" element={<Mitarbeiter />} />
              {/*<Route path="mitarbeiter" element={<MitarbeiterListe />} />*/}


              <Route path="pipeline" element={<Pipeline />} />

              <Route path="partner" element={<Partner />} />
              <Route path="partner/:id" element={<Partner />} />

              <Route path="freigaben" element={<FreigabeNewsGewinn />} />


              <Route path="immo" element={<Immo />} />
              <Route path="immo/:id" element={<Immo />} />

              <Route path="perso-check" element={<PersoCheck />} />

              <Route path="whatsapp" element={<Whatsapp />} />

              <Route path="demv-export" element={<DEMVExport />} />

              <Route path="*" element={<Home />} />

            </Route>


            <Route path="*" element={<Login />} />

          </Routes>
        </Router>
      </MainContextProvider>

  );
};

export default App;
/*
    <div className="App">
        </div>
        */
