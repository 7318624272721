import React, { useEffect, useState } from "react"

import { useNavigate } from 'react-router-dom';

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import {Row, Col} from 'react-bootstrap'





function PipelineListe(props) {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  
  useEffect(() => {
    setData(props.data);
  }, [props])

  


  const handleEdit = (e) => {

    // öffne Modal mit Daten von Ausgewähltem Datensatz => e.id
    props.setPipelineEditData({
        id: e.id,
        name: e.row.name,
        items: e.row.items
    })
    props.setShowEditModal(true);
  }


  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "_id",
      headerName: "ID",
      minWidth: 205,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
        field: "anzahlKunden",
        headerName: "Anzahl Kunden",
        minWidth: 50,
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
    {
      field: "items",
      headerName: "Schritte",
      minWidth: 500,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName:"Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];



  
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>
      </GridToolbarContainer>
    );
  }

  



    return (
      <div style={{ height: `90%`, width: "100%", marginBottom: "30px" }}>
      {props.activeTab === 2 && 
        <Box
          sx={{
            maxHeight: '800px',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px",
            //padding: "5px 10px",
            fontWeight: 'bold',
            //boxShadow: 3,
          }}
        >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleEdit}
            pageSize={20}
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            //components={{ Toolbar: GridToolbar }}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            sx={{
              /*
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
              */
            }}
          />

        </Box>
      }
    </div>
    );
  }
  

export default PipelineListe