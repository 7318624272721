import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Tabs, Alert, Row, Col } from 'react-bootstrap';

import PersoCheckOpenListe from './PersoCheckOpenListe';
import PersoCheckCompletedListe from './PersoCheckCompletedListe';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode } from "@fortawesome/free-solid-svg-icons";

import { useParams, useNavigate } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';


function PersoCheck() {

  const [activeTab, setActiveTab] = useState(2);

  return (

    <div style={{ marginLeft: "0px", marginTop: "35px", width: "100%" }}>

        <div className="mainWork">
            <Tabs
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
              //defaultActiveKey="neuervorteilspartner"
              id="fill-tab-example"
              fill
              style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
            >  

{/*
              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Neuer Vorteilspartner">
                <NeuerVorteilspartner
                  vorteil={vorteil}
                  getKoordinatenFromAdress={getKoordinatenFromAdress}
                  setVorteil={setVorteil}
                  setChanged={setChanged}
                  setOfflinePartner={setOfflinePartner}
                  markerPartner={markerPartner}
                  setMarkerPartner={setMarkerPartner}
                  mapCenter={mapCenter}
                  setMapCenter={setMapCenter}
                  validateBank={validateBank}
                  validateUstId={validateUstId} 
                  newImage={newImageURL}
                  setNewImage={setNewImage}
                  handleLogo={handleLogo}
                />
                
         
              </Tab>
              */}

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="2" title="Offen">
                <PersoCheckOpenListe setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="3" title="Abgeschlossen">
                <PersoCheckCompletedListe setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>
            </Tabs>
        
        
        </div>

    </div>
  )
}

export default PersoCheck