import React, { useState, useRef, Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';

import { Container, Row, Col, Form } from "react-bootstrap";

import { MainContext } from '../../contexts/MainContext';


function NeuenKundenAnlegen(props) {

  const { user } = useContext(MainContext);

  const cancelButtonRef = useRef(null);

  
  // Regex
  const regexTelefon = "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im";
  
  //https://www.twilio.com/docs/glossary/what-e164
  const regexTelefon2 = "^\+[1-9]\d{1,14}$"; // Format E.134 => +49151...


  const initialValues = {
    anrede: "Herr",
    firma: "",
    vorname: "",
    nachname: "",
    strasse: "",
    hausnr: "",
    plz: "",
    ort: "",
    land: "Deutschland",
    laenderVorwahl: "+49",
    telefon: "",
    tagGeburtstag: "",
    monatGeburtstag: "",
    jahrGeburtstag: "",
    loginEmail: ""
  };

  // Kunde
  const [kunde, setKunde] = useState(initialValues);


  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleNeukundeRegister = async (e) => {

    e.preventDefault();


    if( isNaN(kunde.tagGeburtstag) || isNaN(kunde.monatGeburtstag) || isNaN(kunde.jahrGeburtstag)) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Das eingegebene Geburtsdatum ist nicht gültig. Du darfst nur Zahlen verwenden.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(kunde.jahrGeburtstag.length !== 4) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Bitte gib das Jahr deines Geburtstages vollständig an und sende das Formular erneut ab.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(kunde.tagGeburtstag < 1 || kunde.tagGeburtstag > 31 || kunde.monatGeburtstag < 1 || kunde.monatGeburtstag > 12 || kunde.jahrGeburtstag < 1920 || kunde.jahrGeburtstag > new Date().getFullYear() - 17) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Das eingegebene Geburtsdatum ist nicht gültig. Korrigiere dieses und sende das Formular erneut ab. ",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }


    let geburtsdatum = kunde.jahrGeburtstag+"-"+kunde.monatGeburtstag+"-"+kunde.tagGeburtstag;

    // Pflichtfelder ausgefüllt
    if (
      kunde.vorname == "" ||
      kunde.nachname == "" ||
      kunde.strasse == "" ||
      kunde.hausnr == "" ||
      kunde.plz == "" ||
      kunde.ort == "" ||
      kunde.telefon == "" ||
      kunde.geburtsdatum == "" ||
      kunde.loginEmail == ""
    ) {
      Swal.fire({
        title: "Pflichtfelder",
        text: "Bitte fülle alle mit * markierten Pflichtfelder aus.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    try {
        // Axios Request Backend!
        //Create Kunde Firebase, auch mit weiteren Daten!

        await AuthAxios.post('/kunde/preregister',
          {
            anrede: kunde.anrede, //anrede
            firma: kunde.firma, //firma
            vorname: kunde.vorname, //vorname
            nachname: kunde.nachname, //nachname
            anschrift: kunde.strasse + " " + kunde.hausnr,
            plz: kunde.plz, //plz
            stadt: kunde.ort, //stadt
            land: kunde.land, //land
            email: kunde.loginEmail, //email
            telefon: kunde.laenderVorwahl+kunde.telefon, //telefon
            geburtsdatum: geburtsdatum, //geburtsdatum
            mitarbeiter: {
                "label": user.vorname+" "+user.nachname,
                "value": user.mitarbeiterId,
            }
          }
        );

        //Reset Kunde Obj
        setKunde(initialValues);

        //Modal schließen
        props.setShowRegisterModal(false);
    }
    catch(err) {
      Swal.fire({
          icon: 'error',
          title: 'Fehler',
          html: "Fehler beim Anlegen eines neuen Kunden! <br />"
                +err?.message
      });

    }


  };





  return (

    <Transition.Root show={props.showRegisterModal} as={Fragment}>
        <Dialog as="div" className="relative flex z-10" initialFocus={cancelButtonRef} onClose={props.setShowRegisterModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="min-w-52 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Neuen Kunden vorregistrieren
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Der Kunde bekommt eine E-Mail zu seinem erstellten Account, sobald der Datensatz hier gespeichert wird.
                          In dieser E-Mail steht, dass ein My-VIN1 Account für ihn angelegt wurde.
                        </p>
                      </div>
                    </div>
                  </div>

                    <Container>

                        {/* Chrome Browser austricksen damit kein autoComplete... */}
                        <input type="hidden" value="something" />

                        <Row style={{ justifyContent: "center", marginBottom: "80px"}}>


        <Form autoComplete="off" method="POST">

            <p>* Pflichtfeld</p>


            <Form.Label>Anrede</Form.Label>
            <Form.Select
                onChange={(e) => setKunde({...kunde, anrede: e.target.value})}
                value={kunde.anrede}
            >
                {kunde.firma.length == 0 &&
                    <>
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                    </>
                }
                <option value="Firma">Firma</option>
            </Form.Select>
            

  
            <Form.Label htmlFor="firma" className="form-label mt-3">
                Firma (optional)
            </Form.Label>
            <Form.Control
                type="text"
                id="firma"
                name="firma"
                placeholder="Firma"
                autoComplete="off"
                value={kunde.firma}
                onChange={(e) => setKunde({...kunde, firma: e.target.value, anrede: "Firma"})} 
            />
       
           <Form.Label htmlFor="vorname" className="form-label mt-3">
                Vorname*
            </Form.Label>
            <Form.Control
                type="text"
                id="vorname"
                name="vorname"
                placeholder="Vorname"
                autoComplete="off"
                value={kunde.vorname}
                onChange={(e) => setKunde({...kunde, vorname: e.target.value.trim()})}
            />
     

            <Form.Label htmlFor="nachname" className="form-label mt-3">
                Nachname*
            </Form.Label>
            <Form.Control
                type="text"
                id="nachname"
                name="nachname"
                placeholder="Nachname"
                autoComplete="off"
                value={kunde.nachname}
                onChange={(e) => setKunde({...kunde, nachname: e.target.value.trim()})}
            />

           
            <Form.Label htmlFor="strasse" className="form-label mt-3">
                Strasse*
            </Form.Label>
            <Form.Control
                type="text"
                id="strasse"
                name="strasse"
                placeholder="Straße"
                autoComplete="on"
                value={kunde.strasse}
                onChange={(e) => setKunde({...kunde, strasse: e.target.value})}
            />
    
            <Form.Label htmlFor="hausnr" className="form-label mt-3">
                Hausnr*
            </Form.Label>
            <Form.Control
                type="text"
                id="hausnr"
                name="hausnr"
                placeholder="Hausnr"
                inputMode="text"
                autoComplete="off"
                value={kunde.hausnr}
                onChange={(e) => setKunde({...kunde, hausnr: e.target.value.trim()})}
            />
         

            <Form.Label htmlFor="plz" className="form-label mt-3">
                PLZ* (nur Zahlen)
            </Form.Label>
            <Form.Control
                type="text"
                id="plz"
                name="plz"
                placeholder="PLZ"
                autoComplete="off"
                inputMode="numeric"
                value={kunde.plz}
                onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                    setKunde({...kunde, plz: e.target.value.trim()});
                }
                }}
            />
     

            <Form.Label htmlFor="ort" className="form-label mt-3">
                Ort*
            </Form.Label>
            <Form.Control
                type="text"
                id="ort"
                name="ort"
                placeholder="Ort"
                autoComplete="on"
                value={kunde.ort}
                onChange={(e) => setKunde({...kunde, ort: e.target.value})}
            />
            

            <Form.Label htmlFor="land" className="form-label mt-3" >
                Land
            </Form.Label>
            <Form.Select
                value={kunde.land}
                onChange={(e) => setKunde({...kunde, land: e.target.value})}
            >
                <option value="Deutschland">Deutschland</option>
                <option value="Österreich">Österreich</option>
                <option value="Schweiz">Schweiz</option>
            </Form.Select>

  

            <Form.Label htmlFor="telefon" className="form-label mt-3" >
                Telefon/Mobil*
            </Form.Label>

            <Row>
                <Col xs={12} >
                <Form.Select
                    style={{width: "auto", display: "inline-block"}}
                    value={kunde.laenderVorwahl} // Make sure to set the value prop to the state value
                    onChange={(e) => setKunde({...kunde, laenderVorwahl: e.target.value})}
                >
                    <option value="+49">DE +49</option>
                    <option value="+43">AT +43</option>
                    <option value="+41">CH +41</option>
                </Form.Select>

                <Form.Control
                    style={{width: "auto", display: "inline"}}
                    type="text"
                    id="telefon"
                    name="telefon"
                    placeholder="151123456"
                    inputMode="tel"
                    autoComplete="on"
                    value={kunde.telefon}
                    onChange={(e) => {
                    let input = e.target.value.replace(/[^+\d]/g, '').trim();
                    if(input.length > 13) {
                        input = input.slice(0, 13);
                    }
                      setKunde({...kunde, telefon: input});
                    }}
                />
                </Col>
            </Row>
       

            <Form.Label htmlFor="geburtsdatum" className="form-label mt-3" >
                Geburtsdatum*
            </Form.Label>
            <br />
                {/*
                <input
                    type="date"
                    id="geburtsdatum"
                    name="geburtsdatum"
                    placeholder="Geburtsdatum"
                    autoComplete="on"
                    value={geburtsdatum}
                    onChange={(e) => setGeburtsdatum(e.target.value.trim())}
                />*/}

                <Row className="ml-0">
                    <div 
                        style={{
                            border:"1px solid lightgrey",
                            borderRadius: "4px",
                            width:"auto",
                            padding: "4px"
                        }}
                    >
                        <input
                            type="text"
                            id="tag"
                            name="tag"
                            placeholder="TT"
                            style={{display: "inline", width: "3em", textAlign: "center"}}
                            inputMode="numeric"
                            maxLength={2}
                            required
                            value={kunde.tagGeburtstag}
                            onChange={(e) => setKunde({...kunde, tagGeburtstag: e.target.value.replace(/[^\d]/g, "")})}
                        />
                        . 
                        <input
                            type="text" 
                            id="monat"
                            name="monat"
                            placeholder="MM"
                            style={{display: "inline", width: "3.5em", textAlign: "center"}}
                            inputMode="numeric"
                            maxLength={2}
                            required
                            value={kunde.monatGeburtstag}
                            onChange={(e) => setKunde({...kunde, monatGeburtstag: e.target.value.replace(/[^\d]/g, "")})}
                        />
                        .
                        <input
                            type="text"
                            id="jahr"
                            name="jahr"
                            placeholder="JJJJ"
                            style={{display: "inline", width: "5em", textAlign: "center"}}
                            inputMode="numeric"
                            maxLength={4}
                            required
                            value={kunde.jahrGeburtstag}
                            onChange={(e) => setKunde({...kunde, jahrGeburtstag: e.target.value.replace(/[^\d]/g, "")})}
                        />
                    </div>
                </Row>


                <Form.Label htmlFor="EmailLogin" className="form-label mt-3">
                    E-Mail (für Login & Kontakt)*
                </Form.Label>
                <Form.Control
                    type="text"
                    id="EmailLogin"
                    name="EmailLogin"
                    inputMode="email"
                    placeholder="user@example.com"
                    autoComplete="new-email"
                    value={kunde.loginEmail}
                    onChange={(e) => setKunde({...kunde, loginEmail: e.target.value.trim().toLowerCase()})}
                />

            </Form>

        </Row>

    </Container>





                  <div className="mt-5 grid grid-cols-2 gap-1">
                    <button
                      type="button"
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => props.setShowRegisterModal(false)}
                      ref={cancelButtonRef}
                    >
                      Schließen
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
                      onClick={handleNeukundeRegister}
                    >
                      Kunde anlegen
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}

export default NeuenKundenAnlegen






 





