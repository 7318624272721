import React, { useEffect, useCallback, useState, useContext } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, Link } from 'react-router-dom';

import SelectVerantwortung from "../../Bausteine/SelectVerantwortung";

//import SelectMitarbeiter from "../../Bausteine/SelectMitarbeiter"; // keine Archivierten Mitarbeiter drin!
import SelectMitarbeiterAll from "../../Bausteine/SelectMitarbeiterAll";

import SelectGruppe from "../../Bausteine/SelectGruppe";
import SelectKundeObj from "../../Bausteine/SelectKundeObj";
import { COLORS } from '../../constants'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode } from "@fortawesome/free-solid-svg-icons";

import moment from 'moment'
import SelectLand from "../../Bausteine/SelectLand";


function NeueImmo(props) {

  //const { user } = useContext(UserContext);

  const location = useLocation();

  const [selectedAnsprechpartner, setSelectedAnsprechpartner] = useState("");
  const [selectedEigentümer, setSelectedEigentümer] = useState("");
  const [selectedKäufer, setSelectedKäufer] = useState("");
  const [selectedMieter, setSelectedMieter] = useState("");


  const day_of_week = (date) =>  {
    
    var weekday = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    var n = new Date(date).getUTCDay();
    return weekday[n];
  }


  // Wenn Neue Immo aus Kundenkonto aufgerufen wird, Mitarbeiter + Kunden hinterlegen
  useEffect(() => {

    //if(user && user.mitarbeiter) {
    //  props.setSelect("mitarbeiter", {"value":props.user.mitarbeiterId, label: props.user.mitarbeiterId+" | "+props.user.vorname+" "+props.user.nachname})
    //}

  }, [])





  const handleUpload = () => {
    //console.log("Upload...")
  }
  


  return (
    <Container fluid >
      <Row>


        <Col xs={12} md={4}>

            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Technische-Angaben
                </legend>

                <Row>
                    <Col>
                        <Form.Label>Immo ID</Form.Label>
                        <Form.Control
                        className="readOnly"
                        type="text"
                        name="immoId"
                        value={props.data.immoId ? props.data.immoId : "-wird beim Speichern vergeben-" }
                        onChange={()=>{}}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Eintragsdatum</Form.Label>
                        <Form.Control
                            className="readOnly"
                            type="text"
                            name="createdAt"
                            value={props.data.createdAt ? new Date(props.data.createdAt).toLocaleString("de-DE",{
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            }) : "-wird beim Speichern vergeben-"}
                            onChange={()=>{}}
                        />
                    </Col>
                </Row>


                {/* Bearbeiter */}
                <SelectMitarbeiterAll
                    setSelect={props.setSelect}
                    selectedValue={props.data.mitarbeiter}
                />

                
                <Form.Label>Status</Form.Label>
                <Form.Select
                    aria-label="Default select"
                    name="status"
                    value={props.data.status ? props.data.status : "Aktiv"}
                    onChange={(e) => props.setImmo(e)}
                >
                    <option value="Aktiv">Aktiv</option>
                    <option value="Inaktiv">Inaktiv</option>
                    <option value="Archiviert">Archiviert</option>
                </Form.Select>


                <Form.Label>Auftragsart</Form.Label>
                <Form.Select
                    aria-label="Default select"
                    name="auftragsart"
                    value={props.data.auftragsart ? props.data.auftragsart : "keine Angaben"}
                    onChange={(e) => props.setImmo(e)}
                >
                    <option value="keine Angaben">keine Angaben</option>
                    <option value="Normal">Normal</option>
                    <option value="Exklusiv">Exklusiv</option>
                </Form.Select>


                <Form.Label>Auftrag bis</Form.Label>
                <Form.Control
                    type="date"
                    name="auftragBis"
                    min={new Date().toISOString().split('T')[0]}
                    value={props.data.auftragBis ? props.data.auftagBis : ""}
                    onChange={(e) => props.setImmo(e)}
                />

                <Form.Label>Verkauft am</Form.Label>
                <Form.Control
                    type="date"
                    name="verkauftAm"
                    min={new Date().toISOString().split('T')[0]}
                    value={props.data.verkauftAm ? props.data.verkauftAm : ""}
                    onChange={(e) => props.setImmo(e)}
                />

                <Form.Check
                    className="mt-3"
                    type="checkbox"
                    id="stammobjekt"
                    label="Stammobjekt"
                    name="stammobjekt"
                    checked={props.data.stammobjekt ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                {/* select mit zuweisung zum stammobjekt einbauen, wenn kein Stammobjekt */}

                
                <Form.Check
                    type="checkbox"
                    id="exposeversand"
                    label="Autom. Exposéversand"
                    name="exposeversand"
                    checked={props.data.exposeversand ? true : false}
                    onChange={(e) => props.setCBs(e)}
                />

                <Form.Label>Objekttitel</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Objekttitel"
                    name="objekttitel"
                    value={props.data.objekttitel ? props.data.objekttitel : "" }
                    onChange={(e) => props.setImmo(e)}
                />

            </fieldset>




            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Geografische-Angaben
                </legend>


                <Form.Label>Straße</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Straße"
                    name="strasse"
                    value={props.data.strasse ? props.data.strasse : "" }
                    onChange={(e) => props.setImmo(e)}
                />

                <Form.Label>Hausnummer</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Hausnummer"
                    name="hausnummer"
                    value={props.data.hausnummer ? props.data.hausnummer : "" }
                    onChange={(e) => props.setImmo(e)}
                />

                <Form.Label>PLZ</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="PLZ"
                    name="plz"
                    value={props.data.plz ? props.data.plz : null }
                    onChange={(e) => props.setImmo(e)}
                />

                <Form.Label>Ort</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Ort"
                    name="ort"
                    value={props.data.ort ? props.data.ort : "" }
                    onChange={(e) => props.setImmo(e)}
                />

                <Form.Label>Land</Form.Label>
                <SelectLand
                    setSelect={props.setSelect}
                    selectedValue={props.data.land}
                />

            </fieldset>

        </Col>


        <Col xs={12} md={4}>


            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Ansprechpartner
                </legend>

              
                <SelectKundeObj
                  setSelect={(e) => setSelectedAnsprechpartner(e.target.value)}
                  selectedValue={props.data.kunde}
                />
                <Button
                    className="mt-1"
                    onClick={() => {}}
                >
                    Ausgewählten Ansprechpartner hinzuf.
                </Button>


                <ul style={{padding: "0px", marginTop: "20px"}}>
                    {props.data.ansprechpartner.map(ap => 
                        <li
                            key={ap.kdnr}
                            style={{listStyle: "none"}}
                        >
                            <Link to={"/dash/kunde/"+ap.kdnr} style={{color: "#c58164"}}>{ap.kdnr} - {ap.vorname} {ap.nachname}</Link>             
                        </li>
                    )}
                </ul>

            </fieldset>


            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Eigentümer
                </legend>

            
                <SelectKundeObj
                  setSelect={(e) => setSelectedEigentümer(e.target.value)}
                  selectedValue={props.data.kunde}
                />
                <Button
                    className="mt-1"
                    onClick={() => {}}
                >
                    Ausgewählten Eigentümer hinzuf.
                </Button>


                <ul style={{padding: "0px", marginTop: "20px"}}>
                    {props.data.eigentümer?.map(ap => 
                        <li
                            key={ap.kdnr}
                            style={{listStyle: "none"}}
                        >
                            <Link to={"/dash/kunde/"+ap.kdnr} style={{color: "#c58164"}}>{ap.kdnr} - {ap.vorname} {ap.nachname}</Link>             
                        </li>
                    )}
                </ul>

            </fieldset>


            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Käufer
                </legend>

            
                <SelectKundeObj
                  setSelect={(e) => setSelectedKäufer(e.target.value)}
                  selectedValue={props.data.kunde}
                />
                <Button
                    className="mt-1"
                    onClick={() => {}}
                >
                    Ausgewählten Käufer hinzuf.
                </Button>


                <ul style={{padding: "0px", marginTop: "20px"}}>
                    {props.data.käufer?.map(ap => 
                        <li
                            key={ap.kdnr}
                            style={{listStyle: "none"}}
                        >
                            <Link to={"/dash/kunde/"+ap.kdnr} style={{color: "#c58164"}}>{ap.kdnr} - {ap.vorname} {ap.nachname}</Link>             
                        </li>
                    )}
                </ul>

            </fieldset>


            
            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Mieter
                </legend>

            
                <SelectKundeObj
                  setSelect={(e) => setSelectedMieter(e.target.value)}
                  selectedValue={props.data.kunde}
                />
                <Button
                    className="mt-1"
                    onClick={() => {}}
                >
                    Ausgewählten Mieter hinzuf.
                </Button>


                <ul style={{padding: "0px", marginTop: "20px"}}>
                    {props.data.mieter?.map(ap => 
                        <li
                            key={ap.kdnr}
                            style={{listStyle: "none"}}
                        >
                            <Link to={"/dash/kunde/"+ap.kdnr} style={{color: "#c58164"}}>{ap.kdnr} - {ap.vorname} {ap.nachname}</Link>             
                        </li>
                    )}
                </ul>

            </fieldset>

        </Col>




   

        <Col xs={12} md={4}>

            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                >
                    Kategorie
                </legend>

                
                <Form.Label>Objektart</Form.Label>
                <Form.Select
                    aria-label="Default select"
                    name="objektart"
                    value={props.data.objektart ? props.data.objektart : "-"}
                    onChange={(e) => props.setImmo(e)}
                >
                    <option value="-">keine Angaben</option>
                    <option value="zimmer">Zimmer</option>
                    <option value="haus">Haus</option>
                    <option value="wohnung">Wohnung</option>
                    <option value="grundstueck">Grundstück</option>
                    <option value="buero_praxen">Büro/Praxen</option>
                    <option value="einzelhandel">Laden/Einzelhandel</option>
                    <option value="gastgewerbe">Gastgewerbe</option>
                    <option value="hallen_lager_prod">Hallen/Lager/Produktion</option>
                    <option value="land_und_forstwirtschaft">Land/Forstwirtschaft</option>
                    <option value="freizeitimmobilien_gewerblich">Freizeitimmobilie (gewerblich)</option>
                    <option value="gewerbe">Gewerbe</option>
                    <option value="sonstige">Sonstige</option>
                </Form.Select>

                <Form.Label>Nutzungsart</Form.Label>
                <Form.Select
                    aria-label="Default select"
                    name="nutzungsart"
                    value={props.data.nutzungsart ? props.data.nutzungsart : "-"}
                    onChange={(e) => props.setImmo(e)}
                >
                    <option value="-">keine Angaben</option>
                    <option value="wohnen">Wohnen</option>
                    <option value="gewerbe">Gewerbe</option>
                    <option value="anlage">Anlage</option>
                    <option value="waz">WAZ (Wohnen auf Zeit)</option>
                </Form.Select>

                <Form.Label>Vermarktungsart</Form.Label>
                <Form.Select
                    aria-label="Default select"
                    name="vermarktungsart"
                    value={props.data.vermarktungsart ? props.data.vermarktungsart : "-"}
                    onChange={(e) => props.setImmo(e)}
                >
                      <option value="-">keine Angaben</option>
                    <option value="kauf">Kauf</option>
                    <option value="miete">Miete</option>
                    <option value="pacht">Pacht</option>
                    <option value="erbpacht">Erbpacht</option>
                </Form.Select>

            </fieldset>


            <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                    color: COLORS.primCol,
                    background: COLORS.primBG,
                    borderRadius: "8px",
                    fontSize: "12px",
                    }}
                >
                    Intern
                </legend>


                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Interne Beschreibung</Form.Label>
                    <Form.Control as="textarea" rows={10}
                    placeholder="Interne Beschreibung"
                    name="beschreibung"
                    value={props.data.beschreibung ? props.data.beschreibung : ""}
                    onChange={(e) => props.setImmo(e)}
                    />
                </Form.Group>

            </fieldset>

          

        </Col>

      </Row>
    </Container>
  );
}

export default NeueImmo