import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../contexts/MainContext';

import { useNavigate } from 'react-router-dom'

import { Container, Row, Col} from 'react-bootstrap'


function Home() {

    const { user } = useContext(MainContext);
    const navigate = useNavigate();

  return (

    <div style={{padding: "50px 0px 0px 15px", margin: "0px", width: "100%", height: "100vh"}}>
        <h1>
            Home
        </h1>
        <p className="lead">
            Hallo {user.vorname}.
        </p>
        <p>
            Hier findest du die aktuellen Ankündigungen und News für die Nutzung der CRM-Software.
        </p>

        <Container fluid>
            <Row
                style={{display: "flex"}}
            >
                <Col xs={12} md={12} xl={4}>
                </Col>
            </Row>
        </Container>

        <div style={{
                position: "absolute",
                right: "10px",
                bottom: "10px"
            }}
        >
            <span>Version 1.0.0</span>
        </div>


    </div>
  )
}

export default Home