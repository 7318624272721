//import { MapContainer } from 'react-leaflet/MapContainer'
//import { TileLayer } from 'react-leaflet/TileLayer'
//import { useMap } from 'react-leaflet/hooks'
//import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import { useState } from 'react';
import { LeafletMapBasic } from '../Bausteine/Leaflet/leaftlet'
import { Button } from 'react-bootstrap';

function ExklusivmaklerKarte() {

  const [showMap, setShowMap] = useState(false);

  return (
    <div style={{padding: "50px 0px 0px 15px", margin: "0px", width: "100%"}}>
      
      <h1>
          Exklusivmakler Gebiete
      </h1>

      <div style={{paddingRight: "10px"}}>
          {/*
          <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
      */}

        {!showMap &&
          <>
            <p>
              Soll die Karte mit Exklusivmakler PLZ gezeigt werden?
            </p>
            <Button onClick={() => setShowMap(true)}>Karte zeigen</Button>
          </>
        }

        {showMap && 
          <LeafletMapBasic latitude={51.74037} longitude={7.512} width="100%" height="90vh" zoom={6.5} />
        }


      </div>
    </div>
  )
}

export default ExklusivmaklerKarte