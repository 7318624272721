import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap'
import ReactSelect from "react-select";

import CardSmall from "../../Bausteine/Cards/CardSmall";
import Swal from "sweetalert2";


function PipelineWork(props) {

    const [selectedPipeline, setSeletectedPipeline] = useState({});
    const [steps, setSteps] = useState([]);
    const [activePipelineTab, setActivePipelineTab] = useState(1);


    const [optionsArr, setOptionsArr] = useState([
        {
            value: "asf456asf78saf",
            label: "VIN1 Connect"
        },
        {
            value: "asf456asf78saasff",
            label: "VIN1 Connect2"
        }
    ]);

    
    useEffect(() => {

        const newOptionsArr = props.data.map(pipeline => ({
            value: pipeline._id,
            label: pipeline.name
        }));
    
        setOptionsArr(newOptionsArr);

    },[props.data]);

    useEffect(() => {
        const selection = props.data.find(obj => obj._id === selectedPipeline.value);
        if (selection) {
          setSteps(selection.items);
        }
    },[selectedPipeline, props.data])
    

  return (
    <Container fluid >
        <Row style={{paddingLeft: "20px", paddingBottom:"20px"}}>            
        
            <Col xs={12} md={8} xl={4}>
                <ReactSelect
                    value={selectedPipeline ? optionsArr.filter((f) => f.value == selectedPipeline.value) : ""}
                    options={optionsArr}
                    onChange={(val) =>{setSeletectedPipeline(val)}}
                    placeholder={<div className="select-placeholder-text">Wähle eine Pipeline</div>}
                    noOptionsMessage={() => "Lege zunächst eine Pipeline an."}
                />
            </Col>
            {/*
            <Col>
                <Button>
                    Neue Pipeline anlegen
                </Button>
            </Col> */}
        </Row>

      
         

        {Object.keys(selectedPipeline).length > 0 &&
            <div className="mainWork" style={{padding: "10px 0px"}}>
                <Tabs
                    activeKey={activePipelineTab}
                    onSelect={(key) => setActivePipelineTab(parseInt(key))}
                    //defaultActiveKey="mitarbeiter"
                    id="fill-tab-example"
                    fill
                    style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
                >

                    {steps &&
                        steps.map((p, index) => {
                            return (
                                <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} key={index} title={p} eventKey={index+1}>
                                    <Container fluid >
                                        <Row style={{padding: "20px"}}>     
                                            <p>Tabelle mit gefilterten Daten... on Row click =&gt; modal mit Feld für schritt</p>
                                        </Row>
                                    </Container>               
                                </Tab>
                            );
                        })
                    }

                </Tabs>
            </div>
        }


    </Container>
  )
  
}
export default PipelineWork
