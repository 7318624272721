import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MainContext } from '../contexts/MainContext';

import { Row, Col, Form } from "react-bootstrap";
import { COLORS } from "../constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLineChart, faUserPlus, faUserGear, faUsers, faUsersGear, faList, faComment, faCartPlus, faCog, faSignOut, faPuzzlePiece, faBuilding, faShareNodes, faMapLocationDot, faArrowRightArrowLeft, faDiagramPredecessor, faHandshake, faUserTie, faAddressCard } from "@fortawesome/free-solid-svg-icons";


import SelectLand from "../Bausteine/SelectLand";
import SelectBetreuer from "../Bausteine/SelectBetreuer";
import SelectExklusivmakler from "../Bausteine/SelectExklusivmakler";

function InputKundendaten(props) {

  const { user } = useContext(MainContext);

  const navigate = useNavigate();

  /*
  useEffect(() => {
    if(user && Number.isInteger(parseInt(user.mitarbeiterId))) {
      props.setSelect("mitarbeiter", {"value":user.mitarbeiterId, label: user.mitarbeiterId+" | "+user.vorname+" "+user.nachname})
    }
  }, [])
  */


  const handleOpenTask = (id) => {
    navigate('/dash/aufgabe/'+id)
  }
  

  return (
    <>
      <fieldset
        className="border"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <legend
          className="float-none w-auto p-2"
          style={{
            color: COLORS.primCol,
            background: COLORS.primBG,
            borderRadius: "8px",
            fontSize: "12px",
          }}
        >
          Betreuung/Verknüpfungen
        </legend>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <SelectBetreuer
               setSelect={props.setSelect}
               selectedValue={props.data.mitarbeiter ? props.data.mitarbeiter.value : ""}
            />
          </Col>
          
          <Col xs={12} md={6} lg={4}>
            <SelectExklusivmakler
                setSelect={props.setSelect}
                selectedValue={props.data.exklusivmakler ? props.data.exklusivmakler.value : ""}
            />
          </Col>

          <Col xs={12} md={6} lg={4} className="mt-3">
            <h6>Verknüpfungen (Links)</h6>
            {/* oder Link to??? */}

            {props.data.links?.vorteilspartner &&
              <Link
                to={"/dash/vorteilspartner/"+props.data.links.vorteilspartner}
                style={{color: "#c58164"}}
              >
                <FontAwesomeIcon
                  className="menu-item-icon"
                  style={{ paddingRight: "5px"}}
                  icon={faCartPlus}
                  size="lg"
                />
                Vorteilspartner
              </Link>
            }

            {props.data.links?.partner &&
              <Link
                to={"/dash/partner/"+props.data.links.partner}
                style={{color: "#c58164"}}
              >
                <FontAwesomeIcon
                  className="menu-item-icon"
                  style={{ paddingRight: "5px"}}
                  icon={faHandshake}
                  size="lg"
                />
                B2B-Partner
              </Link>
            }
          </Col>

        
          {user.roles.includes("Management") &&
            <Col xs={12} md={6} lg={4} className="mt-3">
              <Form.Check
                type="checkbox"
                id="management"
                name="management"
                label="Kunde nur für Management sichtbar"
                checked={props.data.management ? true : false} 
                onChange={(e) => props.setCBs(e)}
              />
            </Col>
          }

        </Row>
      </fieldset>

      <Row>
        <Col xs={12} md={12} lg={9} xl={9}>
          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Stammdaten
            </legend>

            <Row>
              
                {props.data._id && 
                  <p style={{ margin: "0px", fontSize: "0.9em" }}>
                    Kunde seit: {new Intl.DateTimeFormat('de-DE', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric'
                    }).format(new Date(props.data.createdAt))}
                  </p>
                }

              <Col xs={12} md={4}>
                <Form.Label>Kundennummer</Form.Label>
                <Form.Control
                  className="readOnly"
                  type="text"
                  value={
                    props.data.kdnr
                      ? props.data.kdnr
                      : "-wird beim Speichern vergeben-"
                  }
                  onChange={() => {}}
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Kontaktart</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  name="kontaktart"
                  value={
                    props.data.kontaktart
                      ? props.data.kontaktart
                      : "privatkunde"
                  }
                  onChange={(e) => props.setKunde(e)}
                >
                  <option value="Privatkunde">Privatkunde</option>
                  <option value="Geschäftskunde">Geschäftskunde</option>
                  {/*
                  <option value="Unternehmen">Unternehmen</option>
                  <option value="offentl_einrichtung">
                    öffentl. Einrichtung
                  </option>
                  <option value="geschaftsstelle">Geschäftsstelle</option>
                  <option value="dachverband">Dachverband</option>
                  */}
                </Form.Select>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Form.Label>Titel</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={props.data.anrede ? props.data.anrede : "Herr"}
                  name="anrede"
                  onChange={(e) => props.setKunde(e)}
                >
                  <option>-</option>
                  <option value="dr">Dr.</option>
                  <option value="prof">Prof.</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={2}>
                <Form.Label>Anrede</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={props.data.anrede ? props.data.anrede : "Herr"}
                  name="anrede"
                  onChange={(e) => props.setKunde(e)}
                >
                  <option>-</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                  <option value="Firma">Firma</option>
                </Form.Select>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Label>Firma</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Firma"
                  name="firma"
                  value={props.data.firma ? props.data.firma : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <Form.Label>Geburtsdatum</Form.Label>
                <Form.Control
                  type="date"
                  name="geburtsdatum"
                  value={props.data.geburtsdatum ? props.data.geburtsdatum : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4}>
                <Form.Label>Vorname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vorname"
                  name="vorname"
                  value={props.data.vorname ? props.data.vorname : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Nachname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nachname"
                  name="nachname"
                  value={props.data.nachname ? props.data.nachname : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Anschrift</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Anschrift"
                  name="anschrift"
                  value={props.data.anschrift ? props.data.anschrift : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col xs={12} md={4}>
                <Form.Label pattern="^[0-9]{5}$">Postleitzahl</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Postleitzahl"
                  name="plz"
                  value={props.data.plz ? props.data.plz : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Stadt</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Stadt"
                  name="stadt"
                  value={props.data.stadt ? props.data.stadt : ""}
                  onChange={(e) => props.setKunde(e)}
                />
              </Col>

              <Col xs={12} md={4}>
                <Form.Label>Land</Form.Label>
                <SelectLand
                  setSelect={props.setSelect}
                  selectedValue={props.data.land}
                />
              </Col>
            </Row>
          </fieldset>
        </Col>

        <Col xs={12} md={12} lg={3} xl={3} style={{ overflowX: "hidden" }}>
          <fieldset
            className="border"
            style={{
              background: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Letzte Aktivität
            </legend>

            <ul style={{listStyleType: "none", fontSize: "15px", paddingLeft: "0px"}}>

              {props.letzteAktivität.length >= 1 ? props.letzteAktivität.map((aufgabe) => 

                <li key={aufgabe._id} className="mb-3" style={{cursor: "pointer"}} onClick={() => handleOpenTask(aufgabe._id)} >
                  <span style={{fontSize: "12px"}}>{new Date(aufgabe.createdAt).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span><br />
                  <span style={{textDecoration: "underline"}}>{aufgabe.betreff}</span>
                </li>
                )
                : 
                <li>
                  <p>Es liegt keine Aktivität vor.</p>
                </li>
              }

            </ul>

          </fieldset>
        </Col>
      </Row>
    </>
  );
}
export default InputKundendaten;
