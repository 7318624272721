import React, { useState, useEffect, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { Container, Col, Row, Alert, Tab, Tabs } from 'react-bootstrap';



import FeedbackListe from './FeedbackListe';
//import GruppenAufgabenListe from './GruppenAufgabenListe';
//import VerantwortungAufgabenListe from './VerantwortungAufgabenListe';
//import AbgeschlosseneAufgabenListe from './AbgeschlosseneAufgabenListe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faRotate, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useParams, useNavigate } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';


function Aufgaben() {

  let { id } = useParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")
  const [active, setActive] = useState(true);

  const [feedback, setFeedback] = useState({});
  const [feedbackListe, setFeedbackListe] = useState([]);


  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {

    async function getAllFeedback(id) {
      AuthAxios.get('/my-vin1/feedback')
        .then(res => {
          //console.log(res.data);
          setFeedbackListe(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Feedback konnte nicht gefunden werden."
          });
        });
    }

    /*
    async function getFeedback(id) {
      AuthAxios.get('/my-vin1/feedback/'+id)
        .then(res => {
          //console.log(res.data);
          setFeedback(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Feedback konnte nicht gefunden werden."
          });
        });
    }
    */
    //if (id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
    //  getFeedback(id);
    //}


    //if (reload && id !== undefined && id.length === 24) {
      //getFeedback(id);
    //  setReload(false);
    //} else if (reload && (id == undefined || id.length != 24)) {
    //  getAllFeedback();
    //  setReload(false);
    //}

    getAllFeedback();

  }, [id, reload]);






  return (

    <div style={{ marginLeft: "0px", marginTop: "35px", width: "100%" }}>

  

      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="neueaufgabe"
          id="fill-tab-example"
          fill
          style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
        >

          {/*
            Gruppenaufgaben auswerten Reiter - nur wenn Benutzer auch in Gruppe ist anzeigen!
            Alle Aufgaben nur anzeigen wenn Rechte vorhanden sind!
                      <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Neues Feedback">
            <NeueAufgabe user={user} data={aufgabe} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
          </Tab>
          */}


          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title={"Feedbackliste (" + feedbackListe.length + ")"}>
            <FeedbackListe user={user} data={feedbackListe} setFeedbackListe={setFeedbackListe} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>


        </Tabs>

      </div>
    </div>

  );
}

export default Aufgaben;