import React, { useEffect, useState } from "react"

import { useNavigate } from 'react-router-dom';

import { DataGrid, deDE, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import moment from 'moment';
import Box from '@mui/material/Box';




function VertriebsteamListe(props) {

  const navigate = useNavigate();

  const handleNavigation = (e) => {
    //console.log(e.id);
    //navigate("/dash/mitarbeiter/"+e.id);
    //Open Modal!
    props.setShowEdit(true);
  }

  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "vertriebsteamId",
      headerName: "ID",
      width: 60,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vertriebsteamname",
      headerName: "Vertriebsteam-Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anzahlMitarbeiter",
      headerName: "Anzahl Mitarbeiter",
      width: 150,
      valueGetter: (params) =>
      params.row.anzahlMitarbeiterGruppe !== undefined
        ? params.row.anzahlMitarbeiterGruppe
        : "0",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "beschreibung",
      headerName: "Beschreibung",
      flex: 1,
      minwidth: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName:"Erstellt",
      width: 200,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];
  



    return (
      <div style={{height: `90%`, width:"100%", marginBottom: "30px"}}>
        {props.activeTab === 3 && 
          <Box
              sx={{
              maxHeight: '800px',
              width: '100%',
              '& .super-app-theme--header': {
                  backgroundColor: '#c58164',
              },
              '& .MuiDataGrid-toolbarContainer': {
                  padding: '10px'
              },
              margin: "0px",
              //padding: "5px 10px",
              fontWeight: 'bold',
              //boxShadow: 3,
              }}
          >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={props.data}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            pageSize={20}
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'vertriebsteamId', sort: 'asc' }],
              },
            }}
            sx={{
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
            }}
          />

          </Box>
        }
      </div>
    );
  }
  

export default VertriebsteamListe