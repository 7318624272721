import React, { useState, useEffect, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { Container, Col, Row, Alert, Button, Tab, Tabs } from 'react-bootstrap';

import TemplateVersenden from './TemplateVersenden';
import TemplateÜbersicht from './TemplateÜbersicht';
import TemplateAnlegen from './TemplateAnlegen';
import WhatsappChatContainer from './WhatsappChatContainer'
import VersendeteTemplatesListe from './VersendeteTemplatesListe'
import WAProfile from './WAProfile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faRotate, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useParams, useNavigate } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';




function Whatsapp() {

  let { id } = useParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")
  const [active, setActive] = useState(true);
  const [aufgabe, setAufgabe] = useState({
    status: "Nicht begonnen",
    prio: "normal",
    deadline: new Date().toISOString().substring(0, 10)
  });
  const [aufgabenHistorie, setAufgabenHistorie] = useState([]);

  /*
  const [meineAufgabenListe, setMeineAufgabenListe] = useState([]);
  const [gruppenAufgabenListe, setGruppenAufgabenListe] = useState([]);
  const [verantwortungAufgabenListe, setVerantwortungAufgabenListe] = useState([]);
  const [abgeschlossenAufgabenListe, setAbgeschlossenAufgabenListe] = useState([]);

  const [partnerOffenAufgabenListe, setPartnerOffenAufgabenListe] = useState([]);
  const [partnerAufgabenListe, setPartnerAufgabenListe] = useState([]);
  */

  const [aufgabenListe, setAufgabenListe] = useState([]);

  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    /*

    async function getTask(id) {

      setAufgabenHistorie([]);
      AuthAxios.get('/task/' + id)
        .then(res => {
          //console.log(res.data);
          setAufgabe(res.data);
          return res.data;
        })
        .then((task) => {
            AuthAxios.get('/task/related/' + task.taskId)
            .then(res2 => {
              let relatedTasks = res2.data;
              if(relatedTasks.length >= 1) {
                relatedTasks.push(task.taskId); // add current taskId

                // Order the tasks in descending order
                relatedTasks = relatedTasks.sort((a, b) => a - b);
              }
              setAufgabenHistorie(relatedTasks);
            })
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Aufgabe konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getTask(id);
    }


    if (reload && id !== undefined && id.length === 24) {
      getTask(id);
      setReload(false);
    } else if (reload && (id == undefined || id.length != 24)) {
      newAufgabe();
      setReload(false);
    }
    */

  }, [id, reload]);


  const reloadTasks = () => {
    alert("Alle tasks neu laden. Wird implementiert!")
  }





  // + Icon neuer Partner
  const newAufgabe = () => {
    setAufgabenHistorie([]);
    setAufgabe({
      status: "Nicht begonnen",
      priorität: "normal",
      deadline: new Date().toISOString().substring(0, 10)
    });
    setActiveTab(1);
    navigate("/dash/aufgabe");
  };


  /*
  const setLand = (selectedLandVal) => {
      props.setKunde({
      ...props.data,
      land: selectedLandVal
      });
  }
  */


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [name]: value
    });
  }

  // React Single Select Zwueisung
  const setSelectKunde = (name, kundeObj) => {

    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [name]: kundeObj
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setAufgabe({
      ...aufgabe,
      [name]: newVals
    });

  }

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    const value = event.target.value;
    setAufgabe({
      ...aufgabe,
      [event.target.name]: value
    });
  }


  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);


    setAufgabe({
      ...aufgabe,
      [name]: value
    });
  }


  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setChanged(false);
          setReload(true);
        }
      })
  }


  const saveAufgabe = () => {

    //console.log("kunde vor speichern")

    if (!aufgabe.betreff || !aufgabe.beschreibung) {
      Swal.fire({
        icon: 'info',
        title: "Nicht gespeichert!",
        html: "Gib einen Betreff oder eine Beschreibung ein und versuche es erneut."
      });
      return;
    }

    if (!aufgabe.mitarbeiter && !aufgabe.gruppe) {
      Swal.fire({
        icon: 'info',
        title: "Nicht gespeichert!",
        html: "Hinterlege einen Bearbeiter oder eine Gruppe!"
      });
      return;
    }

    // Diese Werte dürfen nur vom Backend ausgelesen werden
    //delete aufgabe['appzugriffCb'];
    //delete aufgabe['maklervollmachtCb'];
    //delete aufgabe['tippgeberCb'];

    AuthAxios.post('/task', aufgabe)
      .then(res => {
        //console.log(res.data);
        setAufgabe({
          ...aufgabe,
          _id: res.data._id, //Damit nicht erneut ein Task mit selbem Inhalt erstellt wird!
          taskId: res.data.taskId
        });

        if (!aufgabe._id) {
          // Update DataGrid mit neu erstelltem Task   
          setAufgabenListe([
            {
              "_id": res.data._id,
              "taskId": res.data.taskId,
              "ersteller": res.data.ersteller,
              "kunde": res.data.kunde,
              "verantwortung": res.data.verantwortung,
              "gruppe": res.data.gruppe,
              "mitarbeiter": res.data.mitarbeiter,
              "status": res.data.status,
              "prio": res.data.prio,
              "art": res.data.art,
              "deadline": res.data.deadline,
              "betreff": res.data.betreff,
              "beschreibung": res.data.beschreibung,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...aufgabenListe
          ]);
        }
        else {
          // Update DataGrid mit neuem Task (nur Task Update) 
          // alte Id kurz entfernen
          // updated obj hinzufügen
          // rest der daten hinzufügen 
          setAufgabenListe([
            {
              "_id": res.data._id,
              "taskId": res.data.taskId,
              "ersteller": res.data.ersteller,
              "kunde": res.data.kunde,
              "verantwortung": res.data.verantwortung,
              "gruppe": res.data.gruppe,
              "mitarbeiter": res.data.mitarbeiter,
              "status": res.data.status,
              "prio": res.data.prio,
              "art": res.data.art,
              "deadline": res.data.deadline,
              "betreff": res.data.betreff,
              "beschreibung": res.data.beschreibung,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...aufgabenListe.filter(x => x._id !== aufgabe._id),
          ]);
        }
        setAlertSuccess("Aufgabe erfolgreich gespeichert.");
        return res.data;
      })
      .then(newTask => {
        setAufgabenHistorie([]);
        // Aufgabenreihenfolge Folgeaufgaben neu abrufen.
        AuthAxios.get('/task/related/' + newTask.taskId)
        .then(res2 => {
          let relatedTasks = res2.data;
          if(relatedTasks.length >= 1) {
            relatedTasks.push(newTask.taskId); // add current taskId

            // Order the tasks in descending order
            relatedTasks = relatedTasks.sort((a, b) => a - b);
          }
          setAufgabenHistorie(relatedTasks);
        })
      })
      .catch(err => {
        console.log(err);
        setAlertError("Aufgabe konnte nicht gespeichert werden.");
      });

    setChanged(false);

  }







  return (

        <div style={{ marginLeft: "0px", marginTop: "35px", width: "100%" }}>
          <div className="mainWork" style={{paddingBottom: "0px"}}>
            <Tabs
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(parseInt(key))}
                //onSelect={(key) => {
                //if (key != parseInt(1)) {
                    // Revove ID falls am Ende von URL!
                    //const currentUrl = window.location.href;
                    //const base = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
                    //window.history.replaceState({}, document.title, base);
                //}
                //setActiveTab(parseInt(key))
                //}}
                //defaultActiveKey="neueaufgabe"
                id="fill-tab-example"
                fill
                style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
            >


                <Tab style={{ background: "white", position: "relative", paddingTop: "0px" }} eventKey="1" title="Chat">
                  {activeTab === 1 && (
                    <WhatsappChatContainer user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
                  )}
                </Tab>

                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="2" title="Template anlegen">
                  {activeTab === 2 && (
                    <TemplateAnlegen user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
                  )}
                </Tab>

                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="3" title="Template versenden">
                  {activeTab === 3 && (
                    <TemplateVersenden user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
                  )}
                </Tab>

                <Tab style={{ background: "white", position: "relative" }} eventKey="4" title="Template Übersicht">
                  {activeTab === 4 && (
                    <TemplateÜbersicht user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
                  )}
                </Tab>

                <Tab style={{ background: "white", position: "relative" }} eventKey="5" title="Versendet Liste">
                  {activeTab === 5 && (
                    <VersendeteTemplatesListe user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
                  )}
                </Tab>

                <Tab style={{ background: "white", position: "relative" }} eventKey="6" title="Profil">
                  {activeTab === 6 && (
                    <WAProfile user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
                  )}
                </Tab>

                


             

                {/*(user?.roles?.some(role => ["Administrator", "Management"].includes(role))) &&
                <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="7" title={"Partner Zust. Offen"}>
                    <PartnerAufgabeListeOffen data={partnerOffenAufgabenListe} setAufgabenListe={setPartnerOffenAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} />
                </Tab>
                */}



            </Tabs>
          </div>
        </div>

  );
}

export default Whatsapp;