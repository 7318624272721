import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tab, Tabs, Alert, Row, Col, Button } from 'react-bootstrap';

import NeuerVorteilspartner from './NeuerVorteilspartner';
import VorteilspartnerListe from './VorteilspartnerListe';
import QRViewModal from '../../Bausteine/QRViewModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faCreditCard } from "@fortawesome/free-solid-svg-icons";


import { useParams, useNavigate, useLocation } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';
import MeineVorteilspartnerListe from './MeineVorteilspartnerListe';
import VorteilspartnerPipeline from './VorteilspartnerPipeline'

import VorteilVertrieb from './VorteilVertrieb';

import CustomModal from "../../Bausteine/CustomModal"
import VorteilVertriebDetail from './VorteilVertriebDetail';


function Vorteilspartner() {

    let { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // Alle Vorteilspartner
    const [vorteilspartner, setVorteilspartner]  = useState([]);
    const [loading, setLoading] = useState(true);

    // Karte
    const [mapCenter, setMapCenter] = useState({
      lat: 51.7302443,
      lng: 7.4823169
    }); // Standort Noki, überschreiben mit aktueller Position...
    const [markerPartner, setMarkerPartner] = useState({});

    // Tracking ob Offline-Partner, für ermitteln der Koordinaten beim Speichern.
    const [offlinePartner, setOfflinePartner] = useState(false);
    
    // Globaler State für Kunde mit allen Daten
    const [vorteil, setVorteil]  = useState({
      status: "privat",
      land: "Deutschland",
      kategorie: "In Kürze verfügbar"
    })
    
    const [activeTab, setActiveTab] = useState(1);
    const [alertSuccess, setAlertSuccess] = useState("")
    const [alertError, setAlertError] = useState("")
    const [active, setActive] = useState(true);
    const [showQR, setShowQR] = useState(false);
    const [showPaymentLink, setShowPaymentLink] = useState(false);

    // Tracking ob es Änderungen am Vorteilspartner gab
    const [changed, setChanged] = useState(false);

    const [filter, setFilter] = useState({});

    const [ustidProcessing, setUstidProcessing] = useState(false);
    const [bankProcessing, setBankProcessing] = useState(false);
    const [kundeChanged, setKundeChanged] = useState(false);

    const [offeneZahlungen, setOffeneZahlungen] = useState([]);

    //Checkboxen Ref Modal Zahlungslink
    const [werbematerialCb, setWerbematerialCb] = useState(false);
    const [landingpageCb, setLandingpageCb] = useState(false);
    const [zahlungslinkEmailResult, setZahlungslinkEmailResult] = useState(null);
    const [zahlungsLinkProcessing, setZahlungsLinkProcessing] = useState(false);
          

    
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      Swal.fire({
        icon: "success",
        text: "Link in Zwischenablage kopiert."
      })
    }


    // Logo Handling
    const [newImage, setNewImage] = useState(null);
    const [newImageURL, setNewImageURL] = useState(null);


    const handleLogo = (files) => {
    
      setChanged(true);
      // Reset wenn löschen
      if(!files)
      {
        setNewImage(null);
        setNewImageURL(null);
      }
      else
      {
        setNewImage(files[0]);
        // Bilder können nicht direkt so dargetellt werden, daher umwandeln!
        setNewImageURL(URL.createObjectURL(files[0]));
      }
    }

  
    useEffect(() => {
        
        async function getVorteilspartner(id) {
          AuthAxios.get('/vorteilspartner/'+id)
          .then(res => {
              //console.log(res.data);
              //setVorteil({kdnr: "108115", mitarbeiter: "dino"});
              setVorteil(res.data);
              if(res.data.koordinaten) {
                setMarkerPartner({lng: res.data.koordinaten.coordinates[0], lat: res.data.koordinaten.coordinates[1]});
                setMapCenter({lng: res.data.koordinaten.coordinates[0], lat: res.data.koordinaten.coordinates[1]});
              }
          })
          .catch(err => {
              console.log(err);
              Swal.fire({
                  icon: 'error',
                  title: "Fehler",
                  html: "Vorteilspartner konnte nicht gefunden werden."
              });
          });
        }

        if (id !== undefined) {
          getVorteilspartner(id);
          setNewImage(null);
          setNewImageURL(null);
        }

    }, [id]);


    useEffect(() => {
      
      async function getOffeneZahlungen(id) {
        AuthAxios.get('/vorteilspartner/zahlungen/'+id)
        .then(res => {
            setOffeneZahlungen(res.data);
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: "Fehler",
                html: "Offene Zahlungen konnten nicht gefunden werden."
            });
        });
      }

      if (id !== undefined) {
        getOffeneZahlungen(id);
      }
    }, [id, zahlungslinkEmailResult])


    useEffect(() => {

      async function getKontaktdaten(kdnr) {
        AuthAxios.get('/vorteilspartner/kontaktdaten/'+kdnr)
        .then(res => {
            setVorteil({
              ...vorteil,
              email: res.data.email,
              telefon: res.data.telefon,
            });
            setKundeChanged(false);
        })
        .catch(err => {
            setKundeChanged(false);
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: "Fehler",
                html: "Kontaktdaten konnten nicht gefunden werden."
            });
        });
      } 


      if (vorteil.kunde?.value) {
        getKontaktdaten(vorteil.kunde.value);
      }

    }, [vorteil.kunde])




    const getKoordinatenFromAdress = () => {

      if(!vorteil.anschrift || !vorteil.plz || !vorteil.stadt || !vorteil.land) {
        Swal.fire({
          icon: "error",
          title: "Adressabfrage",
          html: "Fülle zunächst die komplette Anschrift aus!"
        })
        return;
      }
  
      AuthAxios.post('/vorteilspartner/get-koordinaten', {
        anschrift: vorteil.anschrift,
        plz: vorteil.plz,
        stadt: vorteil.stadt,
        land: vorteil.land
      })
      .then(res => {
        setMarkerPartner(res.data.koordinaten);
        setMapCenter(res.data.koordinaten);

        //geoJSON Mongo => [lng, lat]
  
        // Adresskorrektur durch Google API
        setVorteil({...vorteil,
          koordinaten: {
            type: 'Point',
            coordinates: [res.data.koordinaten.lng, res.data.koordinaten.lat]
          },
          anschrift: res.data.anschrift,
          plz: res.data.plz,
          stadt: res.data.stadt
        })
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Adressabfrage",
          html: err
        })
      })
    }


    const validateBank = (iban) => {

      setBankProcessing(true);

      AuthAxios.post('/faktura/getDetailsfromIban', {
        iban:iban
      })
      .then(res => {
        setBankProcessing(false);

        if(!res.data.name || !res.data.bic) {
          Swal.fire({
            icon: "info",
            title: "IBAN Validierung",
            html: "IBAN nicht gültig."
          })
        } else {
          setVorteil({...vorteil,
            bankValid: true,
            bank: res.data.name,
            bic: res.data.bic
          })
        }
      })
      .catch(err => {
        setBankProcessing(false);
        Swal.fire({
          icon: "error",
          title: "IBAN Validierung",
          html: err
        })
      })

    }


    const validateUstId = (ustId) => {

      setUstidProcessing(true);

      AuthAxios.post('/faktura/validate-ustid', {
        ustId: ustId
      })
      .then(res => {

        //console.log(res);

        if(res.data.status == "invalid") {
          Swal.fire({
            icon: 'error',
            title: "Fehler bei UstId Prüfung",
            html: "Ust nicht gültig!"
          });
        } else {
          setVorteil({...vorteil,
            ustIdValid: res.data.status,
            ustIdGeprueftAm: new Date()
          });
          setUstidProcessing(false);
        }
      })
      .catch(err => {
        setUstidProcessing(false);
        Swal.fire({
          icon: "error",
          title: "UstId Validierung",
          html: err
        })
      })
    }





    // + Icon neuer Partner
    const newVorteil = () => {
        setVorteil({
          status: "privat",
          land: "Deutschland",
          kategorie: "In Kürze verfügbar"
        });
        setActiveTab(1);
        navigate("/dash/vorteilspartner");
    };


    /*
    const setLand = (selectedLandVal) => {
        props.setKunde({
        ...props.data,
        land: selectedLandVal
        });
    }
    */

    const saveVorteil = () => {
  
      if (!vorteil.name) {
        Swal.fire({
          icon: 'error',
          title: "Speichern",
          html: "Gib einen Vorteilspartnernamen ein!"
        });
        return;
      }
      if ((vorteil.online === false && vorteil.offline === false) || (vorteil.online === undefined && vorteil.offline === undefined) || (vorteil.online === false && vorteil.offline === undefined)  || (vorteil.online === undefined && vorteil.offline === false)) {
          Swal.fire({
            icon: 'error',
            title: "Speichern",
            html: "Wähle aus ob der Vorteil Online und/oder Offline zu finden ist und fülle die Felder im Abschnitt Partner aus!"
          });
          return;
      }


      if (vorteil.online) {

          
          if(!vorteil.vorteilscode) {
              Swal.fire({
              icon: 'error',
              title: "Speichern",
              html: "Hinterlege einen Vorteils/Rabattcode!"
              });
              return;
          }
          

          
          if(!vorteil.webseite) {
              Swal.fire({
                icon: 'error',
                title: "Speichern",
                html: "Hinterlege eine Webseite!"
              });
              return;
          }
          
      }

      if (vorteil.offline) {

          if(!vorteil.vorteilOffline) {
              Swal.fire({
              icon: 'error',
              title: "Speichern",
              html: "Hinterlege einen vor Ort Vorteil!"
              });
              return;
          }
          
          
          if(!vorteil.anschrift) {
              Swal.fire({
                icon: 'error',
                title: "Speichern",
                html: "Hinterlege eine Anschrift!"
              });
              return;
          }
          
          if(!vorteil.plz) {
              Swal.fire({
                icon: 'error',
                title: "Speichern",
                html: "Hinterlege eine PLZ!"
              });
              return;
          }

          if(!vorteil.stadt) {
              Swal.fire({
                icon: 'error',
                title: "Speichern",
                html: "Hinterlege eine Stadt!"
              });
              return;
          }

          if(vorteil.koordinaten.coordinates.length !== 2) {

            // Koordinaten automatisch ermitteln ?
            Swal.fire({
              icon: 'error',
              title: "Speichern",
              html: "Ermittle die Koordinaten der Adresse!"
            });
            return;

        }
      }
      

      // Sending
      let formData = new FormData();
      
      if (newImage !== "") {
        formData.append('file', newImage);
      }
  
      formData.append('vorteilspartner', JSON.stringify(vorteil)); // nur strings, kein JSON...
  
      const headerConfig = { headers: { 'content-type': 'multipart/form-data' } };
  
      // Übergeben der Daten als formData!
    
      AuthAxios.post('/vorteilspartner', formData, headerConfig)
        .then(res => {
          //console.log(res.data);
          setVorteil({
            ...res.data,
            _id: res.data._id, //Damit nicht erneut ein Partner erstellt wird, nur Update fahren wenn Id!
            vorteilId: res.data.vorteilId,
            createdAt: res.data.createdAt
          });

          setNewImage(null);
          setNewImageURL(null);
  
          setAlertSuccess("Vorteilspartner erfolgreich gespeichert.");
        })
        .catch(err => {
          console.log(err);
          setAlertError("Vorteilspartner konnte nicht gespeichert werden.");
        });
  
      setChanged(false);
      
    }



      
      /*
  // Update DataGrid mit neuem Vorteilspartner
  const newVorteilspartner = (newVorteil) => {
    
    setVorteil([
        ...vorteil,
        {
          "_id": newVorteil._id,
          "name": newVorteil.name,
          "vortielId": newVorteil.vorteilId,
          "kunde": newVorteil.kunde,
          "mitarbeiterId": newVorteil.mitarbeiterId,
          "kategorie": newVorteil.kategorie,
          "online": newVorteil.online,
          "offline": newVorteil.offline,
          "vorteilscode": newVorteil.vorteilscode,
          "webseite": newVorteil.webseite,
          "anschrift": "",
          "plz": "",
          "stadt": "",
          "land": "",
          "createdAt": newVorteil.createdAt
        }
    ]);
  }
  */





      const handleZahlungslinkEmail = async () => {

        if(!vorteil.kunde.value) {
          Swal.fire({
            icon: "info",
            html: "Kunde",
            text: "Zunächst einen Kunden zuweisen und speichern!"
          });
        }

        if(!vorteil._id) {
          Swal.fire({
            icon: "info",
            html: "Vorteilspartner ID",
            text: "Vorteilspartner ID nicht übermittelt."
          });
        }

        setZahlungsLinkProcessing(true);

        try {
          let result = await AuthAxios.post('/vorteilspartner/send-paymentlink', {
            id: vorteil._id,
            kdnr: vorteil.kunde.value,
            produkte: {
              werbematerial: werbematerialCb,
              landingpage: landingpageCb
            }
          });
          setZahlungsLinkProcessing(false);
          setZahlungslinkEmailResult(result);
        } catch (error) {
          setZahlungsLinkProcessing(false);
          setZahlungslinkEmailResult(error);
        }
      }


  
    return (
      
      <div style={{ marginLeft: "0px", marginTop: "40px", width: "100%" }}>

        <Row style={{ display: "flex", alignItems: "center",  margin: "0px"  }}>
          <Col xs={12} md={12} className="mainActions" style={{justifyContent: "space-between"}}>
            
            <Col xs={12} md={3}>
                <FontAwesomeIcon
                  icon={faPlus}
                  size="2x"
                  className="actionIcon"
                  onClick={newVorteil}
                  title="Neuen Vorteilspartner anlegen"
                />
                
                {id && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faQrcode}
                    size="2x"
                    className="actionIcon"
                    onClick={() => setShowQR(true)}
                    title="QR-Code Download"
                  />
                }

                {id && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    size="2x"
                    className="actionIcon"
                    onClick={() => setShowPaymentLink(true)}
                    title="Zahlungslink anzeigen"
                  />
                }
                

                {changed && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={saveVorteil}
                    title="Vorteilspartner speichern"
                  />
                }
                {!changed && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2x"
                    className="actionIcon"
                    title="Keine Änderung erfolgt"
                  />
                }
            </Col>


            <Col xs={12} md={3}>
              {alertSuccess.length > 0 &&
                <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                  {alertSuccess}
                </Alert>
              }
              {alertError.length > 0 &&
                <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                  {alertError}
                </Alert>
              }
            </Col>


              <Col xs={12} sm={5} md={4}>
                <Row>
                  <Col className="d-flex justify-content-end">
                  <Button
                    style={{width: "auto"}}
                    className="mt-2"
                    onClick={() => window.open('https://my.vin1.eu/vp-onboarding/vin1')}
                  >
                    Vorteilspartner Registrier-Link
                  </Button>
                  </Col>
                </Row>
              </Col>

          </Col>
        </Row>

          <div className="mainWork">
            <Tabs
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
              //defaultActiveKey="neuervorteilspartner"
              id="fill-tab-example"
              fill
              style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
            >  

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Neuer Vorteilspartner">
                <NeuerVorteilspartner
                  tab={1}
                  vorteil={vorteil}
                  offeneZahlungen={offeneZahlungen}
                  kundeChanged={kundeChanged}
                  setKundeChanged={setKundeChanged}
                  getKoordinatenFromAdress={getKoordinatenFromAdress}
                  setVorteil={setVorteil}
                  setChanged={setChanged}
                  setOfflinePartner={setOfflinePartner}
                  markerPartner={markerPartner}
                  setMarkerPartner={setMarkerPartner}
                  mapCenter={mapCenter}
                  setMapCenter={setMapCenter}
                  validateBank={validateBank}
                  validateUstId={validateUstId} 
                  newImage={newImageURL}f
                  setNewImage={setNewImage}
                  handleLogo={handleLogo}
                  ustidProcessing={ustidProcessing}
                  bankProcessing={bankProcessing}
                />
         
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="2" title="Meine Vorteilspartner">
                <MeineVorteilspartnerListe tab={2} setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>
              

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="3" title="Alle Vorteilspartner">
                <VorteilspartnerListe tab={3} setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="10" title="Pipeline">
                <VorteilspartnerPipeline tab={10} setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>


              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="8" title="Vertrieb">
                <VorteilVertrieb tab={8} setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="9" title="Vertrieb Detail">
                <VorteilVertriebDetail tab={9} setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>


              {/*
              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="6" title="Abo Übersicht">
                <AboUebersicht setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>
              */}



            </Tabs>

            {showQR &&
              <QRViewModal title={vorteil.name+" - "+vorteil.vorteilId} id={vorteil._id} vorteilId={vorteil.vorteilId} visible={setShowQR} />  
            }

            {showPaymentLink &&
         
              <CustomModal 
                  title={"Zahlungslinks"}
                  visible={showPaymentLink}
                  setVisible={() => {
                    setShowPaymentLink(false);
                    setZahlungsLinkProcessing(false);
                  }}
              >

                <h6>Wähle aus, welche Optionen der Vorteilspartner wünscht.</h6>
                <p>Der E-Mail Inhalt und die Artikel auf der Zahlungsseite werden basierend auf den Checkboxen gesetzt!</p>


                <fieldset>
                  <legend className="sr-only">Notifications</legend>
                  <div className="space-y-5">

                  <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="comersteinrichtungments"
                          aria-describedby="comments-description"
                          name="ersteinrichtung"
                          type="checkbox"
                          value={true}
                          disabled={true}
                          onChange={() => {}}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                          Ersteinrichtung Vorteilspartner - 59€ netto
                        </label>
                      </div>
                    </div>
                    


                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="werbematerial"
                          type="checkbox"
                          value={werbematerialCb}
                          onChange={(e) => setWerbematerialCb(e.target.checked)}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                          Erstausstattung Werbematerial - 49€ netto
                        </label>
                        <p id="comments-description" className="text-gray-500">
                          500 Flyer + 2 Poster A2
                        </p>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="candidates"
                          aria-describedby="candidates-description"
                          name="landingpage"
                          type="checkbox"
                          value={landingpageCb}
                          onChange={(e) => setLandingpageCb(e.target.checked)}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="candidates" className="font-medium text-gray-900">
                          Landingpage - 99€ netto (jährlich)
                        </label>
                        <p id="candidates-description" className="text-gray-500">
                          Landingpage mit Daten des Partners (Logo, Name, Registrierungslinks für Kunden mit Sponsorcode, ...)
                        </p>
                      </div>
                    </div>
                  </div>
                </fieldset>
            



                {!werbematerialCb && !landingpageCb &&
                  <p className="fw-bold">Zunächst Optionen wählen!</p>
                }


                  <button
                    type="button"
                    className={`bg-indigo-500 flex items-center rounded-lg px-4 py-2 text-white ${zahlungsLinkProcessing || (!werbematerialCb && !landingpageCb)  ? "opacity-50" : "opacity-100"}`}
                    onClick={handleZahlungslinkEmail}
                    disabled={zahlungsLinkProcessing || (!werbematerialCb && !landingpageCb) ? true : false}
                  >
                  {zahlungsLinkProcessing ?
                    <>
                       <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span className="font-medium"> Wird versendet... </span>
                    </>
                    :
                    <>
                      Zahlungsaufruf per E-Mail versenden
                    </>
                  }
                </button>
           

                {zahlungslinkEmailResult &&
                  <>
                    {zahlungslinkEmailResult?.data?.status == "success" ?
                      <p className="mt-3 italic fw-bold text-gray-500">E-Mail zur Zahlung versendet am: {new Date().toLocaleString("de-De")}</p>
                      :
                      <p className="mt-3 italic fw-bold text-red-500">Fehler: {zahlungslinkEmailResult.message}</p>
                    }
                  </>
                }

              </CustomModal>
            } 
            

          </div>
          
      </div>
    );
  }
  
  export default Vorteilspartner;