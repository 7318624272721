/*
import {createStore, compose, combineReducers, applyMiddleware} from 'redux';

import thunkMiddleware from 'redux-thunk';
import { authReducer } from './reducers/authReducer';
import { messengerReducer } from './reducers/messengerReducer';

const rootReducer = combineReducers({
     auth: authReducer,
     messenger : messengerReducer
})

const middleware = [thunkMiddleware];

const store = createStore(rootReducer,compose(applyMiddleware(...middleware),
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 

));
*/

// New: "When using configureStore from Redux Toolkit, redux-thunk is included by default"


import { configureStore } from '@reduxjs/toolkit';
// import { authReducer } from './reducers/authReducer';
import { messengerReducer } from './reducers/messengerReducer';

const store = configureStore({
  reducer: {
    // auth: authReducer,
    messenger: messengerReducer,
  },
  // Redux Thunk is included by default, additional middleware can be added here
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  // DevTools are enabled by default in development mode
});

export default store;