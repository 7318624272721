import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, NavItem, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton, GridFooterContainer, GridFooter } from '@mui/x-data-grid';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import StripedDataGrid from "../../Bausteine/StripedDataGrid";

import axios from 'axios';
import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";



function TemplateÜbersicht() {

  const navigate = useNavigate();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterSidebarActive, setFilterSidebarActive] = useState(false);

  const [kundenFiltered, setKundenFiltered] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);


  const previousPaginationModel = useRef(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  

  const fetchRows = async (page, pageSize, sortModel, filterModel) => {
    setLoading(true);

    // sicherer abruf nur über Server mit Authaxios!
    const res = await AuthAxios.get('/wa/get-templates');
      
    setKundenFiltered(res.data.data);

    setLoading(false);
  };
  
  
  useEffect(() => {
    // Call fetchRows in the useEffect callback
    fetchRows(1, 25, [{ field: 'kdnr', sort: 'desc' }], {});
  }, [])
  
  
  //useEffect(() => {
    //fetchRows(paginationModel.page+1, paginationModel.pageSize, sortModel, filterModel);
    //}, [paginationModel]);
    
    
    const handlePaginationModelChange = (newPagination) => {
      fetchRows(newPagination.page+1, newPagination.pageSize, [{ field: 'kdnr', sort: 'desc' }], {});
    };
    
    const handlePageChange = (newPage) => {
      console.log("new PAge"+newPage);
    }
    
    
    const handleSortModelChange = (newSortModel) => {
      //setSortModel(newSortModel);
      //fetchRows(page+1, pageSize, newSortModel, filterModel);
    };
  
    
    const handleFilterModelChange = (field, value) => {
      
      if(value) {
        console.log("Allgemeine Suche");
        console.log(field.quickFilterValues[0]);
      }
      if(field.items.length > 0) {
        console.log(field.items);
      }
      //setQueryOptions({ filterModel: { ...filterModel } });
      //setFilterModel((prevFilterModel) => ({ ...prevFilterModel, ...newFilterModal }));
      //console.log(filterModel);
      //setFilterModel(newFilterModel);
      //fetchRows(page+1, pageSize, sortModel, newFilterModel);

      // noch dynamisch mit den anderen Filtern verbinden!
      let filterValue = {'allgemeineSuche': field.quickFilterValues[0]};
      fetchRows(paginationModel.page+1, paginationModel.pageSize, [{ field: 'kdnr', sort: 'desc' }], { filter: filterValue });

    };
    
    
    const handleNavigation = (e) => {
      //console.log(e.id);
      navigate("/dash/kunde/" + e.id);
    }
    
    
  /*
  useEffect(() => {

    setKundenFiltered(kunden.filter(kunde => {

      //Jeden Eintrag des Checkbox-Filters auf true prüfen (true = aktiver Filter)
      let filterActive = false;
      
      // Wenn eine einzige Cb true ist, dann soll gefiltert werden
      Object.keys(filter).forEach(key => {
        if (filter[key] === true)
          filterActive = true;
      });



      if (filterActive) {

        setFilterSidebarActive(true);

            // Filterung
            const appzugriffCb = filter.appzugriffCb ? kunde.appzugriffCb : true;
            const maklervollmachtCb = filter.maklervollmachtCb ? kunde.maklervollmachtCb : true;
            const connectCb = filter.connectCb ? kunde.connectCb : true;
            const tippgeber_verifiziertCb = filter.tippgeber_verifiziertCb ? kunde.tippgeber_verifiziertCb : true;

            // negative Filterung
            const appzugriffCbNot = filter.appzugriffCbNot ? !kunde.appzugriffCb : true;
            const maklervollmachtCbNot = filter.maklervollmachtCbNot ? !kunde.maklervollmachtCb : true;
            const connectCbNot = filter.connectCbNot ? !kunde.connectCb : true;
            const tippgeber_verifiziertCbNot = filter.tippgeber_verifiziertCbNot ? !kunde.tippgeber_verifiziertCb : true;

            // Interessen
            const exklusivpartner = filter.exklusivpartner ? kunde.exklusivpartner : true;
            const investor = filter.investor ? kunde.investor : true;
            const tippgeber = filter.tippgeber ? kunde.tippgeber : true;
            const vorteilspaket = filter.vorteilspaket ? kunde.vorteilspaket : true;
            const vorteilspartner = filter.vorteilspartner ? kunde.vorteilspartner : true;


            return appzugriffCb && maklervollmachtCb && connectCb && tippgeber_verifiziertCb && appzugriffCbNot && maklervollmachtCbNot && connectCbNot && tippgeber_verifiziertCbNot && exklusivpartner && investor && tippgeber && vorteilspaket && vorteilspartner;
      }
      else {
        // Wenn kein Filter aktiv(=true) ist, alle ausgeben
        setFilterSidebarActive(false);
        return true;
      }
    }));


  },[filter])
  */




  const columns = [
    {
      field: "name",
      headerName: "Name Template",
      minWidth: 180,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "category",
      headerName: "Kategorie",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <span>
         {params.row.category == "MARKETING" &&
           <>Marketing</>
         }
         {params.row.category == "SERVICE" &&
           <>Service</>
         }
         {!params.row.category == "MARKETING" && !params.row.category == "SERVICE" &&
           <>Authentifizierung</>
         }
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "language",
      headerName: "Sprache",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <span>
         {params.row.status == "APPROVED" &&
          <Badge bg="success">Aktiv - Qualität ausstehend</Badge>
         }
         {!params.row.status == "REJECTED" &&
          <Badge bg="secondary">In Überprüfung</Badge>
         }
         {params.row.status == "REJECTED" &&
          <Badge bg="danger">Abgelehnt</Badge>
         }
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gesendeteNachrichten",
      headerName: "Gesendete Nachrichten",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "geöffneteNachrichten",
      headerName: "Geöffnete Nachrichten",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "GrundBlockierung",
      headerName: "Grund für Blockierung",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "aktialisiert",
      headerName: "Zuletzt Aktualisiert",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    }
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter placeholder="KdNr, Vorname, Name" />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

        </Row>

      </GridToolbarContainer>
    );
  }


 



  return (
    <>
      <div style={{width: "100%" }}>
        <Box
          sx={{
            height: `calc(100vh - 100px)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >
          <StripedDataGrid
            rows={kundenFiltered}
            rowCount={totalCount}
            columns={columns}
            getRowId={(row) => row.id}
            loading={loading}
            //pageSizeOptions={[5]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(newModel) => {
              // Compare the new pagination model with the previous one
              if (
                newModel.pageSize !== previousPaginationModel.current?.pageSize ||
                newModel.page !== previousPaginationModel.current?.page
              ) {
                //console.log(newModel.pageSize, newModel.page);
                //set new default Value for pageSize, ... & fetch new Data
                setPaginationModel(newModel);
                handlePaginationModelChange(newModel);
              }
            }}
            autoPageSize
            disableSelectionOnClick
            density={'compact'}
            onRowClick={handleNavigation}

            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
          
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
          />

        </Box>

      </div>

    </>
  );
}


export default TemplateÜbersicht