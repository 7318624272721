import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";


import { deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";

function DateiListe(props) {


  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const dateOptionDay = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  const [dateien, setDateien] = useState([]);
  /*
    {
      id: "asdfs65d4f7",
      created: new Date(),
      title: "123456789012345678901324567890132456Testdokument.png",
      bearbeiter: "Marius Schulte",
      gelesen: true,
      category: "Versicherung",
      categoryTwo: "Gesundheit",
      categoryThree: "Gesetzlich",
      gelesenAm: new Date(),
      customerAction: true,
      customerActionType: "Unterschrift",
      bestaetigt: new Date(),
      url: "https://vin1.eu/wp-content/uploads/2022/08/vin1-energie-solarrocker-1024x576.jpg"
    },
    {
      id: "bsdfs65d4f7",
      created: new Date(),
      title: "123456789012345678901324567890132456Testdokument.png",
      bearbeiter: "Marius Schulte",
      gelesen: true,
      category: "Versicherung",
      categoryTwo: "Gesundheit",
      categoryThree: "Gesetzlich",
      gelesenAm: new Date(),
      customerAction: true,
      customerActionType: "Bestätigung",
      bestaetigt: new Date(),
      url: "https://vin1.eu/wp-content/uploads/2022/08/vin1-energie-solarrocker-1024x576.jpg"
    },
    {
      id: "csdfs65d4fa",
      created: new Date(),
      title: "123456789012345678901324567890132456Testdokument mit Bestätigung.jpg",
      bearbeiter: "Marius Schulte",
      gelesen: true,
      category: "Versicherung",
      categoryTwo: "Gesundheit",
      categoryThree: "Gesetzlich",
      gelesenAm: new Date(),
      url: "https://vin1.eu/wp-content/uploads/2022/08/vin1-energie-solarrocker-1024x576.jpg"
    }, 
  ]);
  */

  const [loading, setLoading] = useState(true);

  const [googleUID, setGoogleUID] = useState("");




  async function getDateienListe() {
    AuthAxios.post("/dokumente/datei-liste/", {
        googleUID: props.data.googleuid
      })
      .then((res) => {
        setDateien(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Datei-Liste für Kunde " + props.data.kdnr + " konnten nicht geladen werden.",
        });

      });
  }

  //Dateien für Kunden nur laden wenn der Tab geladen wird
  useEffect(() => {
    if (props.activeTab === 5) {
      getDateienListe();
    }
  }, [props.activeTab]);



  useEffect(() => {

    if (props.activeTab == 5) {
      if (props.data.googleuid && props.data.googleuid !== "") {
        setGoogleUID(props.data.googleuid);
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Dateien",
          html: "Kunde ist noch nicht in der VIN App registriert!<br />"
            + "Kunde zunächst in der VIN-App registrieren lassen."
        })
      }
    }
  }, [props])


  const handleFile = (datarow) => {
    //console.log(datarow.row.url);
    if(datarow.row.url) {
      window.open(datarow.row.url, '_blank');
    }
    else {
      Swal.fire({
        icon: "info",
        title: "Datei",
        html: "Datei Link fehlt."
      })
    }
   
  }




  const columns = [
    {
      field: "created",
      headerName: "Erstellt",
      minWidth: 130,
      renderCell: (params) => (
        <span>{params.row.created?._seconds &&
          new Date(params.row.created._seconds*1000).toLocaleString("de-DE", dateOptions)}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "title",
      headerName: "Name",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.title.substring(36)}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bearbeiter",
      headerName: "Bearbeiter",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "customerAction",
      headerName: "Bestätigung notw?",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.customerAction ?
          "ja"
          : "-"}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "customerActionType",
      headerName: "Bestätigung Art",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.customerActionType ?
          params.row.customerActionType
          : "-"}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bestaetigt",
      headerName: "Bestätigt?",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <span>
          {Date.parse(params.row.bestaetigt) ?
            new Date(params.row.bestaetigt._seconds*1000).toLocaleString("de-DE", dateOptions)
            : "-"
          }
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gelesen",
      headerName: "Gesehen/Heruntergel.",
      minWidth: 130,
      renderCell: (params) => (
        <span>
          {!params.row.gelesen ?
           "-"
          : "ja"}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gelesenAm",
      headerName: "GelesenAm",
      minWidth: 130,
      renderCell: (params) => (
        <span>
          {Date.parse(params.row.gelesenAm) ?
            new Date(params.row.gelesenAm).toLocaleString("de-DE", dateOptions)
            :
            <span>-</span>
          }
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "category",
      headerName: "Kategorie",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "categoryTwo",
      headerName: "Kat. 2",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "categoryThree",
      headerName: "Kat. 3",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

        </Row>

      </GridToolbarContainer>
    );
  }



  return (
    <div style={{ width: "100%" }}>
      {!googleUID ?
        <h5>
          Kunde ist noch nicht in der VIN1 App registriert. Daher kann nicht auf die Dateien zurückgegriffen werden.
        </h5>
        :
      <>
        {props.activeTab === 5 && (
          <>
            <Box
              sx={{
                height: `calc(100vh - 200px)`,
                width: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: '#c58164',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  padding: '10px'
                },
                margin: "0px 0px 0px 0px",
                fontWeight: 'bold',
                boxShadow: 0,
              }}
            >
              <StripedDataGrid
                //loading={loading}
                rows={dateien}
                columns={columns}
                getRowId={(row) => row.id}
                onRowClick={handleFile}
                //pageSize={30}
                //rowsPerPageOptions={[30]}
                autoPageSize
                density={"compact"}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 100 },
                  },
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'created', sort: 'desc' }],
                  },
                }}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.dark',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  bgcolor: 'background.paper'
                }}
              />
            </Box>

          </>
        )}

      </>}

    </div>

  );
}

export default DateiListe;
