import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col } from "react-bootstrap";

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';

import { alpha, styled } from '@mui/material/styles';

import moment from 'moment';

import Box from '@mui/material/Box';
import './VorteilspartnerListe.Module.css';

import AuthAxios from "../../utils/AuthAxios";
import Swal from 'sweetalert2';
import ReactSelect from "react-select";



function VorteilVertriebDetail(props) {

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [vertriebler, setVertriebler] = useState([]);


  useEffect(() => {

    getlast12month();
    setLoading(false);
   /* async function getVertriebler() {

      
      AuthAxios.get('/vorteilspartner/vertrieb')
      .then(res => {
        setVertriebler(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Liste Vertrieb konnten nicht geladen werden."
        });
      });
    }

    if(props.activeTab == props.tab) {
      getVertriebler();
    }
    */

  },[props.activeTab]);




  const [last12Month, setLast12Month] = useState(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);


  const getlast12month = async () => {

    //{label:'Jan 2022', value:01-2022},

    const monthName = new Array("Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez");

    const d = new Date();
    // Tag auf den ersten des Monats festelgen
    d.setDate(1);
    const entries = [];
    for (var i = 0; i <= 11; i++) {

        let json = {};
        json.value = d.getMonth() + 1 + '-' + d.getFullYear();
        json.label = monthName[d.getMonth()] + ' ' + d.getFullYear()
        entries.push(json);

        //Monat abziehen
        d.setMonth(d.getMonth() - 1);
    }
    setLast12Month(entries);
    setSelectedMonthYear(entries[0]);
}







  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "kdnr",
      headerName: "Kdnr",
      minWidth: 65,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amountVorteilspartner",
      headerName: "Anz. Abschlüsse Vorteilspartner",
      minWidth: 150,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>

      </GridToolbarContainer>
    );
  }



    return (

      <div style={{width: "100%"}}>

      <Row>
        <div className="ml-3 mt-2" style={{ width: '300px', marginBottom: '10px' }}>
            <p style={{ marginBottom: '5px' }}>Wähle einen Zeitraum aus</p>
            <ReactSelect
                value={selectedMonthYear}
                options={last12Month}
                onChange={(val) => setSelectedMonthYear(val)}
            />
        </div>
      </Row>

      <h3>ToDo: Tabelle befüllen mit Anzahl der finalen Akquirierungen der Vertriebler, für Provisionsabrechnung!</h3>

        <Box
          sx={{
            height: '70vh',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

        {props.activeTab == props.tab && (
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            rows={[]}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={() => {}}
            pageSize={15}
            //rowsPerPageOptions={[20]}
            //autoPageSize
            //onFilterModelChange={onFilterModelChange}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
          />
        )}
        
        </Box>

      </div>
    );
  }
  

export default VorteilVertriebDetail