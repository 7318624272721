import React from 'react';
import './InputSliderBootstrap.css'; // Make sure to create this CSS file

const InputSliderBootstrap = ({ min, max, value, step, onChange }) => {
    return (
        <div className="bootstrap-slider-container">
            <input
                type="range"
                className="bootstrap-slider"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default InputSliderBootstrap;