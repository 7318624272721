
import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faRotate } from "@fortawesome/free-solid-svg-icons";

import { Container, Col, Row, Tab, Tabs, Alert } from 'react-bootstrap';

import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";
import NeuePipeline from './NeuePipeline';
import PipelineWork from './PipelineWork';


const Pipeline = () => {


  
  let { id } = useParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")
  const [active, setActive] = useState(true);

  const [pipeline, setPipeline] = useState({});
  
  const [pipelineListe, setPipelineListe] = useState([]);

  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);

  useEffect(() => {

    async function getPipeline(id) {
      AuthAxios.get('/pipeline/' + id)
        .then(res => {
          //console.log(res.data);
          setPipeline(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Pipeline konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined) {
      getPipeline(id);
    }
  }, [id]);

  const reloadPipelines = () => {
    alert("Alle Pipelines neu laden. Wird implementiert!")
  }






  
  useEffect(() => {
    async function getPipelines() {
      AuthAxios.get('/pipeline')
      .then(res => {
        //console.log(res.data);
        setPipelineListe(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Pipelines konnten nicht geladen werden."
        });
      });
    }
   
    getPipelines();

  },[]);



  // Update DataGrid mit neuem Mitarbeiter
  const newPipeline = (newPipeline) => {

/*
    AuthAxios.post('/pipeline', newPipeline)
    
    setPipeline([
        ...pipeline,
        {
          "_id": newPipeline._id,
          "username": newPipeline.username,
          "mitarbeiterId": newPipeline.mitarbeiterId,
          "vorname": newPipeline.vorname,
          "nachname": newPipeline.nachname,
          "geburtsdatum": newPipeline.geburtsdatum,
          "gruppen": "",
          "rechte": "",
          "createdAt": newPipeline.createdAt
        }
    ]);    */

    setActiveTab(2);
  }

/*
  const savePipeline = () => {

    //console.log("kunde vor speichern")

    if (!pipeline.name) {
      Swal.fire({
        icon: 'error',
        title: "Speichern",
        html: "Gib einen Pipeline-Namen ein und versuche es erneut."
      });
      return;
    }

    // Diese Werte dürfen nur vom Backend ausgelesen werden
    //delete aufgabe['appzugriffCb'];
    //delete aufgabe['maklervollmachtCb'];
    //delete aufgabe['tippgeberCb'];

    AuthAxios.post('/pipeline', pipeline)
      .then(res => {
        //console.log(res.data);
        setPipeline({
          ...pipeline,
          _id: res.data._id //Damit nicht erneut ein Task mit selbem Inhalt erstellt wird!
        });

        if (!pipeline._id) {
          // Update DataGrid mit neuem Task   
          setPipelineListe([
            {
              "_id": res.data._id,
              "name": res.data.kunde,
              "schritte": res.data.schritte,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...pipelineListe
          ]);
        }
        else {
          // Update DataGrid mit neuem Task (nur Task Update) 
          // alte Id kurz entfernen
          // updated obj hinzufügen
          // rest der daten hinzufügen 
          setPipelineListe([
          {
              "_id": res.data._id,
              "name": res.data.taskId,
              "schritte": res.data.kunde,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...pipelineListe.filter(x => x._id !== pipeline._id),
          ]);
        }
        setAlertSuccess("Pipeline erfolgreich gespeichert.");
      })
      .catch(err => {
        setAlertError("Pipeline konnte nicht gespeichert werden.");
      });

    setChanged(false);

  }
  */

  const handleEditPipeline = (editedPipeline) => {

    // id ist hier einmal mit _id und im edit modal mit id. Daher manuelles Mapping hier.
    setPipelineListe([
      {
        _id: editedPipeline.id,
        name: editedPipeline.name,
        items: editedPipeline.items
      },
      ...pipelineListe.filter(x => x._id !== editedPipeline.id),
    ]);
  }





  return (
    <div style={{ marginLeft: "0px", marginTop: "30px", width: "100%" }}>

      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="mitarbeiter"
          id="fill-tab-example"
          fill
          style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
        >
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Pipelines">
            <PipelineWork data={pipelineListe} loading={false} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="2" title="Neue Pipeline">
            <NeuePipeline data={pipelineListe} handleEditPipeline={handleEditPipeline} loading={false} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
        </Tabs>
      </div>  
    </div>
  );
}

export default Pipeline



