import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import ExklusivmaklerListeAktiv from "./ExklusivmaklerListeAktiv";
import ExklusivmaklerListeInaktiv from "./ExklusivmaklerListeInaktiv";



function ExklusivmaklerListe(props) {


  return (
    <>
      <Row className="pb-4">
        <h5 style={{paddingLeft: "30px"}}>Aktive Exklusivmakler</h5>
        <ExklusivmaklerListeAktiv data={props} />
      </Row>
      
      <Row>
        <h5 style={{paddingLeft: "30px"}}>Inaktive Exklusivmakler</h5>
        <ExklusivmaklerListeInaktiv data={props} />
      </Row>
    </>
  );

  }
  

export default ExklusivmaklerListe