import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";


import { deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";

function Aufgabe(props) {

  const navigate = useNavigate();

  const [aufgaben, setAufgaben] = useState([]);
  const [loading, setLoading] = useState(true);


  async function getAufgaben() {
    AuthAxios.get("/task/kunde/"+props.data.kdnr)
      .then((res) => {
        setAufgaben(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Aufgaben für Kunde "+props.data.kdnr+" konnten nicht geladen werden.",
        });
    
      });
  }

  //Aufgaben für Kunden nur laden wenn der Tab geladen wird
  useEffect(() => {
    if (props.activeTab === 6) {
      getAufgaben();
    }
  }, [props.activeTab]);



  const handleNavigation = (e) => {
    navigate("/dash/aufgabe/" + e.id);
  };




  const columns = [
    {
      field: "taskId",
      headerName: "Nr.",
      width: 60,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "art",
      headerName: "Art",
      minWidth: 180,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "betreff",
      headerName: "Betreff",
      minWidth: 280,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    /*
      {
        field: "verantwortung",
        headerName: "Verantw.",
        width: 120,
        headerClassName: "super-app-theme--header",
      },
    */
    {
      field: "mitarbeiter",
      headerName: "Bearbeiter",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row.mitarbeiter !== undefined
          ? params.row.mitarbeiter.label
          : "Backoffice",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deadline",
      headerName: "Deadline",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-DE", {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "prio",
      headerName: "Prio.",
      minWidth: 80,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.updatedAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },

    /*
    {
      field: "webseite",
      headerName: "Webseite",
      width: 155,
      valueGetter: (params) => (
        <Link href={`${params.row.webseite}`}>{params.row.webseite}</Link>
      ),
      headerClassName: "super-app-theme--header",
    }*/
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

        </Row>

      </GridToolbarContainer>
    );
  }



  return (
      <div style={{width: "100%" }}>
        {/*<div style={{width: "100%" }}>*/}
      
      {props.activeTab === 6 && 
        <>
          <Box
            sx={{
              height: `calc(100vh - 200px)`,
              width: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#c58164',
              },
              '& .MuiDataGrid-toolbarContainer': {
                padding: '10px'
              },
              margin: "0px 0px 0px 0px",
              fontWeight: 'bold',
              boxShadow: 0,
            }}
          >
            <StripedDataGrid
              loading={loading}
              rows={aufgaben}
              columns={columns}
              getRowId={(row) => row._id}
              onRowClick={handleNavigation}
              //pageSize={30}
              //rowsPerPageOptions={[30]}
              autoPageSize
              density={"compact"}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'kdnr', sort: 'desc' }],
                },
              }}
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.dark',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                bgcolor: 'background.paper'
              }}
            />
          </Box>

        </>
      }

    </div>
      
  );
}

export default Aufgabe;
