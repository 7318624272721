import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom'


function ClearingAnalyse() {

  const [kunden, setKunden] = useState([]);
  const [partner, setPartner] = useState([]);

  useEffect(() => {

    setKunden([{
      _id: "asfasf",
      kdnr: "108112",
      vorname: "Max",
      nachname: "Muster",
      anzahl: 5
    },{
      _id: "asfasf1",
      kdnr: "108111",
      vorname: "Marta",
      nachname: "Test",
      anzahl: 2
    }]);

    
    setPartner([{
      _id: "asfasf",
      partner_name: "Testfirma",
      anzahl: 5
    },{
      _id: "asfasf1",
      partner_name: "Testfirma 1",
      anzahl: 2
    }]);

  }, []);
  

  return (
    <>
      <Row className="pb-4 m-2">
        <p>Einbau in welchem Zeitraum es wie viele Beschwerden gab...</p>

        <p>Welcher Kunde hat sich wie oft beschwert?</p>
        {kunden && kunden.map(k => 
          <Col key={k._id} xs={12}>
              <li>{k.anzahl}x - <Link to={"/dash/kunde/"+k.kdnr} style={{color: "#c58164"}}>{k.vorname + " " + k.nachname}</Link></li>
          </Col>
        )}

          <br /><br />
        <p>Welcher Partner hat die meisten beschwerden?</p>
        {partner && partner.map(p => 
          <Col key={p._id} xs={12}>
              <li>{p.anzahl}x - <Link to={"/dash/partner/"+p.partner_name} style={{color: "#c58164"}}>{p.partner_name}</Link></li>
          </Col>
        )}
      </Row>
    </>
  );

  }
  

export default ClearingAnalyse