import React, { useMemo, useState, useEffect, useRef } from 'react'
import { GoogleMap, MarkerF, InfoWindowF, useJsApiLoader } from '@react-google-maps/api';

import { Row, Col }  from "react-bootstrap";
/*
import Parked from "../assets/images/parked.png";
import Incident from "../assets/images/IncidentsDrones.png";
import InAir from "../assets/images/IntheAirDrones.png";
import Mission from "../assets/images/missionmarker.png";
import Offline from "../assets/images/offlinedrones.png";
*/


function GoogleMapsKarte(props) {

  const { isLoaded, loadError } = useJsApiLoader({                
    //googleMapsApiKey: "AIzaSyBnWzbqA5QTiHA5orRSsVJkOO0j8VHvSoU" // Schlüssel auas marius.schulte1@gmx.de 
    googleMapsApiKey: "AIzaSyBq_sSUm7eHIavy2IRO3VJlDHyLOG6agIE" // Schlüssel auas marius.schulte1@gmx.de 
     // Schlüssel aus it@loka1.eu =>  funktioniert nicht mehr? Rechnungskonto?
    // ...otherOptions
  })

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const [zoom, setZoom] = useState(14);  
  const [center, setCenter] = useState();
  
  const [rerenderMap, setRerenderMap] = useState(0);

  useEffect(() => {
    setZoom(props.zoom);
    setCenter(props.center);
  }, [props])



  //Referenz zur Map+Circle und dann Änderungen durchführen.
  //https://developers.google.com/maps/documentation/javascript/reference/polygon?hl=de#Circle.getBounds
  const [ map, setMap ] = useState(null);

  return (
    <>
        {!isLoaded && (
        <div>
            <p>Lade Karte ...</p>
        </div>
        )}


        {isLoaded && (
            <GoogleMap
                key={rerenderMap}
                zoom={zoom}
                center={center}
                mapContainerStyle={containerStyle}
                onLoad={(map) => setMap(map)}
            >
                {props.markerPartner &&
                    <MarkerF
                        position={props.markerPartner}
                    />
                }
            </GoogleMap>
        )}

    </>
  );
}

export default GoogleMapsKarte