import { useState, useEffect, useContext, useRef,  Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Alert, Container, Row, Col, Button } from 'react-bootstrap';

import Stammdaten from './Stammdaten';
import Dateien from './Dateien';
import InterneDateiablage from './InterneDateiablage';
import Faktura from './Faktura';
import Aktivität from './Aktivität';
import Aufgabe from './Aufgabe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faXmark, faTrash } from "@fortawesome/free-solid-svg-icons";

import Kundenstatus from './Kundenstatus';
import NeuenKundenAnlegen from './NeuenKundenAnlegen';


import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';
import DateiListe from './DateiListe';
import { MainContext } from '../../contexts/MainContext';

function Kunde() {

  const { user } = useContext(MainContext);
  let { id } = useParams();
  const navigate = useNavigate();

  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const [activeTab, setActiveTab] = useState(1);

  // Globaler State für Kunde mit allen Daten
  const [kunde, setKunde] = useState({
    anrede: "Herr",
    kontaktart: "privatkunde",
    land: "Deutschland"
  });

  // Letzte Aktivitäten des Kunden
  const [letzteAktivität, setLetzteAktivität] = useState([]);

  // Tracking ob es Änderungen am Kunde gab
  const [changed, setChanged] = useState(false)
  const [reload, setReload] = useState(false)


  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")


  const [notizen, setNotizen] = useState([]);
 
 
  /*
  const [notizen, setNotizen] = useState([
    {
      id: "asfas54fasf65asf",
      notiz: "Die ist die erste Notiz",
      mitarbeiter: "Vitali F",
      createdAt: new Date('2023-01-17T19:37:57').toISOString(),
      pinned: false,
    },
    {
      id: "gfhfghgfh6547fgh",
      notiz: "Lorem ipsum du da lies das nicht - haha",
      mitarbeiter: "Leta Dinovic",
      createdAt: new Date('2023-01-16T13:08:05').toISOString(),
      pinned: false,
    },
    {
      id: "dsfdsf",
      notiz: "Eine Weitere Nachricht...",
      mitarbeiter: "Marius Schulte",
      createdAt: new Date('2023-01-17T15:14:13').toISOString(),
      pinned: true,
    }
  ]);
  */





  useEffect(() => {
  
    async function getKundeByMongoID(id) {
      AuthAxios.get('/kunde/' + id)
        .then(res => {
          setKunde(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht gefunden werden."
          });
        });
    }

    async function getKundeByKdNr(id) {
      AuthAxios.get('/kunde/'+ id)
        .then(res => {
          setKunde(res.data);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined && id.length === 24) {
      getKundeByMongoID(id);
    }

    if (id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getKundeByKdNr(id);
    }

    if(reload) {
      getKundeByMongoID(id);
      setReload(false);
    }

     
    if(reload && id !== undefined && id.length === 24) {
      getKundeByMongoID(id);
      setReload(false);
    } else if (reload && id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getKundeByKdNr(id);
      setReload(false);
    }
    else if (reload && (id == undefined || id.length != 24)) {
      newCustomer();
      setReload(false);
    }
    
  }, [id, reload]);


  useEffect(() => {

    if(kunde.kdnr !== undefined) {

      AuthAxios.get('/kunde/latest-tasks/'+kunde.kdnr)
      .then(res => {
        setLetzteAktivität(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Letzte Aktivitäten konnte nicht gefunden werden."
        });
      });

      // Querverlinkungen!
      AuthAxios.get('/kunde/linked/'+kunde.kdnr)
      .then(res => {
        setKunde({
          ...kunde,
          links: res.data
        });
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Querverlinkungen konnten nicht abgerufen werden."
        });
      })


    }

  },[kunde.kdnr])



  const newCustomer = () => {
    setKunde({});
    //navigate("/dash/kunde"); // id entfernen ggfs.
  };


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setKunde({
      ...kunde,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setKunde({
      ...kunde,
      [name]: value
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setKunde({
      ...kunde,
      [name]: newVals
    });

  }

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    const value = event.target.value;
    setKunde({
      ...kunde,
      [event.target.name]: value
    });
  }

  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setKunde({
      ...kunde,
      [name]: value
    });

    
    /*
    if(name === 'umsatzsteuerid_geprueft_datum') {

      console.log("Datum....")
      setKunde({
        ...kunde,
        ["vorname"]: "Marius"
      });
      console.log(kunde)
    }

    else if(name === 'umsatzsteuerid_geprueft') {
      console.log("geprüft....")
      setKunde(prevData => ({
          ...prevData,
          ["umsatzsteuerid_geprueft"]: true
      }))
      console.log(kunde)
    }

    else if(name === 'BIC') {
      console.log("BIC....")
      setKunde(prevData => ({
          ...prevData,
          ["BIC"]: value
      }))
      console.log(kunde)
    }
    */
  }




  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setChanged(false);
        setReload(true);
      } 
    })
  }



  const saveCustomer = () => {

    if (!kunde.vorname && !kunde.nachname && !kunde.firma) {
      Swal.fire({
        icon: 'error',
        title: "Speichern",
        html: "Gib einen Vornamen, Nachnamen oder eine Firma ein und versuche es erneut."
      });
      return;
    }

    // Zur Sicherheit - Diese Werte dürfen nur vom Backend ausgelesen werden
    delete kunde['appzugriffCb'];
    delete kunde['maklervollmachtCb'];
    delete kunde['tippgeberCb'];

    AuthAxios.post('/kunde', kunde)
      .then(res => {
        setKunde({
          //...kunde,
           //_id: res.data._id, //Damit nicht erneut ein Kunde erstellt wird
          ...res.data
        });

        setAlertSuccess("Kunde erfolgreich gespeichert.");
      })
      .catch(err => {
        console.log(err);
        setAlertError("Fehler beim Speichern des Kunden.");
      });

    setChanged(false);

  }


  const handleArchiv = () => {
  
    if(id) { 
      AuthAxios.post('/kunde/archivieren', {
        kunden: [id]
      })
        .then(res => {
          Swal.fire({
            icon: 'success',
            title: "Archivierung",
            html: "Der Kunde wurde erfolgreich archiviert."
          });
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kunde konnte nicht archiviert werden."
          });
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Keine Kundenreferenz vorhanden, da Kunde nicht angelegt oder URL fehlerhaft."
        });
      }
  }



  const handleDelete = () => {

    //Löschen von Kunden in MongoDB + Google Firebase Acc!

    Swal.fire({
      icon: "info",
      title: "Kunde löschen",
      html: "Willst du den Kunden wirklich löschen?",
      showDenyButton: true,
      confirmButtonText: 'Ja, löschen.',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {

        if(id) { 
          AuthAxios.post('/kunde/delete', {
            id: id,
            googleuid: kunde.googleuid
          })
            .then(res => {
              Swal.fire({
                icon: 'success',
                title: "Löschen",
                html: "Der Kunde wurde erfolgreich gelöscht."
              });

              //navigiere zur Kundenübersicht
              navigate("/dash/kunden-server");
            })
            .catch(err => {
              console.log(err);
              Swal.fire({
                icon: 'error',
                title: "Fehler",
                html: "Kunde konnte nicht gelöscht werden."
              });
            });
    
        } else {
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Keine Kundenreferenz vorhanden, da Kunde nicht angelegt oder URL fehlerhaft."
          });
        }
      } 
    });
  

    
  }
  
  
  


  return (

    <Container fluid style={{ marginLeft: "0px", marginTop: "40px"}}>

      {/* so ists in Aufgaben hinterlegt 
      <Col xs={12} md={12} className="mainActions" style={{ justifyContent: "space-between" }}>
      */}
    <Container fluid >
      <Row className="mainActions">
        <Col xs={12} sm={2}>

          <div style={{display: "flex", alignItems: "center", minWidth: "max-content"}}>

            {/*<FontAwesomeIcon
              icon={faPlus}
              size="2x"
              className="actionIcon"
              onClick={newCustomer}
              title="Neuen Kunden anlegen"
            />*/}

            {changed && activeTab == 1 &&
              <>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={saveCustomer}
                    title="Kunde speichern"
                  />
                </span>
                <span style={{paddingLeft: "8px"}}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2x"
                    className="actionIcon"
                    onClick={cancelEdit}
                    title="Änderungen verwerfen"
                  />
                </span>
              </>
            }
            {!changed &&
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className="actionIcon"
                title="Keine Änderung erfolgt"
              />
            }
          </div>
        </Col>
        <Col xs={12} sm={5} className="meldungen">
            {alertSuccess.length > 0 &&
              <Col xs={12} md={6}>
                <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                  {alertSuccess}
                </Alert>
              </Col>
            }
            {alertError.length > 0 &&
              <Col xs={12} sm={6} md={6}>
                <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                  {alertError}
                </Alert>
              </Col>
            }
        </Col>
        
          {/* Hier muss noch bei Neukunden die "ObjectID" geprüft werden */}
          {id &&
            <Col xs={12} sm={5} md={3}> 
                <Row>
                  <Col style={{width: "100%"}}>
                      <Button
                        style={{width: "100%"}}
                        onClick={handleArchiv}
                        className="mt-2"
                      >
                        Archivieren
                      </Button>
                  </Col>
                  <Col> 
                    <Button
                      style={{width: "100%"}}
                      className="mt-2"
                      onClick={() =>
                        navigate("/dash/aufgabe", {
                          state: {
                            selectedKunde: {"value": kunde.kdnr, "label": kunde.kdnr+" | "+kunde.vorname+" "+kunde.nachname}
                          },
                        })
                      }
                    >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="lg"
                          style={{marginRight: "8px"}}
                          className="actionIcon"
                          title="Aufgabe anlegen"
                        />
                      Aufgabe
                    </Button>
                  </Col>

                  {user.mitarbeiterId == 1 &&
                    <Col> 
                      <Button 
                        
                        style={{width: "100%"}}
                        className="mt-2 btn-danger w-auto"
                        onClick={handleDelete}
                      >
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            style={{marginRight: "8px"}}
                            className="actionIcon"
                            title="Kunde löschen"
                          />
                        Löschen
                      </Button>
                    </Col>
                  }

              </Row>
            </Col>
          }

      </Row>
    </Container>

      <div style={{ marginLeft: "0px", marginTop: "0px", marginBottom: "0px", width: "100%" }}>
        <div className="mainWork">
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(parseInt(key))}
            defaultActiveKey="stammdaten"
            id="fill-tab-example"
            fill
            style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
          >

            {!kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Info">
                <h6 className="p-4">
                  Wähle einen Kunde aus der Gesamtliste (anderer Menüpunkt) aus oder registriere einen neuen Kunden über das My-VIN1 Registrierungsformular.
                </h6>

                <div 
                  className="p-3 grid lg:grid-cols-4 sm:grid-cols-2 gap-2"
                >
                  <a href={"https://my.vin1.eu/register?mitarbeiter="+user.mitarbeiterId}
                    target="_blank"
                    className="btn btn-primary"
                  >
                    Neuen Kunden registrieren
                    (my.vin1.eu)
                  </a>

                  <button
                    onClick={()=>setShowRegisterModal(true)}
                    className="btn btn-primary"
                  >
                    Kunde vorregistrieren
                  </button>
                </div>


              </Tab>
            }


            {kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Stammdaten">
                <Stammdaten data={kunde} letzteAktivität={letzteAktivität} setKunde={handleInputChange} setKundeManually={handleInputChangeManually} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setChanged={setChanged} />
              </Tab>
            }

            {/*kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="2" title="Faktura">
                <Faktura data={kunde} activeTab={activeTab} />
              </Tab>
          */}
            

            {kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="3" title="Dateien">
                <Dateien data={kunde} activeTab={activeTab} />
              </Tab>
            }

            {kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="4" title="Int. Dateien">
                <InterneDateiablage data={kunde} setKunde={setKunde} activeTab={activeTab} />
              </Tab>
            }

            
            {kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="5" title="Datei Liste">
                <DateiListe data={kunde} activeTab={activeTab} />
              </Tab>
            }


           

            {kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="6" title="Aufgaben">
                <Aufgabe data={kunde} setKunde={setKunde} activeTab={activeTab} />
              </Tab>
            }

            {kunde._id &&
              <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="7" title="Aktivität">
                <Aktivität data={kunde} setKunde={setKunde} activeTab={activeTab} />
              </Tab>
            }

          </Tabs>
        </div>
      </div>


      <NeuenKundenAnlegen
        showRegisterModal={showRegisterModal}
        setShowRegisterModal={setShowRegisterModal}
      />
      
      


    </Container>

  );
}

export default Kunde;