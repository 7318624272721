import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';

import { deDE, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from '@mui/material/Box';

import {Row, Col} from 'react-bootstrap'



function PartnerListeAktiv(props) {

  const navigate = useNavigate();

  const [aktivePartner, setAktivePartner] = useState([]);

  useEffect(() => {
    setAktivePartner(props.data.data.filter((f) => f.loginStatus !== false))
  }, [props])

  const handleNavigation = (e) => {
    props.data.setActiveTab(1);
    navigate("/dash/partner/" + e.id);
  }



  const columns = [
    {
      field: "partner_name",
      headerName: "Name/Firma",
      minWidth: 220,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lizenzen",
      headerName: "Lizenzen",
      minWidth:100,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lizenzLaufzeit",
      headerName: "Ablauf Lizenz",
      minWidth:100,
      flex: 1,
      renderCell: (params) => (
        <>
        {(params.row.lizenzLaufzeit && params.row.lizenzLaufzeit != "0000-00-00" ) && <span>{new Date(params.row.lizenzLaufzeit).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
        {(!params.row.lizenzLaufzeit || params.row.lizenzLaufzeit == "0000-00-00") && <span>-</span>}
      </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "anschrift",
      headerName: "Anschrift",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "plz",
      headerName: "PLZ",
      minWidth: 110,
      flex: 1,
      /*renderCell: (params) => (
        <>
          {(params.row.geburtsdatum && params.row.geburtsdatum != "0000-00-00" ) && <span>{new Date(params.row.geburtsdatum).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}).substring(0,10)}</span>}
          {(!params.row.geburtsdatum || params.row.geburtsdatum == "0000-00-00") && <span>-</span>}
        </>
      ),*/
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stadt",
      headerName: "Stadt",
      minWidth: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>
      </GridToolbarContainer>
    );
  }



  return (


    <div style={{ height: `90%`, width: "100%", marginBottom: "30px" }}>
      {props.data.activeTab === 2 && 

        <Box
          sx={{
            height: `calc(100% - 35px)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "35px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={aktivePartner}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            pageSize={20}
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            //components={{ Toolbar: GridToolbar }}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'partnerId', sort: 'asc' }],
              },
            }}
            sx={{/*
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
              */
            }}
          />

        </Box>
      }

    </div>

  );
}


export default PartnerListeAktiv