import React, { useState } from 'react'

const MainContext = React.createContext()
const MainContextProvider = (props) => {
  const localJWT = sessionStorage.getItem('token') || '';
  const employee = sessionStorage.getItem('employee') || '';
  const access = sessionStorage.getItem('access') || '';

  // Daten müssen aus JWT generiert werden, sonst nicht sicher, da manipulierbar.

  const [jwt, setJwt] = useState(localJWT);
  const [user, setUser] = useState({});
  const [rechte, setRechte] = useState(access);

  return (
    <MainContext.Provider value={{ jwt, setJwt, user, setUser, rechte, setRechte }}>
      {props.children}
    </MainContext.Provider>
  )
}

export { MainContextProvider, MainContext }
