import React, { useEffect, useState, useRef } from 'react'

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faImage, faVideo, faFileLines, faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { COLORS } from '../../constants';

import style from './TemplateAnlegen.module.css'
import CardSub from '../Kunden/DateienBausteine/CardSub';
import SelectWhatsappLaender from '../../Bausteine/SelectWhatsappLaender';
import WAProfilePreview from './WAProfilePreview'
import { Scrollbar } from 'react-scrollbars-custom';
import DropdownListKVP from '../../Bausteine/DropdownListKVP';

import StyledProfilePicture from './helpers/ProfilePicture'


function WAProfil() {

    const colorSelected = "#e1e9f5";


    const [imgURL, setImgURL] = useState("");

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
  
    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
          const allowedTypes = ['image/jpeg', 'image/png'];
          if (allowedTypes.includes(selectedFile.type)) {
            const img = new Image();
            img.src = URL.createObjectURL(selectedFile);
      
            img.onload = () => {
              //Vorgabe Whatsapp!
              const minWidth = 640;
              const minHeight = 640;
      
              if (img.width >= minWidth && img.height >= minHeight) {
                // Image meets the resolution requirements
                setImgURL(URL.createObjectURL(selectedFile));
              } else {
                // Image resolution is too small
                Swal.fire({
                    icon: 'info',
                    title: "Bildmaße",
                    html: `Das Bild muss mindestens ${minWidth}x${minHeight} Pixel haben.`
                });
              }
            };

          } else {
            // File type is not allowed
            Swal.fire({
                icon: 'info',
                title: "Dateiformat",
                html: `Nur jpeg und png Dateien erlaubt.`
            });
          }
        }
    };


    const [isLoading, setIsLoading] = useState(false);

    const [sprachen, setSprachen] = useState([
        {
            value: "de",
            label: "Deutsch"
        },
        {
            value: "en_US",
            label: "Englisch US"
        }
    ]);

    // Kategorien value = FB values, Label = deutsche Werte.
    const verticals = [
        { value: "UNDEFINED", label: "Nicht angegeben" },
        { value: "AUTO", label: "KFZ" },
        { value: "BEAUTY", label: "Kosmetik und Schönheitspflege" },
        { value: "APPAREL", label: "Bekleidung" },
        { value: "EDU", label: "Bildung" },
        { value: "ENTERTAIN", label: "Unterhaltung" },
        { value: "EVENT_PLAN", label: "Veranstaltungsplanung und -service" },
        { value: "FINANCE", label: "Finanzen und Bankwesen" },
        { value: "GROCERY", label: "Essen und Lebensmittel" },
        { value: "GOVT", label: "Öffentliche Dienstleistungen" },
        { value: "HOTEL", label: "Hotel und Unterkunft" },
        { value: "HEALTH", label: "Medizin und Gesundheit" },
        { value: "NONPROFIT", label: "Gemeninnützige Organisation" },
        { value: "PROF_SERVICES", label: "Professionelle Dienstleistungen" },
        { value: "RETAIL", label: "Einzelhandel" },
        { value: "TRAVEL", label: "Reisen und Verkehr" },
        { value: "RESTAURANT", label: "Restaurant" },
        { value: "OTHER", label: "Sonstiges" }
    ];

    const [profile, setProfile] = useState({
        messaging_product: "whatsapp", // must always be included!!
        vertical: { value: "OTHER", label: "Sonstiges" }
    });

    // Helper to display German in Profile Preview
    const [kategorie, setKategorie] = useState(verticals.filter(v => v.value === profile.vertical)[0]?.label);


    useEffect(() => {

        async function getProfile() {
            try {
                let res = await AuthAxios.get('/wa/get-profile');
                setProfile(res.data.data[0]);
            }
            catch (err) {
                Swal.fire({
                    icon: "error",
                    title: "Fehler",
                    html: "Profil konnte nich abgerufen werden."
                })
            }
        }
        getProfile();

    }, []);




    return (
        <Container fluid className="p-1">

            <Row className="mt-3">
                <Col xs={12} sm={8} md={8}>


                    <fieldset
                        className="border"
                        style={{ background: "white", borderRadius: "8px" }}
                    >
                        <legend
                            className="float-none w-auto p-2"
                            style={{
                                color: COLORS.primCol,
                                background: COLORS.primBG,
                                borderRadius: "8px",
                                fontSize: "12px",
                            }}
                        >
                            Profil Informationen
                        </legend>

                        <p>
                            <span className="fw-bold">Profilbild</span>
                            <br />
                            <span className="text-sm">Dies ist in deinem Unternehmensprofil sichtbar. (optimale Größe - 640px * 640px)</span>
                        </p>

                        {imgURL ?
                            <>
                                <StyledProfilePicture imgURL={imgURL} />
                                <div>
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png" // You can specify the file types you want to accept here
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={handleFileSelect}
                                    />
                                    <Button
                                        variant="danger"
                                        className="mb-5"
                                        onClick={handleButtonClick}
                                    >
                                        Anders Bild auswählen
                                    </Button>
                                </div>
                            </>
                            :
                            <div>
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .png" // You can specify the file types you want to accept here
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileSelect}
                                />
                                <Button
                                    variant="success"
                                    className="mb-5"
                                    onClick={handleButtonClick}
                                >
                                    Bild auswählen
                                </Button>
                            </div>
                        }


                        <p className="m-0 fw-bold">Display-Name</p>
                        <p className="pt-2">{"Hier Name"}</p>


                        <p className="m-0 fw-bold">Kategorie</p>
                        <DropdownListKVP
                            values={verticals}
                            selected={profile.vertical ?? null}
                            setSelected={(val) => {
                                setProfile({ ...profile, vertical: val });
                                setKategorie(verticals.filter(v => v.value === val)[0]?.label ?? '');
                            }}
                        />


                        <p>
                            <span className="fw-bold">Beschreibung</span>
                            <br />
                            <span className="text-sm">Erzähle deinen Kunden mehr über dein Unternehmen</span>
                        </p>

                        <div>
                            <div className="relative mt-2 mb-3 rounded">
                                <textarea
                                    rows={4}
                                    name="comment"
                                    id="comment"
                                    className="z-0 pl-2 pr-[60px] block w-full rounded-md border py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    placeholder={'Unternehmensbeschreibung'}
                                    value={profile.about ?? ''}
                                    onInput={(e) => {
                                        if (e.target.value.length <= 512) {
                                            setProfile({
                                                ...profile,
                                                about: e.target.value
                                            });
                                        }
                                    }}
                                />
                                <span className="absolute top-2 right-3 text-sm text-gray-400">
                                    {profile.about?.length ?? 0}/512
                                </span>
                            </div>
                        </div>

                        <p>
                            <span className="fw-bold">Kontaktinformationen</span>
                            <br />
                            <span className="text-sm">Füge Kontaktinformationen zu deinem Unternehmen hinzu</span>
                        </p>

                        <label htmlFor="address" className="block text-sm text-gray-900">
                            Adresse
                        </label>
                        <div className="relative mt-2">
                            <input
                                type="text"
                                name="address"
                                id="address"
                                style={{ outline: "1px solid rgba(0, 0, 0, 0.1)" }}
                                className="block w-full rounded-md pl-2 pr-16 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Adresse"
                                aria-describedby="address-description"
                                value={profile.address ?? ''}
                                onChange={(e) => {
                                    if (e.target.value.length <= 256) {
                                        setProfile({
                                            ...profile,
                                            address: e.target.value
                                        });
                                    }
                                }}
                            />
                            <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500">
                                {profile.address?.length ?? 0}/256
                            </span>
                        </div>


                        <label htmlFor="email" className="block text-sm text-gray-900 mt-3">
                            E-Mail-Adresse
                        </label>
                        <div className="relative mt-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                style={{ outline: "1px solid rgba(0, 0, 0, 0.1)" }}
                                className="block w-full rounded-md pl-2 pr-16 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="E-Mail"
                                aria-describedby="email-description"
                                value={profile.email ?? ''}
                                onChange={(e) => {
                                    if (e.target.value.length <= 128) {
                                        setProfile({
                                            ...profile,
                                            email: e.target.value
                                        });
                                    }
                                }}
                            />
                            <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500">
                                {profile.email?.length ?? 0}/128
                            </span>
                        </div>


                        <label htmlFor="website1" className="block text-sm text-gray-900 mt-3">
                            Webseite 1
                        </label>
                        <div className="relative mt-2">
                            <input
                                type="text"
                                name="website1"
                                id="website1"
                                style={{ outline: "1px solid rgba(0, 0, 0, 0.1)" }}
                                className="block w-full rounded-md pl-2 pr-16 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Webseite 1"
                                aria-describedby="webseite-description"
                                value={profile?.websites && profile.websites.length > 0 ? profile.websites[0] : ''}
                                onChange={(e) => {
                                    if (e.target.value.length <= 256) {
                                        setProfile({
                                            ...profile,
                                            websites: [e.target.value, profile.websites[1]]
                                        });
                                    }
                                }}
                            />
                            <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500">
                                {profile?.websites && profile.websites.length > 0 ? profile.websites[0].length : 0}/256
                            </span>
                        </div>


                        <label htmlFor="website2" className="block text-sm text-gray-900 mt-3">
                            Webseite 2
                        </label>
                        <div className="relative mt-2">
                            <input
                                type="text"
                                name="website2"
                                id="website2"
                                style={{ outline: "1px solid rgba(0, 0, 0, 0.1)" }}
                                className="block w-full rounded-md pl-2 pr-16 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Webseite 2"
                                aria-describedby="webseite-description"
                                value={profile?.websites && profile.websites.length > 0 ? profile.websites[1] : ''}
                                onChange={(e) => {
                                    if (e.target.value.length <= 256) {
                                        setProfile({
                                            ...profile,
                                            websites: [profile.websites[0], e.target.value]
                                        });
                                    }
                                }}
                            />
                            <span className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500">
                                {profile?.websites && profile.websites.length > 0 ? profile.websites[1].length : 0}/256
                            </span>
                        </div>






                    </fieldset>
                </Col>

                <Col xs={12} sm={12} md={3}>
                    <h6 className="mb-3 text-center">Profilansicht Vorschau</h6>
                    <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[326px]">
                        <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
                        <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                        <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                        <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>


                        <Scrollbar
                            style={{ width: "310px", height: "572px" }}
                            className="bg-[#0a131a] bg-contain rounded-3xl"
                        >
                            <div className="rounded-[2rem] w-[300px] h-[572px] dark:bg-gray-800">

                                <div
                                    className="h-100 rounded-[2rem]"
                                    style={{ padding: "0px 12px" }}
                                >
                                    <WAProfilePreview data={profile} kategorie={kategorie} />
                                </div>

                            </div>
                        </Scrollbar>

                    </div>

                </Col>


            </Row>

            <div className="pb-5 md:pb-0"></div>


        </Container>
    )
}

export default WAProfil




