import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/MainContext';

import { Tab, Tabs } from 'react-bootstrap';
import AppAnalyse from './AppAnalyse';
import GewinnspieleAnalyse from './GewinnspieleAnalyse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios'


function Analyse() {


  const { user } = useContext(MainContext);

  
  useEffect(() => {
    

    /*
    axios.get(process.env.API_URL+'/kunde', {
      'marius@goldmountains.eu': '123456789'
    })
    .then(data =>
      console.log(data)
    )
    .catch(err =>
      console.log(err)
      )
      */
  }, [])
  
  


  const [active, setActive] = useState(true);


    return (
      <div style={{marginLeft: "0px", marginTop: "0px", width: "100%"}}>

<br />
<br />

        {/*user.roleId !== 1 ?
            <h6>kein Zugriff!</h6>
            :
            <h6>Zugriff..</h6>
    */}
        

    

        {/*
          <p>{JSON.stringify(parsedData.roleId.filter(role => role !== 'Admin'))}</p>

          {parsedData.roleId.filter(role => role !== 'Admin') ?
            <h3>kein Zugriff!</h3>
            :
            <h3>Zugriff..</h3>
            }
          */}

        {active && (
          <div className="mainWork noActionArea">
            
            <Tabs
              defaultActiveKey="app"
              id="fill-tab-example"
              fill
              style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
            >
              <Tab style={{background: "white", position: "relative", padding: "15px"}} eventKey="app" title="App">
                <AppAnalyse />
              </Tab>
              <Tab style={{background: "white", position: "relative", padding: "15px"}} eventKey="gewinnspiele" title="Gewinnspiele">
                <GewinnspieleAnalyse />
              </Tab>
            </Tabs>
            
          </div>
          
        )}
      </div>
    );
  }
  
  export default Analyse;