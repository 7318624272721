import React, {useEffect, useState} from "react";
import { COLORS } from '../constants';
import { Table } from 'react-bootstrap';

import AuthAxios from '../utils/AuthAxios';
import Swal from "sweetalert2";


function UebertrageneBestaende(props) {

    const [gesellschaften, setGesellschaften] = useState([]);

    useEffect(() => {

        AuthAxios.get("/bestaende/gesellschaften/"+props.data._id)
        .then(res => {
            setGesellschaften(res.data);
        })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Übermittelte Gesellschaften konnten nicht abgerufen werden."
            })
        })
        

    }, []);
    
  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Übermittelte Versicherungsgesellschaften
      </legend>
      <div key="inline-checkbox" className="mb-3">

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Gesellschaft</th>
            <th>Übermittelt</th>
          </tr>
        </thead>
        <tbody>
        
        {gesellschaften.map((gesellschaft) => 
          <tr key={gesellschaft.versicherung+gesellschaft.createdAt}>
              <td>
              {gesellschaft.versicherung}
            </td>
            <td>{gesellschaft.createdAt &&
              new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              }).format(new Date(gesellschaft.createdAt))}
            </td>
          </tr>
          )}
          {gesellschaften.length == 0 &&
            <tr>
              <td colSpan="2" className="text-center">
                Noch keine Gesellschaft übermittelt.
            </td>
          </tr>
          }
        </tbody>
      </Table>
       
      </div>
    </fieldset>
  );
}

export default UebertrageneBestaende