import React, { Fragment, useEffect, useState, useRef } from 'react';
import { FaEllipsisH, FaEdit, FaSistrix } from "react-icons/fa";
import ActiveFriend from './ActiveFriend';
import Friends from './Friends';
import RightSide from './RightSide';
import { useDispatch, useSelector } from 'react-redux';
import { getFriends, messageSend, getMessage, ImageMessageSend, seenMessage, updateMessage, getTheme, themeSet } from '../../../store/actions/messengerAction';


import toast,{Toaster} from 'react-hot-toast';
import {io} from 'socket.io-client';
import useSound from 'use-sound';
import notificationSound from '../audio/notification.mp3';
import sendingSound from '../audio/sending.mp3';
import Message from './Message';

const Messenger = () => {

     const [notificationSPlay] = useSound(notificationSound);   
     const [sendingSPlay] = useSound(sendingSound);  

     const scrollRef = useRef();
     const socket = useRef();

     // auskommentiert nur zum Test!! wieder richtig machen
     //const {friends,message,mesageSendSuccess,message_get_success,themeMood,new_user_add} = useSelector(state => state.messenger );
     
     const {mesageSendSuccess,message_get_success,themeMood,new_user_add} = useSelector(state => state.messenger );
     
     // auskommentiert nur zum Test!! wieder richtig machen - aber login gibt es hier im chat ja nicht, daher selbst bauen.
     //const {myInfo} = useSelector(state => state.auth);

     const [currentfriend, setCurrentFriend] = useState('');
     const [newMessage, setNewMessage] = useState('');

     const [activeUser, setActiveUser] = useState([]);
     const [socketMessage, setSocketMessage] = useState('');
     const [typingMessage, setTypingMessage] = useState('');


     //Testing with static data!

     const myInfo = {
          id: "a65fasd4f78ds",
          userName: "VIN1",
          image: "https://my.vin1.eu/logo192.png"
     };


     useEffect(() => {
          setCurrentFriend({
               _id: "asfjkaskhfsgg",
               image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D",
               userName: "Maximilian"
          });
     },[])

     // Wenn senderId = myInfo.id = Message von VIN1
     const message = [
       {
          _id: "1",
          status: "seen",
          senderId: "asfasfs54fasf",
          message: {
               text: "Hello world"
          }
       },
       {
          _id: "2",
          status: "seen",
          senderId: "asfasfs54fasf",
          message: {
               text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."  
          }
       },
       {
          _id: "3",
          status: "delivered",
          senderId: "a65fasd4f78ds",
          message: {
               text: "Hallo von VIN1 :)"
          }
       },
       {
          _id: "4",
          status: "delivered",
          senderId: "a65fasd4f78ds",
          message: {
               text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
          }
       },
       {
          _id: "5",
          status: "seen",
          senderId: "asfasfs54fasf",
          message: {
               text: "Hello again von Friend!"
          }
       },
     ];


     // Was ist wenn nur Bild gesendet wird etc?
     // => "Hat ein Bild gesendet"

     const friends = [{
          fndInfo: {
               _id: "friend1",
               image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpIoI76wjvQ2pq6t25TLmRAwTxv118OFKZxykoCiY_fWRtR8QRr1nWWcDtG3tHdgCuWu8&usqp=CAU",
               userName: "+491514578424",
               createdAt: 1674866497,
          },
          msgInfo: {
               status: "seen",
               senderId: "a65fasd4f78ds",
               message: {
                    text: "Heute ist ein schöner Tag lalalalala. Und wie geht es dir?"
               },
               createdAt: 1674866497
          }
     },{
          fndInfo: {
               _id: "friend2",
               image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIg56NMQftJsBVsWu-IBEoK2KeFo-U3G4I0abyVRx_owrgVqcawml4F8bY4hjKpmIFKc0&usqp=CAU",
               userName: "+49174568478",
               createdAt: 1706406708
          },
          msgInfo: {
               status: "seen",
               senderId: "asf4a5dsg56g",
               message: {
                    text: "Heute ist ein schöner Tag lalalalala. Und wie geht es dir?"
               },
               createdAt: 1706406708
          }
     }]


     
/*
     useEffect(() => {
          socket.current = io('ws://localhost:8000');
          socket.current.on('getMessage',(data) => {
               setSocketMessage(data);
          })

          socket.current.on('typingMessageGet',(data) => {
               setTypingMessage(data);
          })

          socket.current.on('msgSeenResponse', msg => {
               dispatch({
                    type : 'SEEN_MESSAGE',
                    payload : {
                         msgInfo : msg
                    }
               })
          })

          socket.current.on('msgDeliveredResponse', msg => {
               dispatch({
                    type : 'DELIVERED_MESSAGE',
                    payload : {
                         msgInfo : msg
                    }
               })
          })

          socket.current.on('seenSuccess', data => {
               dispatch({
                    type : 'SEEN_ALL',
                    payload : data
               })
          })

     },[]);
     */

/*
     useEffect(() => {
          if(socketMessage && currentfriend){
               if(socketMessage.senderId === currentfriend._id && socketMessage.reseverId === myInfo.id){
                    dispatch({
                         type: 'SOCKET_MESSAGE',
                         payload : {
                              message: socketMessage
                         }
                    })
                    dispatch(seenMessage(socketMessage));
                    socket.current.emit('messageSeen',socketMessage);
                    dispatch({
                         type: 'UPDATE_FRIEND_MESSAGE',
                         payload : {
                              msgInfo : socketMessage,
                              status : 'seen'
                         }
                    })
               }
          }
          setSocketMessage('')
     },[socketMessage]);

     */


     /*
     useEffect(() => {
          socket.current.emit('addUser', myInfo.id, myInfo)
     },[]);

     useEffect(() => {
          socket.current.on('getUser', (users)=>{
               const filterUser = users.filter(u => u.userId !== myInfo.id)
               setActiveUser(filterUser);
          })

          socket.current.on('new_user_add',data => {
               dispatch({
                    type : 'NEW_USER_ADD',
                    payload : {
                         new_user_add : data
                    }
               })
          })
     },[]);

     useEffect(() => {
          if(socketMessage && socketMessage.senderId !== currentfriend._id && socketMessage.reseverId === myInfo.id){
               notificationSPlay();
               toast.success(`${socketMessage.senderName} Send a New Message`)
               dispatch(updateMessage(socketMessage));
               socket.current.emit('deliveredMessage',socketMessage);
               dispatch({
               type: 'UPDATE_FRIEND_MESSAGE',
               payload : {
                    msgInfo : socketMessage,
                    status : 'delivered'
               }
          })

          }
     },[socketMessage]);
     */


 

     const inputHandle = (e) => {
          setNewMessage(e.target.value);

          /*
          socket.current.emit('typingMessage',{
               senderId : myInfo.id,
               reseverId : currentfriend._id,
               msg : e.target.value
          })
          */

     }
 
     const sendMessage = (e) => {
          e.preventDefault();
          sendingSPlay();
          const data = {
               senderName : myInfo.userName,
               reseverId : currentfriend._id,
               message : newMessage ? newMessage : '❤'
          }

          
          socket.current.emit('typingMessage',{
               senderId : myInfo.id,
               reseverId : currentfriend._id,
               msg : ''
          })

          dispatch(messageSend(data));
          setNewMessage('')
     }


     /*
     useEffect(() => {
          if(mesageSendSuccess){
               socket.current.emit('sendMessage', message[message.length -1 ]);
               dispatch({
                    type: 'UPDATE_FRIEND_MESSAGE',
                    payload : {
                         msgInfo : message[message.length -1]
                    }
               })
               dispatch({
                    type: 'MESSAGE_SEND_SUCCESS_CLEAR'
               })
          }
     },[mesageSendSuccess]);
     */



  



     const dispatch = useDispatch();

     /*
     useEffect(() => {
          dispatch(getFriends());
          dispatch({type:'NEW_USER_ADD_CLEAR'})
     },[new_user_add]);
     

     useEffect(() => {

          if(friends && friends.length > 0) {
               setCurrentFriend(friends[0].fndInfo)
          }
     },[friends]);
     */

     useEffect(() => {
          dispatch(getMessage(currentfriend._id));
          if(friends.length > 0){
              
          }
     },[currentfriend?._id]);



     /*
     useEffect(() => {
          if(message?.length > 0){
               if(message[message.length -1].senderId !== myInfo.id && message[message.length -1].status !== 'seen'){
                    dispatch({
                         type: 'UPDATE',
                         payload : {
                              id : currentfriend._id
                         }
                    })
                    socket.current.emit('seen', { senderId: currentfriend._id, reseverId: myInfo.id })
                    dispatch(seenMessage({ _id: message[message.length -1]._id }))
               }
          }
          dispatch ({
               type: 'MESSAGE_GET_SUCCESS_CLEAR'
          })
           
     },[message_get_success]);
     */


 
      useEffect(() => {
          scrollRef.current?.scrollIntoView({behavior: 'smooth'}) 
      },[message]);
 

     const emojiSend = (emu) => {
          setNewMessage(`${newMessage}`+  emu);
          socket.current.emit('typingMessage', {
               senderId : myInfo.id,
               reseverId : currentfriend._id,
               msg : emu
          })
     }

     const ImageSend = (e) => {

          if(e.target.files.length !== 0){
               sendingSPlay();
               const imagename = e.target.files[0].name;
               const newImageName = Date.now() + imagename;

               socket.current.emit('sendMessage', {
                    senderId: myInfo.id,
                    senderName: myInfo.userName,
                    reseverId: currentfriend._id,
                    time: new Date(),
                    message : {
                         text : '',
                         image : newImageName
                    }
               })

               const formData = new FormData();

               formData.append('senderName',myInfo.userName);
               formData.append('imageName',newImageName);
               formData.append('reseverId',currentfriend._id);
               formData.append('image', e.target.files[0]);
               dispatch(ImageMessageSend(formData));
                
          } 
         
     }

     const [hide, setHide] = useState(true);



     useEffect(() => {
         dispatch(getTheme());
     },[]);


     const search = (e) => {

          const getFriendClass = document.getElementsByClassName('hover-friend');
          const frienNameClass = document.getElementsByClassName('Fd_name');
          for (var i = 0; i < getFriendClass.length, i < frienNameClass.length; i++) {
               let text = frienNameClass[i].innerText.toLowerCase();
               if (text.indexOf(e.target.value.toLowerCase()) > -1) {
                    getFriendClass[i].style.display = '';
               } else {
                    getFriendClass[i].style.display = 'none';
               }
          }
     }


  return (
     <div className={themeMood === 'dark' ? 'messenger theme' : 'messenger' }>
          <Toaster
               position={'top-right'}
               reverseOrder = {false}
               toastOptions={{
                    style : {
                         fontSize : '18px'
                    }
               }}
            
          />


          <div className='row'>
               <div className='col-3'>
                    <div className='left-side'>
                         <div className='top'>
                              <div className='image-name'>
                                   <div className='image'>
                                        <img src={myInfo.image} alt='' />
                                   </div>
                                   <div className='name'>
                                        <h3>{myInfo.userName} </h3>
                                   </div>
                              </div>

                              <div className='icons'>
                                   <div onClick={()=> setHide(!hide) }  className='icon'>
                                        <FaEllipsisH />
                                   </div>
                                   <div className='icon'>
                                        <FaEdit/> 
                                   </div>

                                   <div className={hide ? 'theme_logout' : 'theme_logout show'}>
                                        <h3>Dark Mode </h3>
                                   <div className='on'>
                                        <label htmlFor='dark'>ON</label>
                                        <input onChange={(e) => dispatch(themeSet(e.target.value)) } type="radio" value="dark" name="theme" id="dark" />
                                        </div>

                                        <div className='of'>
                                        <label htmlFor='white'>OFF</label>
                                        <input onChange={(e) => dispatch(themeSet(e.target.value)) } type="radio" value="white" name="theme" id="white" />
                                        </div>



                                   </div>


                              </div>
                         </div>

                         <div className='friend-search'>
                              <div className='search'>
                                   <button>
                                        <FaSistrix />
                                   </button>
                                   <input onChange={search}
                                        type="text"
                                        placeholder='Suche'
                                        className='form-control'
                                   />
                              </div>
                         </div>

                         {/* <div className='active-friends'>
                              {activeUser && activeUser.length > 0 ? activeUser.map(u =>  <ActiveFriend setCurrentFriend = {setCurrentFriend} user={u} />) : ''  
                         }
                         
                         </div> */}

                         <div className='friends'>

                              {friends && friends.length > 0 ?
                                   friends.map((fd) =>
                                        <Fragment key={fd._id}>
                                             <div
                                                  onClick={()=> setCurrentFriend(fd.fndInfo)}
                                                  className={currentfriend._id === fd.fndInfo._id ? 'hover-friend active' : 'hover-friend' }
                                             > 
                                                  <Friends
                                                       activeUser={activeUser}
                                                       myId={myInfo.id}
                                                       friend={fd}
                                                  />
                                             </div>
                                             <hr
                                                  style={{
                                                       margin: 0,
                                                       border: 0,
                                                       opacity: .1,
                                                       borderTop: "1px solid black" 
                                                  }}
                                             />
                                        </Fragment>
                                   )
                                   :
                                   'Kein Kontakt vorhanden'
                              } 
                         </div>

                    </div>
                      
               </div>

               {/*currentfriend ?
                    <RightSide 
                         currentfriend={currentfriend}
                         inputHendle={inputHandle}
                         newMessage={newMessage}
                         sendMessage={sendMessage}
                         message={message}
                         scrollRef= {scrollRef}
                         emojiSend = {emojiSend}
                         ImageSend= {ImageSend}
                         activeUser = {activeUser}
                         typingMessage = {typingMessage}
                    />
                    :
                    'Wähle einen Kontakt aus der Liste aus.'
               */}

               <RightSide 
                    currentfriend={currentfriend}
                    inputHendle={inputHandle}
                    newMessage={newMessage}
                    sendMessage={sendMessage}
                    message={message}
                    scrollRef= {scrollRef}
                    emojiSend = {emojiSend}
                    ImageSend= {ImageSend}
                    activeUser = {activeUser}
                    typingMessage = {typingMessage}
               />
                
          </div>

     </div>
  )
};

export default Messenger;
