import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Col, Row, Form } from 'react-bootstrap'


function TrackingCodesTabelle (props) {

    return (
        <table>
            <thead>
            <tr>
                <th style={{paddingRight: "20px"}}>Trackingcode</th>
                <th style={{paddingRight: "20px"}}>Beschreibung</th>
                <th style={{paddingRight: "20px"}}>Bearbeiter</th>
                {/*<th>Aktion</th>*/}
            </tr>
            </thead>
            <tbody>
                {props.data.map(d => 
                    <tr key={d.trackingcode}>
                        <td style={{paddingRight: "20px"}}>
                            {d.trackingcode}
                        </td>
                        <td style={{paddingRight: "40px"}}>
                            {d.beschreibung}
                        </td>
                        <td style={{paddingRight: "40px"}}>
                            {d.mitarbeiter ?? "None"}
                        </td>
                        
                    </tr>
                )}
            </tbody>
        </table>
    );
      
}

export default TrackingCodesTabelle