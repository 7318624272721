import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { COLORS } from '../constants'
import AuthAxios from "../utils/AuthAxios";


function InputKundenstatus(props) {

  const [allePipelines, setAllePipelines] = useState([]);

  useEffect(() => {

    AuthAxios.get("/pipeline")
    .then(res => {
      setAllePipelines(res.data);
    })
    .catch(err => {
      console.log(err)
    })
  
  }, [])
  
  
  return (
    <fieldset
      className="border"
      style={{ background: "white", borderRadius: "8px" }}
    >
      <legend
        className="float-none w-auto p-2"
        style={{
          color: COLORS.primCol,
          background: COLORS.primBG,
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        Pipelinestatus
      </legend>

      <Row>
        <p>Wird noch implementiert.</p>

        {/*!props.data.pipelines &&
          <i>Der Kunde befindet sich momentan in keiner Pipeline.</i>
      */}


        {/*props.data.pipelines?.map(pipeline => 

          <Col xs={12} md={2} key={pipeline.name}>
            <Form.Label>{pipeline.name}</Form.Label>
            <Form.Select
              aria-label="Default select"
              name={pipeline.name}
              value={pipeline.step}
              className="readOnly"
              onChange={()=>{}}
            >
              
            {allePipelines.find(pip => pip.name === pipeline.name).items.map(item => 
              <option key={item} value={item}>{item}</option>
            )}
              
            </Form.Select>
            <Button className="mt-2" onClick={() => {alert("notizen anzeigen die gemacht wurden.")}}>
              Notizen
            </Button>
           
          </Col>
       )*/}
      
        {/*
        <Col xs={12} md={3}>
          <Form.Label>Kontakt</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="kontakt"
            value={"erstkontakt"} //props.data.kontakt}
            className="readOnly"
            onChange={()=>{}}
          >
            <option value="nicht_kontaktiert">nicht Kontaktiert</option>
            <option value="erstkontakt">Erstkontakt</option>
          </Form.Select>
        </Col>


        <Col xs={12} md={3}>
          <Form.Label>Schaden</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="schaden"
            value={props.data.schaden ? props.data.schaden : "" }
            onChange={(e) => props.setKunde(e)}
          >
            <option>-</option>
            <option value="schaden_in_bearbeitung">Schaden in Bearbeitung</option>
            <option value="schaden_bearbeitet">Schaden bearbeitet</option>
          </Form.Select>
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>Versicherungsanfrage</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="versicherungsanfrage"
            value={props.data.versicherungsanfrage ? props.data.versicherungsanfrage : "" }
            onChange={(e) => props.setKunde(e)}
          >
            <option>-</option>
            <option value="noch_zu_ubergeben">Noch zu übergeben</option>
            <option value="an_berater_ubergeben">An Berater übergeben</option>
          </Form.Select>
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>Energieanfrage</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="energieanfrage"
            value={props.data.energieanfrage ? props.data.energieanfrage : "" }
            onChange={(e) => props.setKunde(e)}
          >
            <option>-</option>
            <option value="noch_zu_erfolgen">Noch zu erfolgen</option>
            <option value="erfolgt">erfolgt</option>
          </Form.Select>
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>Finanzierungsanfrage</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="finanzierungsanfrage"
            value={props.data.finanzierungsanfrage ? props.data.finanzierungsanfrage : "" }
            onChange={(e) => props.setKunde(e)}
          >
            <option>-</option>
            <option value="noch_zu_erfolgen">Noch zu erfolgen</option>
            <option value="erfolgt">erfolgt</option>
          </Form.Select>
        </Col>

        <Col xs={12} md={3}>
          <Form.Label>Immobilienanfrage</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="immobilienanfrage"
            value={props.data.immobilienanfrage ? props.data.immobilienanfrage : "" }
            onChange={(e) => props.setKunde(e)}
          >
            <option>-</option>
            <option value="noch_zu_erfolgen">Noch zu erfolgen</option>
            <option value="erfolgt">erfolgt</option>
          </Form.Select>
        </Col>
      */}
      
      </Row>
    </fieldset>
  );
}
export default InputKundenstatus