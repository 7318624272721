
import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import "./Kategorien.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import { faPlus, faSave, faCheckCircle, faCheck, faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs, Alert, Row, Col, Container, Button } from 'react-bootstrap';

import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";

import NewsletterListe from './NewsletterListe';
import GewinnspielListe from './GewinnspielListe';

const FreigabeNewsGewinn = () => {

  const navigate = useNavigate();


  const [newsletterListe, setNewsletterListe] = useState([]);
  const [gewinnspielListe, setGewinnspielListe] = useState([]);

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);



  useEffect(() => {

    async function loadNewsletter () {
      setLoading(true);
      AuthAxios.get('freigabe/newsletter')
      .then(res => {
        setNewsletterListe(res.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler beim Abrufen von Newslettern",
          html: "Versuche es später erneut."
        })
      })
    }

    async function loadGewinnspiele () {
      setLoading(true);
      AuthAxios.get('freigabe/gewinnspiele')
      .then(res => {
        setNewsletterListe(res.data);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler beim Abrufen von Newslettern",
          html: "Versuche es später erneut."
        })
      })
    }

    setLoading(true);

    if(activeTab == 1) {
      loadNewsletter();
    }
    
    if(activeTab == 2) {
      loadGewinnspiele();
    }

  }, [activeTab]);
  

  

  



  return (
    <Container fluid style={{ marginLeft: "0px", marginTop: "35px"}}>

      <p className="p-2 m-0">Freigabe Stelle für Newsletter & Gewinnspiele. Freigabe erfolgt über Popup (öffnet mit Klick auf die Zeile).</p>
      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="partner"
          id="fill-tab-example"
          fill
          style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
        >

          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Newsletter">
            <NewsletterListe data={newsletterListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} tab={1} />
          </Tab>
            
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="2" title="Gewinnspiele">
            <GewinnspielListe data={gewinnspielListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} tab={2} />
          </Tab>

        </Tabs>
      </div>
      
    
  </Container>
  );
}

export default FreigabeNewsGewinn
