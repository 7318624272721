import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { FaRegCheckCircle } from "react-icons/fa";

const Message = ({ message, currentfriend, scrollRef, typingMessage }) => {

  //const { myInfo } = useSelector((state) => state.auth);


  const myInfo = {
    id: "a65fasd4f78ds",
    userName: "VIN1",
    image: "https://my.vin1.eu/logo192.png"
  };

  return (
    <>
      <div className="message-show">
        {message && message.length > 0 ? (
          message.map((m, index) =>

            m.senderId === myInfo.id ? (

              <div ref={scrollRef} className="my-message" key={m._id}>
                {/* Absender "Ich" */}
           
                {/* Absender "Ich" 

              <div className="message-image-time">
              <img src={myInfo.image} alt="" />
              <div className="message-time">

                <img src={myInfo.image} alt="" />
                <div className="image-message">
                  */}


                <div className="message-image-time">
                
                  <div className="my-text">
                    <p className="message-text">
                      {" "}
                      {m.message.text === "" ? (
                        <img src={m.message.image} />
                      ) : (
                        m.message.text
                      )}{" "}
                      
                      <div className="time">
                        {moment(m.createdAt).startOf("minute").fromNow()}
                      </div>
                    </p>

                    {index === message.length - 1 &&
                      m.senderId === myInfo.id ? (
                         m.status === "seen" ? (
                         <img
                              className="img"
                              src={currentfriend.image}
                              alt=""
                         />
                         ) : m.status === "delivered" ? (
                         <span>
                              {" "}
                              <FaRegCheckCircle />{" "}
                         </span>
                         ) : (
                         <span>
                              {" "}
                              <FaRegCheckCircle />{" "}
                         </span>
                         )
                      ) : (
                        ""
                    )}
                  </div>
                  <img src={myInfo.image} alt="" />
                </div>
              </div>

            ) : (

              <div ref={scrollRef} className="fd-message" key={m._id}>
                {/* Absender "Freund" */}
                <div className="image-message-time">
                  <img src={currentfriend.image} alt="" />
                  <div className="message-time">
                    <div className="fd-text">
                      <p className="message-text">
                        {" "}
                        {m.message.text === "" ? (
                          <img src={m.message.image} />
                        ) : (
                          m.message.text
                        )}
                        {" "}

                        <div className="time">
                          {moment(m.createdAt).startOf("minute").fromNow()}
                        </div>
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <div className="friend_connect">
            <img src={currentfriend.image} alt="" />
            <h3>{currentfriend.userName} Connect You </h3>
            <span>
              {" "}
              {moment(currentfriend.createdAt).startOf("minute").fromNow()}{" "}
            </span>
          </div>
        )}
      </div>
      {typingMessage &&
      typingMessage.msg &&
      typingMessage.senderId === currentfriend._id ? (
        <div className="typing-message">
          <div className="fd-message">
            <div className="image-message-time">
              <img src={currentfriend.image} alt="" />
              <div className="message-time">
                <div className="fd-text">
                  <p className="time">Schreibt...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Message;
