import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'

import AuthAxios from "../utils/AuthAxios";
import Swal from "sweetalert2";

function SelectPartnerKategorien (props) {

    const [liste, setListe] = useState([]);
    const [selected, setSelected] = useState("");



    /*
    const handleValue = (selected) => {
        selected.map(s => {
            console.log({value: s, label: s});    
            setSelectedValue(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));       
         })
    }
    */

    useEffect(() => {

        async function getKategorien () {
        
            try {
                const res = await AuthAxios.get('/partner/settings-kategorien');

                
                const kategorieListe = [];
                res.data.map(r => {
                    kategorieListe.push({"value": r.name, "label": r.name});
                });
                setListe(kategorieListe);
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Kategorien konnten nicht geladen werden."
                });
            }               
        }
        getKategorien();  

    },[]);

    useEffect(() => {


        let sel = [];
        //handleValue(props.selectedValue);
        props.selectedValues?.map(s => {

            //sel.push({value: s.value, label: s.label});
            sel.push({value: s, label: s});
            
            /*
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));
              */
        });
        setSelected(sel);

    },[props.selectedValues]);
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        // Object
        props.setSelectMulti(name, selection);
        
    };

    /*
    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex" }}>
          <div>{label}</div>
        </div>
    );
    */


    return (
        <Col xs={12} md={12}>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                //value={selectedValues.value}
                value={selected ? selected : ""}
                //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                onChange={(selection, e) => handleChange(e.name, selection)}
                options={liste}
                noOptionsMessage={() => "Keine Kategorie gefunden"}
                placeholder={<div className="select-placeholder-text">{props.placeholder}</div>}
                name="kategorien"
            />
        </Col>
    );
   
    
}

export default SelectPartnerKategorien