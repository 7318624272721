import React, { useState, useCallback, useRef } from 'react'
import Cropper from 'react-easy-crop'
import ImgDialog from './ImgDialog'
import getCroppedImg from './cropImage'
import { styles } from './styles'

import { withStyles } from '@material-ui/core/styles'

import Button from 'react-bootstrap/Button';
import InputSliderBootstrap from './InputSliderBootstrap';

import AuthAxios from '../../../utils/AuthAxios'
import Swal from 'sweetalert2';


function ProfilePicture ({ classes, imgURL }) {

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [maxZoom, setMaxZoom] = useState(2)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const lastValidZoom = useRef(zoom); // useRef to store the last valid zoom level
  const MIN_SIZE = 640 // Minimum Pixel size for Whatsapp 640x640!


  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixel) => {
      if (
        croppedAreaPixel.width < MIN_SIZE ||
        croppedAreaPixel.height < MIN_SIZE
      ) {
        console.log('Size too small, reverting zoom!')
        setZoom(lastValidZoom.current) // Revert to the last valid zoom level
      } else {
        lastValidZoom.current = zoom // Update the last valid zoom level
        setCroppedAreaPixels(croppedAreaPixel)
      }
    },
    [zoom, MIN_SIZE]
  )


  
  // Handler for zoom changes
  const onZoomChange = useCallback(
    (newZoom) => {
      setZoom(newZoom)
      if (
        croppedAreaPixels &&
        croppedAreaPixels.width >= MIN_SIZE &&
        croppedAreaPixels.height >= MIN_SIZE
      ) {
        lastValidZoom.current = newZoom // Update last valid zoom if size is okay
      }
    },
    [croppedAreaPixels, MIN_SIZE]
  )



  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imgURL,
        croppedAreaPixels,
        rotation
      )
      console.log('done', { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }

  const onClose = () => {
    setCroppedImage(null)
  }



  const saveWAProfilePicture = () => {

    const formData = new FormData();
    formData.append('image',croppedImage);

    const headerConfig = { headers: { 'content-type': 'multipart/form-data' } };
  
    AuthAxios.post('/wa/set-profilepicture', formData, headerConfig)
      .then(res => {

        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Bild erfolgreich gespeichert."
        });

      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Bild konnte nicht gespeichert werden."
        });
      });

  }


  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={imgURL}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          maxZoom={maxZoom}
          aspect={1}
          showGrid={false}
          onCropChange={setCrop}
          cropShape={'round'}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          //onZoomChange={setZoom}
          onZoomChange={onZoomChange}
          onMediaLoaded={(mediaSize) => {
            // console.log("onmedialoaded");
            // Adapt zoom based on media size to fit max height
            //setZoom(1000 / mediaSize.naturalHeight)
          }}
        />
      </div>

      <div className="row mt-2">
        <p>Du kannst den Bildausschnitt mit der Maus verschieben. Zum Feinjustieren des Zooms/Drehung Pfeiltasten benutzen.</p>
      </div>

      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <p
            style={{margin: "0px", paddingRight: "8px"}}
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </p>

          <InputSliderBootstrap
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e) => {
              const newZoom = parseFloat(e.target.value);
              setZoom(newZoom);
            }}
          />

        </div>
        <div className={classes.sliderContainer}>
          <p
            style={{margin: "0px", paddingRight: "8px"}}
            classes={{ root: classes.sliderLabel }}
          >
            Drehung
          </p>
          <InputSliderBootstrap
            value={rotation}
            min={0}
            max={360}
            step={1}
            onChange={(e) => {
              const newRoatation = parseFloat(e.target.value);
              setRotation(newRoatation);
            }}
          />

        </div>

      </div>

      <div className={classes.controls}>
        <Button
          onClick={showCroppedImage}
        >
          Vorschau Zuschnitt
        </Button>

        <Button
          className="ml-1"
          onClick={saveWAProfilePicture}
          variant="success"
        >
          Als Profilbild speichern
        </Button>
      </div>
     
      <ImgDialog img={croppedImage} onClose={onClose} />
    </div>
  )
}

// At the bottom of your ProfilePicture component file
const StyledProfilePicture = withStyles(styles)(ProfilePicture);
export default StyledProfilePicture;
