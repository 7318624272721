import React, { useEffect, useState } from 'react'

import AuthAxios from '../../../utils/AuthAxios';
import Swal from 'sweetalert2';


function LandingpageFreischalten(props) {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {

    async function getData () {
      AuthAxios.get('/vorteilspartner/landingpage-freischalten')
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Freizugebende Landingpages konnten nicht geladen werden."
        });
      });
    }

    if(props.tab == props.activeTab) {
      getData();
    }

  },[props.activeTab]);

  return (
    <div>LandingpageFreischalten</div>
  )
}

export default LandingpageFreischalten