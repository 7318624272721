import React, { useEffect, useState, useContext } from 'react'

import { Col, Row, Form, Button, Spinner } from 'react-bootstrap';

import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { Parser } from '@json2csv/plainjs';
import SelectMitarbeiterDEMVExport from '../../Bausteine/SelectMitarbeiterDEMVExport';
import { MainContext } from '../../contexts/MainContext';




function DEMVExport() {

  const { user } = useContext(MainContext);

  const [mitarbeiterSelect, setMitarbeiterSelect] = useState(null);
  const [mitarbeiter, setMitarbeiter] = useState(null);
  const [selectedMitarbeiterId, setSelectedMitarbeiterId] = useState(null);
  const [anzahlDaten, setAnzahlDaten] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [bestaendeNEU, setBestaendeNEU] = useState([]);
  const [bestaende, setBestaende] = useState([]);

  const [anzahlNeueBestände, setAnzahlNeueBestände] = useState(0);
  const [anzahlGesamtBestände, setAnzahlGesamtBestände] = useState(0);

  
  useEffect(() => {
    if (!isNaN(parseInt(selectedMitarbeiterId))) {

      AuthAxios.get('/user/demv/'+selectedMitarbeiterId)
      .then(res => {
        console.log(res.data);
        setMitarbeiter(res.data);
      })
      .catch(err => {
        console.log(err);
      })
    }

  },[selectedMitarbeiterId]);


  // CSV Export Optionen BEstände
  const exportOpts = {
    defaultValue: '', // Default value for empty cells
    // Specify the field names in the desired order
    fields: [
      {
        label: 'Int. Kundennummer',
        value: (row) => row['kdnr'], 
      },
      'vorname', 'nachname',
      {
        label: 'geburtsdatum',
        value: (row) => row['geburtsdatum'] ?
          new Intl.DateTimeFormat('de-DE', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          }).format(new Date(row['geburtsdatum']))
          : '00.00.0000', // Demv erlaubt keine leeren Geburtstage
      },
      'anschrift', 'plz', 'stadt',
      {
        label: 'telefon',
        value: (row) => row['telefon'] || '', 
      },
      {
        label: 'email',
        value: (row) => row['email'] || '', 
      },
      'versicherung',
      {
        label: 'Kunde seit',
        value: (row) => row['kundeSeit'] ? new Intl.DateTimeFormat('de-DE', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(row['kundeSeit']))
        : '', 
      },
    ],
  };
  



  useEffect(() => {
    AuthAxios.get('/bestaende')
    .then(res => {

      setAnzahlNeueBestände( res.data.filter(col => col.exportiert == false).length )
      setAnzahlGesamtBestände(res.data.length);

      let neueBestaende = [];

      res.data.forEach(elem => {
        if(!elem.exportiert) {
          neueBestaende.push(elem);
        }
      });

      setBestaendeNEU(neueBestaende);
      setBestaende(res.data);

    })
    .catch(err => {
      console.log(err);
    });

  },[])



  const handleMitarbeiterSelect = (name, val) => {
    // nur Update Dropdown..
    setMitarbeiterSelect(val); 
    //getting DEMV Value
    setSelectedMitarbeiterId(val.value);
  }

  const handleCSVDownloadMitarbeiter = (mitarbeiterId) => {

    setIsLoading(true);

    AuthAxios.get('/kunde/demv/'+mitarbeiterId)
      .then(res => {

        setAnzahlDaten(res.data.length);

        // Convert JSON to CSV
        //https://www.npmjs.com/package/@json2csv/plainjs
        const opts = {
            defaultValue: '', // Default value for empty cells
            // Specify the field names in the desired order
            fields: ['kdnr', 'firma', 'anrede', 'vorname', 'nachname', 
              {
                label: 'geburtsdatum',
                value: (row) => row['geburtsdatum'] || '00.00.0000', // Demv erlaubt keine leeren Geburtstage
              },
              'plz', 'stadt',
              {
                label: 'mobil',
                value: (row) => row['mobil'] || '', 
              },
              {
                label: 'telefon',
                value: (row) => row['telefon'] || '', 
              },
              {
                label: 'email',
                value: (row) => row['email'] || '', 
              },
              'maklervorname', 'maklernachname'
            ], 
            // Custom formatter for the birthday field
            //transforms: {
            //    geburtsdatum: (value) => value || '00.00.0000' // Demv erlaubt keine leeren Geburtstage
            //}
        };

        let resDataDEMV = res.data.map(data => ({
          ...data,
          'maklervorname': mitarbeiter.demvVorname,
          'maklernachname': mitarbeiter.demvNachname,
        }));

        const parser = new Parser(opts);
        const csvData = parser.parse(resDataDEMV);

        // Encode the CSV data as UTF-8
        const utf8Encoder = new TextEncoder(); // UTF-8 is the default
        const utf8Data = utf8Encoder.encode(csvData);
        
        // Create a temporary file on the client-side
        const blob = new Blob([utf8Data], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = mitarbeiterSelect.label+'.csv';
        link.click();

        // Clean up the temporary file
        URL.revokeObjectURL(url);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log('Error exporting to CSV:', error);
      });
      
  }


  const neueBestaendeCSVDownload = async () => {
    
    // =========================================================
    // Update exportiert Status bevor die Datensätze per CSV gedownloaded werden
    let ids = bestaendeNEU.map(row => row._id);
  
    let updateData = await AuthAxios.post('/bestaende/update-export', {
      ids: ids
    });

    if(!updateData.status == 200) {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Exportiert Status bei Datensätzen konnte nicht gesetzt werden. Versuche es erneut."
      })
      return;
    }
    // =========================================================
    
    const parser = new Parser(exportOpts);
    const csvData = parser.parse(bestaendeNEU);

    // Encode the CSV data as UTF-8
    const utf8Encoder = new TextEncoder(); // UTF-8 is the default
    const utf8Data = utf8Encoder.encode(csvData);
    
    // Create a temporary file on the client-side
    const blob = new Blob([utf8Data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'NeueBestände_'+new Date().toLocaleDateString("de-DE")+'.csv';
    link.click();

    // Clean up the temporary file
    URL.revokeObjectURL(url);

    
    setAnzahlNeueBestände(0);
  }


  const alleBestaendeCSVDownload = () => {

    const parser = new Parser(exportOpts);
    const csvData = parser.parse(bestaende);
    
    // Encode the CSV data as UTF-8
    const utf8Encoder = new TextEncoder(); // UTF-8 is the default
    const utf8Data = utf8Encoder.encode(csvData);
    
    // Create a temporary file on the client-side
    const blob = new Blob([utf8Data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'AlleBestände_'+new Date().toLocaleDateString("de-DE")+'.csv';
    link.click();

    // Clean up the temporary file
    URL.revokeObjectURL(url);
  }



  return (
    <div style={{padding: "50px 0px 0px 15px", margin: "0px", width: "100%"}}>
    
      {user.roles?.includes("Management") &&
        <Row className="mb-5">
          <Col xs={12} md={6}>
            <h1>DEMV-Export CSV für Mitarbeiter</h1>
            <p>Download aktueller CSV Dateien für die Mitarbeiter</p>
            <Form.Label>
                Für welchem Mitarbeiter willst du die Kundenliste downloaden?
            </Form.Label>

            <div style={{maxWidth: "380px"}}>
              <SelectMitarbeiterDEMVExport
                hideLabel={true}
                selectedValue={mitarbeiterSelect ? mitarbeiterSelect : null}
                setSelect={handleMitarbeiterSelect}
              />
            </div>
   

          </Col>

          {isLoading &&
              <Col xs={12} className="mt-3">
                  <Spinner animation="border" variant="primary" />
              </Col>
          }

          {mitarbeiterSelect?.value && !isLoading &&
            <Col xs={12} className="mt-3">
                <Button 
                    variant='success'
                    onClick={() => {handleCSVDownloadMitarbeiter(mitarbeiterSelect.value)}}
                >
                    Download CSV - {mitarbeiterSelect.label}
                </Button>
                {anzahlDaten && 
                    <p className="mt-3"><b>{anzahlDaten}</b> Kundendatensätze exportiert.</p>
                }
            </Col>
          }
            
        </Row>
      }


        <Row>

          <Col xs={12} md={8}>
            <h1>Bestandsdatenübernahme CSV-Download</h1>
            <p style={{fontSize: "110%"}}>Seit dem letzten Export liegt/-en <b>{anzahlNeueBestände} neue Datensatze/-sätze</b> vor.</p>
            <Button
              variant='primary'
              style={{
                opacity: anzahlNeueBestände == 0 ? "0.5" : "1",
                cursor: anzahlNeueBestände == 0 ? "default" : "pointer"
              }}
              onClick={neueBestaendeCSVDownload}
            >
              Download CSV
            </Button>

            <p style={{fontSize: "110%"}}>Gesamtanzahl Datensätze aktuell: <b>{anzahlGesamtBestände}</b></p>
            <Button
              variant='primary'
              style={{
                opacity: anzahlGesamtBestände == 0 ? "0.5" : "1",
                cursor: anzahlGesamtBestände == 0 ? "default" : "pointer"
              }}
              onClick={alleBestaendeCSVDownload}
            >
              Download Gesamtübersicht/Historie CSV
            </Button>

          </Col>
          
        </Row>

    </div>
  )
}

export default DEMVExport