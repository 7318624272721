import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, NavItem, Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { DataGrid, deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';

import { alpha, styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import moment from 'moment';

import CustomModal from '../../Bausteine/CustomModal';
import AuthAxios from "../../utils/AuthAxios";

function NewsletterListe(props) {

  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [notApprovedMessage, setNowApprovedMessage] = useState("");
  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "partner_name",
      headerName: "Partner",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "title",
      headerName: "Titel",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "publishDate",
      headerName: "VÖ am",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          {moment(params.row.publishDate).isBefore(moment()) ?
            <span>öffentlich</span>
            :
            moment(params.row.publishDate).format("DD.MM.YY, HH:mm")
          }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "externeUrl",
      headerName: "ext. URL",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.externeUrl ?
            <a href={params.row.externeUrl} target="_blank">{params.row.externeUrl}</a>
            : "-"
          }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "pdfName",
      headerName: "PDF Link",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.pdfFileUrl && params.row.pdfName &&
            <a href={params.row.pdfFileUrl} target="_blank">{params.row.pdfName.substring(36)}</a>
          }
          {params.row.pdfFileUrl && !params.row.pdfName &&
            <a href={params.row.pdfFileUrl} target="_blank">PDF hat keinen Namen</a>
          }
          {!params.row.pdfFileUrl && params.row.pdfName &&
            <span>PDF Name, aber keine URL</span>
          }
           {!params.row.pdfFileUrl && !params.row.pdfName &&
            <span>-</span>
          }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "imageName",
      headerName: "Bild Link",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.imageUrl && params.row.imageName &&
            <a href={params.row.imageUrl} target="_blank">{params.row.imageName.substring(36)}</a>
          }
          {params.row.imageUrl && !params.row.imageName &&
            <a href={params.row.imageUrl} target="_blank">Bild hat keinen Namen</a>
          }
          {!params.row.imageUrl && params.row.imageName &&
            <span>Bild Name, aber keine URL</span>
          }
        </>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Zul. Geändert",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.updatedAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span>{moment(params.row.createdAt).format("DD.MM.YY, HH:mm")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
  ];



  const handleNavigation = (e) => {
    setSelectedRow(props.data.find(item => item._id === e.id));
    setShowModal(true);
  }

  const handleFreigabe = (isApproved) => {
    setShowModal(false);
    alert("Bearbeitet!");
    //AuthAxios.post("/")
  }


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>

      </GridToolbarContainer>
    );
  }


    return (
      <div style={{width: "100%"}}>
        <Box
          sx={{
            height: '80vh',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

        {props.activeTab == props.tab && (
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            rows={props.data}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            autoPageSize
            //onFilterModelChange={onFilterModelChange}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
          />
        )}
        
        </Box>

        {showModal &&
            <CustomModal 
                title={"Freigabe Newsletter"}
                visible={showModal}
                setVisible={setShowModal}
                width="xl"
            >

            <Row className="justify-content-center">

              {selectedRow.imageUrl ?
                <img src={selectedRow.imageUrl} style={{height: "160px", width:"auto", maxWidth: "800px"}} />
                :
                <i>-Keine Bild hinterlegt-</i>
              }
           
              <Col xs={12} className="mt-3 text-center">  
                <h4>{selectedRow.title ?? <i>-Keine Titel-</i>}</h4>
              </Col>

              <Col xs={12} className="mt-3 text-center">
                {selectedRow.externeUrl &&
                  <Button
                    className="w-auto"
                    style={{
                      background: "rgb(83, 178, 83)",
                      border: "none",
                      marginRight: "10px"
                    }}
                    onClick={() => window.location(selectedRow.externeUrl)}
                  >
                    PDF Download
                  </Button>
                }
                {selectedRow.pdfFileUrl &&
                    <Button
                    className="w-auto"
                    style={{
                      background: "#520da7",
                      border: "none"
                    }}
                    onClick={() => window.location(selectedRow.pdfFileUrl)}
                  >
                    Mehr Infos
                  </Button>
                }
              </Col>

              <Col className="mt-5 text-center">
                <p style={{fontSize: "100%"}}>{selectedRow.message ?? <i>-Keine Nachricht-</i>}</p>
              </Col>

            </Row>

            <hr />

            <Row>
              <Col xs={12} className="text-center">
                <div
                  className="d-flex justify-content-center"
                  style={{
                    flexDirection: "column", // To vertically center both label and textarea
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <Form.Label>Ablehnungstext (Warum abgelehnt? - wird Partner angezeigt)</Form.Label>
                  <Form.Control
                    style={{ maxWidth: "500px" }}
                    as="textarea"
                    rows={4}
                    placeholder="Ablehnungstext"
                    name="notApprovedMessage"
                    value={notApprovedMessage ? notApprovedMessage : ""}
                    onChange={(e) => setNowApprovedMessage(e)}
                  />
                </div>
              </Col>
              
              <Col xs={12} className="text-center justify-content-center">

                <Button
                  style={{border: "none", marginRight: "10px"}}
                  onClick={() => handleFreigabe(false)}
                  variant="danger"
                >
                  Newsletter ablehnen
                </Button>

                <Button
                  style={{border: "none", marginRight: "10px"}}
                  onClick={() => handleFreigabe(true)}
                  variant="success"
                >
                  Newsletter freigeben
                </Button>
              </Col>
            </Row>
             
            </CustomModal>
          } 

      </div>
    );
  }
  

export default NewsletterListe