
import React, { useState } from 'react'
import { Container, Row, Col, Form, Button  } from "react-bootstrap";

import { COLORS } from '../../constants';


function Aktivität(props) {

  const [aktivitaet] = useState([
    {
      "date": "2022-12-22",
      "nachricht": "Kontakt hat Telefonnr in VIN-App verändert"
    },
    {
      "date": "2022-12-23",
      "nachricht": "Vitali | E-Mail geändert"
    }
  ]);

  return (
    
    <Container fluid className="pb-5">

      <Row style={{ display: "flex", flexDirection: "row" }}>

      <p>Oberfläche schon mal angelegt. Bisher keine Funktion.</p>

        <Col xs={12} md={12} lg={6} xl={6} style={{overflowX: "hidden"}}>

            <fieldset className="border" style={{background: "white", borderRadius: "8px", display: "flex", flexDirection: "column"}}>
          
              <legend className="float-none w-auto p-2" style={{color: COLORS.primCol, background: COLORS.primBG, borderRadius: "8px", fontSize: "12px"}}>Aktivität Kontakt</legend>

              <ul>
              {aktivitaet?.map(a => 
                <li key={a.date+a.nachricht}>
                  {a.date} {a.nachricht}
                </li>
              )}
              </ul>

            </fieldset>
        </Col>


        <Col xs={12} md={6} lg={6} xl={4}>

          <fieldset className="border" style={{background: "white", borderRadius: "8px", display: "flex", flexDirection: "column"}}>

            <legend className="float-none w-auto p-2" style={{color: COLORS.primCol, background: COLORS.primBG, borderRadius: "8px", fontSize: "12px"}}>Andere Trackings</legend>



          </fieldset>

        </Col>

      </Row>
    </Container>
  );
}

export default Aktivität