
export const formatDateTime = (dateTimeString) => {

    const dateTimeObj = new Date(dateTimeString);
    //if (isNaN(dateTimeObj)) {
    //    throw new Error(`Invalid date string: ${dateTimeString}`);
    //}

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        //second: 'numeric',
        timeZone: 'Europe/Berlin',
    }).format(dateTimeObj);
}


export const formatDateTimeLong = (dateTimeString) => {

    const dateTimeObj = new Date(dateTimeString);
    //if (isNaN(dateTimeObj)) {
    //    throw new Error(`Invalid date string: ${dateTimeString}`);
    //}

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Europe/Berlin',
    }).format(dateTimeObj);
}


export const formatDate = (dateString) => {

    if(!dateString) {
        return "Datum fehlt";
    }

    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) {
        throw new Error(`Invalid date string: ${dateString}`);
    }

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
    }).format(dateObj);
}

export const formatDateLong = (dateString) => {

    if(!dateString) {
        return "Datum fehlt";
    }

    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) {
        throw new Error(`Invalid date string: ${dateString}`);
    }

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format(dateObj);
}