import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'

import AuthAxios from "../utils/AuthAxios";
import Swal from "sweetalert2";

function SelectWhatsappLaender (props) {


    //Quelle: https://developers.facebook.com/docs/whatsapp/api/messages/message-templates?locale=de_DE

    const laender = [
        {
            label: "Afrikaans",
            value: "af"
        },
        {
            label: "Albanisch",
            value: "sq"
        },
        {
            label: "Arabisch",
            value: "ar"
        },
        {
            label: "Aserbaidschanisch",
            value: "az"
        },
        {
            label: "Bengalisch",
            value: "bn"
        },
        {
            label: "Bulgarisch",
            value: "bg"
        },
        {
            label: "Katalanisch",
            value: "ca"
        },
        {
            label: "Chinesisch (CHN)",
            value: "zh_CN"
        },
        {
            label: "Chinesisch (HKG)",
            value: "zh_HK"
        },
        {
            label: "Chinesisch (TAI)",
            value: "zh_TW"
        },
        {
            label: "Kroatisch",
            value: "hr"
        },
        {
            label: "Tschechisch",
            value: "cs"
        },
        {
            label: "Dänisch",
            value: "da"
        },
        {
            label: "Niederländisch",
            value: "nl"
        },
        {
            label: "Englisch",
            value: "en"
        },
        {
            label: "Englisch (GB)",
            value: "en_GB"
        },
        {
            label: "Englisch (US)",
            value: "en_US"
        },
        {
            label: "Estnisch",
            value: "et"
        },
        {
            label: "Filipino",
            value: "fil"
        },
        {
            label: "Finnisch",
            value: "fi"
        },
        {
            label: "Französisch",
            value: "fr"
        },
        {
            label: "Deutsch",
            value: "de"
        },
        {
            label: "Griechisch",
            value: "el"
        },
        {
            label: "Gujarati",
            value: "gu"
        },
        {
            label: "Hausa",
            value: "ha"
        },
        {
            label: "Hebräisch",
            value: "he"
        },
        {
            label: "Hindi",
            value: "hi"
        },
        {
            label: "Ungarisch",
            value: "hu"
        },
        {
            label: "Indonesisch",
            value: "id"
        },
        {
            label: "Irisch",
            value: "ga"
        },
        {
            label: "Italienisch",
            value: "it"
        },
        {
            label: "Japanisch",
            value: "ja"
        },
        {
            label: "Kannada",
            value: "kn"
        },
        {
            label: "Kasachisch",
            value: "kk"
        },
        {
            label: "Koreanisch",
            value: "ko"
        },
        {
            label: "Laotisch",
            value: "lo"
        },
        {
            label: "Lettisch",
            value: "lv"
        },
        {
            label: "Mazedonisch",
            value: "mk"
        },
        {
            label: "Malaiisch",
            value: "ms"
        },
        {
            label: "Malayalam",
            value: "ml"
        },
        {
            label: "Marathi",
            value: "mr"
        },
        {
            label: "Norwegisch",
            value: "nb"
        },
        {
            label: "Persisch",
            value: "fa"
        },
        {
            label: "Polnisch",
            value: "pl"
        },
        {
            label: "Portugiesisch (BR)",
            value: "pt_BR"
        },
        {
            label: "Portugiesisch (POR)",
            value: "pt_PT"
        },
        {
            label: "Pandschabi",
            value: "pa"
        },
        {
            label: "Rumänisch",
            value: "ro"
        },
        {
            label: "Russisch",
            value: "ru"
        },
        {
            label: "Serbisch",
            value: "sr"
        },
        {
            label: "Slowakisch",
            value: "sk"
        },
        {
            label: "Slowenisch",
            value: "sl"
        },
        {
            label: "Spanisch",
            value: "es"
        },
        {
            label: "Spanisch (ARG)",
            value: "es_AR"
        },
        {
            label: "Spanisch (SPA)",
            value: "es_ES"
        },
        {
            label: "Spanisch (MEX)",
            value: "es_MX"
        },
        {
            label: "Swahili",
            value: "sw"
        },
        {
            label: "Schwedisch",
            value: "sv"
        },
        {
            label: "Tamilisch",
            value: "ta"
        },
        {
            label: "Telugu",
            value: "te"
        },
        {
            label: "Thai",
            value: "th"
        },
        {
            label: "Türkisch",
            value: "tr"
        },
        {
            label: "Ukrainisch",
            value: "uk"
        },
        {
            label: "Usbekisch",
            value: "uz"
        },
        {
            label: "Vietnamesisch",
            value: "vi"
        },
        {
            label: "Zulu",
            value: "zu"
        }
    ];

    const [selected, setSelected] = useState("");





    useEffect(() => {


        let sel = [];
        //handleValue(props.selectedValue);
        props.selectedValues?.map(s => {

            //sel.push({value: s.value, label: s.label});
            sel.push({value: s, label: s});
            
            /*
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));
              */
        });
        setSelected(sel);

    },[props.selectedValues]);
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        // Object
        props.setSelectMulti(name, selection);
        
    };

    /*
    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex" }}>
          <div>{label}</div>
        </div>
    );
    */


    return (
        <Col xs={12} md={12}>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                //value={selectedValues.value}
                value={selected ? selected : ""}
                //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                onChange={(selection, e) => handleChange(e.name, selection)}
                options={laender}
                noOptionsMessage={() => "Keine Rolle gefunden"}
                placeholder={<div className="select-placeholder-text">{props.placeholder}</div>}
                name="sprachen"
            />
        </Col>
    );
   
    
}

export default SelectWhatsappLaender