import React, { useEffect, useCallback, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import SelectBetreuer from "../../Bausteine/SelectBetreuer";
import SelectRoleSingle from "../../Bausteine/SelectRoleSingle";

function Rechteverwaltung(props) {

    const [roleSettings, setRoleSettings] = useState({});


    const setSelectMulti = () => {

    }

    return (
        <Container style={{ maxWidth: "600px", margin: "15px 15px 15px 15px" }}>
            <h4 className="mb-4">Rechteverwaltung</h4>

            {!props.data.id &&
                <>
                    <h5>Rechte bearbeiten für Mitarbeiter</h5>
                    <SelectBetreuer />
                </>
            }

            <br />

            {!props.data.role &&
                <>
                    <h5>Rechte bearbeiten für Rolle</h5>
                    <Form.Label>Rolle auswählen</Form.Label>
                    <SelectRoleSingle
                        placeholder={"Wähle eine Rolle"}
                        setSelectMulti={setSelectMulti}
                        selectedValues={roleSettings.role ? roleSettings.role.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
                    />
                </>
            }

            <Form.Label>Rechte-Template laden - vorher fragen, dass Rechte "überschrieben werden"</Form.Label>
            <Form.Select
                id="kunden_anlegen"
                name="kunden_anlegen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <br />
            <Button>Rechte als Template abspeichern (Modal öffnen)</Button>

            <br />
            <br />
            <br />

            <h2> Nur anzeigen wenn Kunde oder Mitarbeiter ausgewählt ist!</h2>


            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6>Analysen</h6>
            <Form.Label>Analysen anzeigen</Form.Label>
            <Form.Select
                id="analysen_anzeigen"
                label="Analysen anzeigen"
                name="analysen_anzeigen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Zugriff</option>
                <option value="alle">Alle Analysen</option>
            </Form.Select>


            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6>Kunden</h6>
            <Form.Label>Kunden anzeigen</Form.Label>
            <Form.Select
                id="kunden_anzeigen"
                name="kunden_anzeigen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Zugriff</option>
                <option value="eigene">nur eigene Kunden</option>
                <option value="gruppen">Kunden der Gruppen/Region/Vertriebsteam</option>
                <option value="alle">Alle Kunden</option>
            </Form.Select>
            <br />

            <Form.Check
                id="kunden_anlegen"
                label="Mitarbeiter darf Kunden NEU anlegen"
                name="kunden_anlegen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <Form.Label>Kunden bearbeiten</Form.Label>
            <Form.Select
                id="kunden_bearbeiten"
                name="kunden_bearbeiten"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Zugriff</option>
                <option value="aktivitäten">nur Aktivitäten/Notizen hinterlegen</option>
                <option value="eigene">nur eigene Kunden</option>
                <option value="gruppen">Kunden der Gruppen/Region/Vertriebsteam</option>
                <option value="alle">Alle Kunden</option>
            </Form.Select>


            <Form.Label>Kunden löschen (ins Archiv)</Form.Label>
            <Form.Select
                id="kunden_löschen"
                name="kunden_löschen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Löschen</option>
                <option value="eigene">nur eigene Kunden</option>
                <option value="gruppen">Kunden der Gruppen/Region/Vertriebsteam</option>
                <option value="alle">Alle Kunden</option>
            </Form.Select>

            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6>Aufgaben</h6>

            <Form.Label>Aufgaben anzeigen</Form.Label>
            <Form.Select
                id="aufgaben_anzeigen"
                name="aufgaben_anzeigen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Zugriff</option>
                <option value="eigene">nur eigene Aufgaben</option>
                <option value="gruppen">Aufgaben der Gruppen/Region/Vertriebsteam</option>
                <option value="alle">Alle Aufgaben</option>
            </Form.Select>
            <br />

            <Form.Check
                id="aufgaben_anlegen"
                label="Aufgaben NEU anlegen"
                name="aufgaben_anlegen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <Form.Label>Aufgaben bearbeiten</Form.Label>
            <Form.Select
                id="aufgaben_bearbeiten"
                name="aufgaben_bearbeiten"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">kein Zugriff</option>
                <option value="eigene">nur eigene Aufgaben</option>
                <option value="gruppen">eigene + Aufgaben der Gruppen/Region/Vertriebsteam</option>
                <option value="alle">alle Aufgaben</option>
            </Form.Select>


            <Form.Label>Aufgaben löschen (ins Archiv)</Form.Label>
            <Form.Select
                id="aufgaben_löschen"
                name="aufgaben_löschen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">kein Löschen</option>
                <option value="eigene">nur eigene Aufgaben</option>
                <option value="gruppen">eigene + Aufgaben der Gruppen/Region/Vertriebsteam</option>
                <option value="alle">alle Aufgaben</option>
            </Form.Select>

            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6>Rabattpartner</h6>

            <Form.Label>Rabattpartner anzeigen</Form.Label>
            <Form.Select
                id="rabattpartner_anzeigen"
                name="rabattpartner_anzeigen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">kein Zugriff</option>
                <option value="eigene">nur dem Betreuer zugewiesene Rabattpartner</option>
                <option value="alle">alle Rabattpartner</option>
            </Form.Select>


            <br />

            <Form.Check
                id="rabattpartner_anlegen"
                label="Rabattpartner anlegen"
                name="rabattpartner_anlegen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />


            <Form.Check
                id="rabattpartner_veröffentlichen"
                label="Rabattpartner veröffentlichen"
                name="rabattpartner_veröffentlichen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <Form.Label>Rabattpartner bearbeiten</Form.Label>
            <Form.Select
                id="rabattpartner_bearbeiten"
                name="rabattpartner_bearbeiten"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Zugriff</option>
                <option value="eigene">nur dem Betreuer zugewiesene Rabattpartner</option>
                <option value="alle">Alle Rabattpartner</option>
            </Form.Select>


            <Form.Label>Rabattpartner löschen (ins Archiv)</Form.Label>
            <Form.Select
                id="rabattpartner_löschen"
                name="rabattpartner_löschen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">Kein Löschen</option>
                <option value="eigene">nur dem Betreuer zugewiesene Rabattpartner</option>
                <option value="alle">Alle Rabattpartner</option>
            </Form.Select>

            <br />
            <hr />
            <br />

            
            {/* --------------------------------------------------------------------------------------------- */}

            <h6>Exklusiv/Standortmakler</h6>

            <Form.Label>Exklusiv/Standortmakler anzeigen</Form.Label>
            <Form.Select
                id="standortmakler_anzeigen"
                name="standortmakler_anzeigen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">kein Zugriff</option>
                <option value="eigene">nur dem Betreuer zugewiesene Standortmakler</option>
                <option value="alle">alle Standortmakler</option>
            </Form.Select>


            <br />

            <Form.Check
                id="standortmakler_anlegen"
                label="Standortmakler anlegen"
                name="standortmakler_anlegen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />


            <Form.Check
                id="standortmakler_veröffentlichen"
                label="Standortmakler veröffentlichen"
                name="standortmakler_veröffentlichen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <Form.Label>Standortmakler bearbeiten</Form.Label>
            <Form.Select
                id="standortmakler_bearbeiten"
                name="standortmakler_bearbeiten"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">kein Zugriff</option>
                <option value="eigene">nur dem Betreuer zugewiesene Standortmakler</option>
                <option value="alle">alle Standortmakler</option>
            </Form.Select>


            <Form.Label>Standortmakler löschen (ins Archiv)</Form.Label>
            <Form.Select
                id="standortmakler_löschen"
                name="standortmakler_löschen"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            >
                <option value="deny">kein Löschen</option>
                <option value="eigene">nur dem Betreuer zugewiesene Standortmakler</option>
                <option value="alle">alle Standortmakler</option>
            </Form.Select>

            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6 className="mb-4">Whatsapp</h6>

            <Form.Check
                id="whatsapp_read"
                label="Zugriff auf Whatsapp"
                name="whatsapp_read"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="whatsapp_all"
                label="Whatsapp Nachrichten senden"
                name="whatsapp_all"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <Form.Check
                id="whatsapp_templates"
                label="Whatsapp Templates verwalten"
                name="whatsapp_templates"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6 className="mb-4">Daten-Export</h6>

            <Form.Check
                id="kunden"
                label="Kunden"
                name="kunden"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="aufgaben"
                label="Aufgaben"
                name="aufgaben"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="rabattpartner"
                label="Rabattpartner"
                name="rabattpartner"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="benutzer"
                label="Benutzer"
                name="benutzer"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <br />
            <hr />
            <br />

            {/* --------------------------------------------------------------------------------------------- */}

            <h6 className="mb-4">Mitarbeiter-Modul</h6>

            <Form.Check
                id="kunden"
                label="Modul anzeigen"
                name="kunden"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="aufgaben"
                label="Mitarbeiter anlegen"
                name="aufgaben"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="rabattpartner"
                label="Mitarbeiter bearbeiten"
                name="rabattpartner"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="rabattpartner"
                label="Mitarbeiter löschen"
                name="rabattpartner"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />
            <Form.Check
                id="benutzer"
                label="Benutzer"
                name="benutzer"
                checked={true}
                onChange={(e) => props.setCBs(e)}
            />

            <div style={{ paddingBottom: "4em" }}></div>

        </Container>

    )
}

export default Rechteverwaltung