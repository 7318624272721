import React, { useState } from 'react'
import { Row, Col, Form } from "react-bootstrap"
import { COLORS } from '../constants'

import SelectErreichbar from '../Bausteine/SelectErreichbar';

function InputKontaktdaten(props) {  

  return (
    <fieldset className="border" style={{background: "white", borderRadius: "8px"}}>
      <legend className="float-none w-auto p-2" style={{color: COLORS.primCol, background: COLORS.primBG, borderRadius: "8px", fontSize: "12px"}}>
        Kontakt
      </legend>
      <Row>
        <Col xs={12} md={12}>

          <Form.Label>Sprache</Form.Label>
          <Form.Select
            aria-label="Default select"
            name="sprache"
            value={props.data.sprache ? props.data.sprache : "deutsch" }
            onChange={(e) => props.setKunde(e)}
          >
            <option value="deutsch">deutsch</option>
            <option value="englisch">englisch</option>
            <option value="russisch">russisch</option>
            <option value="französisch">französisch</option>
            <option value="spanisch">spanisch</option>
            <option value="portugiesisch">portugiesisch</option>
          </Form.Select>

          <Form.Label>
          {(props.data.email !== undefined) ? 
            <a href={'mailto:'+props.data.email} style={{color: "#c58164"}}>E-Mail</a>
            : "E-Mail"
          }</Form.Label>
          <Form.Control
            type="email"
            placeholder="email@email.de"
            name="email"
            value={props.data.email ? props.data.email : ""}
            onChange={(e) => props.setKunde(e)}
          />

          <Form.Label>
          {(props.data.telefon !== undefined && props.data.telefon !== "") ? 
            <a href={'tel:'+props.data.telefon} style={{color: "#c58164"}}>Telefon</a>
            : "Telefon"
          }</Form.Label>
          <Form.Control
            type="text"
            placeholder="Telefon"
            name="telefon"
            value={props.data.telefon ? props.data.telefon : "" }
            onChange={(e) => props.setKunde(e)}
          />

          <Form.Label>
          {(props.data.mobil !== undefined && props.data.mobil !== "") ? 
            <a href={'tel:'+props.data.mobil} style={{color: "#c58164"}}>Mobil</a>
            : "Mobil"
          }</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mobil"
            name="mobil"
            value={props.data.mobil ? props.data.mobil : "" }
            onChange={(e) => props.setKunde(e)}
          />

          <Form.Label>Fax</Form.Label>
          <Form.Control
            type="text"
            placeholder="Fax"
            name="fax"
            value={props.data.fax ? props.data.fax : "" }
            onChange={(e) => props.setKunde(e)}
          />

          <Form.Label>
          {(props.data.webseite !== undefined && props.data.webseite !== "") ? 
            <a href={props.data.webseite} target="_blank" rel="nofollow noreferrer" style={{color: "#c58164"}}>Webseite</a>
            : "Webseite"
          }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Webseite"
            name="webseite"
            value={props.data.webseite ? props.data.webseite : "" }
            onChange={(e) => props.setKunde(e)}
          />

          <Form.Check
            type="checkbox"
            className="mt-3"
            id="newsletter"
            label="Newsletter Versand zugestimmt"
            name="newsletter"
            checked={props.data.newsletter ? true : false}
            onChange={(e) => props.setCBs(e)}
          />

         
          
          {/*
           <Form.Label>Erreichbar (morgens, mittags, abends)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Erreichbar"
              name="erreichbar"
              value={props.data.erreichbar ? props.data.erreichbar : "" }
              onChange={(e) => props.setKunde(e)}
            />
          */}
            <SelectErreichbar
              setSelectMulti={props.setSelectMulti}
              selectedValue={props.data.erreichbar}
            />
         
        </Col>
      </Row>
    </fieldset>
  );
}
export default InputKontaktdaten