import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from "react-router-dom";
import { createPortal } from 'react-dom';



function MainNavbar(props) {


  const mainnavbar = <>
     {[false].map((expand) => (
      <Navbar key={expand} bg="dark" variant="dark" expand={expand} className="mb-2 fixed-top" style={{height: "35px", margin: "0", padding: "0", zIndex: "100"}}>
        <Container fluid>

        <div style={{color: "white", paddingLeft: "15px"}}>
            Benutzer: <b>{props.data.vorname} {props.data.nachname}</b>
          </div>
         

          <NavLink to="/dash/home" style={{textDecoration: "none"}}>
            <Navbar.Brand>VIN1 CRM</Navbar.Brand>
          </NavLink>
         
          {/*<Navbar.Brand onClick={props.handleLogout} style={{cursor: "pointer"}}>Logout</Navbar.Brand>*/}

          <div style={{color: "white", paddingRight: "15px"}}> 
          </div>


         {/* // OffCanvas Navigation ist eine Navigation die über Hamburger automatisch von der Seiten eingeblendet werden kann.

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} style={{height: "20px", display: "contents"}} />
          
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >

            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Kategorien
              </Offcanvas.Title>
            </Offcanvas.Header>

           
        
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 link">
                <NavLink id="link" to="/" className={(navData) => navData.isActive ? classes.active : ""}>Kategorie 1</NavLink>
                <NavLink id='link' to="/K2" className={(navData) => navData.isActive ? classes.active : ""}>Kategorie 2</NavLink>
                <NavLink id='link' to="/Kundendaten" className={(navData) => navData.isActive ? classes.active : ""}>Kundendaten</NavLink>
              </Nav>
            </Offcanvas.Body>
         

          </Navbar.Offcanvas>
            */} 

        </Container>
      </Navbar>
    ))}
    </>
  return (
    createPortal(mainnavbar, document.getElementById('header'))
  );
}

export default MainNavbar;