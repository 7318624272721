import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

export default function GewinnspieleAnalyse() {

  const [kundendaten, setKundendaten] = useState({});

  useEffect(()=>{
    
  },[])

  return (
    <Container fluid >
    
    </Container>
  );
}
