import React from 'react'

import WhatsappChat from '../../Bausteine/WhatsappChat/WhatsappChat';


function WhatsappChatContainer() {
  return (
    <div>
      <WhatsappChat />
    </div>
  )
}

export default WhatsappChatContainer