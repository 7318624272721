import axios from 'axios'



const AuthAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  headers: {
    "Content-Type": "application/json",
  },
});

//axios.defaults.baseURL = process.env.REACT_APP_BACKEND;
//axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "null";

// Hier kann man wie in einem Hook Elemente einfügen, bevor der Request gesendet wird.

AuthAxios.interceptors.request.use(
  /*
  res=>{
      request.headers["Authorization"] = sessionStorage.getItem('token');
      return res;
      */
    async (config) => {
      const token = sessionStorage.getItem("token");

      if (token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${token}`,
        };
      }

    return config;
  },
  err=>{
    return Promise.reject(err);
  }
);




// Wenn Token abgelaufen ist neuen anfordern
//https://dev.to/franciscomendes10866/how-to-use-axios-interceptors-b7d
// => Why is memoization done? The answer is very simple, if twenty http requests are made and all of them get a 401 (Unauthorized), we don't want the token to be refreshed twenty times.
//When we memoize the function, the first time it is invoked, the http request is made, but from then on, its response will always be the same because it will be cached for ten seconds (and without making the other nineteen http requests).
//With the public instance of axios configured and the refresh token function memorized, we can move on to the next step.
//import { memoizedRefreshToken } from "./refreshToken";
//import mem from "mem";
/*
authAxios.interceptors.response.use(
  res=>{
    return res;
  },
  err=>{
    const status = err.response ? err.response.status : null;
    if(status === 403)
    {
      axios.post('/auth/getNewTokenUsingRefresh', {
        refrest_token:localStorage.getItem('refresh_token')
      }). then(response => {
        localStorage.setItem('refresh-token', response.data.refrest_token);
        sessionStorage.setItem('access_token', response.data.token);
      })
      .catch(err => {
        localStorage.removeItem('refresh-token');
        sessionStorage.remoteItem('access_token');
        window.location.replace('/');
      })
    }
    return Promise.reject(err);
  }
);
*/

export default AuthAxios



