import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { Form, Container, Row, Col, Button } from "react-bootstrap";
import "../../Input/Input.module.scss"
import InputKundendaten from "../../Input/InputKundendaten";
import InputKontaktdaten from "../../Input/InputKontaktdaten";
import InputInteresse from "../../Input/InputInteresse";
import InputTippgeberStatus from "../../Input/InputTippgeberStatus";
import InputBestätigungen from "../../Input/InputBestätigungen";
import InputKundenstatus from "../../Input/InputKundenstatus";
import InputVertrieb from "../../Input/InputVertrieb";

import InputNotiz from "../../Input/InputNotiz";
import CardNotiz from "../../Bausteine/Cards/CardNotiz";
import NotizenEditModal from "../../Bausteine/NotizenEditModal";

import UebertrageneBestaende from "../../Input/UebertrageneBestaende";

import Swal from "sweetalert2";


function Stammdaten(props) {

  const [showNotizEdit, setShowNotizEdit] = useState(false);
  const [notizEdit, setNotizEdit] = useState({});


  // Angeheftete Notizen Pinned-Status ändern
  const handleNotizenPinned = (id) => {
    props.setChanged(true);

    const neueNotizen = props.data.notiz.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          pinned: !item.pinned,
        };
        return updatedItem;
      }

      return item;
    });

    props.setKundeManually("notiz", neueNotizen);
  }


  // Notiz bearbeiten
  const handleNotizenEdit = (id) => {
    setShowNotizEdit(true);

    if (props.data.notiz.filter(x => x.id == id).length == 1) {
      setNotizEdit(props.data.notiz.filter(x => x.id == id)[0]);
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Notiz bearbeiten",
        html: "Die Notiz konnte nicht gefunden werden."
      })
    }
  }

  // Geänderten Notiz-Content in Kunde hinterlegen
  const handleNewNotizContent = (notizEdited) => {
    const index = props.data.notiz.findIndex(x => x.id == notizEdit.id);
    if (index === -1) {
      Swal.fire({
        icon: "error",
        title: "Update Notiz",
        html: "Die Notiz konnte nicht gefunden werden."
      })
    }
    else {
      props.setKundeManually("notiz", [
        ...props.data.notiz.slice(0, index),
        { ...props.data.notiz[index], notiz: notizEdited },
        ...props.data.notiz.slice(index + 1)
      ])
    }
    setNotizEdit({});
  }

  // Notiz löschen
  const handleNotizenDelete = (id) => {
    props.setChanged(true);
    props.setKundeManually("notiz", props.data.notiz.filter(x => x.id !== id));
  }



  return (
    <Container fluid>

      {showNotizEdit &&
        <NotizenEditModal title={"Notiz bearbeiten"} notiz={notizEdit} newNotizContent={handleNewNotizContent} setChanged={props.setChanged} visible={setShowNotizEdit} />
      }

      <Form>
        <Row style={{ display: "flex" }}>
          <Col xs={12} md={12} xl={8}>
            <InputKundendaten
              data={props.data}
              letzteAktivität={props.letzteAktivität}
              setKunde={props.setKunde}
              setSelect={props.setSelect}
              setCBs={props.setCBs}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <InputKontaktdaten
              data={props.data}
              setKunde={props.setKunde}
              setCBs={props.setCBs}
              setSelectMulti={props.setSelectMulti}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} xl={12}>
            <InputKundenstatus
              data={props.data}
              setKunde={props.setKunde}
            />
          </Col>
        </Row>


        <Row>
          <Col xs={12} md={12} xl={4}>
            <InputBestätigungen
              data={props.data}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <InputTippgeberStatus
              data={props.data}
              setKunde={props.setKunde}
              setSelect={props.setSelect}
            />
          </Col>

          <Col xs={12} md={12} xl={4}>
            <InputInteresse
              data={props.data}
              setCBs={props.setCBs}
            />
          </Col>

        </Row>

        <Row>
          <Col xs={12} md={12} xl={4}>
            <InputVertrieb
              data={props.data}
              setCBs={props.setCBs}
            />
          </Col>
          <Col xs={12} md={12} xl={8} style={{ marginTop: "10px", marginBottom: "50px" }}>
            <UebertrageneBestaende data={props.data} />
          </Col>
        </Row>


        <Row>

          <Col xs={12} md={12} xl={4}>
            <InputNotiz
              data={props.data}
              setKundeManually={props.setKundeManually}
            />
          </Col>


          <Col xs={12} md={12} xl={8} style={{ marginTop: "10px", marginBottom: "50px" }}>
            <h5 style={{ paddingLeft: "10px" }}>Wichtige Notizen</h5>
            {props.data.notiz !== undefined ?
              props.data.notiz.filter(x => x.pinned === true).length > 0 ?
                props.data.notiz.filter(x => x.pinned === true).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((n) =>
                  <CardNotiz key={n.id} data={n} handleNotizenPinned={handleNotizenPinned} handleNotizenEdit={handleNotizenEdit} handleNotizenDelete={handleNotizenDelete}  />
                ) : 
                <p><i style={{marginLeft: "8px"}}>Keine wichtigen Notizen hinterlegt</i></p>
              :
              <p><i style={{marginLeft: "8px"}}>Keine wichtigen Notizen hinterlegt</i></p>
            }

            <br />

            <h5 style={{ paddingLeft: "10px" }}>Notizen</h5>
            {props.data.notiz !== undefined ?
              props.data.notiz.filter(x => x.pinned === false).length > 0 ?
                props.data.notiz.filter(x => x.pinned === false).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((n) =>
                  <CardNotiz key={n.id} data={n} handleNotizenPinned={handleNotizenPinned} handleNotizenEdit={handleNotizenEdit} handleNotizenDelete={handleNotizenDelete} />
                ) :
                <p><i style={{marginLeft: "8px"}}>Keine Notizen hinterlegt</i></p>
              :
              <p><i style={{marginLeft: "8px"}}>Keine Notizen hinterlegt</i></p>
            }
          </Col>





        </Row>

      </Form>
    </Container>
  );
}
export default Stammdaten
