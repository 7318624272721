import React, { Fragment, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

import style from "./CardSub.module.css"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Swal from 'sweetalert2'
import { MainContext } from '../../../contexts/MainContext';
import AuthAxios from '../../../utils/AuthAxios';
//import Dokumente from '../Dokumente/Dokumente';

function CardDokument(props) {

  const { user } = useContext(MainContext);

  async function downloadFile(url, filename) {

    let fileURLObj = await AuthAxios.post('/dokumente/download', {
      googleUID: props.googleUID,
      fileName: filename
    });
    let fileURL = fileURLObj.data;

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;

      fileURL = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = fileURL;
      a.download = filename.substring(36);
      a.click();
      window.URL.revokeObjectURL(fileURL);
    };
    xhr.open('GET', fileURL);
    xhr.send();

  }


  return (

    <div className={style.cardContainerUpload} style={{
      marginBottom: "20px",
      background: !props.data.data.bestaetigung ? "#fff" : (props.data.data.bestaetigt ? "rgba(2, 222, 2, 0.3)" : "rgba(255, 0, 0, 0.3)")
    }} >
      <Row style={{ width: "100%" }}>
        <Col style={{ display: "flex", flex: "11", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px" }}>
          <p className="fw-bold" style={{ padding: "0", marginBottom: "5px" }}>{props.data.data.title.substring(36)} - {new Intl.DateTimeFormat('de-DE', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          }).format(new Date(props.data.data.created._seconds * 1000))}{props.data.data.bearbeiter && " | Bearbeiter: " + props.data.data.bearbeiter}
          </p>

          {(props.data.data.bestaetigung && !props.data.data.bestaetigt) && <span>warte auf Kundenbestätigung.</span>}
          {(props.data.data.bestaetigung && props.data.data.bestaetigt && props.data.data.bestaetigt_am) && <span>Bestätigt: {new Intl.DateTimeFormat('de-DE', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }).format(new Date(props.data.data.bestaetigt_am._seconds * 1000))}</span>}

          {/*
          {(props.data.data.unterschrift && !props.data.data.unterschrieben) && <span>warte auf Kundenbestätigung.</span>}
          */}

        </Col>

        <Col style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
          {!props.data.data.bestaetigung &&
            <>
              {props.data.data.uploader === "vin1" && //user.partner_name &&
                <FontAwesomeIcon style={{ width: "auto", cursor: "pointer", marginTop: "5px", marginRight: "30px", color: "#707070" }} icon={faTrash} size="lg" onClick={() => {
                  Swal.fire({
                    icon: "info",
                    title: "Dokument löschen",
                    html: "Willst du das Dokument: <b>" + props.data.data.title.substring(36) + "</b> wirklich löschen?",
                    showDenyButton: true,
                    confirmButtonText: 'Löschen',
                    denyButtonText: `Abbrechen`
                  })
                    .then(async (result) => {
                      if (result.isConfirmed) {
                        props.deleteFileAndDoc(props.data.id, props.data.data.newsfeeddocid, props.data.data.title);
                      }
                    })
                }
                } />
              }
            </>
          }
          <FontAwesomeIcon style={{ width: "auto", cursor: "pointer", marginTop: "5px", color: "#707070" }} icon={faDownload} size="lg" onClick={() => downloadFile(props.data.data.url, props.data.data.title)} />

        </Col>
      </Row>

      <p className="" style={{}}>{props.data.data.name}</p>
    </div>

  )
}

export default CardDokument