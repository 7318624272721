import React, { useState, useEffect, useContext } from "react";

import "./SideNavBar.css";
import { Scrollbar } from 'react-scrollbars-custom';

import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faWhatsapp } from "@fortawesome/free-solid-svg-icons";
//import { faWhatsapp } from  "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faHome, faLineChart, faUserPlus, faUserGear, faUsers, faUsersGear, faList, faComment, faCartPlus, faPeopleArrowsLeftRight, faCog, faSignOut, faPuzzlePiece, faBuilding, faShareNodes, faMapLocationDot, faArrowRightArrowLeft, faDiagramPredecessor, faHandshake, faUserTie, faAddressCard, faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";

import { FaBuildingUser } from "react-icons/fa6";
import { RiUserSharedFill } from "react-icons/ri";

const SideNavBar = (props) => {

	const navigate = useNavigate();

	
	const { user } = useContext(MainContext);

	const [userRoleId, setUserRoleId] = useState(0);
	const [userRoles, setUserRoles] = useState([]);
	const [pageAccess, setPageAccess] = useState([]);

	useEffect(() => {
	  
	  if(user.roleId) {
		setUserRoleId(user.roleId);
	  }
	  if(user.roles) {
	  	setUserRoles(user.roles);
	  }
	  if(user.pageAccess) {
		setPageAccess(user.pageAccess);
	}
		
	}, [user])

	const [isExpanded, setExpendState] = useState(false);
	/*
	const menuItems = [
		{
			text: "Home",
			icon: "icons/grid.svg",
			access: "read",
            navigate: "/dash/home"
		},
		{
			text: "Analyse",
			icon: "icons/pie-chart.svg",
            access: "readPageAnalyse",
            navigate: "/dash/analyse"
		},
		{
			text: "Kunde bearbeiten",
			icon: "icons/user.svg",
            access: "readPageKundeEdit",
            navigate: "/dash/kunde"
		},
		{
			text: "Kunden",
			icon: "icons/user.svg",
            access: "readPageKunden",
            navigate: "/dash/kunden"
		},
        {
			text: "Aufgaben",
			icon: "icons/message.svg",
            access: "readPageAufgaben",
            navigate: "/dash/aufgabe"
		},
        { 
			text: "Vorteilspartner",
			icon: "shopping-cart.svg",
            access: "readPageVorteilspartner",
            navigate: "/dash/vorteilspartner"
		},
		/*
        {
			text: "Dateien",
			icon: "icons/folder.svg",
            access: "readPageDateien",
            navigate: "/dash"
		},
        
		{
			text: "Sales-Pipeline",
			icon: "icons/shopping-cart.svg",
            access: "readPageSalesPipeline",
            navigate: "/dash/sales-pipeline"
		},
        
		
        {
			text: "Zeiterfassung",
			icon: "icons/heart.svg",
            access: "readPageZeiterfassung",
            navigate: "/dash"
		},
        
		{
			text: "Benutzerkonten",
			icon: "icons/settings.svg",
            access: "readPageBenutzerkonten",
            navigate: "/dash/mitarbeiter"
		}
	];*/

	const menuItems = [
		{
			text: "Home",
			icon: faHome,
			iconType: "fontawesome",
			access: "read",
            navigate: "/dash/home"
		},
		{
			text: "Analyse",
			icon: faLineChart,
			iconType: "fontawesome",
            access: "readPageAnalyse",
            navigate: "/dash/analyse"
		},
		{
			text: "Kunde bearbeiten",
			icon: faUserGear,
			iconType: "fontawesome",
            access: "readPageKundeEdit",
            navigate: "/dash/kunde"
		},
		/*
		{
			text: "Kunden",
			icon: faUsers,
			iconType: "fontawesome",
            access: "readPageKunden",
            navigate: "/dash/kunden"
		},
		*/
		{
			text: "Kunden", //"Kunden Serverseitig abrufen (Test)",
			icon: faUsers,
			iconType: "fontawesome",
            access: "readPageKundenServer",
            navigate: "/dash/kunden-server"
		},
		{
			text: "Kunden zuordnen",
			icon: faUsersGear,
			iconType: "fontawesome",
            access: "readPageKundenBackoffice",
            navigate: "/dash/kunden-backoffice"
		},

        {
			text: "Aufgaben",
			icon: faList,
			iconType: "fontawesome",
            access: "readPageAufgaben",
            navigate: "/dash/aufgabe"
		},
		{ 
			text: "Pipelines",
			icon: faDiagramPredecessor,
			iconType: "fontawesome",
            access: "readPagePipelines",
            navigate: "/dash/pipeline"
		},
		{
			text: "Feedback App",
			icon: faComment,
			iconType: "fontawesome",
            access: "readPageFeedback",
            navigate: "/dash/feedback"
		},
        { 
			text: "Vorteilspartner",
			icon: faCartPlus,
			iconType: "fontawesome",
            access: "readPageVorteilspartner",
            navigate: "/dash/vorteilspartner"
		},
		{ 
			text: "Affiliates",
			icon: faPeopleArrowsLeftRight,
			iconType: "fontawesome",
            access: "readPageAffiliate",
            navigate: "/dash/affiliate"
		},
		{ 
			text: "B2B CRM-Partner",
			icon: FaBuildingUser,
			iconType: "react-icons",
            access: "readPagePartner",
            navigate: "/dash/partner"
		},
		{	
			text: "Freigabe News & Gewinnsp.",
			icon: faFileCircleCheck,
			iconType: "fontawesome",
			acces: "readPageFreigabenNewsGewinn",
			navigate: "/dash/freigaben"
		},
		{ 
			text: "Exklusivmakler",
			icon: faUserTie,
			iconType: "fontawesome",
            access: "readPageExklusivmakler",
            navigate: "/dash/exklusivmakler"
		},
		{ 
			text: "Exklusivmakler-Karte",
			icon: faMapLocationDot,
			iconType: "fontawesome",
            access: "readPageExklusivmaklerKarte",
            navigate: "/dash/exklusivmakler-karte"
		},
	
		/*
		{ 
			text: "Projekte",
			icon: faPuzzlePiece,
            access: "readPageProjekt",
            navigate: "/dash/projekt"
		},
		*/
		/*
		{ 
			text: "Immobilien",
			icon: faBuilding,
            access: "readPageImmobilie",
            navigate: "/dash/immo"
		},
		{ 
			text: "Vertriebsstruktur",
			icon: faShareNodes,
            access: "readPageVertriebsstruktur",
            navigate: "/dash/vertriebsstruktur"
		},
		{ 
			text: "Import/Export",
			icon: faArrowRightArrowLeft,
            access: "readPageImExport",
            navigate: "/dash/import-export"
		},
		*/
		/*
        {
			text: "Dateien",
			icon: "icons/folder.svg",
            access: "readPageDateien",
            navigate: "/dash"
		},
        
		{
			text: "Sales-Pipeline",
			icon: "icons/shopping-cart.svg",
            access: "readPageSalesPipeline",
            navigate: "/dash/sales-pipeline"
		},
        */
		
        /*{
			text: "Zeiterfassung",
			icon: "icons/heart.svg",
            access: "readPageZeiterfassung",
            navigate: "/dash"
		},
        */
	    {
			text: "Perso Verifizierung",
			icon: faAddressCard,
			iconType: "fontawesome",
            access: "readPagePersoCheck",
            navigate: "/dash/perso-check"
	    },
		{
			text: "WhatsApp",
			icon: faWhatsapp,
			iconType: "fontawesome",
            access: "readPageWhatsapp",
            navigate: "/dash/whatsapp"
		},
		{
			text: "DEMV-Export",
			icon: RiUserSharedFill,
			iconType:"react-icons",
            access: "readPageDEMVExport",
            navigate: "/dash/demv-export"
		},
		{
			text: "Einstellungen",
			icon: faCog,
			iconType: "fontawesome",
            access: "readPageEinstellungen",
            navigate: "/dash/mitarbeiter"
		}
	];


	const handleNavigation = (targetURL) => {
		navigate(targetURL);
	}

	return (
		<div
			className={
				isExpanded
					? "side-nav-container"
					: "side-nav-container side-nav-container-NX"
			}
			style={{zIndex: "110"}}
		>
			<div className="nav-upper">
				<div className="nav-heading">
					{isExpanded && (
						<div className="nav-brand">
							{/*<img src="images/VIN1.png" alt="" srcSet="" />*/}
							<h4 className="mt-4" style={{paddingLeft: "30px"}}>Menü</h4>
						</div>
					)}
					<button
						className={
							isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
						}
						onClick={() => setExpendState(!isExpanded)}
					>
						<span></span>
						<span></span>
						<span></span>
					</button>
				
				</div>

				<Scrollbar style={{ width: "auto", height: `calc(100vh - 110px)` }}>
					<div className="nav-menu">

						{menuItems.map((item) => {
							
							const rolesWithAccess = ["Administrator", "Management"]; // add any roles you want to exclude from the if block
							// if user.access != item.access => Benutzerrechte müssen Seite freigeben.
							if( 
							    (!userRoles.some(role => rolesWithAccess.includes(role))
								  && !pageAccess.includes(item.access) // Zugriff über Seiten Access Array z.B. ["readPageDEMV", "readPageFeedback"]
								)
								  && (
									//|| item.access == 'readPageAnalyse'
									//|| item.access == "readPageKundeEdit"
									//|| item.access == "readPageKundenServer"
									//|| item.access == "readPageKundenBackoffice"
									//|| item.access == "readPageAufgaben"
									item.access == 'readPagePipelines'
									|| item.access == 'readPagePartner' //B2B-Partner
									|| item.access == 'readPageFeedback' // Feedback aus App
									|| item.access == 'readPageVorteilspartner'
									|| item.access == 'readPageAffiliate'
									|| item.access == 'readPageFreigabenNewsGewinn' //Freigaben Newsletter + Gewinnspiele B2B-CRM
									|| item.access == 'readPageExklusivmakler' // Exkluxivmakler
									|| item.access == 'readPageExklusivmaklerKarte' 
									|| item.access == 'readPagePersoCheck' // PErso überprüfung Cashback
									|| item.access == 'readPageWhatsapp' // Whatsapp API
									|| item.access == 'readPageDEMVExport'
									|| item.access == 'readPageEinstellungen'
								  )
						    ) {
								// skip element, wenn nicht in Rolle rolesWithAccess
								return null; 
							}
							else
							{
								return (
									<a
										key={item.navigate}
										className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
										onClick={() => handleNavigation(item.navigate)}
										
									>
										{item.icon && item.iconType === "fontawesome" &&
											<FontAwesomeIcon
												className="menu-item-icon"
												style={{ paddingRight: isExpanded ? "15px" : "0px"}}
												icon={item.icon}
												size="xl"
											/>
										}
  										{item.icon && item.iconType === "react-icons" &&
											<item.icon
												className="menu-item-icon"
												style={{
													paddingRight: isExpanded ? "15px" : "0px",
													width: "30px",
													height: "30px"
												}}
											/>
										}
										{item.iconIMG &&
											<img
											className="menu-item-icon"
												src={item.iconIMG} 
												alt=""
												//srcSet=""
												style={{
													width: "45px",
													maxWidth: "45px",
													maxHeigth: "40px",
													aspectRatio: 1,
													paddingLeft: isExpanded ? "0px": "8px",
													paddingRight: isExpanded ? "15px" : "8px"
												}}
											/>
										}	
										{isExpanded && <p>{item.text}</p>}
									</a>
								)
							}

						})}

						<a
							key={"logout"}
							className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
							onClick={props.handleLogout}
						>
							<FontAwesomeIcon
								onClick={props.handleLogout}
								className="menu-item-icon"
								style={{ paddingRight: isExpanded ? "15px" : "0px"}}
								icon={faSignOut}
								size="lg"
							/>
							{isExpanded && <p>Logout</p>}
						</a>

					</div>
				</Scrollbar>

			</div>
			{/*<div className="nav-footer">
				{isExpanded && (
					<h5 style={{display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "15px", margin: "0px"}}>Logout</h5>
				)}

				<FontAwesomeIcon
					onClick={props.handleLogout}
					className="logout-icon"
					style={{marginLeft: "8px", width: "1.2em", height: "1.2em"}}
					icon={faSignOut}
					size="lg"
				/>
				{//<img className="logout-icon" src="icons/logout.svg" alt="" srcSet="" />}
			</div>*/}
		</div>
	);
};

export default SideNavBar;