import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Button, Col, Row, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//import Länder from '../Data/LaenderListe';

import Swal from 'sweetalert2'
import AuthAxios from "../utils/AuthAxios";

function SelectSponsor (props) {

    const [sponsoren, setSponsoren] = useState([]);

    const [selected, setSelected] = useState("");

    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {

        async function getSponsorfeld () {

            AuthAxios.get('/kunde/suche/'+searchValue)
            .then(res => {

                const kundenList = [];

                res.data.map(ma => {
                    kundenList.push({"value": ma.kdnr, "label": ma.kdnr+" | "+ma.vorname+" "+ma.nachname});
                });
                
                setSponsoren(kundenList);
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                icon: 'error',
                title: "Fehler",
                html: "User konnten nicht geladen werden."
                });
            });

            /*
            setSponsoren([
                { "value": "108112", "label": "108112 | Marius Schulte" },
                { "value": "108113", "label": "108113 | Vitali Weisheim" }
            ]) 
            */
        }

        // Suche erst starten nach Eingabe von min. 4 Zeichen
        if(searchValue.length >=4) {
            getSponsorfeld(); 
        }
  
    }, [searchValue]);

    const handleRemove = () => {

        Swal.fire({
            title: 'Sponsor löschen',
            html: "Willst du den Sponsor wirklich aus dem Feld entfernen?<br /><b>Änderungen</b> werden erst <b>beim Speichern des Kunden</b> übernommen!",
            showDenyButton: true,
            confirmButtonText: 'Löschen',
            denyButtonText: `Nicht löschen`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              setSelected("");
              props.setSelect("sponsor", "");
              setSponsoren([]);
              Swal.fire('Sponsor aus Feld gelöscht!', '', 'success');
            }
          })
      
    }
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {
        setSelected({label: name, value: selection.value})
        props.setSelect(name, selection.value);
    };


    const handleInputChange = (val) => {
        setSearchValue(val);
    };


    return (
        <Col xs={12} md={12}>
            <Form.Label>Sponsor zuweisen</Form.Label>
            <Row>
                <Col xs={10} md={10}>
                    <ReactSelect
                        value={props.selectedValue ? sponsoren.filter((f) => f.value == props.selectedValue)  : ""}
                        onChange={(selection, e) => handleChange(e.name, selection)}
                        options={sponsoren}
                        placeholder={<div className="select-placeholder-text">Suche nach Kundennr/Name-min. 4 Zeichen</div>}
                        noOptionsMessage={() => "Suche nach Vorname oder Name um Optionen zu erhalten."}
                        filterOption={createFilter({ignoreAccents: false})}
                        name="sponsor"
                        onInputChange={handleInputChange}
                    />
                </Col>
                <Col xs={1} md={1}>
                    {selected !== "" &&
                        <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                            <FontAwesomeIcon
                                icon={faXmark}
                                size="1x"
                                title="Sponsor entfernen"
                            />
                        </Button>
                    }
                </Col>
            </Row>

        </Col>
    );
   
    
}

export default SelectSponsor