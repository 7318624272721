import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tab, Tabs, Alert, Row, Col, Button } from 'react-bootstrap';

import NeuerAffiliate from './NeuerAffiliate';
import AffiliateListe from './AffiliateListe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faCreditCard } from "@fortawesome/free-solid-svg-icons";


import { useParams, useNavigate, useLocation } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';



function Affiliate() {

    let { id } = useParams();
    const navigate = useNavigate();
    
    // Globaler State für Kunde mit allen Daten
    const [affiliate, setAffiliate]  = useState({
      status: "privat",
      kategorie: "In Kürze verfügbar",
    })
    
    const [activeTab, setActiveTab] = useState(1);
    const [alertSuccess, setAlertSuccess] = useState("")
    const [alertError, setAlertError] = useState("")
    const [active, setActive] = useState(true);


    // Tracking ob es Änderungen am Vorteilspartner gab
    const [changed, setChanged] = useState(false);


    // Logo Handling
    const [newImage, setNewImage] = useState(null);
    const [newImageURL, setNewImageURL] = useState(null);


    const handleLogo = (files) => {
    
      setChanged(true);
      // Reset wenn löschen
      if(!files)
      {
        setNewImage(null);
        setNewImageURL(null);
      }
      else
      {
        setNewImage(files[0]);
        // Bilder können nicht direkt so dargetellt werden, daher umwandeln!
        setNewImageURL(URL.createObjectURL(files[0]));
      }
    }

  
    useEffect(() => {
        
        async function getAffiliates(id) {
          AuthAxios.get('/affiliate/'+id)
          .then(res => {
              //console.log(res.data);
              //setAffiliate({kdnr: "108115", mitarbeiter: "dino"});
              setAffiliate(res.data);
          })
          .catch(err => {
              console.log(err);
              Swal.fire({
                  icon: 'error',
                  title: "Fehler",
                  html: "Affiliate konnte nicht gefunden werden."
              });
          });
        }

        if (id !== undefined) {
          getAffiliates(id);
          setNewImage(null);
          setNewImageURL(null);
        }

    }, [id]);








    // + Icon neuer Partner
    const newAffiliate = () => {
        setAffiliate({
          status: "privat",
          kategorie: "In Kürze verfügbar",
        });
        setActiveTab(1);
        navigate("/dash/affiliate");
    };



    const saveAffiliate = () => {
  
      if (!affiliate.name) {
        Swal.fire({
          icon: 'error',
          title: "Speichern",
          html: "Gib einen Affiliatenamen ein!"
        });
        return;
      }

      if (!affiliate.webseite) {
        Swal.fire({
          icon: 'error',
          title: "Speichern",
          html: "Gib einen Webseite ein!"
        });
        return;
      }

      

      // Sending
      let formData = new FormData();
      
      if (newImage !== "") {
        formData.append('file', newImage);
      }
  
      formData.append('affiliate', JSON.stringify(affiliate)); // nur strings, kein JSON...
  
      const headerConfig = { headers: { 'content-type': 'multipart/form-data' } };
  
      // Übergeben der Daten als formData!
    
      AuthAxios.post('/affiliate', formData, headerConfig)
        .then(res => {
          //console.log(res.data);
          setAffiliate({
            ...res.data,
            _id: res.data._id, //Damit nicht erneut ein Affiliate erstellt wird, nur Update fahren wenn Id!
            createdAt: res.data.createdAt
          });

          setNewImage(null);
          setNewImageURL(null);
  
          setAlertSuccess("Affiliate erfolgreich gespeichert.");
        })
        .catch(err => {
          console.log(err);
          setAlertError("Affiliate konnte nicht gespeichert werden.");
        });
  
      setChanged(false);
      
    }



  


  
    return (
      
      <div style={{ marginLeft: "0px", marginTop: "40px", width: "100%" }}>

        <Row style={{ display: "flex", alignItems: "center",  margin: "0px"  }}>
          <Col xs={12} md={12} className="mainActions" style={{justifyContent: "space-between"}}>
            
            <Col xs={12} md={6}>
                <FontAwesomeIcon
                  icon={faPlus}
                  size="2x"
                  className="actionIcon"
                  onClick={newAffiliate}
                  title="Neuen Affiliate anlegen"
                />

          
                {changed && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={saveAffiliate}
                    title="Vorteilspartner speichern"
                  />
                }
                {!changed && activeTab == 1 &&
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2x"
                    className="actionIcon"
                    title="Keine Änderung erfolgt"
                  />
                }
            </Col>


            <Col xs={12} md={6}>
              {alertSuccess.length > 0 &&
                <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                  {alertSuccess}
                </Alert>
              }
            </Col>

            <Col xs={12} md={6}>
              {alertError.length > 0 &&
                  <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                    {alertError}
                  </Alert>
              }
            </Col>

            <div></div>

          </Col>
        </Row>

          <div className="mainWork">
            <Tabs
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
              //defaultActiveKey="neuerAffiliate"
              id="fill-tab-example"
              fill
              style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
            >  

              <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Neuer Affiliatepartner">
                <NeuerAffiliate
                  tab={1}
                  affiliate={affiliate}
                  setAffiliate={setAffiliate}
                  setChanged={setChanged}
                  newImage={newImageURL}
                  setNewImage={setNewImage}
                  handleLogo={handleLogo}
                />
         
              </Tab>

              <Tab style={{background: "white", position: "relative", paddingTop: "2px"}} eventKey="2" title="Alle Affiliates">
                <AffiliateListe tab={2} setActiveTab={setActiveTab} activeTab={activeTab} />
              </Tab>

            </Tabs>


          </div>
          
      </div>
    );
  }
  
  export default Affiliate;