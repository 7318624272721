import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';


import style from "./GroupModal.module.css"

import { Form, Button, Modal } from "react-bootstrap";


const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}

const ModalOverlay = (props) => {


    const handleInputChangeGroup = (e) => {
      props.setNewGroup({
        ...props.newGroup,
        [e.target.name]: e.target.value,
      });
    };

    return (
      <Modal show={props.visible} onHide={() => props.setVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize: "70%"}}>{props.title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <p>Regionen dienen dazu Mitarbeiter in den Rechten zentraler zu organisieren.</p>

            <Form.Label>Region ID</Form.Label>
            <Form.Control
              type="number"
              placeholder="Eindeutige Zahl vergeben"
              name="regionId"
              value={props.newGroup.regionId ? props.newGroup.regionId : ""}
              onChange={(e) => handleInputChangeGroup(e)}
            />

            <Form.Label>Region-Name</Form.Label>
            <Form.Control
              type="text"
              name="regionenname"
              placeholder="Name der Region"
              value={props.newGroup.regionenname ? props.newGroup.regionenname : ""}
              onChange={(e) => handleInputChangeGroup(e)}
            />

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Region-Beschreibung</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Region-Beschreibung"
                name="beschreibung"
                value={props.newGroup.beschreibung ? props.newGroup.beschreibung : ""}
                onChange={(e) => handleInputChangeGroup(e)}
              />
            </Form.Group>

        </Modal.Body>
        <Modal.Footer>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#df4759", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => props.setVisible(false)}
            >
              Schließen
            </p>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => {
                    props.setVisible(false)
                    props.createNewGroup(props.newGroup);
                }}
            >
              Speichern
            </p>
        </Modal.Footer>
      </Modal>
    )
}

const RegionModal = (props) => {

    return (
      <Fragment>
        {ReactDOM.createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {ReactDOM.createPortal(<ModalOverlay title={props.title} newGroup={props.newGroup} setNewGroup={props.setNewGroup} createNewGroup={props.createNewGroup} visible={props.visible} setVisible={props.setVisible} />, document.getElementById('overlay-root'))}
      </Fragment>
      
    )
}

export default RegionModal