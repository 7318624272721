import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Col, Row, Form } from 'react-bootstrap'


//import Länder from '../Data/LaenderListe';

import AuthAxios from "../utils/AuthAxios";
import Swal from 'sweetalert2'

function SelectExklusivmakler (props) {

    const [exklusivmakler, setExklusivmakler] = useState([]);

    useEffect(() => {
      
        async function getExklusivmakler () {
            try {
                const res = await AuthAxios.get('/exklusivmakler/select');

                const exklusivmaklerList = [];
                
                let aktiveExklusivmakler = res.data.filter((ma) => ma.loginGesperrt !== false);

                aktiveExklusivmakler.map(exkl => {
                    exklusivmaklerList.push({"value": exkl._id, "label": exkl.stadt+" | "+exkl.vorname+" "+exkl.nachname});
                });
                setExklusivmakler(exklusivmaklerList);
            }
            catch(err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Exklusivmakler konnten nicht geladen werden."
                });
            }
        }

        getExklusivmakler();
      
    }, []);


    const handleRemove = () => {
        props.setSelect("exklusivmakler", {});
    }


    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        // Object
        props.setSelect(name, {label: selection.label, value: selection.value});

        //nur ID..
        //props.setSelect(name, selection.value);
    };


    return (
        <Row>
            <Form.Label>Betreuender Standort/Exklusivpartner</Form.Label>
            <Col xs={12}>
                <ReactSelect
                  value={props.selectedValue ? exklusivmakler.filter((f) => f.value == props.selectedValue)  : ""}
                  onChange={(selection, e) => {
                    if (e.action === 'clear') {
                        handleRemove();
                    } else {
                        handleChange(e.name, selection);
                    }
                }}
                  placeholder={<div className="select-placeholder-text">Suche nach Exklusivmakler</div>}
                  options={exklusivmakler}
                  noOptionsMessage={() => "Suche nach Exklusivmakler um Optionen zu erhalten."}
                  filterOption={createFilter({ignoreAccents: false})}
                  name="exklusivmakler"
                  //onInputChange={handleInputChange}
                  isClearable={true}
                />
            </Col>
            {/*
            <Col xs={1} md={1}>
            {selected !== "" &&
                <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="1x"
                        title="Betreuer entfernen"
                    />
                </Button>
            }
            </Col>*/}
        </Row>
    );
      
}

export default SelectExklusivmakler