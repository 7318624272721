import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import AuthAxios from '../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faImage, faVideo, faFileLines, faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { COLORS } from '../../constants';

import style from './TemplateAnlegen.module.css'
import CardSub from '../Kunden/DateienBausteine/CardSub';
import SelectWhatsappLaender from '../../Bausteine/SelectWhatsappLaender';
import MessagePreview from './MessagePreview'

function TemplateAnlegen() {

    const colorSelected = "#e1e9f5";

    const [isLoading, setIsLoading] = useState(false);

    const [step, setStep] = useState(1);

    // 1. Auswahl
    const [templateType, setTemplateType] = useState("");
    const [templateTypeMarketing, setTemplateTypeMarketing] = useState("");

    const [templateName, setTemplateName] = useState("");
    const [sprachen, setSprachen] = useState([
        {
            value: "de",
            label: "Deutsch"
        },
        {
            value: "en_US",
            label: "Englisch US"
        }
    ]);




    // 2. Template erstellen
    const [languageSelected, setLanguageSelected] = useState("de");
    const [kopfzeileArt, setKopfzeileArt] = useState("-");

    const [mediaArt, setMediaArt] = useState("");
    const [kopfzeileText, setKopfzeileText] = useState("");


    const setSelectMulti = (name, value) => {
        let newVals = [];

        value?.map(val => {
          newVals.push(val.value)
        })
    
        setSprachen({
          ...sprachen,
          [name]: newVals
        });
    }

    const [text, setText] = useState("");
    const [fusszeile, setFusszeile] = useState("");


    return (


            <Container fluid className="p-1">

                <Row className="mt-3">
                    <Col xs={12} sm={8} md={8}>


                        <fieldset
                            className="border"
                            style={{ background: "white", borderRadius: "8px" }}
                        >
                            <legend
                                className="float-none w-auto p-2"
                                style={{
                                    color: COLORS.primCol,
                                    background: COLORS.primBG,
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                }}
                            >
                                Template erstellen
                            </legend>

                            <p>Lege hier ein Whatsapp Template an, um dies zu versenden.</p>


                            {/* ========== 1. Template Type ========== */}
                            {step == 1 &&
                              <>
                                <div
                                    className={style.waTemplate}
                                    style={{ background: templateType == "Marketing" ? colorSelected : "lightgrey"}}
                                    onClick={() => { setTemplateType("Marketing")}}
                                >
                                    <h5>Marketing</h5>
                                    <p>Promotions oder Informationen zu deinem Unternehmen, deinen Produkten oder deinen Services. Oder jegliche Nachrichten, die nicht in die Kategorien „Verwaltung“ oder „Authentifizierung“ fallen.</p>
                                    {templateType == "Marketing" &&
                                        <>
                                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                                <input
                                                    className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="radioDefault01" />
                                                <label
                                                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                                    for="radioDefault01"
                                                >
                                                    <span>Benutzerdefiniert</span><br />
                                                    <span style={{fontWeight: "lighter"}}>Sende Angebote, Ankündigungen und mehr, um die Bekanntheit und Interaktionen zu steigern.</span>
                                                </label>
                                            </div>

                                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                                <input
                                                    className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="radioDefault01" />
                                                <label
                                                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                                    for="radioDefault01"
                                                >
                                                    <span>Produktnachrichten</span><br />
                                                    <span style={{fontWeight: "lighter"}}>Sende Nachrichten zu deinem gesamten Katalog oder mehreren Produkten daraus.</span>
                                                    
                                                </label>
                                            </div>
                                        </>
                                    }   
                                </div>

                                <div
                                    className={style.waTemplate}
                                    style={{ background: templateType == "Verwaltung" ? colorSelected : "lightgrey"}}
                                    onClick={() => {setTemplateType("Verwaltung")}}
                                >
                                    <h5>Verwaltung</h5>
                                    <p>Nachrichten zu einer bestimmten Transaktion, Bestellung, Kundenanfrage oder einem bestimmten Konto.</p>
                                </div>

                                <div
                                    className={style.waTemplate}
                                    style={{ background: templateType == "Authentifizierung" ? colorSelected : "lightgrey"}}
                                    onClick={() => {setTemplateType("Authentifizierung")}}
                                >
                                    <h5>Authentifizierung</h5>
                                    <p>Einmalpasswörter, die deine Kunden verwenden, um eine Transaktion oder einen Login zu authentifizieren</p>
                                </div>

                                                    
                                <Form.Label>
                                    <span>Name (max 512 Zeichen)</span><br />
                                    <span style={{fontWeight: "lighter"}}>Benenne deine Nachrichtenvorlage</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name der Nachrichtenvorlage eingeben ..."
                                    name="templateName"
                                    maxLength={512}
                                    value={templateName ? templateName : "" }
                                    onChange={(e) => setTemplateName(e)}
                                />

        
                                <Form.Label>
                                    <span>Sprachen</span><br />
                                    <span style={{fontWeight: "lighter"}}>Wähle Sprachen für deine Nachrichtenvorlage aus. Du kannst später weitere Sprachen hinzufügen oder Sprachen löschen.</span>
                                </Form.Label>

                                <SelectWhatsappLaender
                                    placeholder={"Wähle eine oder mehrere Sprachen"}
                                    setSelectMulti={setSelectMulti}
                                    selectedValues={[]} // ? mitarbeiter.roles.sort((a, b) => a.label > b.label ? 1 : -1) : ""}
                                />

                                <div className="inline-flex justify-content-end w-100 mt-3">
                                    {/*
                                        <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l">
                                            Zurück
                                        </button>
                                    */}
                                    <button
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                                        onClick={() => {setStep(2)}}
                                    >
                                        Weiter
                                    </button>
                                </div>
                              </>
                            }


                            {/* ========== 2. Template Inhalte ========== */}

                            {step == 2 &&
                              <>
                                <Row>
                                    <Col xs={12} md={3}>
                                        <h6 className="mb-3">Sprachen</h6>
                                        {sprachen.map((s) => 
                                            <div
                                                key={s.value}
                                                style={{
                                                    cursor: 'pointer',
                                                    background: languageSelected == s.value ? colorSelected : "",
                                                    fontWeight: languageSelected == s.value ? "bold" : "regular",
                                                    padding: "8px",
                                                    borderRadius: "8px"
                                                }}
                                            >
                                                {s.label}
                                            </div>
                                        )}
                                    </Col>

                                    <Col xs={12} md={9}>
                                        <h6 className="mb-3">Vorlage bearbeiten</h6>
                                        <span style={{fontWeight: "bold", marginRight: "8px"}}>Kopfzeile</span>
                                        <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                            Optional
                                        </span>
                                        <p style={{fontWeight: "lighter"}}>Füge einen Titel hinzu oder wähle aus, welche Art von Medien du für diese Kopfzeile verwendest.</p>
                                        
                                        <Form.Select
                                            className="w-auto"
                                            aria-label="Default select"
                                            name="kopfzeileArt"
                                            value={kopfzeileArt ? kopfzeileArt : ""}
                                            onChange={(e) => setKopfzeileArt(e.target.value)}
                                        >
                                            <option value="-">Keine</option>
                                            <option value="Text">Text</option>
                                            <option value="Medien">Medien</option>
                                        </Form.Select>

                                        <div>
                                            {kopfzeileArt == "Text" &&
                                                <Form.Control
                                                  className="mt-2"
                                                  type="text"
                                                  name="kopfzeileText"
                                                  value={kopfzeileText}
                                                  placeholder='Text eingeben'
                                                  onChange={(e) => setKopfzeileText(e)}
                                                />
                                            }

                                            {kopfzeileArt == "Medien" &&
                                              <>
                                                <Form.Group controlId="mediaArt">
                                                    <Row className="flex justify-start">
                                                        <Col xs={6} sm={3} md={2}
                                                            className={[style.cardMedia, 'shadow-md cursor-pointer']}
                                                            onClick={() => setMediaArt("Bild")}
                                                        >
                                                            <Form.Check
                                                                value="Bild"
                                                                type="radio"
                                                                aria-label="radio 1"
                                                                label=""
                                                                onChange={(e) => setMediaArt(e.target.value)}
                                                                checked={mediaArt === "Bild"}
                                                            />
                                                            <FontAwesomeIcon
                                                                style={{width: "auto", margin: "0px 10px" }}
                                                                icon={faImage}
                                                                size="2x"
                                                                color="#0000ffaa"
                                                            />
                                                            <br />
                                                            <span>Bild</span>
                                                        </Col>

                                                        <Col xs={6} sm={3} md={2}
                                                            className={[style.cardMedia, 'shadow-md cursor-pointer']}
                                                            onClick={() => setMediaArt("Video")}
                                                        >
                                                            <Form.Check
                                                                value="Video"
                                                                type="radio"
                                                                aria-label="radio 1"
                                                                label=""
                                                                onChange={(e) => setMediaArt(e.target.value)}
                                                                checked={mediaArt === "Video"}
                                                            />
                                                            <FontAwesomeIcon
                                                                style={{width: "auto", margin: "0px 10px" }}
                                                                icon={faVideo}
                                                                size="2x"
                                                                color="#0000ffaa"
                                                            />
                                                            <br />
                                                            <span>Video</span>
                                                        </Col>

                                                

                                                        <Col xs={6} sm={3} md={2}
                                                            className={[style.cardMedia, 'shadow-md cursor-pointer']}
                                                            onClick={() => setMediaArt("Dokument")}
                                                        >
                                                            <Form.Check
                                                                value="Dokument"
                                                                type="radio"
                                                                aria-label="radio 1"
                                                                label=""
                                                                onChange={(e) => setMediaArt(e.target.value)}
                                                                checked={mediaArt === "Dokument"}
                                                            />
                                                            <FontAwesomeIcon
                                                                style={{width: "auto", margin: "0px 10px" }}
                                                                icon={faFileLines}
                                                                size="2x"
                                                                color="#0000ffaa"
                                                            />
                                                            <br />
                                                            <span>Dokument</span>
                                                        </Col>

                                                        <Col xs={6} sm={3} md={2}
                                                            className={[style.cardMedia, 'shadow-md cursor-pointer']}
                                                            onClick={() => setMediaArt("Standort")}
                                                        >
                                                            <Form.Check
                                                                value="Standort"
                                                                type="radio"
                                                                aria-label="radio 1"
                                                                label=""
                                                                onChange={(e) => setMediaArt(e.target.value)}
                                                                checked={mediaArt === "Standort"}
                                                            />
                                                            <FontAwesomeIcon
                                                                style={{width: "auto", margin: "0px 10px" }}
                                                                icon={faLocationDot}
                                                                size="2x"
                                                                color="#0000ffaa"
                                                            />
                                                            <br />
                                                            <span>Standort</span>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>


                                                {mediaArt != "Standort" &&
                                                    <>
                                                        <div
                                                            className="mt-3 rounded-lg p-3"
                                                            style={{ background: "lightgrey"}}
                                                        >
                                                            <h6>Beispiele für Kopfzeileninhalte</h6>
                                                            <p style={{fontWeight: "lighter"}}>
                                                                Damit wir deine Inhalte leichter überprüfen können, gib bitte in der Kopfzeile Beispiele für die Variablen oder Medien an. Gib keine Kundeninformationen an. Zur Sicherheit und Integrität unserer Services werden Vorlagen und variable Parameter von der von Meta gehosteten Cloud API überprüft.
                                                            </p>

                                                            <span>{mediaArt}</span>
                                                            {mediaArt=="Bild" &&
                                                              <>
                                                                {" - "}<span className="fw-bold">Optimale Größe: 1125px*600px</span>
                                                              </>
                                                            }
                                                            <br />
                                                            <Button className="cursor-pointer">
                                                                {mediaArt=="Bild" && "JPG- oder PNG-Datei auswählen"}
                                                                {mediaArt=="Video" && "MP4-Datei auswählen"}
                                                                {mediaArt=="Dokument" && "PDF-Datei auswählen"}
                                                            </Button>
                                                            <input type="file" style={{display: "none"}} id="media" name="mediaUpload" accept="image/png, image/jpeg video/mp4 application/pdf" />
                                                        </div>
                                                    </>
                                                }
                                              </>
                                            }  


                                           

                                           
                                        </div>




                                        <h6 className="mt-5">Text</h6>
                                        <p style={{fontWeight: "lighter"}}>Gib den Text für deine Nachricht in der von dir ausgewählten Sprache ein.</p>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control
                                                className="w-100"
                                                as="textarea"
                                                rows={5}
                                                placeholder="Text eingeben"
                                                name="text"
                                                value={text ? text : ""}
                                                onChange={(e) => setText(e.currentTarget.value)}
                                            />
                                            
                                            <span style={{fontWeight: "lighter", fontSize:"90%"}}>Zeichen: 0/1024</span>
                                        </Form.Group>

              

                                        <h6 className="mt-5">
                                            <span style={{fontWeight: "bold", marginRight: "8px"}}>Fußzeile</span>
                                            <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                                Optional
                                            </span>
                                        </h6>
                                        
                                        <p style={{fontWeight: "lighter"}}>
                                            Füge unten in deiner Nachrichtenvorlage eine kurze Textzeile hinzu. Wenn du den Button zum Abmelden von Marketing-Nachrichten hinzufügst, wird hier standardmäßig die entsprechende Fußzeile angezeigt.
                                            Fußzeile der Nachrichtenvorlage.
                                        </p>

                                        <Form.Control
                                            type="text"
                                            name="fusszeile"
                                            value={fusszeile}
                                            placeholder='Text eingeben'
                                            onChange={(e) => setFusszeile(e)}
                                        />
                                        <span style={{fontWeight: "lighter", fontSize:"90%"}}>Zeichen: 0/60</span>


                                        
                                        <h6 className="mt-5">
                                            <span style={{fontWeight: "bold", marginRight: "8px"}}>Buttons</span>
                                            <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                                Optional
                                            </span>
                                        </h6>
                                        
                                        <p style={{fontWeight: "lighter"}}>
                                            Erstelle Buttons, mit denen Kunden auf deine Nachricht reagieren oder Handlungen ausführen können.
                                        </p>

                                        <div
                                            style={{
                                                borderLeft: "4px solid #4dbba6",
                                                padding: "8px",
                                                borderRadius: "4px"
                                            }}
                                            className="shadow-md"
                                        >

                                        <div style={{ display: "flex", alignItems: "center"}} >
                                            <FontAwesomeIcon
                                                style={{ width: "auto", margin: "-24px 10px 0px 10px" }}
                                                icon={faLightbulb}
                                                size="sm"
                                                color="#4dbba6"
                                            />
                                            <span style={{
                                                display: 'block',
                                                marginLeft: '-32px',
                                                paddingLeft: '32px',
                                            }}>
                                                Wir empfehlen dir, den „Abmelden“-Button für Marketing-Nachrichten hinzuzufügen
                                            </span>
                                        </div>



                                                    <br />
                                                    <span style={{
                                                        display: 'block', 
                                                        fontWeight: "lighter",
                                                        fontSize: "95%",
                                                        marginLeft: "32px"
                                                    }}>
                                                        Biete deinen Kunden die Möglichkeit, sich von allen Marketing-Nachrichten abzumelden. Dies kann dazu beitragen, die Zahl der Blockierungen durch Kunden zu verringern und deine Qualitätsbewertung zu verbessern.
                                                        &nbsp;
                                                        <a href="https://www.facebook.com/business/help/448422200528701" target="_blank">Mehr dazu.</a>
                                                    </span>
                                        </div>

                                        <br />
                                        <p>+ Button hinzufügen (todo)</p>


                                        <div>
                                            <FontAwesomeIcon
                                                style={{width: "auto", marginRight: "5px" }}
                                                icon={faLightbulb}
                                                size="sm"
                                            />
                                            <span style={{fontWeight: "lighter"}}>
                                                Wenn du mehr als 3 Buttons hinzufügst, werden sie in einer Liste angezeigt.
                                            </span>
                                        </div>
                                        

                                    </Col>
                                </Row>


                                <div className="inline-flex justify-content-end w-100 mt-3">
                                    <button
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                                        onClick={() => {setStep(1)}}
                                    >
                                        Zurück
                                    </button>
                                    <button
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                                        onClick={() => {setStep(3)}}
                                    >
                                        Weiter
                                    </button>
                                </div>
                              </>
                            }



                          





                        </fieldset>
                    </Col>

                    <Col xs={12} sm={12} md={3}>
                        <h6 className="mb-3 text-center">Ungefähre Vorschau Whatsapp Nachricht</h6>
                        <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[326px]">
                            <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
                            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                            <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                            <div className="rounded-[2rem] overflow-hidden w-[300px] h-[572px] bg-white dark:bg-gray-800">
                                {/*
                                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-light.png" className="dark:hidden w-[272px] h-[572px]" alt="" />
                                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-dark.png" class="hidden dark:block w-[272px] h-[572px]" alt="" />
                                */}

                                <div
                                    className="bg-[#0a131a] bg-[url('pages/Whatsapp/whatsappbg.webp')] bg-contain h-100"
                                    style={{padding: "12px 3%"}}
                                >
                                    <MessagePreview
                                        msg={text}
                                        time={ new Intl.DateTimeFormat('de-DE', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                          }).format(new Date())}
                                        isLink={false}
                                        img={'/logo512.png'}
                                        sent={true}
                                        BtnCTA={"Jetzt ansehen"} // max 25 Zeichen
                                        BtnPhone={"Jetzt anrufen"}
                                        BtnSchnellantwort={"Dies ist ein Test ansehen"}
                                    />
                                
                                </div>

                            
                            
                            
                            </div>
                        </div>

                    </Col>


                </Row>


            </Container>
    )
}

export default TemplateAnlegen




