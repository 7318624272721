import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'

import AuthAxios from "../utils/AuthAxios";
import Swal from "sweetalert2";

function SelectPLZ (props) {

    const [selectedValues, setSelectedValues] = useState([]);

    const [plz, setPlz] = useState([]);

    /*
    const handleValue = (selected) => {
        selected.map(s => {
            console.log({value: s, label: s});    
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));       
         })
    }
    */

    // Zu viele Werte! Hier nur in DB suchen!
    /*
    useEffect(() => {

        async function getPLZ () {
        
            try {
                const res = await AuthAxios.get('/plz');

                const plzListe = [];
                res.data.map(data => {
                    plzListe.push({"value": data.plz, "label": data.plz+" "+data.stadt});
                });
                setPlz(plzListe);
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "PLZ konnten nicht geladen werden."
                });
            }               
        }
        getPLZ();  
        

    },[]);
    */


    // Wenn Exklusivmakler aus Liste geladen wird, Select-Werte wiederherstellen
    useEffect(() => {

        //setSelectedValues(plz.filter(plz => props.selectedValues.includes(plz.value)) );

        if(props.selectedValues.length > 0) {
            let preSelect = [];
            props.selectedValues.map(sel => {
                preSelect.push({
                    value: sel,
                    label: sel.plz+" "+sel.stadt
                })
            })
            setSelectedValues(preSelect);
        }
        else {
            setSelectedValues([]);
        }
    },[props.selectedValues]);


    const searchDB = (searchVal) => {

        if(searchVal.length >= 3) {
            AuthAxios.get('/plz/'+searchVal)
            .then(res => {

                const plzListe = [];
                res.data.map(data => {
                    if(data.exklusivmakler == undefined) {
                        plzListe.push({"value": { id: data._id, "plz": data.plz, "stadt": data.stadt }, "label": data.plz+" "+data.stadt});
                    } else {
                        plzListe.push({"value": { id: data._id, "plz": data.plz, "stadt": data.stadt }, "label": data.plz+" "+data.stadt+" | belegt: "+data.exklusivmakler.name, isDisabled: true});
                    }
                });

                setPlz([...plzListe, ...selectedValues]);
            })
            .catch (err => {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "PLZ konnten nicht geladen werden."
                });
            });     
        }                  
    }
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        props.setSelectMulti(name, selection);
    };

    /*
    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex" }}>
          <div>{label}</div>
        </div>
    );
    */

    return (
        <Col xs={12} md={12}>
            <Form.Label>Wähle eine oder mehrere PLZ. Gib zur Suche min. 3 Zeichen ein (Stadt oder PLZ)</Form.Label>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedValues ? selectedValues : ""}
                //formatOptionLabel={formatOptionLabel} // hat nur relevanz wenn value nicht verwendet wird
                onChange={(selection, e) => handleChange(e.name, selection)}
                onInputChange={inputVal => searchDB(inputVal)}
                options={plz}
                noOptionsMessage={() => "Keine PLZ gefunden"}
                placeholder={<div className="select-placeholder-text">Wähle eine oder mehrere PLZ</div>}
                name="plzGebiete"
            />
            {/*
            <ul>
                {selectedValues.sort((a, b) => a.label > b.label ? 1 : -1).map(sel =>       
                    <li key={sel.value._id}>{sel.value.plz} {sel.value.stadt}</li>          
                )}
            </ul>*/}
        </Col>
    );
   
    
}

export default SelectPLZ