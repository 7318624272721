import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import SelectBetreuer from "../../Bausteine/SelectBetreuer";
import SelectKundeObj from "../../Bausteine/SelectKundeObj";
import SelectLand from "../../Bausteine/SelectLand";

import { COLORS } from '../../constants';

//import KategorieData from './KategorieData';

//import { Editor } from "react-draft-wysiwyg";
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import GoogleMapsKarte from "../../Bausteine/GoogleMapsKarte";
import { gridColumnGroupsLookupSelector } from "@mui/x-data-grid";

import { formatDateTime } from "../../utils/date";


function NeuerVorteilspartner(props) {


  const location = useLocation();

  const [zoom] = useState(15); // initial zoom

  const [changeUstId, setChangeUstId] = useState(false); // initial zoom

  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!    
    props.setChanged(true);
    props.setVorteil({
      ...props.vorteil,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);
    props.setVorteil({
      ...props.vorteil,
      [name]: value
    });
  }

  // React Single Select Zwueisung
  const setSelectKunde = (name, kundeObj) => {

    props.setKundeChanged(true);
    //console.log(kundeObj)
    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);
    props.setVorteil({
      ...props.vorteil,
      [name]: kundeObj
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    props.setChanged(true);
    let newVals = [];

    value?.map(val => {
    newVals.push(val.value)
    })

    props.setVorteil({
    ...props.vorteil,
    [name]: newVals
    });

  }

  const resetKoordinaten = (event) => {

    const value = event.target.value;

    props.setVorteil({
      ...props.vorteil,
      [event.target.name]: value,
      ["koordinaten"]: {
        type: "Point",
        coordinates: []
      },
    });
  }

  const handleInputChange = (event) => {

     // Wenn das erste mal verändert, Speicherdiskette einblenden!!
     props.setChanged(true);

      // Reset Koordinaten, wenn Adresse verändert wird!
      if(event.target.name == "anschrift" || event.target.name == "plz" || event.target.name == "stadt" || event.target.name == "land")
      {
        resetKoordinaten(event);
      }
      else
      {
        const value = event.target.value;
        props.setVorteil({
          ...props.vorteil,
          [event.target.name]: value
        });
      }

  }

  const handleInputChangeTrim = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);
    const value = event.target.value;
    props.setVorteil({
      ...props.vorteil,
      [event.target.name]: value.trim()
    });
  }


  const handleInputChangeIBAN = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);

    const value = event.target.value;
    props.setVorteil({
      ...props.vorteil,
      [event.target.name]: value.trim(),
      bic: null,
      bank: null,
      bankValid: false,
    });
     
  }

  const handleInputChangeUSTID = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);

    const value = event.target.value;
    props.setVorteil({
      ...props.vorteil,
      [event.target.name]: value,
      ustIdValid: false,
      ustIdGeprueftAm: null
    });
  
  }


  const setStatus = (status) => {
    props.setChanged(true);
    props.setVorteil({
        ...props.vorteil,
        ["status"]: status
    });
  }


  // ===== Clear File Picker Logo "Value" =====
   const [fileKey, setFileKey] = useState(Date.now());
   // Function to clear the file input
   const clearFileInput = () => {
       setFileKey(Date.now());
       if (props.handleLogoClear) {
           props.handleLogoClear();
       }
   };
   useEffect(() => {
    if(!props.newImage) {
      clearFileInput();
    }
   }, [props.newImage])


   // ==== Reset UST-ID Editing ===== 
   useEffect(() => {
    if(props.vorteil.ustIdValid) {
      setChangeUstId(false);
    }
   },[props.vorteil.ustIdValid])



  return (
    <Container fluid >
      <Row>
        <Col xs={12} md={6} lg={3}>

        <fieldset
          className="border"
          style={{ background: "white", borderRadius: "8px" }}
        >
            <legend
                className="float-none w-auto p-2"
                style={{
                    color: COLORS.primCol,
                    background: COLORS.primBG,
                    borderRadius: "8px",
                    fontSize: "12px",
                }}
                >
                Art
            </legend>

            {props.vorteil.kunde && !props.kundeChanged &&
              <Col>
                <div className="mb-3">
                  <h6 className="mb-0">E-Mail</h6>
                  <a href={'tel:'+props.vorteil.email} style={{color: "#c58164"}}>{props.vorteil.email}</a>
                </div>

                <h6 className="mb-0">Telefon</h6>
                <a href={'tel:'+props.vorteil.telefon} style={{color: "#c58164"}}>{props.vorteil.telefon}</a>

                <hr />
              </Col>
            }

            <h6 className="mb-2">Freischaltungen</h6>
            <Form.Check
              type="checkbox"
              id="appStatus"
              label="In App sichtbar"
              name="appStatus"
              checked={props.vorteil.appStatus ? true : false}
              onChange={(e) => setCBs(e)}
            />

            <Form.Check
              type="checkbox"
              id="landingpageStatus"
              label="Landingpage aktiv"
              name="landingpageStatus"
              checked={props.vorteil.landingpageStatus ? true : false}
              onChange={(e) => setCBs(e)}
            />

            <hr />
            
            {/*props.vorteil.status === "veröffentlicht" &&
              <Button onClick={() => setStatus("privat")} style={{width: "100%"}} className="btn-danger">
                  Privat schalten
              </Button>
            
            props.vorteil.status === "privat" || props.vorteil.status === undefined) &&
              <Button onClick={() => setStatus("veröffentlicht")} style={{width: "100%", background: COLORS.primCol}}>
                  Veröffentlichen
              </Button>
            */}

            <Form.Label>Vorteilspartner Nummer</Form.Label>
            <Form.Control
              className="readOnly"
              type="text"
              name="vorteilId"
              value={props.vorteil.vorteilId ? props.vorteil.vorteilId : "-wird beim Speichern vergeben-" }
              onChange={()=>{}}
           />

            <Form.Label>Eintragsdatum</Form.Label>
            <Form.Control
                className="readOnly"
                type="text"
                name="createdAt"
                value={props.vorteil.createdAt ? new Date(props.vorteil.createdAt).toLocaleString("de-DE",{
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }) : "-wird beim Speichern vergeben-"}
                onChange={()=>{}}
            />

            <Form.Label>Vorteilspartner Name</Form.Label>
            <Form.Control
                type="text"
                name="name"
                placeholder={"Name des Partners"}
                value={props.vorteil.name ? props.vorteil.name : "" }
                onChange={(e) => handleInputChange(e)}
            />

            <SelectKundeObj
                setSelect={setSelectKunde}
                selectedValue={props.vorteil.kunde}
            />


            <SelectBetreuer
               setSelect={setSelect}
               selectedValue={props.vorteil.mitarbeiter ? props.vorteil.mitarbeiter.value : ""}
            />


            {props.vorteil.branche &&
              <>
                <p>Branche (von Partner übermittelt):</p>
                <p>
                  {props.vorteil.branche ?
                    <b>{props.vorteil.branche}</b>
                    :
                    <i>- keine Angabe -</i>
                  }
                </p>
              </>
            }
  
            <Form.Label>Kategorie</Form.Label>
            <Form.Select
                aria-label="Default select"
                name="kategorie"
                value={props.vorteil.kategorie ? props.vorteil.kategorie : "In Kürze verfügbar"}
                onChange={(e) => handleInputChange(e)}
            > 
                <option value="In Kürze verfügbar">In Kürze verfügbar</option>
                <option value="Beauty">Beauty</option>
                <option value="Bildung">Bildung</option>
                <option value="Body & Soul">Body & Soul</option>
                <option value="Coaching">Coaching</option>
                <option value="Ernährung ">Ernährung</option>
                <option value="Fashion">Fashion</option>
                <option value="Freizeit">Freizeit</option>
                <option value="Gastronomie">Gastronomie</option>
                <option value="Gesundheit">Gesundheit</option>
                <option value="Haushalt">Haushalt</option>
                <option value="Marketing">Marketing</option>
                <option value="Mobilität">Mobilität</option>
                <option value="Reisen">Reisen</option>
                <option value="Spirituosen">Spirituosen</option>
                <option value="Wohnen">Wohnen</option>
                <option value="Andere">Andere</option>
               
            </Form.Select>

            {/* // optionen rendern, damit diese gleich dem Filter sind... => Zentrale einpflege
              {KategorieData?.map((data) => {
                return (
                  <option value={data.value} key={data.i}>
                    {data.option}
                  </option>
                )
              })}
            */}


            <br />
            <p style={{margin: "0px"}}>Wo kann der Vorteil eingelöst werden?</p>
            <Form.Check
                type="checkbox"
                id="online"
                label="Online (Webshop)"
                name="online"
                checked={props.vorteil.online ? true : false}
                onChange={(e) => setCBs(e)}
            />  

            <Form.Check
                type="checkbox"
                id="offline"
                label="Offline (vor Ort per QR-Scan)"
                name="offline"
                checked={props.vorteil.offline ? true : false}
                onChange={(e) => setCBs(e)}
            />

            <br />
            <p style={{margin: "0px"}}>Ist der Partner ein "Premiumpartner"?</p>
            <Form.Check
                type="checkbox"
                id="premiumpartner"
                label="Premiumpartner"
                name="premiumpartner"
                checked={props.vorteil.premiumpartner ? true : false}
                onChange={(e) => setCBs(e)}
            />  
          
          </fieldset>
        </Col>

        <Col xs={12} md={6} lg={3}>

            <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                    >
                    Vorteile
                </legend>


                {((!props.vorteil.online && !props.vorteil.offline) || (props.vorteil.online === undefined && !props.vorteil.offline) || (!props.vorteil.online && props.vorteil.offline === undefined)) &&
                    <p><i>Wähle eine Vorteilspartner Art (online/offline) um weitere Details auszufüllen!</i></p>
                }

                
                {props.vorteil.online &&
                    <>
                      <h5>Details (Online-Partner)</h5>

                      <Form.Label>Vorteilscode</Form.Label>
                      <Form.Control
                          type="text"
                          name="vorteilscode"
                          placeholder={"Vorteilscode"}
                          value={props.vorteil.vorteilscode ? props.vorteil.vorteilscode : "" }
                          onChange={(e) => handleInputChange(e)}
                      />

                      <Form.Label>Welchen Vorteil erhält der Kunde?< br/>(z.B. 10% auf alle Artikel)</Form.Label>
                      <Form.Control
                          type="text"
                          name="vorteilOnline"
                          placeholder={"Kurzbeschr. des Vorteils"}
                          value={props.vorteil.vorteilOnline ? props.vorteil.vorteilOnline : "" }
                          onChange={(e) => handleInputChange(e)}
                      />

                      {/*

                        
                      <Form.Label htmlFor="webseite">Webseite/Shop-Link: example.de</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                          https://
                        </InputGroup.Text>
                        <Form.Control
                          type="url"
                          name="webseite"
                          placeholder={"Webseite"}
                          value={props.vorteil.webseite ? props.vorteil.webseite : "" }
                          onChange={(e) => handleInputChangeTrim(e)}
                      />
                      </InputGroup>  
                     


                      */}

                      <Form.Label>Webseite/Shop-Link - https://example.de</Form.Label>
                      <Form.Control
                          type="url"
                          name="webseite"
                          placeholder={"Webseite"}
                          value={props.vorteil.webseite ? props.vorteil.webseite : "" }
                          onChange={(e) => handleInputChangeTrim(e)}
                      />
                    </>
                }
        

                {props.vorteil.online && props.vorteil.offline && <hr className="mt-4 mb-3" />}
                
                
                {props.vorteil.offline &&
                    <>
                        <h5>Adresse (Offline-Partner)</h5>

                      <Form.Label>vor Ort Vorteil (Kurzbeschreibung)</Form.Label>
                      <Form.Control
                          type="text"
                          name="vorteilOffline"
                          placeholder={"vor Ort Vorteil (Kurzbeschreibung)"}
                          value={props.vorteil.vorteilOffline ? props.vorteil.vorteilOffline : "" }
                          onChange={(e) => handleInputChange(e)}
                      />

                        <Form.Label>Anschrift</Form.Label>
                        <Form.Control
                            type="text"
                            name="anschrift"
                            placeholder={"Strasse + Hausnr."}
                            value={props.vorteil.anschrift ? props.vorteil.anschrift : "" }
                            onChange={(e) => handleInputChange(e)}
                        />

                        <Form.Label>PLZ</Form.Label>
                        <Form.Control
                            type="number"
                            name="plz"
                            placeholder={"PLZ"}
                            value={props.vorteil.plz ? props.vorteil.plz : "" }
                            onChange={(e) => handleInputChange(e)}
                        />

                        <Form.Label>Stadt</Form.Label>
                        <Form.Control
                            type="text"
                            name="stadt"
                            placeholder={"Stadt"}
                            value={props.vorteil.stadt ? props.vorteil.stadt : "" }
                            onChange={(e) => handleInputChange(e)}
                        />

                        <Form.Label>Land</Form.Label>
                        <SelectLand
                          setSelect={setSelect}
                          selectedValue={props.vorteil.land}
                        />

                        {props.vorteil.koordinaten?.coordinates.length != 2 &&
                          <Button className="mt-3" style={{width: "100%"}} variant="danger" onClick={props.getKoordinatenFromAdress}>
                            Koordinaten ermitteln
                          </Button>
                        }
                        {props.vorteil.koordinaten?.coordinates.length == 2 &&
                          <p className="mt-3" style={{fontSize: "90%"}}><i>Koordinaten sind aktuell</i></p>
                        }
                    </>
                }
            
            
            </fieldset>
          </Col>



          <Col xs={12} md={6} lg={3}>

            <fieldset
              className="border"
              style={{ background: "white", borderRadius: "8px" }}
            >
              <legend
                  className="float-none w-auto p-2"
                  style={{
                      color: COLORS.primCol,
                      background: COLORS.primBG,
                      borderRadius: "8px",
                      fontSize: "12px",
                  }}
                  >
                  Medien App & Landingpage
              </legend>


                {/* ==================== LOGO ==================== */} 

                {(props.vorteil.online || props.vorteil.offline) && !props.vorteil.image &&
                          <Form.Group className="mb-3">
                            <Form.Label>Neues Firmenlogo (.png .jpg)</Form.Label>
                            <Form.Control
                              key={fileKey}
                              id="image"
                              name="image"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => props.handleLogo(e.target.files)}
                            />
                          </Form.Group>
                        }
                        {props.vorteil.partnerLogo150 &&
                          <div className="text-18">
                            Durch Auswählen eines neuen Logos (und speichern) kann das bestehende Logo ersetzt werden.
                          </div>
                        }


                        {(props.vorteil.online || props.vorteil.offline) && props.newImage &&
                          <Row className="mb-3">
                            <Col xs={12}>
                              <p><span className="fw-bold">Vorschau Logobild</span> (grauer Hintergr. = transparent)</p>
                              <img
                                style={{
                                  height: "150px",
                                  maxHeight: "200px",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                  aspectRatio: "1",
                                  border: "1px solid black",
                                  background: 'rgba(150, 150, 150, 0.8)',
                                }}
                                src={props.newImage}
                              />
                            </Col>

                            <Col xs={12} className="mt-3">
                              <Button
                                variant={'danger'}
                                onClick={() => props.handleLogo(null)} 
                              >
                                Neues Bild verwerfen
                              </Button>
                            </Col>

                          </Row>
                        }

                        {(props.vorteil.online || props.vorteil.offline) && props.vorteil.partnerLogo150 &&
                          <Row>
                          
                            <Col xs={12}>
                              <p className="fw-bold">Aktuelles Logo</p>
                              <img
                                style={{
                                  width: "150px",
                                  height: "auto",
                                  border: "1px solid black",
                                  //background: 'lightgray',
                                }}
                                src={props.vorteil.partnerLogo150}
                              />
                            </Col>

                            {/* Logo löschen?
                            <Col xs={12} className="mt-3">
                              <Button
                                variant={'danger'}
                                onClick={() => props.handleInputChange('partnerLogo', null)}
                              >
                                Bild entfernen
                              </Button>
                            </Col>
                            */}

                          </Row>
                        }

                  <hr />
      
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Partner-Beschreibung für Landingpage</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={8}
                      placeholder="Beschreibung Landingpage"
                      name="beschreibung"
                      value={props.vorteil.beschreibung ? props.vorteil.beschreibung : ""}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </Form.Group>

                  {/*
                    <Form.Label>Beschreibung Landingpage Vorteil</Form.Label>
                    <Form.Control
                        type="text"
                        name="vorteilLandingpage"
                        placeholder={"Vorteilssatz Landingpage"}
                        maxLength={34}
                        value={props.vorteil.vorteilLandingpage ? props.vorteil.vorteilLandingpage : "" }
                        onChange={(e) => handleInputChange(e)}
                    />
                  */}
                  
              
            </fieldset>
          </Col>


        
          <Col xs={12} md={6} lg={3}>

            
         


            <fieldset
              className="border"
              style={{ background: "white", borderRadius: "8px" }}
            >
              <legend
                  className="float-none w-auto p-2"
                  style={{
                    color: COLORS.primCol,
                    background: COLORS.primBG,
                    borderRadius: "8px",
                    fontSize: "12px",
                  }}
                >
                Bankverbindung für Prämien
              </legend>

                <Form.Label>IBAN</Form.Label>
                <Form.Control
                    type="text"
                    name="iban"
                    placeholder={"IBAN"}
                    maxLength={34}
                    value={props.vorteil.iban ? props.vorteil.iban : "" }
                    onChange={(e) => handleInputChangeIBAN(e)}
                />

                <Form.Label>BIC</Form.Label>
                <Form.Control
                    className="readOnly"
                    type="text"
                    name="bic"
                    placeholder={"BIC"}
                    maxLength={11}
                    value={props.vorteil.bic ? props.vorteil.bic : "-autom. nach Prüf.- IBAN notw.-"}
                    onChange={()=>{}}
                />

                <Form.Label>Name der Bank</Form.Label>
                <Form.Control
                  className="readOnly"
                  type="text"
                  name="bank"
                  value={props.vorteil.bank ? props.vorteil.bank : "-autom. nach Prüf.- IBAN notw.-"}
                  onChange={()=>{}}
                />


                {!props.vorteil.bankValid &&
                  <button
                    type="button"
                    className={`mt-3 w-100 justify-content-center bg-indigo-500 flex items-center rounded-lg px-4 py-2 text-white ${props.bankProcessing ? "opacity-50" : "opacity-100"}`}
                    onClick={() => props.validateBank(props.vorteil.iban)}
                    disabled={props.bankProcessing ? true : false}
                  >
                    {props.bankProcessing ?
                      <>
                        <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span className="font-medium"> Wird geprüft... </span>
                      </>
                      :
                      <>
                         Bankverbindung prüfen
                      </>
                    }
                  </button>
                }
                {props.vorteil.bankValid  &&
                  <p className="mt-3 fw-bold" style={{fontSize: "90%", color: "green"}}><i>Bankverbindung geprüft</i></p>
                }


                <Form.Label>USt. ID</Form.Label>
                <Form.Control
                    className={(props.vorteil.ustIdGeprueftAm && !changeUstId) ? "readOnly" : ""}
                    type="text"
                    name="ustId"
                    placeholder={"Umsatzsteuer-ID"}
                    maxLength={11}
                    value={props.vorteil.ustId ? props.vorteil.ustId : "" }
                    onChange={(e) => {
                        if(!props.vorteil.ustIdGeprueftAm) {
                          handleInputChange(e);
                        }
                        else if (changeUstId) {
                          handleInputChangeUSTID(e);
                        }   
                    }}
                />


              {!props.vorteil.ustIdValid &&
                <button
                  type="button"
                  className={`mt-3 w-100 justify-content-center bg-indigo-500 flex items-center rounded-lg px-4 py-2 text-white ${props.ustidProcessing ? "opacity-50" : "opacity-100"}`}
                  onClick={() => props.validateUstId(props.vorteil.ustId)}
                  disabled={props.ustidProcessing ? true : false}
                >
                  {props.ustidProcessing ?
                    <>
                      <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span className="font-medium"> Wird geprüft... </span>
                    </>
                    :
                    <>
                      USt.-ID prüfen (MIAS)
                    </>
                  }
                </button>
              }

                {/* Quelle Verfügbarkeitszeiten: https://ec.europa.eu/taxation_customs/vies/#/help */}
                <span className="text-xs">
                  Von 23:00-01:30 Uhr steht für DE der Dienst nicht zur Verfügung!
                </span>


                {props.vorteil.ustIdValid &&
                  <>
                    <p className="mt-3" style={{fontSize: "90%"}}><i>USt.-ID geprüft, am: {formatDateTime(props.vorteil.ustIdGeprueftAm)}</i></p>
                    {!changeUstId &&
                      <Button className="" variant="primary" onClick={() => setChangeUstId(true)}>
                        USt.-ID ändern
                      </Button>
                    }
                    {changeUstId &&
                      <Button className="" variant="primary" onClick={() => setChangeUstId(false)}>
                        USt.-ID Änderungen verwerfen
                      </Button>
                    }
                  </>
                }

              </fieldset>




              <fieldset
                className="border"
                style={{ background: "white", borderRadius: "8px" }}
              >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                      color: COLORS.primCol,
                      background: COLORS.primBG,
                      borderRadius: "8px",
                      fontSize: "12px",
                    }}
                  >
                  Offene Zahlungen
                </legend>

                {props.offeneZahlungen.length  == 0 &&
                  <p className="font-medium italic text-green-600">Keine offenen Zahlungen.</p>
                }

                {props.offeneZahlungen && 
                  <ul style={{paddingLeft: "0px"}}>
                    {props.offeneZahlungen.map((z, index) => 
                      <li key={z._id}>
                        
                        {index !== 0 &&
                         <hr />
                        }
                        <span
                          style={{fontSize: "90%"}}
                        >
                          Datum: {formatDateTime(z.createdAt) }<br />
                          Summe: <b>{z.gesamtsumme ? z.gesamtsumme/100+"€" : "invalid"}</b>
                        </span>
                      </li>
                    )}
                  </ul>
                }

                </fieldset>




                <fieldset
                  className="border"
                  style={{ background: "white", borderRadius: "8px" }}
                >
                  <legend
                      className="float-none w-auto p-2"
                      style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                      }}
                    >
                    Vertrag
                  </legend>

                  {!props.vorteil.vertrag?.fileUrl &&
                    <p className="italic text-gray-600">Kein Vertrag hinterlegt.</p>
                  }

                  {props.vorteil.vertrag?.fileUrl &&
                  
                    <>
                      <p>
                        Status: {" "}
                        {props.vorteil.vertrag?.status == "signed" && <span className="font-medium italic text-orange-400">Unterschrift VIN1 erforderlich</span>}
                        {props.vorteil.vertrag?.status == "declined" && <span className="font-medium italic text-red-800">Vertrag abgelehnt</span>}
                        {props.vorteil.vertrag?.status == "accepted" && <span className="font-medium italic text-yellow-600">Warte auf Zahlung</span>}
                        {props.vorteil.vertrag?.status == "payed" && <span className="font-medium italic text-green-600">Alles erledigt & bezahlt</span>}
                      </p>

                      {props.vorteil.vertrag && ["payed", "accepted"].includes(props.vorteil.vertrag.status) &&
                        <button
                          type="button"
                          className={`mt-3 w-100 justify-content-center bg-indigo-500 flex items-center rounded-lg px-4 py-2 text-white ${props.bankProcessing ? "opacity-50" : "opacity-100"}`}
                          onClick={() => window.open(props.vorteil.vertrag.fileUrl)}
                        >
                          Vertrag Download PDF
                        </button>
                      }

                      </>
                    }

                  </fieldset>










                <fieldset
              className="border"
              style={{ background: "white", borderRadius: "8px" }}
            >
              <legend
                  className="float-none w-auto p-2"
                  style={{
                    color: COLORS.primCol,
                    background: COLORS.primBG,
                    borderRadius: "8px",
                    fontSize: "12px",
                  }}
                >
                Kundenumfrage
              </legend>

              <Form.Label>Nutzung externes CRM</Form.Label>
              <Form.Control
                  className="readOnly"
                  type="text"
                  name="verwendetCRM"
                  placeholder={"Externes CRM?"}
                  value={
                    props.vorteil.verwendetCRM == null && "nicht angegeben" ||
                    props.vorteil.verwendetCRM === true && "Ja" ||
                    props.vorteil.verwendetCRM === false && "Nein"
                  }
                  onChange={()=>{}}
              />

              <Form.Label>Jahresumsatz</Form.Label>
              <Form.Control
                  className="readOnly"
                  type="text"
                  name="jahresumsatz"
                  placeholder={"Jahresumsatz"}
                  value={
                    props.vorteil.jahresumsatz == null && "nicht angegeben" ||
                    props.vorteil.jahresumsatz
                  }
                  onChange={()=>{}}
              />

              <Form.Label>Anzahl Mitarbeiter</Form.Label>
              <Form.Control
                  className="readOnly"
                  type="text"
                  name="anzahlMitarbeiter"
                  placeholder={"Anzahl Mitarbeiter"}
                  value={
                    props.vorteil.anzahlMitarbeiter == null && "nicht angegeben" ||
                    props.vorteil.anzahlMitarbeiter
                  }
                  onChange={()=>{}}
              />

              
              <Form.Label>Anzahl Kunden</Form.Label>
              <Form.Control
                  className="readOnly"
                  type="text"
                  name="anzahlKunden"
                  placeholder={"Anzahl Kunden"}
                  value={
                    props.vorteil.anzahlKunden == null && "nicht angegeben" ||
                    props.vorteil.anzahlKunden
                  }
                  onChange={()=>{}}
              />



            </fieldset>

              

        </Col>
        

      </Row>






      {props.vorteil.offline &&
        <Row className="m-3 pb-5" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <GoogleMapsKarte zoom={zoom} center={props.mapCenter} markerPartner={props.markerPartner} />
        </Row>
      }





      {/*
      <Row>

      
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />;
        
        <h4>Vielleicht Content später mit diesem Editor bearbeiten..</h4>
  <Editor
        apiKey='syb4ulosbsi7b7712jpcn95nng2ls7jp3k3v3q4lq7atum7k'
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue=""
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      <button onClick={log}>Log editor content</button>
      </Row>
      */}
    </Container>
  );
}

export default NeuerVorteilspartner