import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';

import style from "./QRViewModal.module.css"

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}

const ModalOverlay = (props) => {

    const [neueBeschreibung, setNeueBeschreibung] = useState(props.notiz.notiz);

    return (
      <Modal show={props.visible} onHide={() => props.visible(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize: "70%"}}>{props.title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/*<img id="myimg" src={props.img} style={{width: "800px", height: "800px"}} />*/}
            {/*<img
              src={props.imgSrc}
              style={{
                width:"100%",
                height: "100%"
              }} />*/}
             <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Notiz</Form.Label>
                <Form.Control as="textarea" rows={4}
                    placeholder="Platz für deine Notizen"
                    name="notiz"
                    value={neueBeschreibung}
                    onChange={(e) => {
                        props.setChanged(true);
                        setNeueBeschreibung(e.target.value);
                    }}
                />
            </Form.Group>

        </Modal.Body>
        <Modal.Footer>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#df4759", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => props.visible(false)}
            >
                Schließen
            </p>
            <p style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => {
                    props.visible(false);
                    props.newNotizContent(neueBeschreibung);
                }}
            >
                Übernehmen
            </p>
        </Modal.Footer>
      </Modal>
    )
}

const NotizenEditModal = (props) => {

    return (
      <Fragment>
        {ReactDOM.createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {ReactDOM.createPortal(<ModalOverlay title={props.title} notiz={props.notiz} newNotizContent={props.newNotizContent} setChanged={props.setChanged} visible={props.visible} />, document.getElementById('overlay-root'))}
      </Fragment>
      
    )
}

export default NotizenEditModal