
import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import "./Kategorien.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs, Alert, Row, Col } from 'react-bootstrap';

import MitarbeiterEdit from './MitarbeiterEdit';
import MitarbeiterListe from './MitarbeiterListe';
import Gruppenverwaltung from './Gruppenverwaltung';

import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";
import Rechteverwaltung from './Rechteverwaltung';
import Verwaltung from './Verwaltung';



const Mitarbeiter = () => {

  const [mitarbeiter, setMitarbeiter] = useState([]);
  const [selectedMitarbeiter, setSelectedMitarbeiter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);




  
  useEffect(() => {
    getMitarbeiter();
  },[]);

  useEffect(() => {
    getMitarbeiter();
  },[activeTab]);


  async function getMitarbeiter() {
    AuthAxios.get('/user')
    .then(res => {
      setMitarbeiter(res.data);
      setLoading(false);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: "Fehler",
        html: "Mitarbeiter konnten nicht geladen werden."
      });
    });
  }



  // Update DataGrid mit neuem Mitarbeiter
  const newMitarbeiter = (newMitarbeiter) => {

    AuthAxios.post('/user', newMitarbeiter)
    .then(res => {
      Swal.fire({
        icon: "success",
        title: "Erfolg",
        html: "Mitarbeiter erfolgreich gespeichert."
      })
    })
    .catch(err => {
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Fehler beim Abspeichern: "+err
      });
    })

    // Find the index of the object to be replaced
    const index = mitarbeiter.findIndex(ma => ma._id === newMitarbeiter._id);

    if (index !== -1) {
      // Create a new array with the object replaced
      const newMitarbeiterList = [...mitarbeiter.slice(0, index), newMitarbeiter, ...mitarbeiter.slice(index + 1)];
      setMitarbeiter(newMitarbeiterList);
    }

  }



  return (
    <div style={{marginLeft: "0px", marginTop: "30px", marginBottom: "0px", width: "100%", height: "100%"}}>

    
      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="mitarbeiter"
          id="fill-tab-example"
          fill
          style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
        >
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Übersicht Mitarbeiter">
            <MitarbeiterListe data={mitarbeiter} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="2" title="Mitarbeiter bearbeiten">
            <MitarbeiterEdit data={mitarbeiter} newMitarbeiter={newMitarbeiter} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="3" title="Gruppen/Regionen/Teams">
            <Gruppenverwaltung data={mitarbeiter} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="5" title="Verwaltung">
            <Verwaltung setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="4" title="Einstellungen/Rechte">
            <Rechteverwaltung data={{id: ""}} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>

        </Tabs>
      </div>
      
    
  </div>
  );
}

export default Mitarbeiter
