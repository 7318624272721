import React from "react";
import QRCode from "qrcode.react";
import Button from "react-bootstrap/Button";

class Component extends React.Component {
  constructor(props) {
  super(props);
  this.downloadPng = this.downloadPng.bind(this);
  this.downloadSvg = this.downloadSvg.bind(this);
};



downloadPng () {

  const svg = document.getElementById('QRCode-svg');
  const canvas = document.querySelector('.HpQrcode > canvas');
  const ctx = canvas.getContext('2d');

  const svgBlob = new Blob([new XMLSerializer().serializeToString(svg)], { type: "image/svg+xml;charset=utf-8" });
  const domURL = window.URL || window.webkitURL;
  const url = domURL.createObjectURL(svgBlob);
  const img = new Image();


  img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
      ctx.drawImage(img, 0, 0); // Draw the SVG onto the canvas

      const png = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = png;
      link.download = "QRCode_Vorteilspartner_" + this.props.id + ".png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      domURL.revokeObjectURL(url); // Clean up
  };

  img.src = url;
};

downloadPng_OLD () {
    const canvas = document.querySelector('.HpQrcode > canvas');
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = "QRCode_VIN1_Vorteilspartner_"+this.props.id+".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};  

downloadSvg () {
    const svg = document.querySelector('.HpQrcode > svg');
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const dataUri = 'data:image/svg+xml,' + encodeURIComponent(svgString);
    const link = document.createElement("a");
    link.href = dataUri;
    link.download = "QRCode_VIN1_Vorteilspartner_"+this.props.vorteilId+".svg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };  


  render() {
    return (
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className="HpQrcode my-2 position-relative">
          <QRCode 
            value={"https://vorteilspartner.vin1.eu/"+this.props.id.toString()}
            id="QRCode-svg"
            size={350}
            renderAs="svg" 
          />
       
          <canvas 
            ref={this.canvasRef}
            width={350}
            height={350}
            style={{display: 'none'}} // Hide the canvas
          />
        </div>

        <p>Link: {"https://vorteilspartner.vin1.eu/"+this.props.id.toString()}</p>
        <Button 
          className="btn-success border-0 rounded w-50 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
          onClick={this.downloadPng}
        >
          Download QR-Code (PNG 350px)
        </Button>
        <Button 
          className="btn border-0 rounded w-50 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
          onClick={this.downloadSvg}
        >
          Download QR-Code SVG (Vektor)
        </Button>    
      </div>
    );
  };
};

export default Component;