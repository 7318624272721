import React, { useState } from 'react';
import { FaPlusCircle, FaFileImage, FaPaperPlane, FaRegSmile } from "react-icons/fa";
import { GoPaperclip } from "react-icons/go";


const MessageSend = ({inputHandle,newMessage,sendMessage,emojiSend,ImageSend}) => {
 
     const emojis = [
          '😀', '😃', '😄', '😁',
          '😆', '😅', '😂', '🤣',
          '😊', '😇', '🙂', '🙃',
          '😉', '😌', '😍', '😝',
          '😜', '🧐', '🤓', '😎',
          '😕', '🤑', '🥴', '😱'
     ];

     const [showEmojis, setShowEmojis] = useState(false);



  return (

     <div className='message-send-section'>

          <div id='emoji' onClick={() => setShowEmojis(!showEmojis)}>
               <FaRegSmile  />
          </div>

          <div className='file hover-attachment'>
               <div className='add-attachment'>
                    Anhang hinzufügen
               </div>
               <GoPaperclip /> 
          </div>  

          <div className='file hover-image'>
               <div className='add-image'>
                    Bild hinzufügen 
               </div>
               <input onChange={ImageSend} type="file" id="pic" className='form-control' />
               <GoPaperclip /> 
          </div>
          

     <div className='message-type'>
          <input
               type="text"
               onChange={inputHandle}
               name='message'
               id='message'
               placeholder='Schreib eine Nachricht'
               className='form-control'
               defaultValue={newMessage}
          />

          {/*<div className='file hover-gift'>
               <label htmlFor='emoji'> ❤️ </label>
          </div>
          */}
     </div>

     <div onClick={sendMessage} className='file'>
          <FaPaperPlane/>
     </div>
     
     <div className={`emoji-section ${showEmojis ? "show" : ""}`}>
          <div className='emoji'>
               {emojis.map((e, index) =>
                    <span key={index} onClick={()=>emojiSend(e)}>{e}</span>
               )}
          </div>
     </div>




     </div>

  )
};

export default MessageSend;
