export default [
  {
    "label": "Andorra",
    "value": "Andorra"
  },
  {
    "label": "Vereinigte Arabische Emirate",
    "value": "Vereinigte Arabische Emirate"
  },
  {
    "label": "Afghanistan",
    "value": "Afghanistan"
  },
  {
    "label": "Antigua und Barbuda",
    "value": "Antigua und Barbuda"
  },
  {
    "label": "Anguilla",
    "value": "Anguilla"
  },
  {
    "label": "Albanien",
    "value": "Albanien"
  },
  {
    "label": "Armenien",
    "value": "Armenien"
  },
  {
    "label": "Niederländische Antillen",
    "value": "Niederländische Antillen"
  },
  {
    "label": "Angola",
    "value": "Angola"
  },
  {
    "label": "Antarktis",
    "value": "Antarktis"
  },
  {
    "label": "Argentinien",
    "value": "Argentinien"
  },
  {
    "label": "Amerikanisch Samoa",
    "value": "Amerikanisch Samoa"
  },
  {
    "label": "Österreich",
    "value": "Österreich"
  },
  {
    "label": "Australien",
    "value": "Australien"
  },
  {
    "label": "Aruba",
    "value": "Aruba"
  },
  {
    "label": "Aland",
    "value": "Aland"
  },
  {
    "label": "Aserbaidschan",
    "value": "Aserbaidschan"
  },
  {
    "label": "Bosnien-Herzegowina",
    "value": "Bosnien-Herzegowina"
  },
  {
    "label": "Barbados",
    "value": "Barbados"
  },
  {
    "label": "Bangladesch",
    "value": "Bangladesch"
  },
  {
    "label": "Belgien",
    "value": "Belgien"
  },
  {
    "label": "Burkina Faso",
    "value": "Burkina Faso"
  },
  {
    "label": "Bulgarien",
    "value": "Bulgarien"
  },
  {
    "label": "Bahrain",
    "value": "Bahrain"
  },
  {
    "label": "Burundi",
    "value": "Burundi"
  },
  {
    "label": "Benin",
    "value": "Benin"
  },
  {
    "label": "Saint Barthélemy",
    "value": "Saint Barthélemy"
  },
  {
    "label": "Bermuda",
    "value": "Bermuda"
  },
  {
    "label": "Brunei",
    "value": "Brunei"
  },
  {
    "label": "Bolivien",
    "value": "Bolivien"
  },
  {
    "label": "Brasilien",
    "value": "Brasilien"
  },
  {
    "label": "Bahamas",
    "value": "Bahamas"
  },
  {
    "label": "Bhutan",
    "value": "Bhutan"
  },
  {
    "label": "Bouvet-Insel",
    "value": "Bouvet-Insel"
  },
  {
    "label": "Botswana",
    "value": "Botswana"
  },
  {
    "label": "Weißrußland",
    "value": "Weißrußland"
  },
  {
    "label": "Belize",
    "value": "Belize"
  },
  {
    "label": "Kanada",
    "value": "Kanada"
  },
  {
    "label": "Kokosinseln (Keeling)",
    "value": "Kokosinseln (Keeling)"
  },
  {
    "label": "Kongo, Demokratische Republik",
    "value": "Kongo, Demokratische Republik"
  },
  {
    "label": "Zentralafrikanische Republik",
    "value": "Zentralafrikanische Republik"
  },
  {
    "label": "Congo",
    "value": "Congo"
  },
  {
    "label": "Schweiz",
    "value": "Schweiz"
  },
  {
    "label": "Côte d'Ivoire",
    "value": "Côte d'Ivoire"
  },
  {
    "label": "Cookinseln",
    "value": "Cookinseln"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "Kamerun",
    "value": "Kamerun"
  },
  {
    "label": "China",
    "value": "China"
  },
  {
    "label": "Kolumbien",
    "value": "Kolumbien"
  },
  {
    "label": "Costa Rica",
    "value": "Costa Rica"
  },
  {
    "label": "Serbien und Montenegro",
    "value": "Serbien und Montenegro"
  },
  {
    "label": "Kuba",
    "value": "Kuba"
  },
  {
    "label": "Kap Verde",
    "value": "Kap Verde"
  },
  {
    "label": "Weihnachtsinseln",
    "value": "Weihnachtsinseln"
  },
  {
    "label": "Zypern",
    "value": "Zypern"
  },
  {
    "label": "Tschechien",
    "value": "Tschechien"
  },
  {
    "label": "Deutschland",
    "value": "Deutschland"
  },
  {
    "label": "Dschibuti",
    "value": "Dschibuti"
  },
  {
    "label": "Dänemark",
    "value": "Dänemark"
  },
  {
    "label": "Dominica",
    "value": "Dominica"
  },
  {
    "label": "Dominikanische Republik",
    "value": "Dominikanische Republik"
  },
  {
    "label": "Algerien",
    "value": "Algerien"
  },
  {
    "label": "Ecuador",
    "value": "Ecuador"
  },
  {
    "label": "Estland",
    "value": "Estland"
  },
  {
    "label": "Ägypten",
    "value": "Ägypten"
  },
  {
    "label": "Westsahara",
    "value": "Westsahara"
  },
  {
    "label": "Eritrea",
    "value": "Eritrea"
  },
  {
    "label": "Spanien",
    "value": "Spanien"
  },
  {
    "label": "Äthiopien",
    "value": "Äthiopien"
  },
  {
    "label": "Finnland",
    "value": "Finnland"
  },
  {
    "label": "Fidschi",
    "value": "Fidschi"
  },
  {
    "label": "Falklandinseln",
    "value": "Falklandinseln"
  },
  {
    "label": "Fürstentum Liechtenstein",
    "value": "Fürstentum Liechtenstein"
  },
  {
    "label": "Mikronesien",
    "value": "Mikronesien"
  },
  {
    "label": "Färöer Inseln",
    "value": "Färöer Inseln"
  },
  {
    "label": "Frankreich",
    "value": "Frankreich"
  },
  {
    "label": "Gabun",
    "value": "Gabun"
  },
  {
    "label": "Großbritannien",
    "value": "Großbritannien"
  },
  {
    "label": "Grenada",
    "value": "Grenada"
  },
  {
    "label": "Georgien",
    "value": "Georgien"
  },
  {
    "label": "Französisch Guyana",
    "value": "Französisch Guyana"
  },
  {
    "label": "Guernsey (Kanalinsel)",
    "value": "Guernsey (Kanalinsel)"
  },
  {
    "label": "Ghana",
    "value": "Ghana"
  },
  {
    "label": "Gibraltar",
    "value": "Gibraltar"
  },
  {
    "label": "Grönland",
    "value": "Grönland"
  },
  {
    "label": "Gambia",
    "value": "Gambia"
  },
  {
    "label": "Guinea",
    "value": "Guinea"
  },
  {
    "label": "Guadeloupe",
    "value": "Guadeloupe"
  },
  {
    "label": "Äquatorial-Guinea",
    "value": "Äquatorial-Guinea"
  },
  {
    "label": "Griechenland",
    "value": "Griechenland"
  },
  {
    "label": "Südgeorgien und Sandwich-Inseln",
    "value": "Südgeorgien und Sandwich-Inseln"
  },
  {
    "label": "Guatemala",
    "value": "Guatemala"
  },
  {
    "label": "Guam",
    "value": "Guam"
  },
  {
    "label": "Guinea-Bissau",
    "value": "Guinea-Bissau"
  },
  {
    "label": "Guyana",
    "value": "Guyana"
  },
  {
    "label": "Hongkong",
    "value": "Hongkong"
  },
  {
    "label": "Heard- und Mcdonald-Inseln",
    "value": "Heard- und Mcdonald-Inseln"
  },
  {
    "label": "Honduras",
    "value": "Honduras"
  },
  {
    "label": "Kroatien",
    "value": "Kroatien"
  },
  {
    "label": "Haïti",
    "value": "Haïti"
  },
  {
    "label": "Ungarn",
    "value": "Ungarn"
  },
  {
    "label": "Indonesien",
    "value": "Indonesien"
  },
  {
    "label": "Irland",
    "value": "Irland"
  },
  {
    "label": "Israel",
    "value": "Israel"
  },
  {
    "label": "Insel Man",
    "value": "Insel Man"
  },
  {
    "label": "Indien",
    "value": "Indien"
  },
  {
    "label": "Britische Territorien im Indischen Ozean",
    "value": "Britische Territorien im Indischen Ozean"
  },
  {
    "label": "Irak",
    "value": "Irak"
  },
  {
    "label": "Iran",
    "value": "Iran"
  },
  {
    "label": "Island",
    "value": "Island"
  },
  {
    "label": "Italien",
    "value": "Italien"
  },
  {
    "label": "Jersey (Kanalinsel)",
    "value": "Jersey (Kanalinsel)"
  },
  {
    "label": "Jamaika",
    "value": "Jamaika"
  },
  {
    "label": "Jordanien",
    "value": "Jordanien"
  },
  {
    "label": "Japan",
    "value": "Japan"
  },
  {
    "label": "Kenia",
    "value": "Kenia"
  },
  {
    "label": "Kirgisistan",
    "value": "Kirgisistan"
  },
  {
    "label": "Kambodscha",
    "value": "Kambodscha"
  },
  {
    "label": "Kiribati",
    "value": "Kiribati"
  },
  {
    "label": "Komoren",
    "value": "Komoren"
  },
  {
    "label": "St. Christopher und Nevis",
    "value": "St. Christopher und Nevis"
  },
  {
    "label": "Nordkorea",
    "value": "Nordkorea"
  },
  {
    "label": "Südkorea",
    "value": "Südkorea"
  },
  {
    "label": "Kuwait",
    "value": "Kuwait"
  },
  {
    "label": "Cayman-Inseln",
    "value": "Cayman-Inseln"
  },
  {
    "label": "Kasachstan",
    "value": "Kasachstan"
  },
  {
    "label": "Laos",
    "value": "Laos"
  },
  {
    "label": "Libanon",
    "value": "Libanon"
  },
  {
    "label": "Saint Lucia",
    "value": "Saint Lucia"
  },
  {
    "label": "Liechtenstein",
    "value": "Liechtenstein"
  },
  {
    "label": "Sri Lanka",
    "value": "Sri Lanka"
  },
  {
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "label": "Lesotho",
    "value": "Lesotho"
  },
  {
    "label": "Litauen",
    "value": "Litauen"
  },
  {
    "label": "Luxemburg",
    "value": "Luxemburg"
  },
  {
    "label": "Lettland",
    "value": "Lettland"
  },
  {
    "label": "Libyen",
    "value": "Libyen"
  },
  {
    "label": "Marokko",
    "value": "Marokko"
  },
  {
    "label": "Monaco",
    "value": "Monaco"
  },
  {
    "label": "Moldawien",
    "value": "Moldawien"
  },
  {
    "label": "Montenegro",
    "value": "Montenegro"
  },
  {
    "label": "Saint Martin",
    "value": "Saint Martin"
  },
  {
    "label": "Madagaskar",
    "value": "Madagaskar"
  },
  {
    "label": "Marshall-Inseln",
    "value": "Marshall-Inseln"
  },
  {
    "label": "Mazedonien",
    "value": "Mazedonien"
  },
  {
    "label": "Mali",
    "value": "Mali"
  },
  {
    "label": "Myanmar",
    "value": "Myanmar"
  },
  {
    "label": "Mongolei",
    "value": "Mongolei"
  },
  {
    "label": "Macao",
    "value": "Macao"
  },
  {
    "label": "Nördliche Marianen Inseln",
    "value": "Nördliche Marianen Inseln"
  },
  {
    "label": "Martinique",
    "value": "Martinique"
  },
  {
    "label": "Mauretanien",
    "value": "Mauretanien"
  },
  {
    "label": "Montserrat",
    "value": "Montserrat"
  },
  {
    "label": "Malta",
    "value": "Malta"
  },
  {
    "label": "Mauritius",
    "value": "Mauritius"
  },
  {
    "label": "Malediven",
    "value": "Malediven"
  },
  {
    "label": "Malawi",
    "value": "Malawi"
  },
  {
    "label": "Mexiko",
    "value": "Mexiko"
  },
  {
    "label": "Malaysia",
    "value": "Malaysia"
  },
  {
    "label": "Mosambik",
    "value": "Mosambik"
  },
  {
    "label": "Namibia",
    "value": "Namibia"
  },
  {
    "label": "Neu-Kaledonien",
    "value": "Neu-Kaledonien"
  },
  {
    "label": "Niger",
    "value": "Niger"
  },
  {
    "label": "Norfolk-Insel",
    "value": "Norfolk-Insel"
  },
  {
    "label": "Nigeria",
    "value": "Nigeria"
  },
  {
    "label": "Nicaragua",
    "value": "Nicaragua"
  },
  {
    "label": "Niederlande",
    "value": "Niederlande"
  },
  {
    "label": "Norwegen",
    "value": "Norwegen"
  },
  {
    "label": "Nepal",
    "value": "Nepal"
  },
  {
    "label": "Nauru",
    "value": "Nauru"
  },
  {
    "label": "Niue",
    "value": "Niue"
  },
  {
    "label": "Neuseeland",
    "value": "Neuseeland"
  },
  {
    "label": "Oman",
    "value": "Oman"
  },
  {
    "label": "Panama",
    "value": "Panama"
  },
  {
    "label": "Peru",
    "value": "Peru"
  },
  {
    "label": "Französisch Polynesien",
    "value": "Französisch Polynesien"
  },
  {
    "label": "Papua-Neuguinea",
    "value": "Papua-Neuguinea"
  },
  {
    "label": "Philippinen",
    "value": "Philippinen"
  },
  {
    "label": "Pakistan",
    "value": "Pakistan"
  },
  {
    "label": "Polen",
    "value": "Polen"
  },
  {
    "label": "Saint Pierre und Miquelon",
    "value": "Saint Pierre und Miquelon"
  },
  {
    "label": "Pitcairn",
    "value": "Pitcairn"
  },
  {
    "label": "Puerto Rico",
    "value": "Puerto Rico"
  },
  {
    "label": "Palästina",
    "value": "Palästina"
  },
  {
    "label": "Portugal",
    "value": "Portugal"
  },
  {
    "label": "Palau",
    "value": "Palau"
  },
  {
    "label": "Paraguay",
    "value": "Paraguay"
  },
  {
    "label": "Katar",
    "value": "Katar"
  },
  {
    "label": "Réunion",
    "value": "Réunion"
  },
  {
    "label": "Rumänien",
    "value": "Rumänien"
  },
  {
    "label": "Serbien",
    "value": "Serbien"
  },
  {
    "label": "Russland",
    "value": "Russland"
  },
  {
    "label": "Ruanda",
    "value": "Ruanda"
  },
  {
    "label": "Saudiarabien",
    "value": "Saudiarabien"
  },
  {
    "label": "Salomonen",
    "value": "Salomonen"
  },
  {
    "label": "Seychellen",
    "value": "Seychellen"
  },
  {
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "label": "Schweden",
    "value": "Schweden"
  },
  {
    "label": "Singapur",
    "value": "Singapur"
  },
  {
    "label": "Sankt Helena",
    "value": "Sankt Helena"
  },
  {
    "label": "Slowenien",
    "value": "Slowenien"
  },
  {
    "label": "Svalbard und Jan Mayen",
    "value": "Svalbard und Jan Mayen"
  },
  {
    "label": "Slowakei",
    "value": "Slowakei"
  },
  {
    "label": "Sierra Leone",
    "value": "Sierra Leone"
  },
  {
    "label": "San Marino",
    "value": "San Marino"
  },
  {
    "label": "Senegal",
    "value": "Senegal"
  },
  {
    "label": "Somalia",
    "value": "Somalia"
  },
  {
    "label": "Surinam",
    "value": "Surinam"
  },
  {
    "label": "São Tomé und Príncipe",
    "value": "São Tomé und Príncipe"
  },
  {
    "label": "El Salvador",
    "value": "El Salvador"
  },
  {
    "label": "Syrien",
    "value": "Syrien"
  },
  {
    "label": "Swasiland",
    "value": "Swasiland"
  },
  {
    "label": "Turks- und Caicosinseln",
    "value": "Turks- und Caicosinseln"
  },
  {
    "label": "Tschad",
    "value": "Tschad"
  },
  {
    "label": "Französische Südgebiete",
    "value": "Französische Südgebiete"
  },
  {
    "label": "Togo",
    "value": "Togo"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "Tadschikistan",
    "value": "Tadschikistan"
  },
  {
    "label": "Tokelau",
    "value": "Tokelau"
  },
  {
    "label": "Ost Timor",
    "value": "Ost Timor"
  },
  {
    "label": "Turkmenistan",
    "value": "Turkmenistan"
  },
  {
    "label": "Tunisien",
    "value": "Tunisien"
  },
  {
    "label": "Tonga",
    "value": "Tonga"
  },
  {
    "label": "Türkei",
    "value": "Türkei"
  },
  {
    "label": "Trinidad und Tobago",
    "value": "Trinidad und Tobago"
  },
  {
    "label": "Tuvalu",
    "value": "Tuvalu"
  },
  {
    "label": "Taiwan",
    "value": "Taiwan"
  },
  {
    "label": "Tansania",
    "value": "Tansania"
  },
  {
    "label": "Ukraine",
    "value": "Ukraine"
  },
  {
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "label": "Kleinere Amerikanische Überseeinseln",
    "value": "Kleinere Amerikanische Überseeinseln"
  },
  {
    "label": "Vereinigte Staaten",
    "value": "Vereinigte Staaten"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  },
  {
    "label": "Usbekistan",
    "value": "Usbekistan"
  },
  {
    "label": "Vatikanstadt",
    "value": "Vatikanstadt"
  },
  {
    "label": "Sankt Vincent und die Grenadinen",
    "value": "Sankt Vincent und die Grenadinen"
  },
  {
    "label": "Venezuela",
    "value": "Venezuela"
  },
  {
    "label": "Jungferninseln (Britisch)",
    "value": "Jungferninseln (Britisch)"
  },
  {
    "label": "Jungferninseln (U.S.)",
    "value": "Jungferninseln (U.S.)"
  },
  {
    "label": "Vietnam",
    "value": "Vietnam"
  },
  {
    "label": "Vanuatu",
    "value": "Vanuatu"
  },
  {
    "label": "Wallis und Futuna",
    "value": "Wallis und Futuna"
  },
  {
    "label": "Samoa",
    "value": "Samoa"
  },
  {
    "label": "Jemen",
    "value": "Jemen"
  },
  {
    "label": "Mayotte",
    "value": "Mayotte"
  },
  {
    "label": "Südafrika",
    "value": "Südafrika"
  },
  {
    "label": "Sambia",
    "value": "Sambia"
  },
  {
    "label": "Zimbabwe",
    "value": "Zimbabwe"
  }
]