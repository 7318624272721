import React, { useState, useEffect, useContext } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { Container, Col, Row, Alert, Button, Tab, Tabs } from 'react-bootstrap';
import NeueAufgabe from './NeueAufgabe';
import AufgabenListe from './AufgabenListe';
import MeineAufgabenListe from './MeineAufgabenListe';
import GruppenAufgabenListe from './GruppenAufgabenListe';
import OhneGruppenAufgabenListe from './OhneGruppenAufgabenListe';
import VerantwortungAufgabenListe from './VerantwortungAufgabenListe';
import OffeneAufgabenListe from './OffeneAufgabenListe';
import AbgeschlosseneAufgabenListe from './AbgeschlosseneAufgabenListe';

import PartnerAufgabeListeOffen from './PartnerAufgabeListeOffen';
import PartnerAufgabeListe from './PartnerAufgabeListe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck, faPenToSquare, faQrcode, faRotate, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useParams, useNavigate } from 'react-router-dom'

//import api from '../../utils/axiosConfigaxiosConfig';
import Swal from 'sweetalert2';
import AuthAxios from '../../utils/AuthAxios';



function Aufgaben() {

  let { id } = useParams();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [alertSuccess, setAlertSuccess] = useState("")
  const [alertError, setAlertError] = useState("")
  const [active, setActive] = useState(true);
  const [aufgabe, setAufgabe] = useState({
    status: "Nicht begonnen",
    prio: "normal",
    deadline: new Date().toISOString().substring(0, 10)
  });
  const [aufgabenHistorie, setAufgabenHistorie] = useState([]);

  const [meineAufgabenListe, setMeineAufgabenListe] = useState([]);
  const [gruppenAufgabenListe, setGruppenAufgabenListe] = useState([]);
  const [ohneGruppenAufgabenListe, setOhneGruppenAufgabenListe] = useState([]);
  const [verantwortungAufgabenListe, setVerantwortungAufgabenListe] = useState([]);
  const [offeneAufgabenListe, setOffeneAufgabenListe] = useState([]);
  const [abgeschlossenAufgabenListe, setAbgeschlossenAufgabenListe] = useState([]);

  const [partnerOffenAufgabenListe, setPartnerOffenAufgabenListe] = useState([]);
  const [partnerAufgabenListe, setPartnerAufgabenListe] = useState([]);

  const [aufgabenListe, setAufgabenListe] = useState([]);

  // Tracking ob es Änderungen gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {

    async function getTask(id) {

      setAufgabenHistorie([]);
      AuthAxios.get('/task/' + id)
        .then(res => {
          //console.log(res.data);
          setAufgabe(res.data);
          return res.data;
        })
        .then((task) => {
            AuthAxios.get('/task/related/' + task.taskId)
            .then(res2 => {
              let relatedTasks = res2.data;
              if(relatedTasks.length >= 1) {
                relatedTasks.push(task.taskId); // add current taskId

                // Order the tasks in descending order
                relatedTasks = relatedTasks.sort((a, b) => a - b);
              }
              setAufgabenHistorie(relatedTasks);
            })
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Aufgabe konnte nicht gefunden werden."
          });
        });
    }

    if (id !== undefined && id.length <= 24 && Number.isInteger(parseInt(id))) {
      getTask(id);
    }


    if (reload && id !== undefined && id.length === 24) {
      getTask(id);
      setReload(false);
    } else if (reload && (id == undefined || id.length != 24)) {
      newAufgabe();
      setReload(false);
    }

  }, [id, reload]);


  const reloadTasks = () => {
    //alert("Alle tasks neu laden. Wird implementiert!")
  }





  // + Icon neuer Partner
  const newAufgabe = () => {
    setAufgabenHistorie([]);
    setAufgabe({
      status: "Nicht begonnen",
      priorität: "normal",
      deadline: new Date().toISOString().substring(0, 10)
    });
    setActiveTab(1);
    navigate("/dash/aufgabe");
  };


  /*
  const setLand = (selectedLandVal) => {
      props.setKunde({
      ...props.data,
      land: selectedLandVal
      });
  }
  */


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [name]: value
    });
  }

  // React Single Select Zwueisung
  const setSelectKunde = (name, kundeObj) => {

    setChanged(true);
    setAufgabe({
      ...aufgabe,
      [name]: kundeObj
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setAufgabe({
      ...aufgabe,
      [name]: newVals
    });

  }

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);
    const value = event.target.value;
    setAufgabe({
      ...aufgabe,
      [event.target.name]: value
    });
  }


  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setAufgabe({
      ...aufgabe,
      [name]: value
    });

    console.log(aufgabe.activity);
  }


  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setChanged(false);
          setReload(true);
        }
      })
  }


  const saveAufgabe = () => {

    //console.log("kunde vor speichern")

    if (!aufgabe.betreff || !aufgabe.beschreibung) {
      Swal.fire({
        icon: 'info',
        title: "Nicht gespeichert!",
        html: "Gib einen Betreff oder eine Beschreibung ein und versuche es erneut."
      });
      return;
    }

    if (!aufgabe.mitarbeiter && !aufgabe.gruppe) {
      Swal.fire({
        icon: 'info',
        title: "Nicht gespeichert!",
        html: "Hinterlege einen Bearbeiter oder eine Gruppe!"
      });
      return;
    }

    // Diese Werte dürfen nur vom Backend ausgelesen werden
    //delete aufgabe['appzugriffCb'];
    //delete aufgabe['maklervollmachtCb'];
    //delete aufgabe['tippgeberCb'];

    AuthAxios.post('/task', aufgabe)
      .then(res => {
        //console.log(res.data);
        setAufgabe({
          ...aufgabe,
          _id: res.data._id, //Damit nicht erneut ein Task mit selbem Inhalt erstellt wird!
          taskId: res.data.taskId
        });

        if (!aufgabe._id) {
          // Update DataGrid mit neu erstelltem Task   
          setAufgabenListe([
            {
              "_id": res.data._id,
              "taskId": res.data.taskId,
              "ersteller": res.data.ersteller,
              "kunde": res.data.kunde,
              "verantwortung": res.data.verantwortung,
              "gruppe": res.data.gruppe,
              "mitarbeiter": res.data.mitarbeiter,
              "status": res.data.status,
              "prio": res.data.prio,
              "art": res.data.art,
              "deadline": res.data.deadline,
              "betreff": res.data.betreff,
              "beschreibung": res.data.beschreibung,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },
            ...aufgabenListe
          ]);
        }
        else {
          console.log(res.data)
          // Update DataGrid mit neuem Task (nur Task Update) 
          // alte Id kurz entfernen
          // updated obj hinzufügen
          // rest der daten hinzufügen 
          setAufgabenListe([
            /*
            {
              "_id": res.data._id,
              "taskId": res.data.taskId,
              "ersteller": res.data.ersteller,
              "kunde": res.data.kunde,
              "verantwortung": res.data.verantwortung,
              "gruppe": res.data.gruppe,
              "mitarbeiter": res.data.mitarbeiter,
              "status": res.data.status,
              "prio": res.data.prio,
              "art": res.data.art,
              "deadline": res.data.deadline,
              "betreff": res.data.betreff,
              "beschreibung": res.data.beschreibung,
              "createdAt": res.data.createdAt,
              "updatedAt": res.data.updatedAt
            },*/
            {
              ...res.data
            },
            ...aufgabenListe.filter(x => x._id !== aufgabe._id),
          ]);
        }
        setAlertSuccess("Aufgabe erfolgreich gespeichert.");
        return res.data;
      })
      .then(newTask => {
        setAufgabenHistorie([]);
        // Aufgabenreihenfolge Folgeaufgaben neu abrufen.
        AuthAxios.get('/task/related/' + newTask.taskId)
        .then(res2 => {
          let relatedTasks = res2.data;
          if(relatedTasks.length >= 1) {
            relatedTasks.push(newTask.taskId); // add current taskId

            // Order the tasks in descending order
            relatedTasks = relatedTasks.sort((a, b) => a - b);
          }
          setAufgabenHistorie(relatedTasks);
        })
      })
      .catch(err => {
        console.log(err);
        setAlertError("Aufgabe konnte nicht gespeichert werden.");
      });

    setChanged(false);

  }


  const handleFolgeaufgabe = () => {


    if(aufgabenHistorie.length >= 1 && id != aufgabenHistorie[aufgabenHistorie.length-1]) {
      Swal.fire({
        icon: 'info',
        title: 'Folgeaufgabe',
        html: 'Folgeaufgaben können nur für die letzte Aufgabe erstellt werden.<br />'
        +'Wechsel zunächst zu Aufgabe: <b>'+aufgabenHistorie[aufgabenHistorie.length-1]+'</b>'
      })
      return;
    }
    
    // Wenn Aufgabe mit id geladen in Frontend
    if(id) {
      Swal.fire({
        icon: 'info',
        title: 'Folgeaufgabe',
        html: 'Soll eine Folgeaufgabe erstellt werden?',
        showDenyButton: true,
        confirmButtonText: 'Ja',
        denyButtonText: "Nein",
      })
      .then(res => {
        const newTask = {
          kunde: aufgabe.kunde, // Kunde direkt wieder auswählen
          status: "Nicht begonnen",
          priorität: "normal",
          deadline: new Date().toISOString().substring(0, 10),
          prevTask: true,
          prevTaskId: aufgabe.taskId
        };
        setAufgabe(newTask);

        navigate("/dash/aufgabe");
        
      })
      .catch(err => {
        console.log(err);
      })
      
    }
  }






  return (

    <div style={{ marginLeft: "0px", marginTop: "40px", width: "100%" }}>

      <Row style={{ display: "flex", alignItems: "center", margin: "0px" }}>
        <Col xs={12} md={12} className="mainActions" style={{ justifyContent: "space-between" }}>

          <Col xs={12} md={4}>
            <FontAwesomeIcon
              icon={faPlus}
              size="2x"
              className="actionIcon"
              onClick={newAufgabe}
              title="Neue Aufgabe anlegen"
            />

            {changed && activeTab == 1 &&
              <>
                <span style={{ paddingLeft: "8px" }}>
                  <FontAwesomeIcon
                    icon={faSave}
                    size="2x"
                    className="actionIcon"
                    onClick={saveAufgabe}
                    title="Aufgabe speichern"
                  />
                </span>
                <span style={{ paddingLeft: "8px" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2x"
                    className="actionIcon"
                    onClick={cancelEdit}
                    title="Änderungen verwerfen"
                  />
                </span>
              </>
            }


            {!changed && activeTab == 1 &&
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className="actionIcon"
                title="Keine Änderung erfolgt"
              />
            }

            {/*activeTab != 1 &&
              <FontAwesomeIcon
                icon={faRotate}
                size="2x"
                className="actionIcon"
                onClick={reloadTasks}
                title="Tasks neu abrufen"
              />
            */}

          </Col>


          <Col xs={12} sm={5} className="meldungen">
            {alertSuccess.length > 0 &&
              <Col xs={12} md={6}>
                <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                  {alertSuccess}
                </Alert>
              </Col>
            }
            {alertError.length > 0 &&
              <Col xs={12} sm={6} md={6}>
                <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                  {alertError}
                </Alert>
              </Col>
            }
          </Col>

          <Col xs={12} sm={5} md={3}>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  style={{
                    width: "100%",
                    opacity: id ? 1 : 0.5,
                    cursor: id ? "pointer" : "default"
                  }}
                  className="m-2 btn btn-primary w-auto"
                  onClick={() => handleFolgeaufgabe(id)}
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size="lg"
                    style={{marginRight: "8px"}}
                    className="actionIcon"
                    title="Aufgabe anlegen"
                  />
                    Folgeaufgabe
                </Button>
              </Col>
            </Row>
          </Col>
          



        </Col>
      </Row>

      <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //onSelect={(key) => {
            //if (key != parseInt(1)) {
              // Revove ID falls am Ende von URL!
              //const currentUrl = window.location.href;
              //const base = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
              //window.history.replaceState({}, document.title, base);
            //}
            //setActiveTab(parseInt(key))
          //}}
          //defaultActiveKey="neueaufgabe"
          id="fill-tab-example"
          fill
          style={{ display: "inline-flex", width: "auto", marginTop: "0px", padding: "0px" }}
        >

          {/*
            Gruppenaufgaben auswerten Reiter - nur wenn Benutzer auch in Gruppe ist anzeigen!
            Alle Aufgaben nur anzeigen wenn Rechte vorhanden sind!
          */}

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="1" title="Neue Aufgabe">
            <NeueAufgabe user={user} data={aufgabe} history={aufgabenHistorie} setAufgabe={handleInputChange} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setManually={handleInputChangeManually} />
          </Tab>

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="2" title={"Meine Aufg. (" + meineAufgabenListe.length + ")"}>
            <MeineAufgabenListe user={user} data={meineAufgabenListe} setAufgabenListe={setMeineAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={2}  />
          </Tab>

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="3" title={"Gruppen (" + gruppenAufgabenListe.length + ")"}>
            <GruppenAufgabenListe data={gruppenAufgabenListe} setAufgabenListe={setGruppenAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={3} />
          </Tab>

          
          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="10" title={"Ohne Gruppe (" + ohneGruppenAufgabenListe.length + ")"}>
            <OhneGruppenAufgabenListe data={ohneGruppenAufgabenListe} setAufgabenListe={setOhneGruppenAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={10} />
          </Tab>

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="4" title={"Verantwortung (" + verantwortungAufgabenListe.length + ")"}>
            <VerantwortungAufgabenListe data={verantwortungAufgabenListe} setAufgabenListe={setVerantwortungAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={4}  />
          </Tab>

          
          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="9" title={"Offen (" + offeneAufgabenListe.length + ")"}>
            <OffeneAufgabenListe data={offeneAufgabenListe} setAufgabenListe={setOffeneAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={9} />
          </Tab>

          <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="5" title={"Abgeschl. (" + abgeschlossenAufgabenListe.length + ")"}>
            <AbgeschlosseneAufgabenListe data={abgeschlossenAufgabenListe} setAufgabenListe={setAbgeschlossenAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={5} />
          </Tab>

          {(user?.roles?.some(role => ["Administrator", "Management"].includes(role))) &&
            <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="6" title={"Alle (" + aufgabenListe.length + ")"}>
              <AufgabenListe data={aufgabenListe} setAufgabenListe={setAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={6} />
            </Tab>
          }

          {/* Controlling Partner CRM Aufgaben */}
          {/*(user?.roles?.some(role => ["Administrator", "Management"].includes(role))) &&
            <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="7" title={"Partner Zust. Offen"}>
              <PartnerAufgabeListeOffen data={partnerOffenAufgabenListe} setAufgabenListe={setPartnerOffenAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={7} />
            </Tab>
          */}

          {/*(user?.roles?.some(role => ["Administrator", "Management"].includes(role))) &&
            <Tab style={{ background: "white", position: "relative", paddingTop: "15px" }} eventKey="8" title={"Partner Zust. Abgeschl."}>
              <PartnerAufgabeListe data={partnerAufgabenListe} setAufgabenListe={setPartnerAufgabenListe} setActiveTab={setActiveTab} activeTab={activeTab} tab={8} />
            </Tab>
        */}

        </Tabs>

      </div>
    </div>

  );
}

export default Aufgaben;