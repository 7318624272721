import React, { useEffect, useState } from 'react'

import AuthAxios from '../../../utils/AuthAxios';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { deDE, DataGrid, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../../Bausteine/StripedDataGrid";

import { alpha, styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';

import { Container, Row, Col, Button } from "react-bootstrap";

import moment from 'moment';



function OffeneZahlung(props) {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {

    async function getData () {
      AuthAxios.get('/vorteilspartner/offene-zahlungen')
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Offene Zahlungen konnten nicht geladen werden."
        });
      });
    }

    if(props.tab == props.activeTab) {
      getData();
    }

  },[props.activeTab]);


  
  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));



  const columns = [
    {
      field: "createdAt",
      headerName: "Datum",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gesamtsumme",
      headerName: "Betrag",
      minWidth: 60,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.gesamtsumme/100}€</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "products",
      headerName: "Produkte",
      minWidth: 275,
      flex: 1,
      renderCell: (params) => (
        <ul style={{margin: "0px 8px", padding: "0px"}}>
          {params.row.products.map((p, index) =>
            <li key={index}>
              <span>- {p}</span>
            </li>
          )}
        </ul>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorteilspartner_name",
      headerName: "Vorteilspartner Name",
      minWidth: 130,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mitarbeiter",
      headerName: "Bearbeiter",
      minWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleNavigation = (e) => {
    //console.log(e.id);
    //props.setActiveTab(1);
    //navigate("/dash/vorteilspartner/" + e.id);
  }


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>

      </GridToolbarContainer>
    );
  }





    return (
      <div style={{width: "100%"}}>
        <h6 className="py-2 px-2 fw-bold">Offene Zahlungen {1000/100}€</h6>
        <Box
          sx={{
            height: '80vh',
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "0px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

        {props.activeTab == 4 && (
          <StripedDataGrid
            //checkboxSelection
            //onSelectionModelChange={handleCheckboxSelection}
            disableSelectionOnClick
            //autoHeight
            density={'compact'}
            getRowHeight={() => 'auto'}
            rows={data}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            autoPageSize
            //onFilterModelChange={onFilterModelChange}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '4px' },
            }}
          />
        )}
        
        </Box>

      </div>
    );
  }
  


export default OffeneZahlung