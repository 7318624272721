import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

//import Cropper, { ReactCropperElement } from "react-cropper";
//import "cropperjs/dist/cropper.css";

import { COLORS } from '../../constants';
//import "./Cropper.css";

import SelectPartnerKategorien from "../../Bausteine/SelectPartnerKategorien";
import SelectPartnerUploadKategorien from "../../Bausteine/SelectPartnerUploadKategorien";

import CustomModal from "../../Bausteine/CustomModal";

import AuthAxios from "../../utils/AuthAxios";
import Swal from "sweetalert2";
import SelectKundeObj from "../../Bausteine/SelectKundeObj";
import SelectVorteilspartner from "../../Bausteine/SelectVorteilspartner";


function NeuerPartner(props) {

  const kategorieRef = useRef(null);
  const kategorieUploadRef = useRef(null);

  const [showModalNeueKategorie, setShowModalNeueKategorie] = useState(false);
  const [showModalNeueUploadKategorie, setShowModalNeueUploadKategorie] = useState(false);

  const [kategorien, setKategorien] = useState([]);
  const [kategorienUpload, setKategorienUpload] = useState([]);


  useEffect(() => {

    async function getKategorien () {
        try {
          const res = await AuthAxios.get('/partner/settings-kategorien');

          const kategorieListe = [];
          res.data.map(r => {
            kategorieListe.push({"value": r._id, "label": r.name});
          });
          setKategorien(kategorieListe);
        }
        catch (err) {
          console.log(err);
          Swal.fire({
              icon: 'error',
              title: "Fehler",
              html: "Kategorien konnten nicht geladen werden."
          });
        }               
    }

    async function getKategorienUpload () {
      try {
        const res = await AuthAxios.get('/partner/settings-kategorien-upload');
        const kategorieListe = [];
        res.data.map(r => {
          kategorieListe.push({"value": r._id, "label": r.name});
        });
        setKategorienUpload(kategorieListe);
      }
      catch (err) {
        console.log(err);
        Swal.fire({
            icon: 'error',
            title: "Fehler",
            html: "Kategorien konnten nicht geladen werden."
        });
      }               
  }
    getKategorien();
    getKategorienUpload();

},[]);



  const handleSaveNewKategorie = (kategorie) => {
    
    AuthAxios.post("/partner/settings-kategorien", {
      name: kategorie
    })
    .then(res => {
      setKategorien([...kategorien, {
        value: res.data._id,
        label: res.data.name
      }]);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Fehler beim Speichern der neuen Kategorie!"
      })
    })
    
    kategorieRef.current.value = "";
    kategorieRef.current.focus();
  }


  const handleSaveNewKategorieUpload = (kategorie) => {
    
    
    AuthAxios.post("/partner/settings-kategorien-upload", {
      name: kategorie
    })
    .then(res => {
      setKategorienUpload([...kategorienUpload, {
        value: res.data._id,
        label: res.data.name
      }]);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Fehler beim Speichern der neuen Kategorie!"
      })
    })
    
    kategorieUploadRef.current.value = "";
    kategorieUploadRef.current.focus();
  }


    // ===== Clear File Picker Logo "Value" =====
    const [fileKey, setFileKey] = useState(Date.now());
    // Function to clear the file input
    const clearFileInput = () => {
        setFileKey(Date.now());
        if (props.handleLogoClear) {
            props.handleLogoClear();
        }
    };
    useEffect(() => {
     if(!props.newImage) {
       clearFileInput();
     }
    }, [props.newImage])
 

  


  return (
    <Container fluid >
      <Row>
        <Col xs={12} md={6} lg={3}>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                    borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Grunddaten
            </legend>

            <Form.Check
              type="checkbox"
              id="loginStatus"
              label="Login für Partner in B2B CRM möglich"
              name="loginStatus"
              checked={props.data.loginStatus ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <Form.Check
              type="checkbox"
              id="appStatus"
              label="App freigegeben (wenn gesetzt wird Partner in App sichtbar!)"
              name="appStatus"
              checked={props.data.appStatus ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <hr className="mt-4" />     

            <span>Kunde ist ein <b><u>Vorteilspartner</u></b>?<br />
            <span className="text-sm">(Notwendig für Statistik-Element in B2B-CRM)</span></span>
            <SelectVorteilspartner
              setSelect={props.setSelect}
              selectedValue={props.data.vorteil}
            />
            
            <hr className="mt-4" />
            <span>Kunde hinterlegen für Kontaktdaten etc.</span>
            <SelectKundeObj
              setSelect={props.setSelect}
              selectedValue={props.data.kunde}
            />

            <hr className="mt-4" />

            <Form.Label>
            {(props.data.telefon !== undefined && props.data.telefon !== "") ? 
              <a href={'tel:'+props.data.telefon} style={{color: "#c58164"}}>Telefon/Mobil für Bereich Vorteil/B2B Partner</a>
              : "Mobil"
            }</Form.Label>
            <Form.Control
              type="text"
              name="telefon"
              placeholder="Telefon/Mobil"
              value={props.data.telefon ? props.data.telefon : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            
            <Form.Label>E-Mail Buchhaltung (Rechnungen zu Lizenzen)</Form.Label>
            <Form.Control
              type="text"
              name="email_buchhaltung"
              placeholder="E-Mail Buchhaltung (Rechnungen zu Lizenzen)"
              value={props.data.email_buchhaltung ? props.data.email_buchhaltung : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <hr />
            
            <Form.Check
              type="checkbox"
              id="email_benachrichtigung_status"
              label="E-Mail Benachrichtigungen erhalten? - Nachfolgende E-Mail bei neuem Dokument v. Kunde"
              name="email_benachrichtigung_status"
              checked={props.data.email_benachrichtigung_status ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

             
            <Form.Label>E-Mail App Benachrichtigungen (neue Vorfälle)</Form.Label>
            <Form.Control
              type="text"
              name="email_benachrichtigung"
              placeholder="E-Mail App Benachrichtigungen (neue Vorfälle)"
              value={props.data.email_benachrichtigung ? props.data.email_benachrichtigung : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <hr />

            <Form.Label>Anschrift</Form.Label>
            <Form.Control
              type="text"
              name="anschrift"
              placeholder="Anschrift"
              value={props.data.anschrift ? props.data.anschrift : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <Form.Label>PLZ</Form.Label>
            <Form.Control
              type="number"
              name="plz"
              placeholder="PLZ"
              value={props.data.plz ? props.data.plz : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <Form.Label>Stadt</Form.Label>
            <Form.Control
              type="text"
              name="stadt"
              placeholder="Stadt"
              value={props.data.stadt ? props.data.stadt : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

          </fieldset>
        </Col>


        
        <Col>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              Lizenzen
            </legend>

            <p>Die Ablaufdaten von den Lizenzen richten sich nach den Rechnungen! Achtung bei manueller Anpassung.</p>

            <Form.Label>Anzahl Lizenzen</Form.Label>
            <Form.Control
              type="number"
              name="lizenzen"
              min={1}
              value={props.data.lizenzen ? props.data.lizenzen : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <Form.Label>Lizenz Ablauf (Login)</Form.Label>
            <Form.Control
              type="date"
              name="lizenzLaufzeit"
              min={new Date().toISOString().split('T')[0]}
              value={props.data.lizenzLaufzeit ? props.data.lizenzLaufzeit : "1900-01-01"}
              onChange={(e) => props.setB2BPartner(e)}
            />


            <Form.Label>Lizenzablauf setzen</Form.Label><br />
            <Button onClick={() => props.setB2BPartnerManually("lizenzLaufzeit", new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().substring(0, 10) )} className="ms-1 mb-1">+ 1 Jahr</Button>

            <br />

            <hr />
            <p><b>KI-Unterstützung</b></p>

            <Form.Check
              type="checkbox"
              id="chatLizenz_status"
              label="Chat GPT-Lizenz aktiv"
              name="chatLizenz_status"
              checked={props.data.chatLizenz_status ? true : false}
              onChange={(e) => props.setCBs(e)}
            />

            <Form.Label>Zusatzlizenz Chat GPT Newsletter</Form.Label>
            <Form.Control
              type="date"
              id="chatLizenzAILaufzeit"
              name="chatLizenzAILaufzeit"
              min={new Date().toISOString().split('T')[0]}
              value={props.data.chatLizenzAILaufzeit ? props.data.chatLizenzAILaufzeit : new Date().toISOString().substring(0, 10)}
              onChange={(e) => props.setB2BPartner(e)}
            />

            
            <Form.Label>Lizenzablauf setzen</Form.Label><br />
            <Button onClick={() => props.setB2BPartnerManually("chatLizenzAILaufzeit", new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().substring(0, 10) )} className="ms-1 mb-1">+ 1 Jahr</Button>

            <br />
            <hr />

            <p><b>Hauptadmin Login B2B-Partner</b></p>
            <p style={{fontSize:"85%"}}>Das Passwort muss durch den Partner selber mit der "Passwort vergessen" Funktion beim partner.vin1.eu Login gesetzt werden.</p>

            <Form.Label>E-Mail für Haupt Admin Login</Form.Label>
            <Form.Control
              type="text"
              name="email_login"
              placeholder="E-Mail B2B CRM Login"
              value={props.data.email_login ? props.data.email_login : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <Form.Label>Vorname (Admin Login)</Form.Label>
            <Form.Control
              type="text"
              name="vorname"
              placeholder="Vorname"
              value={props.data.vorname ? props.data.vorname : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

            <Form.Label>Nachname (Admin Login)</Form.Label>
            <Form.Control
              type="text"
              name="nachname"
              placeholder="Nachname"
              value={props.data.nachname ? props.data.nachname : ""}
              onChange={(e) => props.setB2BPartner(e)}
            />

          </fieldset>

        </Col>


        <Col xs={12} md={8} lg={6}>

          <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
          >
            <legend
              className="float-none w-auto p-2"
              style={{
                color: COLORS.primCol,
                background: COLORS.primBG,
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              App-Daten
            </legend>


            {!props.data.partnerIMG &&
              <Form.Group className="mb-3">
                <Form.Label>Firmenlogo (.png .jpg) - Optimale Größe: ca. 200px Höhe</Form.Label>
                <Form.Control
                  key={fileKey}
                  id="partnerIMG"
                  name="partnerIMG"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => props.handleB2BPartnerIMG(e.target.files)}
                />
              </Form.Group>
            }

            {props.newImage &&
              <Row>
              
                <Col xs={12}>
                  <p>Neues Firmenlogo: </p>
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "400px",
                      objectFit: "contain",
                      border: "1px solid black",
                      background: 'rgba(150, 150, 150, 0.8)',
                    }}
                    src={props.newImage}
                  />
                </Col>

                <Col xs={12} className="mt-3">
                  <Button
                    variant={'danger'}
                    onClick={() => props.handleB2BPartnerIMG(null)} 
                  >
                    Bild entfernen
                  </Button>
                </Col>

              </Row>
            }

            {props.data.partnerIMG &&
              <Row>
              
                <Col xs={12}>
                  <p>Altes Firmenlogo: </p>
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "400px",
                      objectFit: "contain",
                      border: "1px solid black",
                      background: 'rgba(150, 150, 150, 0.8)',
                    }}
                    src={props.data.partnerIMG}
                  />
                </Col>

                <Col xs={12} className="mt-3">
                  <Button
                    variant={'danger'}
                    onClick={() => props.setB2BPartnerManually('partnerIMG', null)}
                  >
                    Bild entfernen
                  </Button>
                </Col>

              </Row>
            }
            
            <h6 className="mt-3">
              Nachfolgende Kategorien dürfen nur ergänzt und nicht gelöscht werden!
              Sonst haben Kunden keinen Zugriff mehr auf die Dokumente in dem gelöschten Bereich!
            </h6>

            <h6>"Dokumente freigeben" - Dokumentenbereich Freigeben</h6>
           
            <Form.Check
              type="checkbox"
              id="kategorienFreigeben"
              label="Dokumentenbereich für Kunden freigeben"
              name="kategorienFreigeben"
              checked={props.data.kategorienFreigeben ? props.data.kategorienFreigeben : false}
              onChange={(e) => props.setCBs(e)}
            />
    
            <Form.Group className="mb-3">
              <Form.Label>Nachfolgende Datei-Kategorien werden im Dateiupload für den Partner und für den Kunden in der App sichtbar</Form.Label>

              <SelectPartnerKategorien
                placeholder={"Wähle eine/mehrere Kategorien aus"}
                setSelectMulti={props.setSelectMulti}
                selectedValues={props.data.kategorien} 
              />
              {/* ? props.data.kategorien.sort((a, b) => a.label > b.label ? 1 : -1) : "" */}
              
              <Button
                className="mt-1"
                variant={'success'}
                onClick={() => setShowModalNeueKategorie(true)}
              >
                Neue Kategorie anlegen
              </Button>
            </Form.Group>



            <hr />

            <h6>"Dateien senden" - Kunden Datei Upload an Partner</h6>
           
            <Form.Check
              type="checkbox"
              id="dateienSenden"
              label="Dateien senden erlauben/freischalten"
              name="dateienSenden"
              checked={props.data.dateienSenden ? props.data.dateienSenden : false}
              onChange={(e) => props.setCBs(e)}
            />

            <Form.Group className="mb-3">
              <SelectPartnerUploadKategorien
                  placeholder={"Wähle eine/mehrere Kategorien aus"}
                  setSelectMulti={props.setSelectMulti}
                  selectedValues={props.data.dateienSendenKategorien} 
              />
              <Button
                className="mt-1"
                variant={'success'}
                onClick={() => setShowModalNeueUploadKategorie(true)}
              >
                Neue "Datei senden" Kategorie anlegen
              </Button>
            </Form.Group>

          </fieldset>


        </Col>


      </Row>

      {showModalNeueKategorie &&
        <CustomModal 
            title={"Neue Kategorie anlegen"}
            visible={showModalNeueKategorie}
            setVisible={setShowModalNeueKategorie}
        >
          <p>Gib einen Bezeichner für die neue Kategorie ein:</p>
          <div className="mb-3">
            <Form.Label>Neue Kategorie</Form.Label>
            <Form.Control
              type="text"
              name="kategorie"
              ref={kategorieRef}
            />
            <Button
              className="mt-1"
              variant={'success'}
              onClick={() => handleSaveNewKategorie(kategorieRef.current.value)}
            >
              Neue Kategorie anlegen
            </Button>

          </div>
          <p className="fw-bold">Vorhandene Kategorien</p>
          <ul>
            {kategorien.map(k => 
               <li key={k.key}>{k.label}</li>
            )}
          </ul>
          
        </CustomModal>
      } 

      {showModalNeueUploadKategorie &&
        <CustomModal 
            title={'Neue "Datei senden" Kategorie anlegen'}
            visible={showModalNeueUploadKategorie}
            setVisible={setShowModalNeueUploadKategorie}
        >
          <p>Gib einen Bezeichner für die neue "Datei senden" Kategorie ein:</p>
          <div className="mb-3">
            <Form.Label>Neue "Datei senden" Kategorie</Form.Label>
            <Form.Control
              type="text"
              name="kategorieUpload"
              ref={kategorieUploadRef}
            />
            <Button
              className="mt-1"
              variant={'success'}
              onClick={() => handleSaveNewKategorieUpload(kategorieUploadRef.current.value)}
            >
              Neue Kategorie anlegen
            </Button>

          </div>
          <p className="fw-bold">Vorhandene "Datei senden" Kategorien</p>
          <ul>
            {kategorienUpload.map(k => 
               <li key={k.key}>{k.label}</li>
            )}
          </ul>
          
        </CustomModal>
      } 

    </Container>
  );
}

export default NeuerPartner


/*
  //const [newImage, setNewImage] = useState(false);

  //const defaultSrc = "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";
  //const [image, setImage] = useState(defaultSrc);
  //const cropperRef = useRef();
  //const [cropImage, setCropImage] = useState(""); // per props, wegen Speichern

  
  //const getCropData = () => {
    //if (cropperRef.current?.cropper !== "undefined") {
      //props.setCropImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      //props.setChanged(true);
      /*getCroppedCanvas([options])
          Properties:
          width: the destination width of the output canvas.
          height: the destination height of the output canvas.
          minWidth: the minimum destination width of the output canvas, the default value is 0.
          minHeight: the minimum destination height of the output canvas, the default value is 0.
          maxWidth: the maximum destination width of the output canvas, the default value is Infinity.
          maxHeight: the maximum destination height of the output canvas, the default value is Infinity.
          fillColor: a color to fill any alpha values in the output canvas, the default value is the transparent.
          imageSmoothingEnabled: set to change if images are smoothed (true, default) or not (false).
          imageSmoothingQuality: set the quality of image smoothing, one of "low" (default), "medium", or "high".
      */
        
        //};
      
      /*
        const onChange = (e) => {
          e.preventDefault();
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(files[0]);
        };
      
      */
      
           {/*
                  
                    props.data.partnerIMG && 
                    <>
                      <Form.Label>Aktuelles Bild in App</Form.Label>
                      <img style={{ width: "100%", float: "left", aspectRatio: "1" }} src={props.data.partnerIMG} alt="cropped" /> 
                      <br />
                      {!props.newImage &&
                        <Button className="mt-3" onClick={() => props.setNewImage(true)}>Neues Bild festlegen</Button>
                      }
                      {props.newImage &&
                        <Button className="mt-3 btn btn-danger" onClick={() => {
                          props.setNewImage(false);
                          props.setCropImage("");
                        }}> Neues Bild verwerfen</Button>
                      }
                    </>  
                    }
                  
                  (!props.data.partnerIMG || props.newImage) && 
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Firmenlogo</Form.Label>
                        <Form.Control
                          id="logo"
                          name="logo"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => {
                              const reader = new FileReader();
                              reader.onload = () => {
                                setImage(reader.result);
                              };
                              reader.readAsDataURL(e.target.files[0]);
                            }}
                        />
                      </Form.Group>
         
                      <Cropper
                        ref={cropperRef}
                        style={{ height: "auto", maxHeight: "800px", maxWidth: "100%" }}
                        zoomTo={0.3}
                        initialAspectRatio={1}
                        aspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={150} // min crop height in pix 
                        minCropBoxWidth={150}  // min crop width in pix 
                        
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                        //frameBorder={true} // ????
                      />
                    
      
                      <div className="w-100 text-center">
                        <Button className="mt-3 btn btn-success" onClick={getCropData}>Bild zuschneiden</Button>
                      </div>
      
                      <div>
      
                        <div className="box" style={{ width: "50%", float: "left" }}>
                          <h6>Vorschau Bildzuschnitt</h6>
                          <div
                            className="img-preview"
                            style={{ width: "100%", float: "left", aspectRatio: "1", border: "2px solid black"}}
                          />
                        </div>
      
                        <div
                          className="box"
                          style={{ width: "50%", float: "right"}}
                        >
                          <h6>Finaler Bildzuschnitt</h6>
                          {!props.cropImage && <p>Zunächst Bild zuschneiden</p>}
                          {props.cropImage && <img style={{ width: "100%", float: "left", aspectRatio: "1", border: "2px solid black"}} src={props.cropImage} alt="cropped" />}
                          
                        </div>
      
                      </div>
                      <br style={{ clear: "both" }} />
                    </>
                          */}
              