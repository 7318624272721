import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Row, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//import Länder from '../Data/LaenderListe';
 
import Swal from 'sweetalert2';
import AuthAxios from "../utils/AuthAxios";


function SelectKundeObj (props) {

    const navigate = useNavigate();

    const [kunden, setKunden] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
  
        async function getKunden () {

            AuthAxios.get('/kunde/suche/'+searchValue)
            .then(res => {

                const kundenList = [];

                res.data.map(ma => {
                    kundenList.push({"value": ma.kdnr, "label": ma.kdnr+" | "+ma.vorname+" "+ma.nachname});
                });
                
                setKunden(kundenList);
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Benutzer konnten nicht geladen werden."
                });
            });
            /*
            setSponsoren([
                { "value": "108112", "label": "108112 | Marius Schulte" },
                { "value": "108113", "label": "108113 | Vitali Weisheim" }
            ])
            */
        }

        // Suche erst starten nach Eingabe von min. 4 Zeichen
        if(searchValue.length >=4) {
            getKunden(); 
        }

    }, [searchValue]);


    useEffect(() => {
        // Warten bis Props geladen sind!
        if(props.selectedValue !== null && props.selectedValue !== undefined) {
            setKunden([{label: props.selectedValue.label, value: props.selectedValue.value}]);
        }
    },[props.selectedValue])

    const handleRemove = () => {
        setKunden([]); // Reset Select List
        props.setSelect("kunde", null); // Update Outer value
    }
  
    const handleChange = (name, selection) => {
        props.setSelect(name, {label: selection.label, value: selection.value}); // Object
    };

    const handleInputChange = (val) => {
        setSearchValue(val);
    };

    const handleNavigation = () => {
        if(props.selectedValue?.value) {
            navigate("/dash/kunde/" + props.selectedValue.value);
        } 
    }


    return (
        <Row>
            
            <Form.Label onClick={handleNavigation}>
                {(props.selectedValue && Object.keys(props.selectedValue).length >= 1) ? 
                    <Link to={"/dash/kunde/"+props.selectedValue.value} style={{color: "#c58164"}}>Verknüpfter Kunde</Link>
                :"Verknüpfter Kunde"
                }
            </Form.Label>
            <Col xs={12}>
                <ReactSelect
                    //onInputChange={() => console.log("typing...")}
                    value={props.selectedValue ? kunden.filter((f) => f.value == props.selectedValue?.value) : null}
                    onChange={(selection, e) => {
                        if (e.action === 'clear') {
                            handleRemove();
                        } else {
                            handleChange(e.name, selection);
                        }
                    }}
                    options={kunden}
                    placeholder={<div className="select-placeholder-text">Suche nach Kdnr, Vorname o. Name</div>}
                    noOptionsMessage={() => "Suche nach Kdnr, Vorname o. Name um Optionen zu erhalten."}
                    //noOptionsMessage={() => "Suche nach KdNr/Name um Optionen zu erhalten."}
                    filterOption={createFilter({ignoreAccents: false})}
                    name="kunde"
                    onInputChange={handleInputChange}
                    isClearable={true}
                />
            </Col>
            {/*<Col xs={1} md={1}>
                {props.selectedValue &&
                    <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                        <FontAwesomeIcon
                            icon={faXmark}
                            size="1x"
                            title="Kunde entfernen"
                        />
                    </Button>
                }
            </Col>*/}
        </Row>
    );
   
    
}

export default SelectKundeObj