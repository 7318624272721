import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from "axios";
import { MainContext } from '../contexts/MainContext';

import './Login.css';

export default function (props) {

  let [authModeLogin, setAuthModeLogin] = useState(true);
  const { jwt, setJwt, user, setUser } = useContext(MainContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [resetMail, setResetMail] = useState('');


  const changeAuthMode = () => {
    setAuthModeLogin(!authModeLogin)
  }


  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setUsername(event.target.value)
        break;
      case 'password':
        setPassword(event.target.value)
        break;
      case 'resetMail':
        setResetMail(event.target.value)
        break;
      default:
        break;
    }
  }


  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const credentials = {
        username,
        password
      }

      let response = await axios.post(`${process.env.REACT_APP_BACKEND}/user/login`, credentials)
    
      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      setJwt(response.data.token);
      setUser(response.data.user);
      navigate('/dash/home');
    } catch (err) {

      if (err.response && err.response.status === 403) 
      {
        alert("Benutzername oder Passwort fehlerhaft.");
      }
      else
      {
        alert("Login zur Zeit nicht möglich. Backend nicht erreichbar.");
      }
    }

  }

  const handleResetPassword = async (event) => {
    event.preventDefault();

    axios.post(`${process.env.REACT_APP_BACKEND}/user/forgot-password`, { email: resetMail} )
    .then(res => {

        Swal.fire({
          icon: "success",
          title: "Password Reset",
          html: "Du erhältst eine E-Mail mit einem Link, um dein Passwort zurückzusetzen."
        });

        setAuthModeLogin(false);
    })
    .catch(err => {
      Swal.fire({
        icon: "info",
        title: "Password Reset",
        html: "Kontrolliere deine Eingabe oder ein Rücksetzen des Passworts ist momentan nicht möglich."
      });
    })
  }

  useEffect(() => {
    if (jwt || jwt !== '') {
      return navigate('/dash')
    }
  }, [jwt, navigate])





  if (authModeLogin) {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title text-center">CRM-Login</h3>
            <div className="form-group mt-3">
              <label>E-Mail</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="E-Mail"
                autoComplete="email"
                name="email"
                autoFocus
                value={username}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Passwort</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Passwort"
                name="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary" onClick={handleLogin}>
                Login
              </button>
            </div>
            <p className="text-center mt-4">
              <a href="#" onClick={changeAuthMode}>Password vergessen?</a>
            </p>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Passwort Reset anfordern</h3>
          <div className="form-group mt-3">
            <label>E-Mail</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="E-Mail"
              name="resetMail"
              value={resetMail}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary" onClick={handleResetPassword}>
              Passwort Reset anfordern
            </button>
          </div>
          <p className="text-center mt-4">
            <a href="#" onClick={changeAuthMode}>Zurück zum Login</a>
          </p>
        </div>
      </form>
    </div>
  )
}