
import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from '../../contexts/MainContext';
import { useParams, useNavigate } from 'react-router-dom';

import "./Kategorien.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCirclePlus, faPlus, faSave, faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import { faPlus, faSave, faCheckCircle, faCheck, faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs, Alert, Row, Col, Container, Button } from 'react-bootstrap';

import Swal from 'sweetalert2'
import AuthAxios from "../../utils/AuthAxios";

import NeueImmo from './NeueImmo';
import ImmoListe from './ImmoListe';

const Immo = () => {

  let { id } = useParams();
  //const { user } = useContext(MainContext);
  const navigate = useNavigate();

  const [immo, setImmo] = useState({
    ansprechpartner: [{
        kdnr: 108000,
        vorname: "Max",
        nachname: "Mustermann"
    },
    {
        kdnr: 108112,
        vorname: "Marius",
        nachname: "Schulte"
    }],

  });




  //const [cropImage, setCropImage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [newImageURL, setNewImageURL] = useState(null);

  const [immoListe, setImmoListe] = useState([]);

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [alertSuccess, setAlertSuccess] = useState("");
  const [alertError, setAlertError] = useState("");

  // Tracking ob es Änderungen am Makler gab
  const [changed, setChanged] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadAll, setReloadAll] = useState(false);

/*
  useEffect(() => {

    AuthAxios.get('/immo')
    .then(res => {
      setImmoListe(res.data);
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Fehler beim Abrufen von Partnern",
        html: "Versuche es später erneut."
      })
    })

    setLoading(false);

  },[activeTab]);
  */


/*
  useEffect(() => {

    async function getImmo(id) {
      AuthAxios.get('/immo/' + id)
      .then(res => {
        //console.log(res.data);
        setImmo(res.data);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: "Fehler",
          html: "Immobilie konnte nicht gefunden werden."
        });
      });
    }
    
    if( getImmo !== undefined && id.length === 24) {
      getImmo(id);
      //alert("id: "+id)
    }
    
  }, [id]);
  */
  

  


  
  const newImmo = () => {
    setImmo({}); // define default values!
    navigate("/dash/immo"); // id entfernen ggfs.
  };


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setImmo({
      ...immo,
      [e.target.name]: e.target.checked
    });
  }


  // React Single Select Zwueisung
  const setSelect = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setImmo({
      ...immo,
      [name]: value
    });
  }


  // React Multi Select Zuweisung
  const setSelectMulti = (name, value) => {

    setChanged(true);
    let newVals = [];

    value?.map(val => {
      newVals.push(val.value)
    })

    setImmo({
      ...immo,
      [name]: newVals
    });

  }
  

  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    const value = event.target.value;
    setImmo({
      ...immo,
      [event.target.name]: value
    });
  }

  const handleInputChangeManually = (name, value) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    setChanged(true);

    setImmo({
      ...immo,
      [name]: value
    });
  }


  const cancelEdit = () => {
    Swal.fire({
      icon: "info",
      title: "Änderungen verwerfen",
      html: "Willst du deine Änderungen wirklich verwerfen?",
      showDenyButton: true,
      confirmButtonText: 'Verwerfen',
      denyButtonText: `Abbrechen`
    })
    .then( async (result) => {
      if (result.isConfirmed) {
        setChanged(false);
        setReload(true);
      } 
    })
  }


  const handleImmoIMG = (files) => {
    
    // Reset wenn löschen
    if(!files)
    {
      setNewImage(null);
      setNewImageURL(null);
    }
    else
    {
      setNewImage(files[0]);
      // Bilder können nicht direkt so dargetellt werden, daher umwandeln!
      setNewImageURL(URL.createObjectURL(files[0]));
    }
  }


  const saveImmo = () => {

    /*
    if (!immo.name && !b2bPartner.telefon && !b2bPartner.email_buchhaltung && !b2bPartner.anschrift && !b2bPartner.plz && !b2bPartner.stadt &&
        (!b2bPartner.image || !b2bPartner.newImage) && !b2bPartner.lizenz && !b2bPartner.lizenzLaufzeit) {
      Swal.fire({
        icon: 'error',
        title: "Pflichtfelder",
        html: "Alle Grunddaten, Firmenlogo (App-Daten)"
      });
      return;
    }
    */
    

    let formData = new FormData();
    
    
    //if (cropImage !== "") {
    //  formData.append('file', cropImage);
    //}
    
    if (newImage !== "") {
      formData.append('file', newImage);
    }

    formData.append('immo', JSON.stringify(immo)); // nur strings, kein JSON...

    const headerConfig = { headers: { 'content-type': 'multipart/form-data' } };

    // Übergeben der Daten als formData!



    AuthAxios.post('/immo', formData, headerConfig)
      .then(res => {
        //console.log(res.data);
        setImmo({
          ...immo,
          _id: res.data._id, //Damit nicht erneut ein Partner erstellt wird
        });

        setAlertSuccess("Immobilie erfolgreich gespeichert.");
        setChanged(false);
      })
      .catch(err => {
        console.log(err);
        setAlertError("Fehler beim Speichern der Immobilie.");
      });
   
    setReloadAll(true);
  }




  return (
    <Container fluid style={{ marginLeft: "0px", marginTop: "40px"}}>

        {/* so ists in Aufgaben hinterlegt 
        <Col xs={12} md={12} className="mainActions" style={{ justifyContent: "space-between" }}>
        */}
        <Container fluid >
            <Row className="mainActions">
                <Col xs={12} sm={2}>
                    <div>
                        <FontAwesomeIcon
                            icon={faPlus}
                            size="2x"
                            className="actionIcon"
                            onClick={newImmo}
                            title="Neue Immobilie anlegen"
                        />

                        {changed && activeTab == 1 &&
                            <>
                            <span style={{paddingLeft: "8px"}}>
                                <FontAwesomeIcon
                                icon={faSave}
                                size="2x"
                                className="actionIcon"
                                onClick={saveImmo}
                                title="Immobilie speichern"
                                />
                            </span>
                            <span style={{paddingLeft: "8px"}}>
                                <FontAwesomeIcon
                                icon={faXmark}
                                size="2x"
                                className="actionIcon"
                                onClick={cancelEdit}
                                title="Änderungen verwerfen"
                                />
                            </span>
                            </>
                        }
                        {!changed &&
                            <FontAwesomeIcon
                            icon={faCheckCircle}
                            size="2x"
                            className="actionIcon"
                            title="Keine Änderung erfolgt"
                            />
                        }
                    </div>
                </Col>
                <Col xs={12} sm={5} className="meldungen">
                    {alertSuccess.length > 0 &&
                        <Col xs={12} md={6}>
                        <Alert key={1} variant={'success'} onClick={() => setAlertSuccess("")}>
                            {alertSuccess}
                        </Alert>
                        </Col>
                    }
                    {alertError.length > 0 &&
                        <Col xs={12} sm={6} md={6}>
                        <Alert key={2} variant={'danger'} onClick={() => setAlertError("")}>
                            {alertError}
                        </Alert>
                        </Col>
                    }
                </Col>
            
                {/* Hier muss noch bei Neuen Datensätzen die "ObjectID" geprüft werden */}
                {id &&
                <Col xs={12} sm={5} md={3} style={{display: "flex", justifyContent: "flex-end"}}> 
                    <Row>
                    {/* <Col style={{width: "100%"}}>
                            <Button
                            style={{width: "100%"}}
                            onClick={handleArchiv}
                            className="mt-2"
                            >
                            Archivieren
                            </Button>
                        </Col>*/}
                        <Col> 
                        <Button
                            style={{width: "auto"}}
                            className="mt-2"
                            onClick={() =>
                                navigate("/dash/aufgabe")
                            }
                        >
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                className="actionIcon"
                                title="Aufgabe anlegen"
                            />
                            Aufgabe
                        </Button>
                        </Col>
                    </Row>
                </Col>
                }

            </Row>
        </Container>

        <div className="mainWork">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(parseInt(key))}
          //defaultActiveKey="partner"
          id="fill-tab-example"
          fill
          style={{display: "inline-flex", width:"auto", marginTop: "0px", padding: "0px"}}
        >
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="1" title="Immo Liste">
            <ImmoListe data={immoListe} loading={loading} setActiveTab={setActiveTab} activeTab={activeTab} />
          </Tab>
            
          <Tab style={{background: "white", position: "relative", paddingTop: "15px"}} eventKey="2" title="Neue Immo">
          {/* cropImage={cropImage} setCropImage={setCropImage} */}
            <NeueImmo data={immo} id={id} newImage={newImageURL} newImmo={newImmo} setActiveTab={setActiveTab} activeTab={activeTab} setImmo={handleInputChange} setImmoManually={handleInputChangeManually} setCBs={setCBs} setSelect={setSelect} setSelectMulti={setSelectMulti} setChanged={setChanged} handleImmoIMG={handleImmoIMG} />
          </Tab>

         

        </Tabs>
      </div>
      
    
  </Container>
  );
}

export default Immo
