import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter } from "react-select";

import { Col, Form } from 'react-bootstrap'


function SelectErreichbar (props) {

    const [selectedValues, setSelectedValues] = useState([]);


    /*
    const handleValue = (selected) => {
        selected.map(s => {
            console.log({value: s, label: s});    
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));       
         })
    }
    */

    useEffect(() => {

        let sel = [];
        //handleValue(props.selectedValue);
        props.selectedValue?.map(s => {

            sel.push({value: s, label: s});
            /*
            setSelectedValues(prevState => ({
                selectedValues: [{value: s, label: s}, ...prevState.selectedValues]
              }));
              */
        });
        setSelectedValues(sel);

    },[props.selectedValue]);


    const [erreichbar, setErreichbar] = useState([
        { label: "morgens", value: "morgens"},
        { label: "mittags", value: "mittags"},
        { label: "abends", value: "abends"}
      ]);
    

    // selectedOption ist wie "event"
    const handleChange = (name, selection) => {

        props.setSelectMulti(name, selection);
    };


    return (
        <Col xs={12} md={12}>
            <Form.Label>Erreichbar (morgens, mittags, abends)</Form.Label>
            <ReactSelect
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedValues}
                onChange={(selection, e) => handleChange(e.name, selection)}
                options={erreichbar}
                placeholder={<div className="select-placeholder-text">Kunde Erreichbar</div>}
                name="erreichbar"
            />
        </Col>
    );
   
    
}

export default SelectErreichbar