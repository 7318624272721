import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import PartnerListeAktiv from "./PartnerListeAktiv";
import PartnerListeInaktiv from "./PartnerListeInaktiv";
import Partner from "./Partner";




function PartnerListe(props) {


  return (
    <>
      <Row className="pb-4">
        <h5 style={{paddingLeft: "30px"}}>Aktive Partner</h5>
        <PartnerListeAktiv data={props} />
      </Row>
      <Row>
        <h5 style={{paddingLeft: "30px"}}>Inaktive Partner</h5>
        <PartnerListeInaktiv data={props} />
      </Row>
    </>
  );

  }
  

export default PartnerListe