import React, {useState} from 'react'
import { Pie } from "react-chartjs-2"
import { useRouteLoaderData } from 'react-router-dom'

function PieChartHerkunft({chartData}) {

  return (
    <Pie
      data={chartData}
      options={{
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 0,
            bottom: 0
          }
        }
      }}
    />
  )

}

export default PieChartHerkunft