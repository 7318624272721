import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

import { deDE, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from '@mui/material/Box';


import TextareaAutosize from 'react-textarea-autosize';

import CardVorfall from "../../Bausteine/Cards/CardVorfall";

import CustomModal from "../../Bausteine/CustomModal"

import AuthAxios from "../../utils/AuthAxios"

function PartnerClearingOffen(props) {

  const navigate = useNavigate();

  const [vorfall, setVorfall] = useState([]);
  const [vorfallOffen, setVorfallOffen] = useState([]);
  //const [vorfallAbgeschlossen, setVorfallAbgeschlossen] = useState([]);

  const [selectedVorfall, setSelectedVorfall] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);


  useEffect(() => {

    AuthAxios.get('/partner/clearing')
    .then(res => {
      setVorfall(res.data)
    })
    .catch(err => {
      console.log(err);
    })
    
    /*
    setVorfall([{
      _id: "23asf68as7gas",
      createdAt: new Date(),
      partner_name: "Testfirma",
      nachricht: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt"
      +"ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat."
      +"Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi."
      +"Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat."
      +"Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis."
      +"At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur",
      kdnr: "108111",
      vorname: "Max",
      nachname: "Mustermann"
    }, {
      _id: "23asf68as7gas1",
      createdAt: new Date(),
      partner_name: "Testfirma 2",
      nachricht: "Die Testfirma hat meine Dokumente gelöscht.",
      kdnr: "108112",
      vorname: "Martha",
      nachname: "Hans"
    }])*/
  
  }, [])


  useEffect(() => {
    /*
      //setVorfallAbgeschlossen(vorfall.filter(v => f.status === ("abgeschlossen-negativ" || "abgeschlossen-positiv"))
      setVorfallOffen(vorfall.filter(v => f.status !== ("abgeschlossen-negativ" || "abgeschlossen-positiv"))
    */

  },[vorfall])
  

  
{/*
  return (
    <>
    <Row className="m-3">
      <Col sm={12} md={6} lg={4}>
        <Form.Label>Suche nach Partner/Kunde</Form.Label>
        <Form.Control
          type="text"
          placeholder={"Suche nach Partner oder Kunde"}
          onChange={() => {}}
        />
        </Col>
    </Row>
    <Row className="pb-4 m-2">

        {vorfall && vorfall.map(v => 
          <Col key={v.id} xs={12} style={{margin: "0px 10px"}}>
            <CardVorfall data={v} />
          </Col>
        )}
      </Row>
    </>
  );
  */}

  {/*
  useEffect(() => {
    setVorfall(props.data.data.filter((f) => f.status !== false))
  }, [props])
*/}

  const handleNavigation = (e) => {

    setShowEditModal(true);
    //alert(e.id);
    setSelectedVorfall(vorfall.find((v) => v._id == e.id));
    //console.log(vorfall.find((v) => v._id == e.id));

    //props.data.setActiveTab(1);
    //navigate("/dash/partner/" + e.id);
  }

  const handleEditSave = () => {
    alert("Speichern implementieren")
  }
  


  const columns = [
    {
      field: "partner_name",
      headerName: "Partner",
      minWidth: 60,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "kdnr",
      headerName: "Kdnr",
      minWidth: 60,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vorname",
      headerName: "Vorname",
      minWidth: 60,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachname",
      headerName: "Nachname",
      minWidth: 60,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nachricht",
      headerName: "Nachricht",
      minWidth: 700,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <span>{new Date(params.row.createdAt).toLocaleString("de-De")}</span>
      ),
      headerClassName: "super-app-theme--header",
    }
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}
        </Row>
      </GridToolbarContainer>
    );

  }

  return (

    <div style={{ height: `90%`, width: "100%", marginBottom: "30px" }}>
      <h5 style={{paddingLeft: "10px"}}>Offene Vorfälle</h5>
      {props.activeTab === 4 && 
        <Box
          sx={{
            height: `calc(100%)`,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#c58164',
            },
            '& .MuiDataGrid-toolbarContainer': {
              padding: '10px'
            },
            margin: "10px 0px 0px 0px",
            fontWeight: 'bold',
            boxShadow: 0,
          }}
        >

          <StripedDataGrid
            autoHeight
            density={'compact'}
            rows={vorfall}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleNavigation}
            pageSize={20}
            rowsPerPageOptions={[20]}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            //components={{ Toolbar: GridToolbar }}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 100 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
            sx={{/*
              marginTop: "20px",
              boxShadow: 2,
              border: "2px solid black",
              padding: "5px 10px",
              borderColor: 'primary.dark',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              bgcolor: 'background.paper'
              */
            }}
          />
        </Box>
      }

    {Object.keys(selectedVorfall).length > 0 &&
        <CustomModal 
            title={"Vorfall bearbeiten - Partner: "+ selectedVorfall.partner_name +" | "+ selectedVorfall.vorname+" "+selectedVorfall.nachname+ " | "+new Intl.DateTimeFormat('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                /*hour: 'numeric',
                minute: 'numeric'*/
              }).format(new Date(selectedVorfall.createdAt))
            }
            visible={showEditModal}
            setVisible={setShowEditModal}
            handleSave={handleEditSave}
        >
        <span>Zum Kunden: </span> <Link to={"/dash/kunde/"+selectedVorfall.kdnr} style={{color: "#c58164"}}>{selectedVorfall.vorname + " " + selectedVorfall.nachname}</Link>

          <TextareaAutosize
            defaultValue={selectedVorfall.nachricht ? selectedVorfall.nachricht : "keine Nachricht übermittelt"}
            style={{width: "100%", border: "none", borderRadius: "8px"}}
            className="readOnly"
            readOnly
          />

          
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Select>
              <option value="nicht bearbeitet">nicht bearbeitet</option>
              <option value="in Klärung">in Klärung</option>
              <option value="abgeschlossen-negativ">abgeschlossen - zum Nachteil für Partner</option>
              <option value="abgeschlossen-positiv">abgeschlossen - kein Nachteil für  Partner</option>
            </Form.Select>
          </Form.Group>

      
          <Form.Group className="mb-3">
            <Form.Label>Interne Notiz</Form.Label>
            <Form.Control as="textarea" rows={4}
              placeholder="Interne Notiz"
              name="notiz"
              value={selectedVorfall.notiz ? selectedVorfall.notiz : ""}
              onChange={(e) => setSelectedVorfall({...selectedVorfall, notiz: e.target.value})}
            />
          </Form.Group>

        </CustomModal>
      } 

    </div>



  );

  }
  

export default PartnerClearingOffen