import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import MitarbeiterListeAktiv from "./MitarbeiterListeAktiv";
import MitarbeiterListeInaktiv from "./MitarbeiterListeInaktiv";




function MitarbeiterListe(props) {

  

  return (
    <>

      <Row className="mb-3" style={{paddingLeft: "10px"}}>
        <Col>
          <Button onClick={() => props.setActiveTab(2)}>Neuen Mitarbeiter anlegen</Button>
        </Col>
      </Row>

      <Row className="pb-4">


        <h5 style={{paddingLeft: "30px"}}>Aktive Mitarbeiter</h5>
        <MitarbeiterListeAktiv data={props} />
      </Row>
      <Row>
        <h5 style={{paddingLeft: "30px"}}>Inaktive Mitarbeiter - kein Login möglich</h5>
        <MitarbeiterListeInaktiv data={props} />
      </Row>
      
  
    </>
  );

  }
  

export default MitarbeiterListe