import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import { COLORS } from '../../constants';

import moment from 'moment';



function NeuerAffiliate(props) {


  // CB einzeln auswerten, da nicht e.target.value, sondern e.target.checked!
  const setCBs = (e) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!    
    props.setChanged(true);
    props.setAffiliate({
      ...props.affiliate,
      [e.target.name]: e.target.checked
    });
  }


  const handleInputChange = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);

    const value = event.target.value;
    props.setAffiliate({
      ...props.affiliate,
      [event.target.name]: value
    }); 
  }

  const handleInputChangeTrim = (event) => {

    // Wenn das erste mal verändert, Speicherdiskette einblenden!!
    props.setChanged(true);
    const value = event.target.value;
    props.setAffiliate({
      ...props.affiliate,
      [event.target.name]: value.trim()
    });
  }


  const setStatus = (status) => {
    props.setChanged(true);
    props.setAffiliate({
        ...props.vorteil,
        ["status"]: status
    });
  }


  // ===== Clear File Picker Logo "Value" =====
   const [fileKey, setFileKey] = useState(Date.now());
   // Function to clear the file input
   const clearFileInput = () => {
       setFileKey(Date.now());
       if (props.handleLogoClear) {
           props.handleLogoClear();
       }
   };
   useEffect(() => {
    if(!props.newImage) {
      clearFileInput();
    }
   }, [props.newImage])




  return (
    <Container fluid >
      <Row>
        <Col xs={12} md={6} lg={3}>

        <fieldset
          className="border"
          style={{ background: "white", borderRadius: "8px" }}
        >
            <legend
                className="float-none w-auto p-2"
                style={{
                    color: COLORS.primCol,
                    background: COLORS.primBG,
                    borderRadius: "8px",
                    fontSize: "12px",
                }}
                >
                Art
            </legend>


            <h6 className="mb-2">Freischaltungen</h6>
            <Form.Check
              type="checkbox"
              id="appStatus"
              label="In App sichtbar"
              name="appStatus"
              checked={props.affiliate.appStatus ? true : false}
              onChange={(e) => setCBs(e)}
            />

            <hr />

            <Form.Label>Eintragsdatum</Form.Label>
            <Form.Control
                className="readOnly"
                type="text"
                name="createdAt"
                value={props.affiliate.createdAt ?
                  moment(props.affiliate.createdAt).format('DD.MM.YYYY')
                  : "-wird beim Speichern vergeben-"}
                onChange={()=>{}}
            />

            <Form.Label>Vorteilspartner Name</Form.Label>
            <Form.Control
                type="text"
                name="name"
                placeholder={"Name des Partners"}
                value={props.affiliate.name ? props.affiliate.name : "" }
                onChange={(e) => handleInputChange(e)}
            />
  
            {/*
            <Form.Label>Kategorie</Form.Label>
            <Form.Select
                aria-label="Default select"
                name="kategorie"
                value={props.affiliate.kategorie ? props.affiliate.kategorie : "In Kürze verfügbar"}
                onChange={(e) => handleInputChange(e)}
            > 
                <option value="In Kürze verfügbar">In Kürze verfügbar</option>
                <option value="Beauty">Beauty</option>
                <option value="Bildung">Bildung</option>
                <option value="Body & Soul">Body & Soul</option>
                <option value="Coaching">Coaching</option>
                <option value="Ernährung ">Ernährung</option>
                <option value="Fashion">Fashion</option>
                <option value="Freizeit">Freizeit</option>
                <option value="Gastronomie">Gastronomie</option>
                <option value="Gesundheit">Gesundheit</option>
                <option value="Haushalt">Haushalt</option>
                <option value="Marketing">Marketing</option>
                <option value="Mobilität">Mobilität</option>
                <option value="Reisen">Reisen</option>
                <option value="Spirituosen">Spirituosen</option>
                <option value="Wohnen">Wohnen</option>
                <option value="Andere">Andere</option>
               
            </Form.Select>
            */}


            
            <Form.Label>Webseite/Shop-Link - https://example.de</Form.Label>
            <Form.Control
                type="url"
                name="webseite"
                placeholder={"Webseite"}
                value={props.affiliate.webseite ? props.affiliate.webseite : "" }
                onChange={(e) => handleInputChangeTrim(e)}
            />
          
          </fieldset>
        </Col>

        <Col xs={12} md={6} lg={3}>

            <fieldset
            className="border"
            style={{ background: "white", borderRadius: "8px" }}
            >
                <legend
                    className="float-none w-auto p-2"
                    style={{
                        color: COLORS.primCol,
                        background: COLORS.primBG,
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                    >
                    Vorteile
                </legend>
              
              
                <h5>Details (Optional)</h5>


                <Form.Label>Vorteilscode</Form.Label>
                <Form.Control
                    type="text"
                    name="vorteilscode"
                    placeholder={"Vorteilscode"}
                    value={props.affiliate.vorteilscode ? props.affiliate.vorteilscode : "" }
                    onChange={(e) => handleInputChange(e)}
                />

                <Form.Label>Welchen Vorteil erhält der Kunde?< br/>(z.B. 10% auf alle Artikel)</Form.Label>
                <Form.Control
                    type="text"
                    name="vorteilOnline"
                    placeholder={"Kurzbeschr. des Vorteils"}
                    value={props.affiliate.vorteilOnline ? props.affiliate.vorteilOnline : "" }
                    onChange={(e) => handleInputChange(e)}
                />

        
            
            </fieldset>
          </Col>



          <Col xs={12} md={6} lg={3}>

            <fieldset
              className="border"
              style={{ background: "white", borderRadius: "8px" }}
            >
              <legend
                  className="float-none w-auto p-2"
                  style={{
                      color: COLORS.primCol,
                      background: COLORS.primBG,
                      borderRadius: "8px",
                      fontSize: "12px",
                  }}
                  >
                  Medien App
              </legend>


                {/* ==================== LOGO ==================== */} 

                {!props.affiliate.image &&
                          <Form.Group className="mb-3">
                            <Form.Label>Neues Firmenlogo (.png .jpg)</Form.Label>
                            <Form.Control
                              key={fileKey}
                              id="image"
                              name="image"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => props.handleLogo(e.target.files)}
                            />
                          </Form.Group>
                        }
                        {props.affiliate.partnerLogo150 &&
                          <div className="text-18">
                            Durch Auswählen eines neuen Logos (und speichern) kann das bestehende Logo ersetzt werden.
                          </div>
                        }


                        {props.newImage &&
                          <Row className="mb-3">
                            <Col xs={12}>
                              <p><span className="fw-bold">Vorschau Logobild</span> (grauer Hintergr. = transparent)</p>
                              <img
                                style={{
                                  height: "150px",
                                  maxHeight: "200px",
                                  maxWidth: "100%",
                                  objectFit: "contain",
                                  aspectRatio: "1",
                                  border: "1px solid black",
                                  background: 'rgba(150, 150, 150, 0.8)',
                                }}
                                src={props.newImage}
                              />
                            </Col>

                            <Col xs={12} className="mt-3">
                              <Button
                                variant={'danger'}
                                onClick={() => props.handleLogo(null)} 
                              >
                                Neues Bild verwerfen
                              </Button>
                            </Col>

                          </Row>
                        }

                        {props.affiliate.partnerLogo150 &&
                          <Row>
                          
                            <Col xs={12}>
                              <p className="fw-bold">Aktuelles Logo</p>
                              <img
                                style={{
                                  width: "150px",
                                  height: "auto",
                                  border: "1px solid black",
                                  //background: 'lightgray',
                                }}
                                src={props.affiliate.partnerLogo150}
                              />
                            </Col>

                            {/* Logo löschen?
                            <Col xs={12} className="mt-3">
                              <Button
                                variant={'danger'}
                                onClick={() => props.handleInputChange('partnerLogo', null)}
                              >
                                Bild entfernen
                              </Button>
                            </Col>
                            */}

                          </Row>
                        }

                {/*
                  <hr />
      
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Beschreibung des Affiliate</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={8}
                      placeholder="Beschreibung"
                      name="beschreibung"
                      value={props.affiliate.beschreibung ? props.affiliate.beschreibung : ""}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </Form.Group>
                */}

                  
              
            </fieldset>
          </Col>
    

      </Row>

    </Container>
  );
}

export default NeuerAffiliate