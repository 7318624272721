import React, {useState, useEffect} from "react";
import ReactSelect, { createFilter }  from "react-select";

import { Button, Col, Row, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//import Länder from '../Data/LaenderListe';

import AuthAxios from "../utils/AuthAxios";
import Swal from 'sweetalert2'

function SelectMitarbeiter (props) {

    const [mitarbeiter, setMitarbeiter] = useState([]);
    const [mitarbeiterActive, setMitarbeiterActive] = useState([]);

    useEffect(() => {
      
        async function getMitarbeiter () {
            try {
                const res = await AuthAxios.get('/user/select');

                const activeMitarbeiterList = [];
                let aktiveMitarbeiter = res.data.filter((ma) => ma.loginGesperrt !== true);
                aktiveMitarbeiter.map(ma => {
                    activeMitarbeiterList.push({"value": ma.mitarbeiterId, "label": ma.mitarbeiterId+" | "+ma.vorname+" "+ma.nachname});
                });
                setMitarbeiterActive(activeMitarbeiterList);

                const mitarbeiterList = [];
                res.data.map(ma => {
                    mitarbeiterList.push({"value": ma.mitarbeiterId, "label": ma.mitarbeiterId+" | "+ma.vorname+" "+ma.nachname});
                });
                setMitarbeiter(mitarbeiterList);

                //return mitarbeiterList; // nur bei Combi select
            }
            catch(err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Mitarbeiter konnten nicht geladen werden."
                });
            }
        }

        getMitarbeiter();


        // Wenn Gruppen auch mit im Select stehen sollen als Combi Select
        /*

        async function getGruppen () {
        
            try {
                const res = await AuthAxios.get('/group');

                const gruppenListe = [];
                res.data.map(g => {
                    gruppenListe.push({"value": "G"+g.groupId, "label": "G"+g.groupId+" | "+g.gruppenname});
                });
                return gruppenListe;
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: "Fehler",
                    html: "Gruppen konnten nicht geladen werden."
                });
            }               
        }
        

        async function getAllMitarbeiter () {
            let mitarbeiterListe = await getMitarbeiter();
            let gruppenListe = await getGruppen();

            setMitarbeiter([...mitarbeiterListe, ...gruppenListe]);
        }
        getAllMitarbeiter();
        */
  
    }, []);


    useEffect(() => {
        // Warten bis Props geladen sind!
        if(props.selectedValue !== null && props.selectedValue !== undefined) {
            setMitarbeiter([{label: props.selectedValue.label, value: props.selectedValue.value}]);
        }
    },[props.selectedValue])

    const handleRemove = () => {
        props.setSelect("mitarbeiter", null); // Update Outer value
    }

    const handleChange = (name, selection) => {
        props.setSelect(name, {label: selection.label, value: selection.value}); // Object
    };


    return (
        <Row>
            {!props.hideLabel && <Form.Label>Bearbeiter</Form.Label>}
                <Col xs={12}>
                    <ReactSelect
                        value={props.selectedValue ? mitarbeiter.filter((f) => f.value == props.selectedValue?.value) : null}
                        onChange={
                            (selection, e) => {
                            if (e.action === 'clear') {
                                handleRemove();
                            } else {
                                handleChange(e.name, selection);
                            }
                        }}
                        placeholder={<div className="select-placeholder-text">Suche nach Mitarbeiter</div>}
                        options={mitarbeiterActive}
                        noOptionsMessage={() => "Suche nach Mitarbeiter um Optionen zu erhalten."}
                        filterOption={createFilter({ignoreAccents: false})}
                        name="mitarbeiter"
                        //onInputChange={handleInputChange}
                        isClearable={true}
                    />
                </Col>
            {/*<Col xs={1} md={1}>
            {selected !== "" &&
                <Button style={{maxWidth: "40px", aspectRatio: "1"}} onClick={handleRemove}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="1x"
                        title="Kunde entfernen"
                    />
                </Button>
            }
            </Col>*/}
        </Row>
    );
      
}

export default SelectMitarbeiter