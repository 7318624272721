import React, { useEffect, useState } from "react";
//import Input from "../../Input/Input";

import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button, NavItem } from "react-bootstrap";


import { deDE, GridToolbar, gridClasses, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton } from '@mui/x-data-grid';
import StripedDataGrid from "../../Bausteine/StripedDataGrid";
import Box from "@mui/material/Box";

import Swal from "sweetalert2";
import AuthAxios from "../../utils/AuthAxios";

function InterneDateiablage(props) {


  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const dateOptionDay = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  const [dateien, setDateien] = useState([]);
  /*
    {
      id: "asdfs65d4f7",
      created: new Date(),
      title: "123456789012345678901324567890132456Testdokument.png",
      bearbeiter: "Marius Schulte",
      gelesen: true,
      category: "Versicherung",
      categoryTwo: "Gesundheit",
      categoryThree: "Gesetzlich",
      gelesenAm: new Date(),
      customerAction: true,
      customerActionType: "Unterschrift",
      bestaetigt: new Date(),
      url: "https://vin1.eu/wp-content/uploads/2022/08/vin1-energie-solarrocker-1024x576.jpg"
    },
    {
      id: "bsdfs65d4f7",
      created: new Date(),
      title: "123456789012345678901324567890132456Testdokument.png",
      bearbeiter: "Marius Schulte",
      gelesen: true,
      category: "Versicherung",
      categoryTwo: "Gesundheit",
      categoryThree: "Gesetzlich",
      gelesenAm: new Date(),
      customerAction: true,
      customerActionType: "Bestätigung",
      bestaetigt: new Date(),
      url: "https://vin1.eu/wp-content/uploads/2022/08/vin1-energie-solarrocker-1024x576.jpg"
    },
    {
      id: "csdfs65d4fa",
      created: new Date(),
      title: "123456789012345678901324567890132456Testdokument mit Bestätigung.jpg",
      bearbeiter: "Marius Schulte",
      gelesen: true,
      category: "Versicherung",
      categoryTwo: "Gesundheit",
      categoryThree: "Gesetzlich",
      gelesenAm: new Date(),
      url: "https://vin1.eu/wp-content/uploads/2022/08/vin1-energie-solarrocker-1024x576.jpg"
    }, 
  ]);
  */

  const [loading, setLoading] = useState(true);




  async function getDateienListe() {
    AuthAxios.post("/dokumente/interne-datei-liste/", {
        kdnr: props.data.kdnr
      })
      .then((res) => {
        console.log(res.data);
        setDateien(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Datei-Liste für Kunde " + props.data.kdnr + " konnten nicht geladen werden.",
        });

      });
  }

  //Dateien für Kunden nur laden wenn der Tab geladen wird
  useEffect(() => {
    if (props.activeTab === 4) {
      getDateienListe();
    }
  }, [props.activeTab]);




  const handleFile = (datarow) => {
    //console.log(datarow.row.url);
    if(datarow.row.url) {
      window.open(datarow.row.url, '_blank');
    }
    else {
      Swal.fire({
        icon: "info",
        title: "Datei",
        html: "Datei Link fehlt."
      })
    }
   
  }




  const columns = [
    {
      field: "created",
      headerName: "Erstellt",
      minWidth: 130,
      renderCell: (params) => (
        <span>{params.row.createdAt &&
          new Date(params.row.createdAt).toLocaleString("de-DE", dateOptions)}
        </span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "title",
      headerName: "Datei",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <span>{params.row.title.substring(36)}</span>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "type",
      headerName: "Typ",
      minWidth: 160,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Row style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Col xs={12} lg={2}>
            <GridToolbarQuickFilter />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarFilterButton />
          </Col>
          <Col xs={6} lg={1}>
            <GridToolbarColumnsButton />
          </Col>
          {/*
          <Col xs={6} lg={1}>
            <GridToolbarExport />
          </Col>
          */}

          {/*selectedRows.length >= 1 &&
            <Col xs={6} lg={1}>
              <Button onClick={handleArchive} >Archivieren</Button>
            </Col>
        */}

        </Row>

      </GridToolbarContainer>
    );
  }



  return (
    <div style={{ width: "100%" }}>
      <>
        {props.activeTab === 4 && (
          <>
            <Box
              sx={{
                height: `calc(100vh - 200px)`,
                width: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: '#c58164',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  padding: '10px'
                },
                margin: "0px 0px 0px 0px",
                fontWeight: 'bold',
                boxShadow: 0,
              }}
            >
              <StripedDataGrid
                //loading={loading}
                rows={dateien}
                columns={columns}
                getRowId={(row) => row._id}
                onRowClick={handleFile}
                //pageSize={30}
                //rowsPerPageOptions={[30]}
                autoPageSize
                density={"compact"}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 100 },
                  },
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'created', sort: 'desc' }],
                  },
                }}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.dark',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  bgcolor: 'background.paper'
                }}
              />
            </Box>

          </>
        )}

      </>}

    </div>

  );
}

export default InterneDateiablage;
