import "./MessagePreview.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faPhone } from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as LinkArrow} from './LinkArrow.svg';

function MessagePreview({ msg, time, isLink, img, sent, BtnCTA, BtnPhone, BtnSchnellantwort }) {
  return (
    // Message container
    <div
      className={"flex justify-end rounded-md h-auto w-fit p-2 my-1 bg-[#005c4b] mr-1 ml-[50px] w-[230px]"}
    >
      {/* Image message */}
      {img ? (
        <div className="relative w-100 pt-2">
          {/* Image */}
          {/*<img
            src={img}s
            alt="img_message"
            className="rounded-md max-h-[250px] w-100"
          />*/}

          {/* Optimales Format: 1125*600px */}
          <div
            /*className="relative h-screen max-h-[250px] overflow-hidden"*/
          >
  
            <img
              src={img}
              alt="img_message"
              /*className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"*/
              style={{
                aspectRatio: "1.875",
                maxHeight: "200px",
                objectFit: "cover"
              }}
            />
          </div>


          <p className="text-white text-sm mr-1 min-w-full pt-2 pl-1">{msg}</p>
          
          {BtnSchnellantwort &&
            <div className="text-center m-0 p-0">
              <hr className="my-2"/>
              <div className="cursor-pointer">
                <LinkArrow
                  fill={"white"}
                  className="inline"
                  width="15px"
                />
                <span className="ml-2 mt-0 text-white text-sm">{BtnSchnellantwort}</span>
              </div>
            </div>
          }

          {BtnCTA &&
            <div className="text-center m-0 p-0">
              <hr className="my-2"/>
              <div className="cursor-pointer">
                <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    size="xs"
                    title="Btn"
                    color="white"
                />
                <span className="ml-2 mt-0 text-white text-sm">{BtnCTA}</span>
              </div>
            </div>
          }

          {BtnPhone &&
            <div className="text-center m-0 p-0" >
              <hr className="my-2"/>
              <div className="cursor-pointer">
                <FontAwesomeIcon
                    icon={faPhone}
                    size="xs"
                    title="Btn"
                    color="white"
                />
                <span className="ml-2 mt-0 text-white text-sm">{BtnPhone}</span>
              </div>
            </div>
          }
          
          {/* Time */}
          <p className="m-0 text-end text-[#8796a1] text-[10px] min-w-[50px]">{time}</p>
        </div>
      ) : (
        // Text (link/normal) message
        <div
          className="flex flex-col justify-between items-end max-w-[410px] p-2"
          style={{ wordBreak: "break-word" }}
        >
          {/* Link */}
          {isLink ? (
            <a
              href={msg}
              target="blank"
              className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline pl-1 m-1"
            >
              {msg}
            </a>
          ) : (
            // Normal text
            <p className="text-white text-sm mr-1 min-w-full pl-1">{msg}</p>
          )}


          <div className="relative w-100 pt-2">
            {BtnSchnellantwort &&
              <div className="text-center m-0 p-0">
                <hr className="my-2"/>
                <div className="cursor-pointer">
                  <LinkArrow
                    fill={"white"}
                    className="inline"
                    width="15px"
                  />
                  <span className="ml-2 mt-0 text-white text-sm">{BtnSchnellantwort}</span>
                </div>
              </div>
            }

            {BtnCTA &&
              <div className="text-center m-0 p-0">
                <hr className="my-2"/>
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      size="xs"
                      title="Btn"
                      color="white"
                  />
                  <span className="ml-2 mt-0 text-white text-sm">{BtnCTA}</span>
                </div>
              </div>
            }

            {BtnPhone &&
              <div className="text-center m-0 p-0" >
                <hr className="my-2"/>
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                      icon={faPhone}
                      size="xs"
                      title="Btn"
                      color="white"
                  />
                  <span className="ml-2 mt-0 text-white text-sm">{BtnPhone}</span>
                </div>
              </div>
            }
          </div>


          <p className="m-0 text-end text-[#8796a1] text-[10px] min-w-[50px]">{time}</p>

        </div>
      )}
    </div>
  );
}

export default MessagePreview;